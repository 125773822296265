import {CommonDataService} from "../../services/common.service";
import {UtilityService} from "../../services/utility.service";
import { GET_EXCHANGERATES, GET_CURRENCY, GET_LANGUAGE } from "../types/commonTypes";
import {SET_HOTELRES, SET_OGHOTELRES, SET_HOTEL_RMDETAILS_RES} from "../types/hotelTypes";
import {SET_OGTOURRES, SET_TOURRES, SET_TOUROPTRES} from "../types/tourTypes";
import {SET_OGTRNSFRRES, SET_TRNSFRRES} from "../types/transferTypes";
import store from '../store';
const _ = require("lodash"); 

export const getExchangeRates = () => {
  return async (dispatch) => {
      try {
          const response = await CommonDataService.getExchangeRatesData();
          let exchangeRates = _.mapValues(_.keyBy(response, 'currencyCode'), 'exchangeRate')
          dispatch({ type: GET_EXCHANGERATES, payload: exchangeRates })
      } catch (err) {
          console.log("getExchangeRates Error", err);
      }
  }
}

export const getSelectedCur = (obj) => {
    //console.log("a000", store.getState())
    window.localStorage.setItem('selectedCur', obj.curValue)
    if (obj.page === "/hotelListing") {
        let htlResOrgObj = store.getState().hotelList.htlResOrgObj
        let htlResObj = store.getState().hotelList.htlResObj
        let rootCurrency =  obj.curValue
        let rootExchangeRate =  store.getState().exchangeRate.exchangerates

        UtilityService.conHtlListSelectedCur(htlResOrgObj, rootCurrency, rootExchangeRate)
        UtilityService.conHtlListSelectedCur(htlResObj, rootCurrency, rootExchangeRate)
        return async (dispatch) => {
            try {
                dispatch({ type: GET_CURRENCY, payload: obj.curValue })
                dispatch({ type: SET_OGHOTELRES, payload: htlResOrgObj })
                dispatch({ type: SET_HOTELRES, payload: htlResObj })
            }  catch (err) {
                console.log("Get SelectedCur Error hotelListing", err);
            }
        }
    }
    else if (obj.page === "/hotelDetails") {
        let htlRmDtlObj = store.getState().hotelRmDetails.htlRmDtlObj
        let rootCurrency =  obj.curValue
        let rootExchangeRate =  store.getState().exchangeRate.exchangerates

        UtilityService.conHtlRmDtlsSelectedCur(htlRmDtlObj, rootCurrency, rootExchangeRate)
        return async (dispatch) => {
            try {
                dispatch({ type: GET_CURRENCY, payload: obj.curValue })
                dispatch({ type: SET_HOTEL_RMDETAILS_RES, payload: htlRmDtlObj })
                sessionStorage.setItem("hotelStore", JSON.stringify({"hotelRmDetails": htlRmDtlObj}))
            }  catch (err) {
                console.log("Get SelectedCur Error hotelDetails", err);
            }
        }
    }

    else if (obj.page === "/tourListing") {
        let tourResOrgObj = store.getState().tourList.tourResOrgObj
        let tourResObj = store.getState().tourList.tourResObj
        let rootCurrency =  obj.curValue
        let rootExchangeRate =  store.getState().exchangeRate.exchangerates

        UtilityService.tourConvBaseToSelectedCur(tourResOrgObj, rootCurrency, rootExchangeRate)
        UtilityService.tourConvBaseToSelectedCur(tourResObj, rootCurrency, rootExchangeRate)
        return async (dispatch) => {
            try {
                dispatch({ type: GET_CURRENCY, payload: obj.curValue })
                dispatch({ type: SET_OGTOURRES, payload: tourResOrgObj })
                dispatch({ type: SET_TOURRES, payload: tourResObj })
            }  catch (err) {
                console.log("Get SelectedCur Error tourListing", err);
            }
        }
    }

    else if (obj.page === "/tourDetails") {
        let tourOptionsObj = store.getState().tourOptions.tourOptObj
        let rootCurrency =  obj.curValue
        let rootExchangeRate =  store.getState().exchangeRate.exchangerates

        UtilityService.tourOptionConvBaseToSelectedCur(tourOptionsObj, rootCurrency, rootExchangeRate)
        return async (dispatch) => {
            try {
                dispatch({ type: GET_CURRENCY, payload: obj.curValue })
                dispatch({ type: SET_TOUROPTRES, payload: tourOptionsObj })
            }  catch (err) {
                console.log("Get SelectedCur Error tourDetails", err);
            }
        }
    }

    else if (obj.page === "/transferListing") {
        let transferResOrgObj = store.getState().transferList.transferResOrgObj
        let transferResObj = store.getState().transferList.transferResObj
        let rootCurrency =  obj.curValue
        let rootExchangeRate =  store.getState().exchangeRate.exchangerates

        UtilityService.transferConvBaseToSelectedCur(transferResOrgObj, rootCurrency, rootExchangeRate)
        UtilityService.transferConvBaseToSelectedCur(transferResObj, rootCurrency, rootExchangeRate)
        return async (dispatch) => {
            try {
                dispatch({ type: GET_CURRENCY, payload: obj.curValue })
                dispatch({ type: SET_OGTRNSFRRES, payload: transferResOrgObj })
                dispatch({ type: SET_TRNSFRRES, payload: transferResObj })
            }  catch (err) {
                console.log("Get SelectedCur Error transferListing", err);
            }
        }
    }

    else{
        return async (dispatch) => {
            try {
                dispatch({ type: GET_CURRENCY, payload: obj.curValue })
            }  catch (err) {
                console.log("Get SelectedCur Error", err);
            }
        }
    }
}

export const getActiveLang = (language) => {
    window.localStorage.setItem('currentLang', language)
    return {
        type: GET_LANGUAGE,
        payload: language,
    };
}

// export const getSelectedCur = (currency) => dispatch => {
//     //const id = uuid();
//     dispatch({type: SET_ALERT, payload: {message, type, id}});
    
// }

//export const getSelectedCur = () => {
    //return 
    // return async (dispatch) => {
    //     try {
    //         const response = await CommonDataService.getExchangeRatesData();
    //         dispatch({ type: GET_EXCHANGERATES, payload: response.data })
    //     } catch (err) {
    //         console.log("getExchangeRates Error", err);
    //     }
    // }
  //}