import {
  RESET_TRNSFR_RES, SET_TRNSFRRES, SET_OGTRNSFRRES, 
  RESET_TRNSFR_FILTER, SET_TRNSFRFILTER, SET_TRNSFRFILTERSORT
  } from "../types/transferTypes";
  
const intialState = {
  transferResObj:null,
  transferResOrgObj:null,
  transferFltr: {
    priceFilter: []
  },
  transferFilterSort : {
    srtVal: '0',
    srchTxt: ''
  },
};

export const transferListResultReducer = (state = intialState.transferResObj, { type, payload }) => {
  switch (type) {
    case RESET_TRNSFR_RES:
      return { ...state, transferResObj: null, transferResOrgObj: null };
    case SET_OGTRNSFRRES:
      return { ...state, transferResOrgObj: payload };
    case SET_TRNSFRRES:
      return { ...state, transferResObj: payload };
    
    default:
      return state;
  }
};

export const transferFltrReducer = (state = intialState.transferFltr, { type, payload }) => {
  switch (type) {
    case RESET_TRNSFR_FILTER:
      return { ...state, transferFltr:{priceFilter: []}, transferFilterSort:{srtVal: '0',srchTxt: ''} };
    case SET_TRNSFRFILTER:
      return {...state, transferFltr: payload}  
    default:
      return state;
  }
};

export const transferFltrSortReducer = (state = intialState.transferFilterSort, { type, payload }) => {
  switch (type) {
    case RESET_TRNSFR_FILTER:
      return { ...state, transferFltr:{priceFilter: []}, transferFilterSort:{srtVal: '0',srchTxt: ''} };
    case SET_TRNSFRFILTERSORT:
      return {...state, transferFilterSort: payload}  
    default:
      return state;
  }
};