import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Form, Card, Button, Collapse, Accordion} from 'react-bootstrap';
import {doFilterSort} from "../../store/actions/hotel";
import ReactSlider from 'react-slider';

const HotelFilter = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getCurrency = useSelector((state) => state.currency);
  const [filterCollapse, setFilterCollapse] = useState(true);
  useEffect(() => {
    let w = window.innerWidth;
    if (w < 960) {
      setFilterCollapse(false)
    }
  }, []);

  useEffect(() => {
    setFilterCollapse(props.filterChoose)
  }, [props]);
 
  const OrgHtlResult = useSelector((state) => state.hotelList.htlResOrgObj);
  const HtlFilterSort = useSelector((state) => state.htlFltrSort.htlFilterSort);

  const rating = OrgHtlResult.hotels.hotelDetail.map(rec => {
    return rec.starrating
  });

  const [classNone, setClassNone] = useState('d-none');
  const [priceFilter, setPriceFilter] = useState(OrgHtlResult.hotels.hotelDetail.length && [Number(parseFloat(OrgHtlResult.hotels.hotelDetail[0].minprice -1).toFixed(2)), Number(parseFloat(OrgHtlResult.hotels.hotelDetail[OrgHtlResult.hotels.hotelDetail.length - 1].minprice + 1).toFixed(2))]);
  const [minPrice, setMinPrice] =useState(OrgHtlResult.hotels.hotelDetail.length && Number(parseFloat(OrgHtlResult.hotels.hotelDetail[0].minprice -1).toFixed(2)));
  const [maxPrice, setMaxPrice] =useState(OrgHtlResult.hotels.hotelDetail.length && Number(parseFloat(OrgHtlResult.hotels.hotelDetail[OrgHtlResult.hotels.hotelDetail.length - 1].minprice + 1).toFixed(2)));

  const [startRating, setStartRating] = useState([]);

  useEffect(() => {
    setPriceFilter(OrgHtlResult.hotels.hotelDetail.length && [Number(parseFloat(OrgHtlResult.hotels.hotelDetail[0].minprice -1).toFixed(2)), Number(parseFloat(OrgHtlResult.hotels.hotelDetail[OrgHtlResult.hotels.hotelDetail.length - 1].minprice + 1).toFixed(2))])
    setMinPrice(OrgHtlResult.hotels.hotelDetail.length && Number(parseFloat(OrgHtlResult.hotels.hotelDetail[0].minprice -1).toFixed(2)));
    setMaxPrice(OrgHtlResult.hotels.hotelDetail.length && Number(parseFloat(OrgHtlResult.hotels.hotelDetail[OrgHtlResult.hotels.hotelDetail.length - 1].minprice + 1).toFixed(2)));
    //sliderRef.current.handleResize();
  }, [getCurrency]);

  useEffect(() => {
    filterSort();
  }, [priceFilter, startRating]);

  
  // useEffect(() => {
  //   sliderRef.current.handleResize();
  // },[sliderRef]
  // );
  const mapViewBtn = () => {
    props.sendMap({Modal:true})
  }

  const starChange = (e)=>{
    if(e.target.checked === true){
      setStartRating([...startRating, e.target.value]);
    }
    else if(e.target.checked === false){
      let freshArray = startRating.filter(val => val !== e.target.value);
      setStartRating([...freshArray]);
    }
  };

  const filterSort = () =>{
    let htlFilters = {
      startRating: startRating,
      priceFilter: priceFilter
    }
    let htlFilterSorts = { srtVal: '0', srchTxt: ''}
    let obj = { 'htlFilters': htlFilters, 'htlFilterSort': HtlFilterSort ? HtlFilterSort : htlFilterSorts}
    dispatch(doFilterSort(OrgHtlResult, obj));
  };

  const reset = () =>{
    setPriceFilter([Number(parseFloat(OrgHtlResult.hotels.hotelDetail[0].minprice -1).toFixed(2)), Number(parseFloat(OrgHtlResult.hotels.hotelDetail[OrgHtlResult.hotels.hotelDetail.length - 1].minprice + 1).toFixed(2))])
    setStartRating([]);
    let htlFilters = {startRating: [], priceFilter: []}
    let htlFilterSorts = {srtVal: '0', srchTxt: ''}
    let obj = { 'htlFilters': htlFilters, 'htlFilterSort': htlFilterSorts}
    dispatch(doFilterSort(OrgHtlResult, obj));
  }

  return(
    <>
    {OrgHtlResult.hotels.hotelDetail && OrgHtlResult.hotels.hotelDetail.length ? 
      <div className="d-lg-table-cell align-top mainContent">
        <div className="leftFilter">
          <div className="sidebarinner">
            <div className="bg-white position-relative mapDiv d-none d-lg-block"><img src={require('../../assets/images/map.jpg')} alt="Map" className="w-100" /> <Button onClick={mapViewBtn} variant="warning" size="sm" className="rounded-0 mapBtn">{t("MapView")}</Button></div>
            <Button variant="primary" className="text-start mapFilter w-100" onClick={() => props.filterClose(true)}><FontAwesomeIcon icon={solid('filter')} /> &nbsp;Filter <FontAwesomeIcon icon={solid('sort-down')} className="float-end" /></Button>
            
            <Collapse in={filterCollapse} id="leftCollapse" className="position-relative">
              <div>
                <Button onClick={() => props.filterClose(false)} variant='link' className="crossBtn mt-2 p-0"><FontAwesomeIcon icon={solid('times-circle')} className="text-danger" /></Button>
                <div className="bg-white px-2 py-3 border-bottom">
                    <span className="fn16 blue align-middle"><strong>{t("FilterYourSearch")}</strong></span> &nbsp; <Button variant='link' className="badge bg-light text-dark float-lg-end fn14 fw-normal" onClick={reset}>{t("ResetAll")}</Button>
                </div>
                
                <Accordion alwaysOpen defaultActiveKey={['0','1','2']}>
                  <Accordion.Item as={Card} eventKey="0">
                    <Accordion.Header as={Card.Header}>{t("Price")}</Accordion.Header>
                    <Accordion.Body as={Card.Body}>
                      <ReactSlider
                        //ref={sliderRef}
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        defaultValue={priceFilter}
                        min={minPrice}
                        max={maxPrice}
                        //defaultValue={[Number(parseFloat(OrgHtlResult.hotels.hotelDetail[0].minprice -1).toFixed(2)), Number(parseFloat(OrgHtlResult.hotels.hotelDetail[OrgHtlResult.hotels.hotelDetail.length - 1].minprice + 1).toFixed(2))]}
                        //min= {Number(parseFloat(OrgHtlResult.hotels.hotelDetail[0].minprice -1).toFixed(2))}
                        //max= {Number(parseFloat(OrgHtlResult.hotels.hotelDetail[OrgHtlResult.hotels.hotelDetail.length - 1].minprice + 1).toFixed(2))}
                        value={priceFilter}
                        onChange={event => setPriceFilter(event)}
                        //onAfterChange={filterSort}
                        //renderMark={(props, state) => <div {...props}>{state}</div>}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                        renderThumb={(props) => <div {...props}></div>}
                        //pearling
                        //withTracks
                        //minDistance={50} 
                        />
                      <div className="my-1 text-muted d-flex justify-content-between fn12">
                          {/* <span>{getCurrency.currency} {priceFilter[0]}</span>
                          <span>{getCurrency.currency} {priceFilter[1]}</span> */}
                          <span>{getCurrency.currency} {OrgHtlResult.hotels.hotelDetail.length && parseFloat(OrgHtlResult.hotels.hotelDetail[0].minprice -1).toFixed(2)}</span>
                          <span>{getCurrency.currency} {OrgHtlResult.hotels.hotelDetail.length && parseFloat(OrgHtlResult.hotels.hotelDetail[OrgHtlResult.hotels.hotelDetail.length - 1].minprice + 1).toFixed(2)}</span>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item as={Card} eventKey="1">
                    <Accordion.Header as={Card.Header}>{t("StarRating")}</Accordion.Header>
                    <Accordion.Body as={Card.Body}>
                    <Form.Check id="star5">
                      <Form.Check.Input value="5" onChange={e => starChange(e)} checked={startRating.includes("5")} />
                      <Form.Check.Label className='mb-0 w-100'>
                        <>
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <span className="float-end text-black-50">({Object.values(rating).reduce((a, item) => a + (item === '5' ? 1 : 0), 0)})</span>
                        </>
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check id="star4">
                      <Form.Check.Input value="4" onChange={e => starChange(e)} checked={startRating.includes("4")} />
                      <Form.Check.Label className='mb-0 w-100'>
                        <>
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <span className="float-end text-black-50">({Object.values(rating).reduce((a, item) => a + (item === '4' ? 1 : 0), 0)})</span>
                        </>
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check id="star3">
                      <Form.Check.Input value="3" onChange={e => starChange(e)} checked={startRating.includes("3")} />
                      <Form.Check.Label className='mb-0 w-100'>
                        <>
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <span className="float-end text-black-50">({Object.values(rating).reduce((a, item) => a + (item === '3' ? 1 : 0), 0)})</span>
                        </>
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check id="star2">
                      <Form.Check.Input value="2" onChange={e => starChange(e)} checked={startRating.includes("2")} />
                      <Form.Check.Label className='mb-0 w-100'>
                        <>
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <span className="float-end text-black-50">({Object.values(rating).reduce((a, item) => a + (item === '2' ? 1 : 0), 0)})</span>
                        </>
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check id="star1">
                      <Form.Check.Input value="1" onChange={e => starChange(e)} checked={startRating.includes("1")} />
                      <Form.Check.Label className='mb-0 w-100'>
                        <FontAwesomeIcon icon={solid('star')} className="stargold fn15" />
                        <span className="float-end text-black-50">({Object.values(rating).reduce((a, item) => a + (item === '1' ? 1 : 0), 0)})</span>
                      </Form.Check.Label>
                    </Form.Check>

                    <Form.Check id="star0">
                      <Form.Check.Input value="0" onChange={e => starChange(e)} checked={startRating.includes("0")} />
                      <Form.Check.Label className='mb-0 w-100'>
                        {t("NoRatings") }
                        <span className="float-end text-black-50">({Object.values(rating).reduce((a, item) => a + (item === '0' ? 1 : 0), 0)})</span>
                      </Form.Check.Label>
                    </Form.Check>
                      
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item as={Card} eventKey="2">
                    <Accordion.Header as={Card.Header}>{t("Amenities")}</Accordion.Header>
                    <Accordion.Body as={Card.Body}>
                      <div>
                        {
                          OrgHtlResult.hotels.amenities.map((am, i) => {
                            return (
                            <div key={`amKey${i}`} className={`moreBox ${i > 9 ? classNone :''}`}>
                              <Form.Check id={`am${i}`}>
                                <Form.Check.Input />
                                <Form.Check.Label className='mb-0 w-100 fn13'>{am}</Form.Check.Label>
                              </Form.Check>
                            </div>
                            )
                          })
                        }
                        {
                          OrgHtlResult.hotels.amenities.length > 9 && 
                          <div>
                            <Button onClick={()=> classNone==='d-none' ? setClassNone('d-block') : setClassNone('d-none')} variant='link' className='p-0 fn13'>
                              {classNone==='d-none' ? 'Show more': 'Show less'} 
                            </Button>
                          </div>
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      : ''
    }
    </>
  );
}
export default HotelFilter;