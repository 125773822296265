import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./locales/en.json";
import ar from "./locales/ar.json";
const resources = {
  en,
  ar
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // resources: {
    //   en: {
    //     translation: {
    //       Welcome: 'Welcome to this React Internationalization App'
    //     },
    //   },
    //   fr: {
    //     translation: {
    //       Welcome: "Bienvenue sur cette application d'internationalisation React",
    //     },
    //   },
    // },
    lng: process.env.REACT_APP_LANGUAGE, // if you're using a language detector, do not define the lng option
    fallbackLng: process.env.REACT_APP_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

  export default i18n;