import React, {useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import {Container, Row, Col, Button,} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import { format } from 'date-fns';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import DefaultLayout from '../../layouts/DefaultLayout';
import {getHtlDtlsStaticData, getHtlRmDtls} from "../../store/actions/hotel";
import RoomDetails from "../../components/hotel/RoomDetails";


const HotelDetails = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [searchparams] = useSearchParams();
    const qry = Object.fromEntries([...searchparams]);
    
    useEffect(()=>{
        dispatch(getHtlDtlsStaticData(qry.hotelId));
        roomDetails()
    },[dispatch]);

    const roomDetails = () => {
        let paxInfoArr = []
        let indx = 0   
        for (var k = 0; k < qry.num_rooms; k++) {
            indx = indx + 1
            paxInfoArr.push({
                idAdt: `adt${k}`,
                idChd: `chd${k}`,
                idDelBtn: `delRoom${k}`,
                adtVal: qry['adults' + indx],
                chdVal: qry['children' + indx],
                chdAgesArr: [{
                    idchdAges: 'chdAges0',
                    chdAgeVal: qry['ca' + indx].replace(/,/g, '')[0],
                    disabled: Number(qry['ca' + indx][0]) > 0 ? false : true
                },
                {
                    idchdAges: 'chdAges1',
                    chdAgeVal: qry['ca' + indx].replace(/,/g, '')[1],
                    disabled: Number(qry['ca' + indx][1]) > 0 ? false : true
                },
                {
                    idchdAges: 'chdAges2',
                    chdAgeVal: qry['ca' + indx].replace(/,/g, '')[2],
                    disabled: Number(qry['ca' + indx][2]) > 0 ? false : true
                },
                {
                    idchdAges: 'chdAges3',
                    chdAgeVal: qry['ca' + indx].replace(/,/g, '')[3],
                    disabled: Number(qry['ca' + indx][3]) > 0 ? false : true
                }]
            })
        }    
        dispatch(getHtlRmDtls({qry: qry, paxInfoArr: paxInfoArr}));               
    };
    
    const HtlDetails = useSelector((state) => state.hotelDetails);

    const getImages = () => {
        const images = [];
        if(HtlDetails.htlDtlObj && HtlDetails.htlDtlObj.data){
            for (let i = 0; i <= HtlDetails.htlDtlObj.data.imageUrls.length; i++) {
                images.push({
                    original: 'https://static.giinfotech.ae/media'+HtlDetails.htlDtlObj.data.imageUrls[i],
                    thumbnail: 'https://static.giinfotech.ae/media'+HtlDetails.htlDtlObj.data.imageUrls[i]
                });
            }
        }
        return images;
    };
    const [activeMarker, setActiveMarker] = useState(true);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPAPIKEY
    });

    const scrollPage =(idDiv) => {
        document.getElementById(idDiv).scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <>
    <DefaultLayout>
        <div className="innermiddle">
            <Container> 
                { HtlDetails && HtlDetails.htlDtlObj ?
                    <div className="fullbox rounded mb-3">
                        <Row>
                            <Col lg={4}>
                                <h3 className="fn21 blue mb-2">{HtlDetails.htlDtlObj.data.name}</h3>
                                <div className="mb-2">
                                    <span className="boxRating">
                                    {
                                        Array.apply(null, { length:parseInt(HtlDetails.htlDtlObj.data.rating)}).map((e, i) => (
                                        <FontAwesomeIcon key={i} icon={solid('star')} className="stargold" />
                                        ))
                                    }
                                    </span> &nbsp;&nbsp;
                                    { !(HtlDetails.htlDtlObj.data.tripAdvisorRating.toString().indexOf('.') > -1) ?
                                        <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${HtlDetails.htlDtlObj.data.tripAdvisorRating}.0-13387-4.png`} alt="tripadvisor" />
                                        :
                                        <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${HtlDetails.htlDtlObj.data.tripAdvisorRating}-13387-4.png`} alt="tripadvisor" />
                                    }
                                    
                                </div>
                                <div className="text-muted li21 mb-2">
                                    <div className="mb-1"><FontAwesomeIcon icon={solid('map-marker-alt')} /> {HtlDetails.htlDtlObj.data.address.line1}, {HtlDetails.htlDtlObj.data.address.cityName}</div>
                                    <Button variant="link" size="sm" onClick={()=>scrollPage('mapView')}>{t("MapView")}</Button>
                                </div>
                                <div className="text-muted li21">{HtlDetails.htlDtlObj.data.description}</div>
                                <hr />
                                <div className="nametitle blue mb-2">
                                    {t("Check-in")}: {format(new Date(qry.chk_in), 'EEE, dd MMM yyyy')}
                                </div>
                                <div className="nametitle blue">
                                    {t("Check-out")}: {format(new Date(qry.chk_out), 'EEE, dd MMM yyyy')}
                                </div>
                                <hr />
                                { HtlDetails.htlDtlObj.data.attractions &&
                                    <>
                                    <div className="blue fn16 mb-1"><FontAwesomeIcon icon={solid('map-marker-alt')} className="fn13" />  {t("WhatsNearby")}</div>
                                    <ul className="bulletList mb-3">
                                        {
                                        HtlDetails.htlDtlObj.data.attractions.map((n, i) => (
                                        <li key={i} className="d-flex"><span>{n.name}</span> : &nbsp;<span>{n.distance}</span></li>
                                        ))
                                        }
                                    </ul>
                                    </>
                                }
                                <div className="mb-2"><Button variant="warning" onClick={()=>scrollPage('selectRoom')}>&nbsp;{t("SelectRoom")} <FontAwesomeIcon icon={solid('arrow-down')} className="fn12" /></Button></div>
                            </Col>
                            <Col lg={8}>
                                <>
                                    {
                                    HtlDetails.htlDtlObj.data.imageUrls.length ?
                                    <ImageGallery items={getImages()} showIndex={true} />
                                    :
                                    <div className="bg-light justify-content-center h-100 d-flex">
                                        <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                                    </div>
                                    }
                                </>
                            </Col>
                        </Row>
                        <hr />
                        <div id="selectRoom">
                            <RoomDetails />
                        </div>
                        <hr />
                        { (HtlDetails.htlDtlObj.data.masterHotelAmenities || HtlDetails.htlDtlObj.data.masterRoomAmenities) &&
                            <>
                            <div className="text-muted">
                                <div className="fn18 mb-2 text-dark"><strong>{t("AmenitiesInfo")}</strong></div>
                                {/* <Amenitiesdetails :type="page"/> */}
                                <Row className="gx-2">
                                    {HtlDetails.htlDtlObj.data.masterHotelAmenities &&
                                    <Col lg={3} md={6}>
                                        <h4 className="blue1 fn16 fw-normal">{t("HotelAmenities")}</h4>
                                        <ul className="tickList">
                                            {HtlDetails.htlDtlObj.data.masterHotelAmenities.map((c, i) => ( 
                                                <li key={i}><FontAwesomeIcon icon={solid('check')} /> {c}</li>
                                            ))
                                            }
                                        </ul>
                                    </Col>
                                    }
                                    {HtlDetails.htlDtlObj.data.masterRoomAmenities &&
                                    <Col lg={3} md={6}>
                                        <h4 className="blue1 fn16 fw-normal">{t("RoomAmenities")}</h4>
                                        <ul className="tickList">
                                            {HtlDetails.htlDtlObj.data.masterRoomAmenities.map((d, i) => ( 
                                                <li key={i}><FontAwesomeIcon icon={solid('check')} /> {d}</li>
                                            ))
                                            }
                                        </ul>
                                    </Col>
                                    }
                                </Row>
                            </div>
                            <hr />
                            </>
                        }
                        <div id="mapView">
                            <div className="fn18 mb-2"><strong>{t("MapView")}</strong></div>
                            {isLoaded && 
                            <GoogleMap
                            zoom={12}
                            center={{lat: HtlDetails.htlDtlObj.data.geocode.lat, lng: HtlDetails.htlDtlObj.data.geocode.lon}}
                            mapContainerStyle={{ width: "100%", height: "500px" }}>
                                { activeMarker &&
                                <InfoWindow onCloseClick={() => setActiveMarker(false)} position={{
                                    lat: HtlDetails.htlDtlObj.data.geocode.lat, lng: HtlDetails.htlDtlObj.data.geocode.lon
                                    }}
                                    options={{pixelOffset: {width: 0, height: -35}}}>
                                    <div className="blue"><strong>{HtlDetails.htlDtlObj.data.name}</strong></div>
                                </InfoWindow>
                                }
                                <Marker
                                    position={{lat: HtlDetails.htlDtlObj.data.geocode.lat, lng: HtlDetails.htlDtlObj.data.geocode.lon}}
                                    onClick={() => setActiveMarker(!activeMarker)}></Marker>
                                        
                                
                            </GoogleMap>
                            }
                        </div>
                    </div>
                    : 
                    <>
                    {HtlDetails && HtlDetails.htlDtlObj ==='' ?
                        <div className="fullbox text-center mb-3">
                            <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
                            <div className="fn21">{t("NoResultFound")}</div>
                        </div>
                        :
                        <div className="fullbox rounded mb-3">
                            <Row>
                                <Col lg={5}>
                                    <h3 className="nametitle blue"><div className="dummyDiv" style={{width:'70%', height:'27px'}}></div></h3>
                                    <div className="dummyDiv" style={{height:'20px', width:'45%'}}></div>
                                    <div>
                                        <div className="dumwave">
                                            <div className="anim anim1"></div>
                                            <div className="anim anim2"></div>
                                            <div className="anim anim3"></div>
                                            <div className="anim anim4"></div>
                                            <div className="anim anim5"></div>
                                        </div>
                                    </div>
                                    <div className="dummyDiv" style={{height:'20px', width:'30%'}}></div>
                                </Col>
                                <Col lg={7}>
                                    <div className="bg-light justify-content-center h-100 d-flex">
                                        <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                    </>
                    
                }
            </Container> 
        </div>
    </DefaultLayout>
    </>
  )
}

export default HotelDetails
