import React, {useEffect, useState } from 'react';
import {useNavigate, createSearchParams } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {doPreBookingReq} from "../../store/actions/payment";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../common/Loader';
const _ = require("lodash");

const PaymentSection = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const srvCode = props.srvCodeObj;
  const getCurrency = useSelector((state) => state.currency)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(srvCode === "1"){
      preBookHotel()
    }
    else if(srvCode === "3"){
      preBookTransfer()
    }
    else if(srvCode === "4"){
      preBookTour()
    }

    
  }, [srvCode]);

  const preBookHotel = async () => {
    let bookingObj = props.bookingObj.htlRepriceObj
    bookingObj.travObj = props.travObj
    bookingObj.userSelectedCur = getCurrency.currency
    let requestData = {
      "ServiceCode": 1,
      "GroupCode": bookingObj.selectedRmDtls.gp,
      "HotelCode": bookingObj.selectedRmDtls.hId,
      "UserSelectedCur": bookingObj.userSelectedCur,
      "Currency": process.env.REACT_APP_CURRENCY,
      "Nationality": "QA",
      "CustomerRefNumber": "gib2c" + Math.floor(Math.random() * 101),
      "DestinationCode": bookingObj.giDestinationId,
      "SessionId": bookingObj.generalInfo.sessionId,
      "Rooms": {
          "Room": []
      }
    }
    let serverKeyMap = {
      count: "Count",
      childAge: "ChildAge",
      identifier: "Identifier",
      text: "Text"
    };
    bookingObj.hotel.rooms.room.forEach((v) => {
      let childTempObj = {}
      if (v.children !== null) {
        childTempObj = _.mapKeys(v.children, function (value, key) {
          return serverKeyMap[key];
        });

        _.forEach(childTempObj.ChildAge, (m, n) => {
          if (m) {
            childTempObj.ChildAge[n] = _.mapKeys(m, function (value, key) {
                return serverKeyMap[key];
            });
          }
        })
      }
      requestData.Rooms.Room.push({
          'Adult': v.adult,
          'Children': v.children !== undefined && v.children !== null && v.children.count > 0 ? childTempObj : null,
          'RateKey': v.rateKey,
          'GroupCode': v.groupCode,
          'RoomIdentifier': v.roomIdentifier,
          "Price": {
              "Gross": bookingObj.hotel.rooms.grossOg !== undefined ? bookingObj.hotel.rooms.grossOg : bookingObj.hotel.rooms.gross,
              "Net": bookingObj.hotel.rooms.netOg !== undefined ? bookingObj.hotel.rooms.netOg : bookingObj.hotel.rooms.net,
              "Tax": bookingObj.hotel.rooms.taxOg !== undefined ? bookingObj.hotel.rooms.taxOg : bookingObj.hotel.rooms.tax,
          },
          "Guests": {
              "Guest": []
          }
      })
    })

    //Filling guest
    requestData.Rooms.Room.forEach((v, i) => {
      if (v.Adult > 0) {
        for (var a = 0; a < v.Adult; a++) {
          v.Guests.Guest.push(
            {
              "Title": {
                  "Code": "",
                  "Text": bookingObj.travObj.title
              },
              "FirstName": a === 0 && i === 0 ? bookingObj.travObj.fName : bookingObj.travObj.fName + '_' + a + '_' + parseInt(Math.ceil(Math.random() * 10)),
              "LastName": a === 0 && i === 0 ? bookingObj.travObj.lName : bookingObj.travObj.lName + '_' + a + '_' + parseInt(Math.ceil(Math.random() * 10)),
              "Type": 'Adult',
              "IsLeadPAX": a === 0 && i === 0 ? 1 : 0,
              "Email": bookingObj.travObj.email
            }
          )
        }
      }

      if (v.Children !== null && v.Children.Count > 0) {
        for (var c = 0; c < v.Children.Count; c++) {
          v.Guests.Guest.push({
            "Title": {
                "Code": "",
                "Text": bookingObj.travObj.title
            },
            "FirstName": bookingObj.travObj.fName + '_' + c + '_' + parseInt(Math.ceil(Math.random() * 10)),
            "LastName": bookingObj.travObj.lName + '_' + c + '_' + parseInt(Math.ceil(Math.random() * 10)),
            "Type": 'Child',
            "IsLeadPAX": 0,
            "Age": v.Children.ChildAge[c].Text
          })
        }
      }
    })

    sessionStorage.setItem("preReq", JSON.stringify({bookingObj, srvCode}))
    let response = dispatch(doPreBookingReq(requestData));
    let res = await response
    sessionStorage.setItem("preRes", JSON.stringify({res}))
    if(res && res.customerRefNumber){
      let obj ={
        "sessionId": bookingObj.generalInfo.sessionId,
        "customerRefNumber":res.customerRefNumber,
        "serviceCode": srvCode
      }
      navigate(`/paymentOrder?${createSearchParams(obj.sessionId).toString()}`,{state:{obj}})
    }
    else{
      toast.error("Something Wrong !!",{theme: "colored"});
      setTimeout(() => {
        navigate(-1)
    }, 5000);
    }
  };

  const preBookTransfer = async () => {
    setLoading(true)
    let bookingObj = props.bookingObj
    bookingObj.travObj = props.travObj
    bookingObj.transferReq = props.transferReq
    bookingObj.userSelectedCur = getCurrency.currency

    console.log("bookingObj", bookingObj)

    let requestData = {
      "ServiceCode": 3,
	    "GroupCode": bookingObj.transferReq.gp,
	    "TransferType": bookingObj.transferReq.transferType == "1" ? "OneWay" : "RoundTrip",
	    "UserSelectedCur": bookingObj.UserSelectedCur,
	    "Currency": process.env.REACT_APP_CURRENCY,
	    "CustomerRefNumber": "gib2c" + Math.floor(Math.random() * 101),
	    "PickupDetails": {},
      "DropoffDetails": {},
      "AdditionalLocationDetails": [],
      "SpecialInstructions": "",//todo
      "UseAdditionalServices": false,//todo
	    "SessionId": bookingObj.transferReq.sId,
	    "RateKey": bookingObj.transferReq.rt,
	    "Paxes": {
        "Pax": []
      },
    }
    //Pickup /Dropoff Details
    let pickupAddress = ""
    let pickupGeo = ""
    let pickupDropoffAddress = ""
    let pickupDropoffGeo = ""
    let pickupFlightNumber = ""
    let pickupTerminal = ""
    let pickupTrainNumber = ""
    let pickupWagonNumber = ""
    let pickupDate = ""
    let pickupTime = ""
    let pickupDropoffDate = ""
    let pickupDropoffTime = ""
    let pickupDropoffFlightNumber = ""
    let pickupDropoffTerminal = ""

    let dropoffPickupAddress = ""
    let dropoffPickupGeo = ""
    let dropoffAddress = ""
    let dropoffGeo = ""
    let dropoffFlightNumber = ""
    let dropoffDate = ""
    let dropoffTime = ""
    let dropoffPickupDate = ""
    let dropoffPickupTime = ""
    let dropoffTerminal = ""
    let dropoffTrainNumber = ""
    let dropoffWagonNumber = ""
    let dropoffDropoffFlightNumber = ""
    let dropoffDropoffTerminal = ""

    if (bookingObj.transferReq.transferType == "2") {
      pickupAddress = bookingObj.transferReq.pickupAddress
      pickupGeo = bookingObj.transferReq.pickupGeo
      pickupDropoffAddress = bookingObj.transferReq.dropoffAddress
      pickupDropoffGeo = bookingObj.transferReq.dropoffGeo
      pickupFlightNumber = (bookingObj.onwardCarrier !== '' && bookingObj.onwardFlightNumber !== '') ? bookingObj.onwardCarrier + " (" + bookingObj.onwardFlightNumber + ")" : ""
      pickupDate = bookingObj.onwardPickupDate
      pickupTime = bookingObj.onwardPickupTime
      pickupDropoffDate = bookingObj.onwardDropoffDate
      pickupDropoffTime = bookingObj.onwardDropoffTime
      pickupTerminal = bookingObj.onwardTerminal
      pickupDropoffFlightNumber = (bookingObj.onwardDropoffCarrier !== '' && bookingObj.onwardDropoffFlightNumber !== '') ? bookingObj.onwardDropoffCarrier + " (" + bookingObj.onwardDropoffFlightNumber + ")" : ""
      pickupDropoffTerminal = bookingObj.onwardDropoffTerminal
      pickupTrainNumber = bookingObj.onwardTrainNumber
      pickupWagonNumber = bookingObj.onwardWagonNumber
      dropoffPickupAddress = bookingObj.transferReq.dropoffAddress
      dropoffPickupGeo = bookingObj.transferReq.dropoffGeo
      dropoffAddress = bookingObj.transferReq.pickupAddress
      dropoffGeo = bookingObj.transferReq.pickupGeo
      dropoffFlightNumber = (bookingObj.returnCarrier !== '' && bookingObj.returnFlightNumber !== '') ? bookingObj.returnCarrier + " (" + bookingObj.returnFlightNumber + ")" : ""
      dropoffDate = bookingObj.returnDropoffDate
      dropoffTime = bookingObj.returnDropoffTime
      dropoffPickupDate = bookingObj.returnPickupDate
      dropoffPickupTime = bookingObj.returnPickupTime
      dropoffTerminal = bookingObj.returnTerminal
      dropoffDropoffFlightNumber = (bookingObj.returnDropoffCarrier !== '' && bookingObj.returnDropoffFlightNumber !== '') ? bookingObj.returnDropoffCarrier + " (" + bookingObj.returnDropoffFlightNumber + ")" : ""
      dropoffDropoffTerminal = bookingObj.returnDropoffTerminal
      dropoffTrainNumber = bookingObj.returnTrainNumber
      dropoffWagonNumber = bookingObj.returnWagonNumber
  } else {
      pickupAddress = bookingObj.transferReq.pickupAddress
      pickupGeo = bookingObj.transferReq.pickupGeo
      pickupDropoffAddress = bookingObj.transferReq.dropoffAddress
      pickupDropoffGeo = bookingObj.transferReq.dropoffGeo
      pickupFlightNumber = (bookingObj.onwardCarrier !== '' && bookingObj.onwardFlightNumber !== '') ? bookingObj.onwardCarrier + " (" + bookingObj.onwardFlightNumber + ")" : ""
      pickupDate = bookingObj.onwardPickupDate
      pickupTime = bookingObj.onwardPickupTime
      pickupDropoffDate = bookingObj.onwardDropoffDate
      pickupDropoffTime = bookingObj.onwardDropoffTime
      pickupTerminal = bookingObj.onwardTerminal
      pickupDropoffFlightNumber = (bookingObj.onwardDropoffCarrier !== '' && bookingObj.onwardDropoffFlightNumber !== '') ? bookingObj.onwardDropoffCarrier + " (" + bookingObj.onwardDropoffFlightNumber + ")" : ""
      pickupDropoffTerminal = bookingObj.onwardDropoffTerminal
      pickupTrainNumber = bookingObj.onwardTrainNumber
      pickupWagonNumber = bookingObj.onwardWagonNumber
      dropoffPickupAddress = ""
      dropoffPickupGeo = ""
      dropoffAddress = ""
      dropoffGeo = ""
      dropoffFlightNumber = ""
      dropoffDate = ""
      dropoffTime = ""
      dropoffPickupDate = ""
      dropoffPickupTime = ""
      dropoffTerminal = ""
      dropoffDropoffFlightNumber = ""
      dropoffDropoffTerminal = ""
      dropoffTrainNumber = ""
      dropoffWagonNumber = ""
  }

  requestData.PickupDetails = {
    'Address': pickupAddress,
    'Geo': pickupGeo,
    'DropoffAddress': pickupDropoffAddress,
    'DropoffGeo': pickupDropoffGeo,
    'FlightNumber': pickupFlightNumber,
    'DropoffDate': pickupDropoffDate,
    'DropoffTime': pickupDropoffTime,
    'PickupDate': pickupDate,
    'PickupTime': pickupTime,
    'Terminal': pickupTerminal,
    'TrainNumber': pickupTrainNumber,
    'TrainCarriageNumber': pickupWagonNumber,
    'DropoffFlightNumber': pickupDropoffFlightNumber,
    'DropoffTerminal': pickupDropoffTerminal
  }
  requestData.DropoffDetails = {
    'Address': dropoffPickupAddress,
    'Geo': dropoffPickupGeo,
    'DropoffAddress': dropoffAddress,
    'DropoffGeo': dropoffGeo,
    'FlightNumber': dropoffFlightNumber,
    'DropoffDate': dropoffDate,
    'DropoffTime': dropoffTime,
    'PickupDate': dropoffPickupDate,
    'PickupTime': dropoffPickupTime,
    'Terminal': dropoffTerminal,
    'TrainNumber': dropoffTrainNumber,
    'TrainCarriageNumber': dropoffWagonNumber,
    'DropoffFlightNumber': dropoffDropoffFlightNumber,
    'DropoffTerminal': dropoffDropoffTerminal
  }
  //Paxes
  if (parseInt(bookingObj.transferReq.adults) > 0) {
    for (var a = 0; a < parseInt(bookingObj.transferReq.adults); a++) {
      requestData.Paxes.Pax.push({
        "Title": {
          "Code": "",
          "Text": bookingObj.travObj.title
        },
        "FirstName": a === 0 ? bookingObj.travObj.fName : bookingObj.travObj.fName + '_' + a + '_' + parseInt(Math.ceil(Math.random() * 10)),
        "LastName": a === 0 ? bookingObj.travObj.lName : bookingObj.travObj.lName + '_' + a + '_' + parseInt(Math.ceil(Math.random() * 10)),
        "Type": 'Adult',
        'Age': 35,
        "IsLeadPAX": a === 0 ? 1 : 0,
        'Address': {
          'Email': bookingObj.travObj.email,
          'Telephone': bookingObj.travObj.mob,
        }
      })
    }
  }

  if (bookingObj.transferReq.children && parseInt(bookingObj.transferReq.children) > 0) {
    let chdAgesArr = bookingObj.transferReq.ca.split(',');
    for (var c = 0; c < chdAgesArr.length; c++) {
      requestData.Paxes.Pax.push({
        "Title": {
          "Code": "",
          "Text": bookingObj.travObj.title
        },
        "FirstName": bookingObj.travObj.fName + '_' + c + '_' + parseInt(Math.ceil(Math.random() * 10)),
        "LastName": bookingObj.travObj.lName + '_' + c + '_' + parseInt(Math.ceil(Math.random() * 10)),
        "Type": 'Child',
        "IsLeadPAX": 0,
        "Age": chdAgesArr[c]
      })
    }
  }

  sessionStorage.setItem("preReq", JSON.stringify({bookingObj, srvCode}))
  let response = dispatch(doPreBookingReq(requestData));
  let res = await response
  console.log("res",res)
  sessionStorage.setItem("preRes", JSON.stringify({res}))
    // if(res && res.customerRefNumber){
    //   setLoading(false)
    //   let obj ={
    //     "sessionId": res.generalInfo.sessionId,
    //     "customerRefNumber":res.customerRefNumber,
    //     "serviceCode": srvCode
    //   }
    //   navigate(`/paymentOrder?${createSearchParams(obj.sessionId).toString()}`,{state:{obj}})
    // }
    // else{
    //   setLoading(false)
    //   toast.error("Something Wrong !!",{theme: "colored"});
    //   setTimeout(() => {
    //     navigate(-1)
    //   }, 5000);
    // }
  }

  const preBookTour = async () => {
    setLoading(true)
    let bookingObj = props.bookingObj
    bookingObj.travObj = props.travObj
    bookingObj.tourReq = props.tourReq
    bookingObj.userSelectedCur = getCurrency.currency
    let requestData = {
      "ServiceCode": 4,
      "GroupCode": bookingObj.tourReq.groupCode,
      "TourCode": bookingObj.tourReq.tourCode,
      "UserSelectedCur": bookingObj.userSelectedCur,
      "Currency": process.env.REACT_APP_CURRENCY,
      "CustomerRefNumber": "gib2c" + Math.floor(Math.random() * 101),	
      "DestinationCode": bookingObj.tourReq.destinationCode,
      "SessionId": bookingObj.tourReq.sessionId,
      "CountryCode": bookingObj.tourReq.countryCode,
      "ServiceDate": bookingObj.tourReq.serviceDate,
      "RateKey": bookingObj.rateKey,
      "Paxes": {
        "Pax": []
      },
      "Answers": {
        "Answer": []
      },
    }

    //Paxes
    if (parseInt(bookingObj.tourReq.adults) > 0) {
      for (var a = 0; a < parseInt(bookingObj.tourReq.adults); a++) {
        requestData.Paxes.Pax.push({
          "Title": {
              "Code": "",
              "Text": bookingObj.travObj.title
          },
          "FirstName": a === 0 ? bookingObj.travObj.fName : bookingObj.travObj.fName + '_' + a + '_' + parseInt(Math.ceil(Math.random() * 10)),
          "LastName": a === 0 ? bookingObj.travObj.lName : bookingObj.travObj.lName + '_' + a + '_' + parseInt(Math.ceil(Math.random() * 10)),
          "Type": 'Adult',
          'Age': 35,
          "IsLeadPAX": a === 0 ? 1 : 0,
          'Address': {
            'Email': bookingObj.travObj.email,
            'Telephone': bookingObj.travObj.mob,
          }
        })
      }
    }

    if (bookingObj.tourReq.children && parseInt(bookingObj.tourReq.children) > 0) {
      let chdAgesArr = bookingObj.tourReq.ca.split(',');
      for (var c = 0; c < chdAgesArr.length; c++) {
        requestData.Paxes.Pax.push({
          "Title": {
              "Code": "",
              "Text": bookingObj.travObj.title
          },
          "FirstName": bookingObj.travObj.fName + '_' + c + '_' + parseInt(Math.ceil(Math.random() * 10)),
          "LastName": bookingObj.travObj.lName + '_' + c + '_' + parseInt(Math.ceil(Math.random() * 10)),
          "Type": 'Child',
          "IsLeadPAX": 0,
          "Age": chdAgesArr[c]
        })
      }
    }

    sessionStorage.setItem("preReq", JSON.stringify({bookingObj, srvCode}))
    let response = dispatch(doPreBookingReq(requestData));
    let res = await response
    console.log("res", res)
    debugger
    sessionStorage.setItem("preRes", JSON.stringify({res}))
    if(res && res.customerRefNumber){
      setLoading(false)
      let obj ={
        "sessionId": res.generalInfo.sessionId,
        "customerRefNumber":res.customerRefNumber,
        "serviceCode": srvCode
      }
      navigate(`/paymentOrder?${createSearchParams(obj.sessionId).toString()}`,{state:{obj}})
    }
    else{
      setLoading(false)
      toast.error("Something Wrong !!",{theme: "colored"});
      setTimeout(() => {
        navigate(-1)
      }, 5000);
    }
  }

  return(
    <>
    {loading ?
        <Loader type='1' />
        :
        null 
    }
    </>
  );
}
export default PaymentSection;