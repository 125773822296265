import {TourService} from "../../services/tour.service";
import {UtilityService} from "../../services/utility.service";
import {
    RESET_TOUR_RES, SET_TOURRES, SET_OGTOURRES, 
    RESET_TOUR_FILTER, SET_TOURFILTER, SET_TOURFILTERSORT,
    SET_TOURDETRES, SET_TOUROPTRES } from "../types/tourTypes";
import store from '../store';
import cloneDeep from 'lodash/cloneDeep';

export const doTourSearchOnLoad = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_TOUR_RES})
            dispatch({ type: RESET_TOUR_FILTER})

            const response = await TourService.doTourSearch(reqObj);

            if(response.tours && response.tours !==''){
                response.tours = response.tours.sort((a,b) => {
                    return a.minPrice - b.minPrice
                })
            }

            let rootCurrency =  store.getState().currency.currency
            let rootExchangeRate =  store.getState().exchangeRate.exchangerates

            if (rootCurrency !== process.env.REACT_APP_CURRENCY) {
                UtilityService.tourConvBaseToSelectedCur(response, rootCurrency, rootExchangeRate)
            }

            dispatch({ type: SET_OGTOURRES, payload: response })
            dispatch({ type: SET_TOURRES, payload: response })
            
        } catch (err) {
            console.log("Tour Listing Error", err);
        }
    }
}

export const doFilterSort = (res, obj) => {
    let tourResult = cloneDeep(res)
    const fltrResPr = tourResult.tours.filter((o) => {
      let status = []
      if (obj.tourFilters.priceFilter.length > 0) {
        status.push(o.minPrice >= obj.tourFilters.priceFilter[0] && o.minPrice <= obj.tourFilters.priceFilter[1])
      }
      let statusVar = status.includes(false)
      return !statusVar
    });

    const fltrResCt = fltrResPr.filter((o) => {
      let status = []
      if (obj.tourFilters.typeCat.length > 0) {
        status.push(obj.tourFilters.typeCat.includes(o.type))
      } 
      let statusVar = status.includes(false)
      return !statusVar
    });

    if(obj.tourFilterSort.srtVal !== '0'){
        switch (obj.tourFilterSort.srtVal) {
            case 'plth':
                fltrResCt.sort((a, b) => parseFloat(a.minPrice) - parseFloat(b.minPrice))
                break
            case 'phtl':
                fltrResCt.sort((a, b) => parseFloat(b.minPrice) - parseFloat(a.minPrice))
                break
        }
    }

    if (obj.tourFilterSort.srchTxt !== '') {
        const srtTxtRes = fltrResCt.filter((pdt) => {
          return pdt.name.toLowerCase().includes(obj.tourFilterSort.srchTxt.toLowerCase())
        })
        tourResult.tours = srtTxtRes
    }
    else{
        tourResult.tours = fltrResCt
    }

    return async (dispatch) => {
        try {
            dispatch({ type: SET_TOURFILTER, payload: obj.tourFilters })
            dispatch({ type: SET_TOURFILTERSORT, payload: obj.tourFilterSort })
            dispatch({ type: SET_TOURRES, payload: tourResult })
        }  catch (err) {
            console.log("Tour Filter Error", err);
        }
    }
}

export const doTourDetails = (req) => {
    return async (dispatch) => {
        try {
            const response = await TourService.doTourDetails(req);
            //dispatch({ type: SET_TOURDET_Og_RES, payload: response })
            dispatch({ type: SET_TOURDETRES, payload: response })
        } catch (err) {
            console.log("Tour Details Error", err);
        }
    }
}

export const doTourOptions = (req) => {
    return async (dispatch) => {
        try {
            const response = await TourService.doTourOptions(req);
            let rootCurrency =  store.getState().currency.currency
            let rootExchangeRate =  store.getState().exchangeRate.exchangerates

            if (rootCurrency !== process.env.REACT_APP_CURRENCY) {
                UtilityService.tourOptionConvBaseToSelectedCur(response, rootCurrency, rootExchangeRate)
            }
            //dispatch({ type: SET_TOUROPT_Og_RES, payload: response })
            dispatch({ type: SET_TOUROPTRES, payload: response })
        } catch (err) {
            console.log("Tour Details Options Error", err);
        }
    }
}

export const doTourCancelPolicy = (req) => {
    return async () => {
        try {
            const response = await TourService.getTourCancelPolicy(req);
            return response
        } catch (err) {
            console.log("Tour CancelPolicy Error", err);
            return false
        }
    }
}

export const doTourTimeSlot = (req) => {
    return async () => {
        try {
            const response = await TourService.getTourTimeSlot(req);
            return response
        } catch (err) {
            console.log("Tour Time Slot Error", err);
            return false
        }
    }
}

export const doTourAvlb = (req) => {
    return async () => {
        try {
            const response = await TourService.getTourAvlb(req);
            return response
        } catch (err) {
            console.log("Tour Available Error", err);
            return false
        }
    }
}