import React, { useState } from 'react';
import {useSelector} from 'react-redux';
import { useTranslation } from "react-i18next";
import {Col, Collapse, Button} from 'react-bootstrap';

const TourFareBreakup = (Contentss) => {
  const {t} = useTranslation();
  const TourOptinDetails = Contentss.FtourOptinDetails;
  const TourDetReqObj = Contentss.FtourDetReqObj
  const getCurrency = useSelector((state) => state.currency);
  const [open, setOpen] = useState(true);

  const totalGuest = () => {
    let guest = 0
    guest = guest + parseInt(TourDetReqObj.SearchParameter.Adults) + (TourDetReqObj.SearchParameter.Children ? parseInt(TourDetReqObj.SearchParameter.Children.Count) : 0)
    return guest
  }

  return(
    <>
      <Col className="mb-2" lg={{span:4, order:2}} xs={{order:1}}>
        <div className="bg-white rounded shadow-sm sticky-top shadow-lg-none">
          <h3 className="nametitle blue plr10 pt-lg-3 plrlg0">{t("FareBreakup")} <Button size="sm" variant="link" className="fn14 text-warning d-inlineblock d-lg-none" onClick={() => setOpen(!open)}>(Show / Hide Details)</Button></h3>
          <Collapse in={open}>
            <div>
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td>{TourOptinDetails.tourOptionName} <small>({TourOptinDetails.transferName})</small></td>
                  </tr>
                </tbody>
              </table>
              <table className="table mb-0">
                <tbody>
                  <tr>
                    <td>Pax - {totalGuest()}</td>
                    <td className="text-end">
                      {TourOptinDetails.localField ?
                      <>{getCurrency.currency} {parseFloat(TourOptinDetails.localField.totalNet).toFixed(2)}</>
                      :
                      <>{getCurrency.currency} {parseFloat(TourOptinDetails.finalAmount).toFixed(2)}</>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Collapse>
          <table className="table mb-0">
            <tbody>
              <tr className="bg-light">
                <td><strong>{t("TotalAmount")}</strong></td>
                <td className="text-end"><strong>
                {TourOptinDetails.localField ?
                  <>{getCurrency.currency} {parseFloat(TourOptinDetails.localField.totalNet).toFixed(2)}</>
                  :
                  <>{getCurrency.currency} {parseFloat(TourOptinDetails.finalAmount).toFixed(2)}</>
                }
                </strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Col>
    </>
  );
}
export default TourFareBreakup;