import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Form} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';

const EmailSection = ({toggleTrav}) => {
  const {t} = useTranslation();
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setDefaultConuntry(data.country_code.toUpperCase())
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);
  const [defaultConuntry, setDefaultConuntry] = useState('IN')

  const [emailTxt, setEmailTxt] = useState('')
  const [emailerror, setEmailerror] = useState('')
  const [phoneTxt, setPhoneTxt] = useState('')
  const [phoneerror, setPhoneerror] = useState('')
  
  const emailValidation = () => {
    let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if(!emailTxt){
      setEmailerror("Please enter your email.");
      return false;
    }
    else if(regex.test(emailTxt) === false){
      setEmailerror("Email is not valid.");
      return false;
    }
    setEmailerror("");
    return true;
  }

  const emailField = async (value) => {
    setEmailTxt(value);
  }

  useEffect(()=>{
    if(emailTxt !== ''){
      emailValidation()
    }
  },[emailTxt]);

  const onSubmit = () => {
    let emailValid = emailValidation()
    let phoneValid = isValidPhoneNumber(phoneTxt)

    if(emailValid && phoneValid){
      let travObj = {
        email:emailTxt,
        phone:phoneTxt
      }
      toggleTrav(travObj)
      setPhoneerror("")
    }
    else if(!phoneValid){
      setPhoneerror("Mobile Number is not valid.");
    }
  }

  return(
    <>
      <div className="pt-lg-4 px-lg-4 p-0">
        <Row className="mb-3">
          <Form.Label className="col-sm-3 align-self-center text-sm-end"><strong>{t("EmailAddress")}<sup className="text-danger">*</sup></strong></Form.Label>
          <Col sm={6}>
            <Form.Control className={emailerror && 'is-invalid'} type="email" value={emailTxt} onChange={(event) => emailField(event.target.value)} placeholder={t('EnterYourEmail')} />
            <div className="text-danger fn13 mt-1 ms-2">{emailerror}</div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Form.Label className="col-sm-3 align-self-center text-sm-end"><strong>{t("MobileNumber")}<sup className="text-danger">*</sup></strong></Form.Label>
          <Col sm={6}>
            <PhoneInput
              className={'form-control ' + (phoneerror && 'is-invalid')}
              placeholder="Enter phone number"
              countryCallingCodeEditable={false}
              defaultCountry={defaultConuntry}
              international
              value={phoneTxt}
              onChange={(value)=> {
                if(value){
                  setPhoneerror("")
                  isValidPhoneNumber(value) ? setPhoneerror("") :  setPhoneerror("Mobile Number is not valid.")
                }
                else{
                  setPhoneerror("Mobile Number is not valid.");
                }
                setPhoneTxt(value)
              }} />
            <div className="text-danger fn13 mt-1 ms-2">{phoneerror}</div>
          </Col>
        </Row>
      </div>
      <div className="text-end resFixedBtn"><Button onClick={()=> onSubmit()} variant="success" size="lg" className="rounded-0">&nbsp;{t("Continue")} &nbsp; <FontAwesomeIcon icon={solid('long-arrow-alt-right')} className="align-middle fn18" /></Button></div>
    </>
  );
}
export default EmailSection;