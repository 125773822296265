import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Row, Col, Button, Form, InputGroup, Pagination} from 'react-bootstrap';
import {doFilterSort} from "../../store/actions/tour";
import { useSearchParams, createSearchParams } from 'react-router-dom';

const TourResult = () => {
  const dispatch = useDispatch();
  const { t} = useTranslation();
  const getCurrency = useSelector((state) => state.currency)
  const OrgTourResult = useSelector((state) => state.tourList.tourResOrgObj);
  const TourResult = useSelector((state) => state.tourList.tourResObj)

  const TourFilter = useSelector((state) => state.tourFilter.tourFltr);
  const TourFilterSort = useSelector((state) => state.tourFltrSort.tourFilterSort);

  const srtTxt = (val) =>{
    let tourFilterSortLet = {
      srtVal: TourFilterSort ? TourFilterSort.srtVal : "0",
      srchTxt: val
    }
    let obj = { 'tourFilters': TourFilter, 'tourFilterSort': tourFilterSortLet}
    dispatch(doFilterSort(OrgTourResult, obj));   
  };

  const srtVal = (val) =>{
    let tourFilterSortLet = {
      srtVal: val,
      srchTxt: TourFilterSort ? TourFilterSort.srchTxt : ""
    }
    let obj = { 'tourFilters': TourFilter, 'tourFilterSort': tourFilterSortLet}
    dispatch(doFilterSort(OrgTourResult, obj));  
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(50);  
  const [pagesCount, setPagesCount] = useState(0);

  useEffect(()=>{
    setPagesCount(Math.ceil(TourResult.tours.length / pageSize))
    setCurrentPage(0)
  },[TourResult]);

  const handleClick = (inde) => {
    setCurrentPage(inde)
  };

  const [searchparams] = useSearchParams();
  const qry = Object.fromEntries([...searchparams])

  const selectTour = (req) => {
    let tourDetailsQry = {
      "destinationCode":qry.destinationCode,
      "countryCode":qry.countryCode,
      "selectedCity": qry.selectedCity,
      "selectedCountry": qry.selectedCountry,
      "serviceDate":qry.serviceDate,
      "groupCode": req.groupCode,
      "tourCode": req.tourCode,
      "adults": qry.adults,
      "children": qry.children,
      "ca": qry.ca,
      "sessionId": TourResult.generalInfo.sessionId,
    }
    window.open(`/tourDetails?${createSearchParams(tourDetailsQry).toString()}`)
  };

  return( 
    <>
      {TourResult.tours && TourResult.tours.length ?
        <div className="d-lg-table-cell align-top rightResult">
          <div className="text-end mb-1">{t("TotalResultFound")}: {OrgTourResult.tours.length}</div>
          
          <div className="sortTop sticky-top">
            <Row className="justify-content-between gx-2">
              <Col md={3} xs={4} className="mb-1">
                <Form.Select size="sm" onChange={event => srtVal(event.target.value)} value={TourFilterSort.srtVal}>
                  <option value="0">{t("SortBy")}</option>
                  <option value="plth">{t("PriceLowHigh")}</option>
                  <option value="phtl">{t("PriceHighLow")}</option>
                </Form.Select>
              </Col>
              <Col md={5} xs={8} className="mb-1">
                <InputGroup>
                    <Form.Control className="border-end-0 fn13" placeholder={t('EnterExcursionName')} value={TourFilterSort.srchTxt} onChange={event => srtTxt(event.target.value)} />
                    <InputGroup.Text className="bg-white"><FontAwesomeIcon icon={solid('search')} className="fn14" /></InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </div> 

          {pagesCount >= 2 &&
            <Pagination size='sm' className="justify-content-center mb-3">
              <Pagination.First disabled={currentPage <= 0} onClick={() => handleClick(0)} />
              <Pagination.Prev disabled={currentPage <= 0} onClick={() => handleClick(currentPage - 1)} />
              {[...Array(pagesCount)].map((page, i) => 
                <Pagination.Item active={i === currentPage} key={i} onClick={() => handleClick(i)}>
                    {i + 1}
                </Pagination.Item>
              )}
              <Pagination.Next disabled={currentPage >= pagesCount - 1} onClick={() => handleClick(currentPage + 1)} />
              <Pagination.Last disabled={currentPage >= pagesCount-1} onClick={() => handleClick(pagesCount-1)} />
            </Pagination>
          }

          {
            TourResult.tours.slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize
            ).map((v, i) => {
              return (
                <div key={i} className="htlboxcol">
                  <Row className="gx-2">
                    <Col md={10}>
                      <div className="d-flex flex-row">
                        <div className="hotelImg rounded-start curpointer" onClick={()=> selectTour(v)}>
                          {v.imagePath ? 
                          <img src={v.imagePath} alt={v.name} />
                          :
                          <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                          }
                        </div>
                        <div className="ps-3 pe-1 pt-2 w-100 htlright">
                          <Row className="gx-2 h-100">
                            <Col md={12} className="align-self-center">
                              <h3 className="nametitle blue curpointer" onClick={()=> selectTour(v)}>{v.name}</h3>
                              <div className="text-muted mb-2 fn13 li20" dangerouslySetInnerHTML={{ __html: v.tourShortDescription.substring(0,180)+'...' }}></div>
                              <ul className="list-inline mb-1">
                                  {/* <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={regular('user')} className="fn14" /> 2 Pax</li> */}
                                  <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={regular('clock')} className="fn14" /> {v.duration}</li>
                              </ul>
                            </Col>
                            <Col md={12} className="mb-2 align-self-end text-lg-end">
                              <span className="boxRating">
                                {
                                  Array.apply(null, { length:parseInt(v.rating)}).map((e, i) => (
                                    <FontAwesomeIcon key={i} icon={solid('star')} className="stargold" />
                                  ))
                                }
                              </span>
                              {v.reviewCount !==null && v.reviewCount !=='' && v.reviewCount !== 0 &&
                                <span className="text-muted fn13">&nbsp; ({v.reviewCount} Review)</span>
                              }
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>

                    <Col md={2} className="border-start d-flex">
                      <div className="align-self-center mx-md-auto ms-auto text-center d-flex d-md-block px-2 align-items-center">
                        <div className="mt-md-1"><strong>{t("From1")}</strong></div>
                        <div className="mx-1 mx-md-0"><span className="nametitle blue d-md-block mb-0">{getCurrency.currency} {parseFloat(v.minPrice).toFixed(2)}</span></div>
                        <div className="mb-md-2 fn10 mt-md-n1 mx-1 mx-md-0">{t("PerPerson")}</div>
                        <div className="mb-2"><Button variant="warning" size="sm" onClick={()=> selectTour(v)}>&nbsp;&nbsp;{t("Select")}&nbsp;&nbsp;</Button></div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })
          }

          {pagesCount >= 2 &&
            <Pagination size='sm' className="justify-content-center mb-3">
              <Pagination.First disabled={currentPage <= 0} onClick={() => handleClick(0)} />
              <Pagination.Prev disabled={currentPage <= 0} onClick={() => handleClick(currentPage - 1)} />
              
              {[...Array(pagesCount)].map((page, i) => 
                <Pagination.Item active={i === currentPage} key={i} onClick={() => handleClick(i)}>
                    {i + 1}
                </Pagination.Item>
              )}

              <Pagination.Next disabled={currentPage >= pagesCount - 1} onClick={() => handleClick(currentPage + 1)} />
              <Pagination.Last disabled={currentPage >= pagesCount-1} onClick={() => handleClick(pagesCount-1)} />
            </Pagination>
          }
        </div> 
        :
        <div className="d-lg-table-cell align-top rightResult"> 
          <div className="text-center my-5">
            <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
            <div className="fn21">{t("NoResultFound")}</div>
          </div>
        </div>
      }
    </> 
  );
}
export default TourResult;