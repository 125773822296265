import React, { useState } from "react";

const Loader = (type) => {
  const TypeLoad = type
  return(
  <>
  Riyaj {TypeLoad.type}
  {TypeLoad.type === '1' &&
    <div className="updating">
      <div className="loader"></div>
    </div>
  }
  </>
  );
}
export default Loader;