const UtilityService = {
  conHtlListSelectedCur: function (resObj, selectedCur, exch) {
    try {
      if (typeof resObj && resObj.hotels && resObj.hotels.hotelDetail && exch !== '' && exch[selectedCur] !== null && typeof exch[selectedCur] !== 'undefined') {
        resObj.hotels.hotelDetail.forEach((v, index) => {
          if (v.minprice > 0) {
            if (typeof v.minpriceOg == "undefined") {
                v.minpriceOg = v.minprice
                v.minprice = parseFloat(parseFloat(v.minprice) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
                v.minprice = parseFloat(parseFloat(v.minpriceOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }
        })
      }
      return resObj
    } catch (error) {
      console.log("error", error)
      return false
    }
  },

  conHtlRmDtlsSelectedCur: function (resObj, selectedCur, exch) {
    try {
      if (typeof resObj && resObj.hotel && resObj.hotel.rooms && exch !== '' && exch[selectedCur] !== null && typeof exch[selectedCur] !== 'undefined') {
        resObj.hotel.rooms.forEach((v, index) => {
          v.mappedRooms.forEach((k) => {
            if (k.prices.netAmount > 0) {
              if (typeof k.prices.netAmountOg == "undefined") {
                k.prices.netAmountOg = k.prices.netAmount
                k.prices.netAmount = parseFloat(parseFloat(k.prices.netAmount) / parseFloat(exch[selectedCur])).toFixed(2)
              } else {
                k.prices.netAmount = parseFloat(parseFloat(k.prices.netAmountOg) / parseFloat(exch[selectedCur])).toFixed(2)
              }
            }
            if (k.prices.grossAmount > 0) {
              if (typeof k.prices.grossAmountOg == "undefined") {
                k.prices.grossAmountOg = k.prices.grossAmount
                k.prices.grossAmount = parseFloat(parseFloat(k.prices.grossAmount) / parseFloat(exch[selectedCur])).toFixed(2)
              } else {
                k.prices.grossAmount = parseFloat(parseFloat(k.prices.grossAmountOg) / parseFloat(exch[selectedCur])).toFixed(2)
              }
            }
            if (k.prices.tax > 0) {
              if (typeof k.prices.taxOg == "undefined") {
                k.prices.taxOg = k.prices.tax
                k.prices.tax = parseFloat(parseFloat(k.prices.tax) / parseFloat(exch[selectedCur])).toFixed(2)
              } else {
                k.prices.tax = parseFloat(parseFloat(k.prices.taxOg) / parseFloat(exch[selectedCur])).toFixed(2)
              }
            }
          })
        })
      }
      return resObj
    } catch (error) {
      console.log("error", error)
      return false
    }
  },


  conHtlCanPolicySelectedCur: function (resObj, selectedCur, exch) {
    try {
      if (typeof resObj !== "undefined" && resObj.rooms !== "undefined" && resObj.rooms.room !== "undefined" && exch !== '' && exch[selectedCur] !== null && typeof exch[selectedCur] !== 'undefined') {
        resObj.rooms.room.forEach((v) => {
          v.policies.policy.forEach((k) => {
              k.condition.forEach((m) => {
                if (m.fixed > 0) {
                  if (typeof m.fixedOg == "undefined") {
                    m.fixedOg = m.fixed
                    m.fixed = parseFloat(parseFloat(m.fixed) / parseFloat(exch[selectedCur])).toFixed(2)
                  } else {
                    m.fixed = parseFloat(parseFloat(m.fixedOg) / parseFloat(exch[selectedCur])).toFixed(2)
                  }
                }
              })
          })
        })
      }
      return resObj
    } catch (error) {
      console.log("error", error)
      return false
    }
  },


  conHtlFareBrkSelectedCur: function (resObj, selectedCur, exch) {
    try {
      if (typeof resObj !== "undefined" && resObj.priceBreakdown !== "undefined" && exch !== '' && exch[selectedCur] !== null && typeof exch[selectedCur] !== 'undefined') {
        resObj.priceBreakdown.forEach((v) => {
          v.dateRange.forEach((k) => {
            if (k.text > 0) {
              if (typeof k.textOg == "undefined") {
                k.textOg = k.text
                k.text = parseFloat(parseFloat(k.text) / parseFloat(exch[selectedCur])).toFixed(2)
              } else {
                k.text = parseFloat(parseFloat(k.textOg) / parseFloat(exch[selectedCur])).toFixed(2)
              }
            }
          })
          if (v.extrabed > 0) {
            if (typeof v.extrabedOg == "undefined") {
              v.extrabedOg = v.extrabed
              v.extrabed = parseFloat(parseFloat(v.extrabed) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.extrabed = parseFloat(parseFloat(v.extrabedOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.discount > 0) {
            if (typeof v.discountOg == "undefined") {
              v.discountOg = v.discount
              v.discount = parseFloat(parseFloat(v.discount) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.discount = parseFloat(parseFloat(v.discountOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.otherCharges > 0) {
            if (typeof v.otherChargesOg == "undefined") {
              v.otherChargesOg = v.otherCharges
              v.otherCharges = parseFloat(parseFloat(v.otherCharges) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.otherCharges = parseFloat(parseFloat(v.otherChargesOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.serviceCharge > 0) {
            if (typeof v.serviceChargeOg == "undefined") {
              v.serviceChargeOg = v.serviceCharge
              v.serviceCharge = parseFloat(parseFloat(v.serviceCharge) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.serviceCharge = parseFloat(parseFloat(v.serviceChargeOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.extraGuestCharges > 0) {
            if (typeof v.extraGuestChargesOg == "undefined") {
              v.extraGuestChargesOg = v.extraGuestCharges
              v.extraGuestCharges = parseFloat(parseFloat(v.extraGuestCharges) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.extraGuestCharges = parseFloat(parseFloat(v.extraGuestChargesOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.commission > 0) {
            if (typeof v.commissionOg == "undefined") {
              v.commissionOg = v.commission
              v.commission = parseFloat(parseFloat(v.commission) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.commission = parseFloat(parseFloat(v.commissionOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.netAmount > 0) {
            if (typeof v.netAmountOg == "undefined") {
              v.netAmountOg = v.netAmount
              v.netAmount = parseFloat(parseFloat(v.netAmount) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.netAmount = parseFloat(parseFloat(v.netAmountOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.grossAmount > 0) {
            if (typeof v.grossAmountOg == "undefined") {
              v.grossAmountOg = v.grossAmount
              v.grossAmount = parseFloat(parseFloat(v.grossAmount) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.grossAmount = parseFloat(parseFloat(v.grossAmountOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.tax > 0) {
            if (typeof v.taxOg == "undefined") {
              v.taxOg = v.tax
              v.tax = parseFloat(parseFloat(v.tax) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.tax = parseFloat(parseFloat(v.taxOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.otherFee > 0) {
            if (typeof v.otherFeeOg == "undefined") {
              v.otherFeeOg = v.otherFee
              v.otherFee = parseFloat(parseFloat(v.otherFee) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.otherFee = parseFloat(parseFloat(v.otherFeeOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }
        })
      }
      return resObj
    } catch (error) {
      console.log("error", error)
      return false
    }
  },

  conHtlTrvlerSelectedCur: function (resObj, selectedCur, exch) {
    try {
      if (typeof resObj !== "undefined" && resObj.hotel.rooms !== "undefined" && resObj.hotel.rooms.room && exch !== '' && exch[selectedCur] !== null && typeof exch[selectedCur] !== 'undefined') {
        resObj.hotel.rooms.room.forEach((v, index) => {
          if (v.policies !== null && v.policies.policy.length > 0) {
            v.policies.policy.forEach((k) => {
              k.condition.forEach((m) => {
                if (m.fixed > 0) {
                  if (typeof m.fixedOg == "undefined") {
                    m.fixedOg = m.fixed
                    m.fixed = parseFloat(parseFloat(m.fixed) / parseFloat(exch[selectedCur])).toFixed(2)
                  } else {
                    m.fixed = parseFloat(parseFloat(m.fixedOg) / parseFloat(exch[selectedCur])).toFixed(2)
                  }
                }
              })
            })
          }
          if (v.price.net > 0) {
            if (typeof v.price.netOg == "undefined") {
              v.price.netOg = v.price.net
              v.price.net = parseFloat(parseFloat(v.price.net) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.price.net = parseFloat(parseFloat(v.price.netOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }
          if (v.price.tax > 0) {
            if (typeof v.price.taxOg == "undefined") {
              v.price.taxOg = v.price.tax
              v.price.tax = parseFloat(parseFloat(v.price.tax) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.price.tax = parseFloat(parseFloat(v.price.taxOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }
          if (v.price.gross > 0) {
            if (typeof v.price.grossOg == "undefined") {
              v.price.grossOg = v.price.gross
              v.price.gross = parseFloat(parseFloat(v.price.gross) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.price.gross = parseFloat(parseFloat(v.price.grossOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }
        })

        if (resObj.hotel.rooms.net > 0) {
          if (typeof resObj.hotel.rooms.netOg == "undefined") {
            resObj.hotel.rooms.netOg = resObj.hotel.rooms.net
            resObj.hotel.rooms.net = parseFloat(parseFloat(resObj.hotel.rooms.net) / parseFloat(exch[selectedCur])).toFixed(2)
          } else {
            resObj.hotel.rooms.net = parseFloat(parseFloat(resObj.hotel.rooms.netOg) / parseFloat(exch[selectedCur])).toFixed(2)
          }
        }
        if (resObj.hotel.rooms.tax > 0) {
          if (typeof resObj.hotel.rooms.taxOg == "undefined") {
            resObj.hotel.rooms.taxOg = resObj.hotel.rooms.tax
            resObj.hotel.rooms.tax = parseFloat(parseFloat(resObj.hotel.rooms.tax) / parseFloat(exch[selectedCur])).toFixed(2)
          } else {
            resObj.hotel.rooms.tax = parseFloat(parseFloat(resObj.hotel.rooms.taxOg) / parseFloat(exch[selectedCur])).toFixed(2)
          }
        }
        if (resObj.hotel.rooms.gross > 0) {
          if (typeof resObj.hotel.rooms.grossOg == "undefined") {
            resObj.hotel.rooms.grossOg = resObj.hotel.rooms.gross
            resObj.hotel.rooms.gross = parseFloat(parseFloat(resObj.hotel.rooms.gross) / parseFloat(exch[selectedCur])).toFixed(2)
          } else {
            resObj.hotel.rooms.gross = parseFloat(parseFloat(resObj.hotel.rooms.grossOg) / parseFloat(exch[selectedCur])).toFixed(2)
          }
        }
      }
      return resObj
    } catch (error) {
      console.log("error", error)
      return false
    }
  },
  
  
  tourConvBaseToSelectedCur: function (resObj, selectedCur, exch) {
    try {
      if (typeof resObj && resObj.tours && resObj.tours.length && exch !== '' && exch[selectedCur] !== null && typeof exch[selectedCur] !== 'undefined') {
        resObj.tours.forEach((v, index) => {
          if (v.minPrice > 0) {
            if (typeof v.minPriceOg === "undefined") {
              v.minPriceOg = v.minPrice
              v.minPrice = parseFloat(parseFloat(v.minPrice) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.minPrice = parseFloat(parseFloat(v.minPriceOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }
        })
      }
      return resObj
    } catch (error) {
      console.log("error", error)
      return false
    }
  },

  tourOptionConvBaseToSelectedCur: function (resObj, selectedCur, exch) {
    try {
      if (typeof resObj && resObj.tourOptions && resObj.tourOptions.length && exch !== '' && exch[selectedCur] !== null && typeof exch[selectedCur] !== 'undefined') {
        resObj.tourOptions.forEach((v, index) => {
          if (v.finalAmount > 0) {
            if (typeof v.finalAmountOg == "undefined") {
              v.finalAmountOg = v.finalAmount
              v.finalAmount = parseFloat(parseFloat(v.finalAmount) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.finalAmount = parseFloat(parseFloat(v.finalAmountOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          if (v.localField && v.localField.totalNet > 0) {
            if (typeof v.localField.totalNetOg == "undefined") {
              v.localField.totalNetOg = v.localField.totalNet
              v.localField.totalNet = parseFloat(parseFloat(v.localField.totalNet) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.localField.totalNet = parseFloat(parseFloat(v.localField.totalNetOg) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

        })
      }
      return resObj
    } catch (error) {
        console.log("error", error)
        return false
    }
  },

  transferConvBaseToSelectedCur: function (resObj, selectedCur, exch) {
    try {
      if (typeof resObj && resObj.transfers && resObj.transfers.transfer && resObj.transfers.transfer.length && exch !== '' && exch[selectedCur] !== null && typeof exch[selectedCur] !== 'undefined') {
        resObj.transfers.transfer.forEach((v, index) => {
          let curr = v.vehicleDetails.vehicleDetail[0].rate.supplierCurrency
          let currexchrate = parseFloat(exch[curr])
          if (parseFloat(v.additionalLocationPrice) > 0) {
            if (typeof v.additionalLocationPriceOg === "undefined") {
              v.additionalLocationPriceOg = v.additionalLocationPrice
              v.additionalLocationPrice = parseFloat((parseFloat(v.additionalLocationPrice) * currexchrate) / parseFloat(exch[selectedCur])).toFixed(2)
            } else {
              v.additionalLocationPrice = parseFloat((parseFloat(v.additionalLocationPriceOg) * currexchrate) / parseFloat(exch[selectedCur])).toFixed(2)
            }
          }

          v.vehicleDetails.vehicleDetail.forEach((u, index) => {
            if (parseFloat(u.rate.supplierBasePrice) > 0) {
              if (typeof u.rate.supplierBasePriceOg == "undefined") {
                u.rate.supplierBasePriceOg = u.rate.supplierBasePrice
                u.rate.supplierBasePrice = parseFloat((parseFloat(u.rate.supplierBasePrice) * currexchrate) / parseFloat(exch[selectedCur])).toFixed(2)
              } else {
                u.rate.supplierBasePrice = parseFloat((parseFloat(u.rate.supplierBasePriceOg) * currexchrate) / parseFloat(exch[selectedCur])).toFixed(2)
              }
            }

            if (parseFloat(u.rate.supplierTotalNet) > 0) {
              if (typeof u.rate.supplierTotalNetOg == "undefined") {
                u.rate.supplierTotalNetOg = u.rate.supplierTotalNet
                u.rate.supplierTotalNet = parseFloat((parseFloat(u.rate.supplierTotalNet) * currexchrate) / parseFloat(exch[selectedCur])).toFixed(2)
              } else {
                u.rate.supplierTotalNet = parseFloat((parseFloat(u.rate.supplierTotalNetOg) * currexchrate) / parseFloat(exch[selectedCur])).toFixed(2)
              }
            }
          })

      })
      }
      return resObj
    } catch (error) {
      console.log("error", error)
      return false
    }
  },

  
}
export default UtilityService
export { UtilityService }