import React, {useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import {Container, Row, Col, Button, Nav, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import { format } from 'date-fns';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import DefaultLayout from '../../layouts/DefaultLayout';
import Loader from '../../components/common/Loader';
import {doTourDetails, doTourOptions, doTourCancelPolicy, doTourTimeSlot, doTourAvlb} from "../../store/actions/tour";
import {ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const TourDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const getCurrency = useSelector((state) => state.currency)
    const [searchparams] = useSearchParams();
    const qry = Object.fromEntries([...searchparams]);  
    let tourDetailObj = {
        "SessionId": qry.sessionId,
        "SearchParameter": {
            "DestinationCode": qry.destinationCode,
            "CountryCode": qry.countryCode,
            "ServiceDate": qry.serviceDate,
            "Currency": process.env.REACT_APP_CURRENCY,
            "GroupCode": qry.groupCode,
            "TourCode": qry.tourCode,
            "Adults": parseInt(qry.adults),
        }
    }
    if (parseInt(qry.children) > 0) {
        let childrenObj = {}
        let arrChildAges = []
        let indx = 0
        let chdAgesArr = qry.ca.split(',');
        for (var k = 0; k < chdAgesArr.length; k++) {
            indx = indx + 1
            let ageObj = {}
            ageObj.Identifier = indx
            ageObj.Text = chdAgesArr[k]
            arrChildAges.push(ageObj)
        }
        childrenObj.Count = parseInt(qry.children)
        childrenObj.ChildAge = arrChildAges;
        tourDetailObj.SearchParameter.Children = childrenObj
    }

    useEffect(()=>{
        dispatch(doTourDetails(tourDetailObj));
        dispatch(doTourOptions(tourDetailObj));
    },[dispatch]);

    const [loading, setLoading] = useState(false);
    const TourDetails = useSelector((state) => state.tourDetails)
    const TourOptions = useSelector((state) => state.tourOptions)

    const [respTimeSlot, setRespTimeSlot] = useState(null);
 
    const getImages = () => {
        const images = [];
        if(TourDetails.tourDtlObj && TourDetails.tourDtlObj.tour){
            for (let i = 0; i <= TourDetails.tourDtlObj.tour.tourInfo.tourImages.length - 1; i++) {
                images.push({
                    original: TourDetails.tourDtlObj.tour.tourInfo.tourImages[i].imagePath,
                    thumbnail: TourDetails.tourDtlObj.tour.tourInfo.tourImages[i].imagePath
                });
            }
        }
        return images;
    };

    const [activeItem, setActiveItem] = useState('descriptionTab');
    const setActive = (menuItem) => {
        setActiveItem(menuItem)
    }
    const isActive = (menuItem) => {
        return activeItem === menuItem
    }

    const [showCancelModal, setShowCancelModal] = useState(false);
    const [respCancelPolicy, setRespCancelPolicy] = useState([]);
    const [resTourDetails, setResTourDetails] = useState(null);
    
    const cancelPolicy = async (req) => {
        setRespCancelPolicy([]);
        setShowCancelModal(true);
        let tourCancelObj = {
            "SessionId": qry.sessionId,
            "SearchParameter": {
                "ServiceDate": format(new Date(qry.serviceDate), 'yyyy-MM-dd'),
                "Currency": process.env.REACT_APP_CURRENCY,
                "GroupCode": qry.groupCode,
                "TourCode": req.tourCode,
            }
        }
        let res = dispatch(doTourCancelPolicy(tourCancelObj));
        let response = await res
        setRespCancelPolicy(response)
    }

    const [modalTimeSlot, setModalTimeSlot] = useState(false);
    
    const timeSlot = async (req) => {
        setLoading(true)
        setRespTimeSlot(null)
        let tourTimeSlotObj = {
            "SessionId": qry.sessionId,
            "SearchParameter": {
                "DestinationCode": qry.destinationCode,
                "CountryCode": qry.countryCode,
                "ServiceDate": format(new Date(qry.serviceDate), 'yyyy-MM-dd'),
                "Currency":  process.env.REACT_APP_CURRENCY,
                "GroupCode": qry.groupCode,
                "TourCode": req.rateKey,
                "Adults": parseInt(qry.adults),
            }
        }
        if (parseInt(qry.children) > 0) {
            let childrenObj = {}
            let arrChildAges = []
            let indx = 0
            let chdAgesArr = qry.ca.split(',');
            for (var k = 0; k < chdAgesArr.length; k++) {
                indx = indx + 1
                let ageObj = {}
                ageObj.Identifier = indx
                ageObj.Text = chdAgesArr[k]
                arrChildAges.push(ageObj)
            }
            childrenObj.Count = parseInt(qry.children)
            childrenObj.ChildAge = arrChildAges;
            tourTimeSlotObj.SearchParameter.Children = childrenObj
        }

        let res = dispatch(doTourTimeSlot(tourTimeSlotObj));
        let responseTimeSlot = await res
        if(responseTimeSlot){
            setLoading(false)
            setRespTimeSlot(responseTimeSlot)
            setModalTimeSlot(true)
            //this.$refs['modalTimeSlot'].show()
        }
        else{
            setRespTimeSlot(null)
            setLoading(false)
            toast.error(t("OopsSomethingWentWrongTryAgain"),{theme: "colored"});
        }

    }

    const avlbTour = async (req) => {
        debugger
        setLoading(true)
        let tourDt = null
        if(resTourDetails){
            setResTourDetails(tourDt)
        }
        else{
            tourDt = req
        }
        let tourAvlbObj = {
            "tourOptinDetails" : tourDt,
            "SessionId": qry.sessionId,
            "SearchParameter": {
                "DestinationCode": qry.destinationCode,
                "CountryCode": qry.countryCode,
                "ServiceDate": format(new Date(qry.serviceDate), 'yyyy-MM-dd'),
                "Currency": process.env.REACT_APP_CURRENCY,
                "GroupCode": qry.groupCode,
                "RateKey":req.rateKey,
                "Adults": parseInt(qry.adults),
            }
        }
        if (parseInt(qry.children) > 0) {
            let childrenObj = {}
            let arrChildAges = []
            let indx = 0
            let chdAgesArr = qry.ca.split(',');
            for (var k = 0; k < chdAgesArr.length; k++) {
                indx = indx + 1
                let ageObj = {}
                ageObj.Identifier = indx
                ageObj.Text = chdAgesArr[k]
                arrChildAges.push(ageObj)
            }
            childrenObj.Count = parseInt(qry.children)
            childrenObj.ChildAge = arrChildAges;
            tourAvlbObj.SearchParameter.Children = childrenObj
        }
        let res = dispatch(doTourAvlb(tourAvlbObj));
        let response = await res
        if(response && response !== ''){
            setLoading(false)
            if(!response.isBookable){
                toast.error(response.message,{theme: "colored"});
            }
            else{
                // navigate(`/tourItinerary`,{state:{'tourDetails':TourDetails, 'tourOptinDetails':tourDt, 'tourDetReqObj':tourDetailObj}})
                navigate(`/tourItinerary`,{state:{'reqObj':qry, 'tourOptinDetails':tourDt}})
            }
        }
        else{
            setLoading(false)
            toast.error(t("OopsSomethingWentWrongTryAgain"),{theme: "colored"});
        }
    }
    
  return (
    <>
    <ToastContainer />
    {loading ?
        <Loader type='1' />
        :
        null 
    }
    <DefaultLayout>
        <div className="innermiddle">
            <Container> 
            { TourDetails && TourDetails.tourDtlObj ?
            <div className="fullbox rounded mb-3">
                <Row>
                    <Col lg={5}>
                        <h3 className="fn21 blue mb-2">{TourDetails.tourDtlObj.tour.name}</h3>
                        <div className="mb-3">
                            {TourDetails.tourDtlObj.tour.rating &&
                            <span className="boxRating">
                                {
                                Array.apply(null, { length:parseInt(TourDetails.tourDtlObj.tour.rating)}).map((e, i) => (
                                <FontAwesomeIcon key={i} icon={solid('star')} className="stargold" />
                                ))
                                }
                            </span>
                            }
                            {TourDetails.tourDtlObj.tour.reviewCount !== null && TourDetails.tourDtlObj.tour.reviewCount !== 0 &&
                            <span className="text-muted fn13">&nbsp; ({TourDetails.tourDtlObj.tour.reviewCount} Review)</span>
                            }

                            <div>
                                <ul className="list-inline mb-2">
                                    <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={regular('user')} className="fn14" /> &nbsp;{parseInt(qry.adults) + parseInt(qry.children)} Pax &nbsp;&nbsp;</li>
                                    <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={regular('clock')} className="fn14" /> &nbsp;{TourDetails.tourDtlObj.tour.duration}</li>
                                </ul>
                            </div>
                            <hr />
                            <div className="fn15"><strong>{t("Date")}: </strong> {format(new Date(qry.serviceDate), 'EEE, dd MMM yyyy')}</div>
                            <hr />
                            <div className="fn18 blue mb-1"><strong>{t("Details")}</strong></div>
                            <ul className="bulletList">
                                {TourDetails.tourDtlObj.tour.duration && 
                                    <li><Row className="gx-2"><strong className="col-4">{t("Duration1")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.duration}</span></Row></li>
                                }
                                {TourDetails.tourDtlObj.tour.tourInfo.departurePoint && 
                                    <li><Row className="gx-2"><strong className="col-4">{t("DeparturePoint")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.tourInfo.departurePoint}</span></Row></li>
                                }
                                {TourDetails.tourDtlObj.tour.tourInfo.reportingTime && 
                                    <li><Row className="gx-2"><strong className="col-4">{t("ReportingPoint")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.tourInfo.reportingTime}</span></Row></li>
                                }
                                {TourDetails.tourDtlObj.tour.tourInfo.tourLanguage && 
                                    <li><Row className="gx-2"><strong className="col-4">{t("TourLanguage")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.tourInfo.tourLanguage}</span></Row></li>
                                }
                                {TourDetails.tourDtlObj.tour.tourInfo.meal && 
                                    <li><Row className="gx-2"><strong className="col-4">{t("Meals")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.tourInfo.meal}</span></Row></li>
                                }
                            </ul>
                            <div className="fn12 my-2 text-muted"><strong>Note:</strong> If tour is booked without transfers, please confirm the tour location & timings with our customer support team if required.</div>
                        </div>
                    </Col>
                    <Col lg={7}>
                        <>
                            {
                            TourDetails.tourDtlObj.tour.tourInfo.tourImages.length ?
                            <ImageGallery items={getImages()} showIndex={true} />
                            :
                            <div className="bg-light justify-content-center h-100 d-flex">
                                <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                            </div>
                            }
                        </>
                    </Col>
                </Row>
                <hr />
                {TourOptions && TourOptions.tourOptObj ?
                    <>
                    {TourOptions.tourOptObj.tourOptions ?
                    <div className="table-responsive">
                        <table className="b-table table table-hover table-sm mb-0 border">
                            <thead className="thead-light">
                                <tr>
                                    <th className="text-nowrap"><strong>{t("ExcursionOption")}</strong></th>
                                    <th className="text-nowrap"><strong>{t("TransferOption")}</strong></th>
                                    <th className="text-center"><strong>{t("Policy")}</strong></th>
                                    <th><strong>{t("Status")}</strong></th>
                                    <th><strong>{t("Price1")}</strong></th>
                                    <th className="text-end">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                { TourOptions.tourOptObj.tourOptions.map((cat, i) => (
                                <tr key={i}>
                                    <td className="align-middle">{cat.tourOptionName}</td>
                                    <td className="align-middle">{cat.transferName}</td>
                                    <td className="align-middle text-center"><Button variant="link" size="sm" onClick={()=>cancelPolicy(cat)}><FontAwesomeIcon icon={solid('file-alt')} className="fn14" /></Button></td>
                                    <td className="align-middle text-success">{cat.status}</td>
                                    {cat.localField ?
                                    <td className="align-middle text-nowrap">{getCurrency.currency} {parseFloat(cat.localField.totalNet).toFixed(2)}</td>
                                    :
                                    <td className="align-middle text-nowrap">{getCurrency.currency} {parseFloat(cat.finalAmount).toFixed(2)}</td>
                                    }
                                    <td className="align-middle text-end">
                                        {cat.isSlot ? 
                                        <Button variant="warning" size="sm" onClick={()=> timeSlot(cat)}>&nbsp;{t("Select")}&nbsp;</Button>
                                        :
                                        <Button variant="warning" size="sm" onClick={()=> avlbTour(cat)} className="text-nowrap">&nbsp;{t("BookNow")}&nbsp;</Button>
                                        }
                                    </td>
                                </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div className="text-center my-5">
                        <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
                        <div className="fn21">{t("NoResultFound")}</div>
                    </div>
                    }

                    <hr />
                    </> :
                    <>
                    <div className="text-center blue my-3">
                        <span className="fn21 align-top pt-1 d-inline-block"><strong>Getting Cheapest Excursion Rates For You..</strong></span>&nbsp; 
                        <div className="dumwave align-top">
                            <div className="anim anim1" style={{backgroundColor:"#06448f",marginRight:"3px"}}></div>
                            <div className="anim anim2" style={{backgroundColor:"#06448f",marginRight:"3px"}}></div>
                            <div className="anim anim3" style={{backgroundColor:"#06448f",marginRight:"3px"}}></div>
                        </div>
                    </div>
                    <hr />
                    </>
                }

                <div className="arrowtab">
                    <ul className="nav nav-tabs">
                        {TourDetails.tourDtlObj.tour.tourInfo.tourDescription &&
                        <li className="nav-item">
                            <Nav.Link onClick={() => setActive('descriptionTab')} className={isActive('descriptionTab') && 'active'}>
                                <span className="tabTxt">Description</span>
                            </Nav.Link>
                        </li>
                        }
                        {TourDetails.tourDtlObj.tour.tourInfo.tourInclusion &&
                        <li className="nav-item">
                        <Nav.Link onClick={() => setActive('inclusionsTab')} className={isActive('inclusionsTab') && 'active'}>
                            <span className="tabTxt">Inclusions</span>
                        </Nav.Link>
                        </li>
                        }
                        {TourDetails.tourDtlObj.tour.tourInfo.usefulInformation &&
                        <li className="nav-item">
                        <Nav.Link onClick={() => setActive('usefulInfoTab')} className={isActive('usefulInfoTab') && 'active'}>
                            <span className="tabTxt">Useful Info</span>
                        </Nav.Link>
                        </li>
                        }
                        {TourDetails.tourDtlObj.tour.tourInfo.termsAndConditions &&
                        <li className="nav-item">
                        <Nav.Link onClick={() => setActive('termsTab')} className={isActive('termsTab') && 'active'}>
                            <span className="tabTxt">Terms &amp; Conditions</span>
                        </Nav.Link>
                        </li>
                        }
                        {TourDetails.tourDtlObj.tour.tourInfo.faqDetails &&
                        <li className="nav-item">
                        <Nav.Link onClick={() => setActive('faqTab')} className={isActive('faqTab') && 'active'}>
                            <span className="tabTxt">FAQ</span>
                        </Nav.Link>
                        </li>
                        }
                        <li className="nav-item">
                        <Nav.Link onClick={() => setActive('bookingPolicyTab')} className={isActive('bookingPolicyTab') && 'active'}>
                            <span className="tabTxt">Booking Policy</span>
                        </Nav.Link>  
                        </li>
                    </ul>
                    <div className="tab-content py-3 li21 fn13">
                        <div className={"tab-pane fade " + (isActive('descriptionTab') && 'active show')}>
                            {TourDetails.tourDtlObj.tour.tourInfo.tourDescription &&
                                <div dangerouslySetInnerHTML={{ __html: TourDetails.tourDtlObj.tour.tourInfo.tourDescription }}></div>
                            }
                            {TourDetails.tourDtlObj.tour.tourInfo.importantInformation &&
                                <>
                                <strong className="fn15 mb-2">Important Information</strong>
                                <div dangerouslySetInnerHTML={{ __html: TourDetails.tourDtlObj.tour.tourInfo.importantInformation }}></div>
                                </>
                            }

                            {TourDetails.tourDtlObj.tour.tourInfo.tourDescription === '' && TourDetails.tourDtlObj.tour.tourInfo.importantInformation ==='' &&
                            <div className="fn15 mb-2">No Data Found</div>
                            }
                        </div>

                        <div className={"tab-pane fade " + (isActive('inclusionsTab') && 'active show')}>
                            {TourDetails.tourDtlObj.tour.tourInfo.tourInclusion &&
                                <div dangerouslySetInnerHTML={{ __html: TourDetails.tourDtlObj.tour.tourInfo.tourInclusion }}></div>
                            }
                        </div>

                        <div className={"tab-pane fade " + (isActive('usefulInfoTab') && 'active show')}>
                            {TourDetails.tourDtlObj.tour.tourInfo.usefulInformation &&
                                <div dangerouslySetInnerHTML={{ __html: TourDetails.tourDtlObj.tour.tourInfo.usefulInformation }}></div>
                            }
                        </div>

                        <div className={"tab-pane fade " + (isActive('termsTab') && 'active show')}>
                            {TourDetails.tourDtlObj.tour.tourInfo.termsAndConditions &&
                                <div dangerouslySetInnerHTML={{ __html: TourDetails.tourDtlObj.tour.tourInfo.termsAndConditions }}></div>
                            }
                        </div>

                        <div className={"tab-pane fade " + (isActive('faqTab') && 'active show')}>
                            {TourDetails.tourDtlObj.tour.tourInfo.faqDetails &&
                                <div dangerouslySetInnerHTML={{ __html: TourDetails.tourDtlObj.tour.tourInfo.faqDetails }}></div>
                            }
                        </div>

                        <div className={"tab-pane fade " + (isActive('bookingPolicyTab') && 'active show')}>
                            {TourDetails.tourDtlObj.tour.tourInfo.cancellationPolicyDescription &&
                                <>
                                <strong className="fn15 mb-2">{t("CancellationPolicy")}</strong>
                                <div dangerouslySetInnerHTML={{ __html: TourDetails.tourDtlObj.tour.tourInfo.cancellationPolicyDescription }}></div>
                                </>
                            }

                            {TourDetails.tourDtlObj.tour.tourInfo.childCancellationPolicyDescription &&
                                <>
                                <strong className="fn15 mb-2">Child Policy</strong>
                                <div dangerouslySetInnerHTML={{ __html: TourDetails.tourDtlObj.tour.tourInfo.childCancellationPolicyDescription }}></div>
                                </>
                            }

                            {TourDetails.tourDtlObj.tour.tourInfo.cancellationPolicyDescription === '' && TourDetails.tourDtlObj.tour.tourInfo.childCancellationPolicyDescription ==='' &&
                            <div className="fn15 mb-2">No Data Found</div>
                            }
                        </div>


                    </div>
                </div>

                <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title className="fn16">{t("CancellationPolicy")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {respCancelPolicy &&
                        <>
                        {respCancelPolicy.cancellationPolicies ?
                        <>
                        {respCancelPolicy.cancellationPolicies.map((v, i) => ( 
                        <table key={i} className="table table-bordered">
                            <thead className="bg-light">
                                <tr>
                                    <th>{t("From")}</th>
                                    <th>{t("To")}</th>
                                    <th className="text-center">{t("Percentage")}</th>
                                    <th>{t("Fixed")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {v.condition.map((k, ind) => ( 
                                <tr key={ind}>
                                    <td className="align-middle">{format(new Date(k.fromDate), 'dd MMM yyyy')}<br /> {k.fromDate.split('T')[1].includes('+') ? k.fromDate.split('T')[1].split('+')[0]: k.fromDate.split('T')[1]}</td>
                                    <td className="align-middle">{format(new Date(k.toDate), 'dd MMM yyyy')}<br />  {k.toDate.split('T')[1].includes('+') ? k.toDate.split('T')[1].split('+')[0]: k.toDate.split('T')[1]}</td>
                                    <td className="align-middle text-center">{k.percentage && parseFloat(k.percentage).toFixed(2)}</td>
                                    <td>{k.fixed && parseFloat(k.fixed).toFixed(2)}</td>
                                </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        ))
                        } 
                        </>
                        : 
                        <div className="fn16 blue mb-3">Loading...</div>
                        }
                        <div className="fn13 text-muted">Please note that the cancellation policy is based on date/time at local destination.</div>
                        </>
                        }
                    </Modal.Body>
                </Modal>

                <Modal show={modalTimeSlot} onHide={() => setModalTimeSlot(false)} size="xl" centered>
                    <Modal.Header closeButton>
                        <Modal.Title className="fn16">{t("CancellationPolicy")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="nametitle blue mb-2">Select Time Options</div>
                            <Row className="mb-3">
                                <Col xs="auto"><FontAwesomeIcon icon={solid('circle')} className="fn14 text-success" /> Available</Col>
                                <Col xs="auto"><FontAwesomeIcon icon={solid('circle')} className="fn14 text-warning" /> Limited Availability</Col>
                                <Col xs="auto"><FontAwesomeIcon icon={solid('circle')} className="fn14 text-danger" /> Sold Out</Col>
                            </Row>
                            {respTimeSlot && respTimeSlot.timeSlots &&
                            <Row>
                                {respTimeSlot.timeSlots.map((v, i) => ( 
                                <Col md="3" v-for="v in respTimeSlot.timeSlots" key={i}>
                                    <div className={"bggray my-3 rounded-lg overflow-hidden border " + (v.available > 10 ? 'border-success':'' ||  v.available > 0 && v.available < 10 ? 'border-warning':'' ||  v.available == 0 ? 'border-danger':'')}>
                                        <div className={"px-3 py-2 text-white " + (v.available > 10 ? 'bg-success':'' ||  v.available > 0 && v.available < 10 ? 'bg-warning':'' ||  v.available == 0 ? 'bg-danger':'')}>
                                            <div className="d-flex justify-content-between">
                                                <div>Time: {v.timing}</div>
                                                <div>(Avl: {v.available})</div>
                                            </div>
                                        </div>
                                        <div className="p-3 text-center">
                                            <div className="mb-1"><strong>Adult:</strong> {v.paxPrices[0].gross}</div>
                                            {qry.children &&
                                            <div className="mb-1">
                                                <strong>Child [Age: {v.paxPrices[1].age}]:</strong> {v.paxPrices[1].gross}
                                            </div>
                                            }
                                            {v.available !== 0 &&
                                            <Button className="fn12 mt-2" variant="warning" size="sm" onClick={()=> avlbTour(v)}>&nbsp;Book&nbsp;</Button>
                                            }
                                        </div>
                                    </div>
                                </Col>
                                ))
                               }
                            </Row>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
                
            </div>
            : 
            <>
            {TourDetails && TourDetails.tourDtlObj ==='' ?
                <div className="fullbox text-center mb-3">
                    <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
                    <div className="fn21">{t("NoResultFound")}</div>
                </div>
                :
                <div className="fullbox rounded mb-3">
                    <Row>
                        <Col lg={5}>
                            <h3 className="nametitle blue"><div className="dummyDiv" style={{width:'70%', height:'27px'}}></div></h3>
                            <div className="dummyDiv" style={{height:'20px', width:'45%'}}></div>
                            <div>
                                <div className="dumwave">
                                    <div className="anim anim1"></div>
                                    <div className="anim anim2"></div>
                                    <div className="anim anim3"></div>
                                    <div className="anim anim4"></div>
                                    <div className="anim anim5"></div>
                                </div>
                            </div>
                            <div className="dummyDiv" style={{height:'20px', width:'30%'}}></div>
                        </Col>
                        <Col lg={7}>
                            <div className="bg-light justify-content-center h-100 d-flex">
                                <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                            </div>
                        </Col>
                    </Row>
                </div>
            }
            </>
            }
            </Container> 
        </div>
    </DefaultLayout>
    </>
  )
}

export default TourDetails
