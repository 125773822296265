import React, { useState, useEffect } from "react";
import {useSelector, useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate} from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {getSelectedCur, getActiveLang} from "../../store/actions/common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Container, Navbar, Nav, NavDropdown, Dropdown, Button } from 'react-bootstrap';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import logoKZN from '../../assets/images/logoKZN.png';

const Header = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [fixedClass, setFixedClass] = useState(false);
  const handleScrollss = () => {
    setFixedClass(window.scrollY > 30)
  }

  const { i18n , t} = useTranslation();
  const dispatch = useDispatch();
  const getexchangeRates = useSelector((state) => state.exchangeRate)

  const [isActiveCur, setIsActiveCur] = useState(window.localStorage.getItem('selectedCur'));
  const getCurrency = useSelector((state) => state.currency)
  const getLanguage = useSelector((state) => state.language)
  const changeSlctdCur = (curValue) => {
    setIsActiveCur(curValue)
    let obj = {
      'curValue':curValue,
      'page':location.pathname
    }
    dispatch(getSelectedCur(obj));
	};

  const [isActiveLang, setIsActiveLang] = useState(window.localStorage.getItem('currentLang'));
  const changeLang = (lanValue) => {
    setIsActiveLang(lanValue)
    dispatch(getActiveLang(lanValue));
    i18n.changeLanguage(lanValue);
	};

  const [showDropdown, setShowDropdown] = useState(true);
   
  useEffect(() => {
    let w = window.innerWidth;
    if (w < 960) {
      setShowDropdown(false)
    } 
    document.addEventListener("scroll", handleScrollss);
    return () => document.removeEventListener("scroll", handleScrollss);
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const scrollPage = (idDiv) => {
    navigate(`/`)  
    document.getElementById(idDiv).scrollIntoView({ behavior: 'smooth' });
  }
    return(
      <>
      <header className={"headerMain " + (fixedClass ? 'fixedNav': 'absoluteNav')}>
        <div className="headerTop">
            <Container>
              <div>
                <Nav as="ul">
                  <Nav.Item as="li"><Nav.Link className="p-0" href="mailto:support@kizan.com.sa"><FontAwesomeIcon icon={solid('envelope')} /> support@kizan.com.sa</Nav.Link></Nav.Item>
                  <Nav.Item as="li">&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<div className="ardirLtr d-inline-block"><FontAwesomeIcon icon={solid('phone')} /> +(800) 111 1202</div></Nav.Item>
                </Nav>
              </div>
                
                <div>
                  <Nav as="ul" className="corActive">
                      { location.pathname !== "/hotelItinerary" && location.pathname !== "/hotelConfirmation" && location.pathname !== "/tourItinerary" && location.pathname !== "/transferItinerary" && location.pathname !== "/privacy" &&
                        <NavDropdown as="li" title={getCurrency.currency}>
                          {getexchangeRates && getexchangeRates.exchangerates ?
                            Object.entries(getexchangeRates.exchangerates).map((item, index) => (
                              <NavDropdown.Item key={index} className={"fn13 ar-text-left " + (isActiveCur===item[0] ? 'active' : '')} onClick={()=>changeSlctdCur(item[0])}>{item[0]} </NavDropdown.Item>
                            ))
                            :
                            <NavDropdown.Item className="fn13 ar-text-left"><strong></strong></NavDropdown.Item>
                          }
                        </NavDropdown>
                      }
                      
                      <NavDropdown as="li" title={getLanguage.language==='ar' ? 'Arabic': 'English'} bsPrefix={"nav-link "+(isActiveLang==='ar' ? 'ArabicFlag' : 'EnglishFlag')}>
                        <NavDropdown.Item className={"ArabicFlag fn14 " + (isActiveLang==='ar' ? 'active' : '')} onClick={()=>changeLang('ar')}>Arabic</NavDropdown.Item>
                        <NavDropdown.Item className={"EnglishFlag fn14 " + (isActiveLang==='en' ? 'active' : '')} onClick={()=>changeLang('en')}>{t('English')}</NavDropdown.Item>
                      </NavDropdown>
                  </Nav>
                </div>
            </Container>
        </div>
        <Navbar expand="lg" className="cusnav">
          <Container>
            <Navbar.Toggle aria-controls="mainnavigation" className="p-0" />
            
            <Link to="/" className="navbar-brand"><img src={logoKZN} alt="Kizan Tourism" className="logoHeight" /></Link>
            <Nav.Link className="mt-2 border-0 float-right pe-0 fn18 text-dark d-block d-lg-none" onClick={()=>toggleDropdown()}><FontAwesomeIcon icon={solid('ellipsis-vertical')} /></Nav.Link>
            <Navbar.Collapse id="mainnavigation" className="mainNav">
              <Nav className="mx-auto mt-2">
                <Nav.Link onClick={()=>scrollPage('Home')}>{t('Home')}</Nav.Link>
                <Nav.Link onClick={()=>scrollPage('Services')}>{t('Services')}</Nav.Link>
                <Nav.Link onClick={()=>scrollPage('About')}>{t('About')}</Nav.Link>
                <Nav.Link href="#action2">{t('Blog')}</Nav.Link>
                <Nav.Link onClick={()=>scrollPage('Contact')}>{t('Contact')}</Nav.Link>
              </Nav>
            </Navbar.Collapse>
            {showDropdown &&
            <div className="acNav">
              <Dropdown align='end'>
                <Dropdown.Toggle variant="warning" className="d-inline-block myacBtn">{t('myAccount')}</Dropdown.Toggle>
                <Dropdown.Menu className="fn14">
                  <Dropdown.Item as="div" bsPrefix="p-2 pb0 text-center">
                    <Button variant="primary" size="sm"> {t('Signin')} </Button>
                    <div className="pt-2">New user? <Link href="#action2">{t('Register')}</Link></div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-1"><FontAwesomeIcon icon={solid('keyboard')} /> {t('ManageBooking')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            }
            

          </Container>
        </Navbar>
    </header>
    </>
    );
}
export default Header;