import React, { useState, useEffect } from "react";
import {useSelector} from 'react-redux';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";

const MapResults = () => {
  const { t} = useTranslation();
  const getCurrency = useSelector((state) => state.currency)
  const HtlResult = useSelector((state) => state.hotelList.htlResObj);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPAPIKEY
  })

  const [markers, setMarkers] = useState([])

  useEffect(() => {
    markersFunc();    
  }, [HtlResult]);

  const markersFunc = () => {
    let marObjct =[]
    if(HtlResult.hotels.hotelDetail && HtlResult.hotels.hotelDetail.length){
      HtlResult.hotels.hotelDetail.forEach((v) => {	
        const marker = {
          //sId:this.getOgHtlRes.generalInfo.sessionId,
          lat: parseFloat(v.geocodes.lat),
          lng: parseFloat(v.geocodes.lon),
          pdtName: v.productname,
          pdtCode: v.productcode,
          starRating: v.starrating,
          price: v.minprice,
          cur: getCurrency.currency
          }
          marObjct.push({ position: marker });
          //this.center = marker;
          //this.currentPlace = null;
      })
      setMarkers(marObjct)
    }
  };

  const [activeMarker, setActiveMarker] = useState(null);
  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  return ( 
    <div style={{width:'100%', height:'100%', minHeight:'700px'}}>
       {HtlResult.hotels.hotelDetail && HtlResult.hotels.hotelDetail.length ? 
        <>
          {
            isLoaded ? 
            <GoogleMap
              zoom={12}
              center={{
                lat: parseFloat(HtlResult.hotels.hotelDetail[0].geocodes.lat),
                lng: parseFloat(HtlResult.hotels.hotelDetail[0].geocodes.lon),
              }}
              //onLoad={handleOnLoad}
              onClick={() => setActiveMarker(null)}
              mapContainerStyle={{ width: "100%", height: "700px" }}>
                {markers.map((m, i) => (
                  <Marker
                    key={i}
                    position={m.position}
                    onClick={() => handleActiveMarker(i)}
                  >
                    {activeMarker === i ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>
                          <h3 className="nametitle">{m.position.pdtName} </h3>
                          <div className="mb3">
                            <span className="boxRating">
                              {
                                Array.apply(null, { length:Number(m.position.starRating)}).map((e, i) => (
                                  <FontAwesomeIcon key={i} icon={solid('star')} className="stargold" />
                                ))
                              }
                            </span>
                          </div>
                          <div className="nametitle">{m.position.cur} {parseFloat(m.position.price).toFixed(2)} </div>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                ))}
            </GoogleMap> :''
          }
        </>
        :
        <div className="text-center my-5">
          <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
          <div className="fn21">{t("NoResultFound")}</div>
        </div>
      }
    </div> 
  );
}
export default MapResults;