import React, {useEffect, useState } from "react";
import {useNavigate, useLocation } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {doPaymentReq} from "../../store/actions/payment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const PaymentOrder = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const qry = useLocation(); 

    useEffect(()=>{
        doBooking()
    }, []);

    const [htmlLoad, setHtmlLoad] = useState();

    const doBooking = async () => {
        if(qry.state){
            let obj = {
                "ServiceCode": qry.state.obj.serviceCode,
                "BookingNo": qry.state.obj.customerRefNumber.substring(0, qry.state.obj.customerRefNumber.indexOf("-")),
                "DomainName": window.location.host,
                "PGSupplier": 4,
                "SessionId": qry.state.obj.sessionId
            }
            let response = dispatch(doPaymentReq(obj));
            let res = await response
            if(res && res.pgResponseType && res.pgResponseType !==''){
                if(res.pgResponseType===1){
                    setHtmlLoad(res.responseDetail)
                } 
            }
            else{
                toast.error("Something Wrong !!",{theme: "colored"});
                setTimeout(() => {
                    sessionStorage.clear();
                    navigate(`/`)  
                }, 9000); 
            }
        }
    }

    useEffect(()=>{
        subMit()
    }, [htmlLoad]);

    const subMit = () => {
        if(htmlLoad){
            document.forms["nonseamless"].submit()
        }
    }

  return (
    <>
    <ToastContainer />
    <div className="innermiddle">
        <div className="vh-100 align-items-center d-flex justify-content-center">
            <div className="fn18 text-center mb-5">
                <FontAwesomeIcon icon={solid('spinner')} className="green my-4 slow-spin" style={{fontSize:"48px"}} />
                <br />Payment and booking is under process. Please do not refresh the page.<br /><br />
            </div>
            {htmlLoad && 
                <div dangerouslySetInnerHTML={{ __html: htmlLoad }}></div>
            }
        </div>
    </div>
    </>
  )
}

export default PaymentOrder
