import ApiService from './api.service'

const TourService = {
  doTourSearch: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: null,
      url: '/api/Tours/Search',
    }
    try {
      let tempReqObj = {
        "CustomerCode": reqObj.CustomerCode,
        "SearchParameter": {
          "DestinationCode": reqObj.SearchParameter.DestinationCode,
          "CountryCode": reqObj.SearchParameter.CountryCode,
          "ServiceDate": reqObj.SearchParameter.ServiceDate,
          "Currency": reqObj.SearchParameter.Currency,
          "Adults": reqObj.SearchParameter.Adults,
          "Children": reqObj.SearchParameter.Children,
          "TassProField": {
            "CustomerCode": 7,
            "RegionId": 65,
            "SupplierCodeFK": 3583,
            "H2HCode": 138,
            "H2HName": "LOCAL"
          },
        }
      }
      requestData.data = tempReqObj
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doTourDetails: async function (reqObj) {
    const requestData = {
      method: 'post',
      url: '/api/Tours/Detail',
      data: reqObj,
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doTourOptions: async function (reqObj) {
    const requestData = {
      method: 'post',
      url: '/api/Tours/Options',
      data: reqObj,
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  getTourCancelPolicy: async function (reqObj) {
    const requestData = {
      method: 'post',
      url: '/api/Tours/CancellationPolicy',
      data: reqObj,
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  getTourTimeSlot: async function (reqObj) {
    const requestData = {
      method: 'post',
      url: '/api/Tours/TimeSlot',
      data: reqObj,
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  getTourAvlb: async function (reqObj) {
    const requestData = {
      method: 'post',
      url: '/api/Tours/Availability',
      data: reqObj,
    }
    try {
      let tempReqObj = {
        "SessionId": reqObj.SessionId,
        "SearchParameter": {
          "DestinationCode": reqObj.SearchParameter.DestinationCode,
          "CountryCode": reqObj.SearchParameter.CountryCode,
          "ServiceDate": reqObj.SearchParameter.ServiceDate,
          "Currency": reqObj.SearchParameter.Currency,
          "GroupCode": reqObj.SearchParameter.GroupCode,
          "Adults": reqObj.SearchParameter.Adults,
          "RateKey": reqObj.SearchParameter.RateKey,
          "Children": reqObj.SearchParameter.Children,
        }
      }
      requestData.data = tempReqObj
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

}
export default TourService
export { TourService }