import React, { useState } from 'react';
import {useSelector} from 'react-redux';
import { useTranslation } from "react-i18next";
import {Col, Collapse, Button} from 'react-bootstrap';

const HotelFareBreakup = () => {
  const {t} = useTranslation();
  const reviewObj = useSelector((state) => state.hotelRepriceData)
  const getCurrency = useSelector((state) => state.currency)
  const [open, setOpen] = useState(true);

  return(
    <>
      <Col className="mb-2" lg={{span:4, order:2}} xs={{order:1}}>
        <div className="bg-white rounded shadow-sm sticky-top shadow-lg-none">
          <h3 className="nametitle blue plr10 pt-lg-3 plrlg0">{t("FareBreakup")} <Button size="sm" variant="link" className="fn14 text-warning d-inlineblock d-lg-none" onClick={() => setOpen(!open)}>(Show / Hide Details)</Button></h3>
          <Collapse in={open}>
            <div>
              {reviewObj.htlRepriceObj.hotel.rooms.room.map((v, i) => (
              <table className="table mb-0" key={i}>
                <tbody>
                  <tr>
                    <td>{t("BasePriceRoom")} </td>
                    <td className="text-end">{getCurrency.currency} {parseFloat(v.price.gross !== undefined ? v.price.gross : v.price.grossAmount).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
              ))
              }
              <table className="table mb-0">
                <tbody>
                  {reviewObj.htlRepriceObj.hotel.rooms.tax > 0 &&
                  <tr>
                    <td>{t("Tax")} </td>
                    <td className="text-end">{getCurrency.currency} {parseFloat(reviewObj.htlRepriceObj.hotel.rooms.tax).toFixed(2)}</td>
                  </tr>
                  }
                </tbody>
              </table>
            </div>
          </Collapse>
          { reviewObj.htlRepriceObj.hotel !== null && reviewObj.htlRepriceObj.hotel.rooms !== undefined &&
            <table className="table mb-0">
              <tbody>
                <tr className="bg-light">
                  <td><strong>{t("TotalAmount")}</strong></td>
                  <td className="text-end"><strong>{getCurrency.currency} {parseFloat(reviewObj.htlRepriceObj.hotel.rooms.net).toFixed(2)}</strong></td>
                </tr>
              </tbody>
            </table>
          }
          {/* <hr className="mt-0" /> */}
        </div>
      </Col>
    </>
  );
}
export default HotelFareBreakup;