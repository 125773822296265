import React, { useState } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Container, Row, Col, Button, Dropdown, Form, Collapse, ButtonGroup} from 'react-bootstrap';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker, { registerLocale } from "react-datepicker";
import {doTourSearchOnLoad} from "../../store/actions/tour";
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar-SA";
registerLocale("en", en);
registerLocale("ar", ar);


const TourModifySearch = ({Type, TourReq, filterOpen}) => {
  const dispatch = useDispatch();
  const { t} = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(TourReq !== '' ? [{
    cityName:TourReq.SearchParameter.selectedCity, 
    countryCode: TourReq.SearchParameter.CountryCode, 
    countryName:TourReq.SearchParameter.selectedCountry,
    giDestinationId: TourReq.SearchParameter.DestinationCode
  }]:[]);

  const handleSearch = async (query) => {
    setIsLoading(true);
    setOptions([]);
    const res = await axios.post(process.env.REACT_APP_DESTINATION_PREDICTIONS, {
      "text": query,
      "customercode": "KZN"
    })
    setOptions(res.data.data);
    setIsLoading(false);
  };
  const filterBy = () => true;
  const getLanguage = useSelector((state) => state.language)
  const [selectedDestination, setSelectedDestination] = useState(options);
  const [serviceDate, setServiceDate] = useState(TourReq !== '' ? new Date(TourReq.SearchParameter.ServiceDate) : new Date());
  
  const [showPaxDropdown, setShowPaxDropdown] = useState(false);
  const [adtVal, setAdtVal] = useState(TourReq !== '' ? TourReq.qry.adults : "1");
  const [chdVal, setChdVal] = useState(TourReq !== '' ? TourReq.qry.children : "0");
  const [chdAgesArr, setChdAgesArr] = useState(TourReq !== '' ? TourReq.qry.ca.split(',') : []);
  
  const PaxDropdown = () => {
    
    const updateChildAges = (value) =>{
      setChdVal(value)
      let chdAgesArrNew = []
      let chdCount = parseInt(value)
      if (parseInt(chdCount) > 0) {
        for (var count = 0; count < chdCount; count++) {
          chdAgesArrNew.push("2")
        }
      }
      setChdAgesArr(chdAgesArrNew)
    }

    const chdAgeChange = (e,index) =>{
      let items = [...chdAgesArr];
      items[index] = e.target.value
      setChdAgesArr(items)
    }
  
    const doneClick = () => {
      setShowPaxDropdown(false)              
    }

    return(
      <>
        <Dropdown align='end' show={showPaxDropdown}>
          <Dropdown.Toggle className="w-100 p-0 h-0 invisible"></Dropdown.Toggle>
          <Dropdown.Menu className="fn14 dropArrow px-2 w-300" flip={false}>
            <div>
              <Row className="gx-2">
                <Col xs={6} className="mb-2">
                    <label>&nbsp; {t("adult")}</label>
                    <Form.Select size="sm" value={adtVal} onChange={(e) => setAdtVal(e.target.value)}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                      <option value="19">19</option>
                    </Form.Select>
                </Col>
                <Col xs={6} className="mb-2">
                    <label>&nbsp;{t("childern")}</label>
                    <Form.Select size="sm" value={chdVal} onChange={(e)=> updateChildAges(e.target.value)}>
                      <option value="0">-</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                    </Form.Select>
                </Col>
              </Row>
              {parseInt(chdVal) > 0 &&
              <Row className="gx-1">
                <Col xs={12}>
                  <label>&nbsp;{t("ageChildern")}</label>
                </Col>
                {chdAgesArr.map((age, index) => (
                  <Col xs={3} className="mb-2" key={index}>
                    <Form.Select size="sm" value={chdAgesArr[index]} onChange={(e) => chdAgeChange(e,index)}>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                    </Form.Select>
                  </Col>
                ))}  
              </Row>
              }
              

              <Dropdown.Divider />
            </div>
            <Row className="gx-2 justify-content-end">
              <Col xs="auto">
                <Button variant="primary" size="sm" onClick={()=> doneClick()}>{t("done")}</Button>
              </Col>
            </Row>
          </Dropdown.Menu>
        </Dropdown> 
      </>
    )
  }

  const totalGuest = () => {
    let guest = 0
    guest = guest + parseInt(adtVal) + parseInt(chdVal)
    return guest
  }

  const validate = () => {
    let status = true
    let destiCode = (selectedDestination.length ? selectedDestination.giDestinationId : '')
    let selectedCity = (selectedDestination.length ? selectedDestination.cityName : '')
    if (destiCode === '' || selectedCity === '') {
      status = false
      toast.error("Please Select The Destination!",{theme: "colored"})
      return false
    }
    if (serviceDate === '' || serviceDate === null) {
      status = false
      toast.error("Please select service date",{theme: "colored"})
      return false
    }
   
    return status
  }

  const srchTour = () => {
    let allowMe = validate();
    if (allowMe) {
      let qry = {
        //destinationQuery: selectedDestination.length ? selectedDestination[0].cityName : TourReq.SearchParameter.selectedCity+','+ selectedDestination.length ? selectedDestination[0].countryName : TourReq.SearchParameter.selectedCountry,
        selectedCity: selectedDestination.length ? selectedDestination[0].cityName : TourReq.SearchParameter.selectedCity,
        selectedCountry: selectedDestination.length ? selectedDestination[0].countryName : TourReq.SearchParameter.selectedCountry,
        destinationCode: selectedDestination.length ? selectedDestination[0].giDestinationId : TourReq.SearchParameter.DestinationCode,
        countryCode: selectedDestination.length ? selectedDestination[0].countryCode : TourReq.SearchParameter.CountryCode,
        serviceDate: format(serviceDate, 'yyyy-MM-dd'),
        adults: adtVal,
        children: chdVal,
        ca: ''
      }

      qry['ca'] = chdAgesArr.map(function (e) {
        return e;
      }).join(",")

      let tourSrchObj = {
        "CustomerCode": "",
        "SearchParameter": {
          "DestinationCode": selectedDestination.length ? selectedDestination[0].giDestinationId : TourReq.SearchParameter.DestinationCode,
          "CountryCode": selectedDestination.length ? selectedDestination[0].countryCode : TourReq.SearchParameter.CountryCode,
          "selectedCity": selectedDestination.length ? selectedDestination[0].cityName : TourReq.SearchParameter.selectedCity,
          "selectedCountry": selectedDestination.length ? selectedDestination[0].countryName : TourReq.SearchParameter.selectedCountry,
          "ServiceDate": format(serviceDate, 'yyyy-MM-dd'),
          "Currency": process.env.REACT_APP_CURRENCY,
          "Adults": parseInt(adtVal),
        }
      }

      if (parseInt(chdVal) > 0) {
        let childrenObj = {}
        let arrChildAges = []
        let indx = 0

        for (var k = 0; k < chdAgesArr.length; k++) {
          indx = indx + 1
          let ageObj = {}
          ageObj.Identifier = indx
          ageObj.Text = chdAgesArr[k]
          arrChildAges.push(ageObj)
        }

        childrenObj.Count = parseInt(chdVal)
        childrenObj.ChildAge = arrChildAges;
        tourSrchObj.SearchParameter.Children = childrenObj
      }
      tourSrchObj.qry = qry

      if (Type === 'result') {
        navigate({
          pathname: "/tourListing",
          search: createSearchParams(qry).toString(),
        })
        dispatch(doTourSearchOnLoad(tourSrchObj));
      }
      else{
        navigate({
          pathname: "/tourListing",
          search: createSearchParams(qry).toString(),
        })
      }

    }
  }

  const [modifyCollapse, setModifyCollapse] = useState(false);

  return(
    <>
    <ToastContainer />
    {Type === 'landing' ?
      <div className="searchColumn">
        <Row className="gx-2">
          <Col lg={10}>
            <Row className="gx-2">
              <Col lg={6}>
                <div className="form-group mb-4">
                  <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} /> {t("Entercitycountryname")}</label>
                  <AsyncTypeahead clearButton
                    highlightOnlyResult={true}
                    filterBy={filterBy}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey={(option) => `${option.cityName}, ${option.countryName}`}
                    minLength={3}
                    onSearch={handleSearch}
                    options={options}
                    placeholder={t('pleaseEnterDestination')}
                    className="typeHeadDropdown"
                    selected={selectedDestination}
                    onChange={setSelectedDestination}
                    inputProps={{
                      className: 'hmform-control',
                    }}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group mb-4">
                  <div className="designCalendar">
                    <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('calendar-alt')} /> {t("Date")}</label>
                    <DatePicker className="form-control hmform-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={serviceDate} minDate={new Date()} onChange={ (date) => setServiceDate(date)} />
                  </div>
                </div>
              </Col>
              <Col lg={3} className="passdropdown">
                <div className="form-group mb-4">
                  <label className="text-white">&nbsp; {t("Noguests")}</label>
                  <Button variant="light" className="hmform-control w-100 text-start dropdown-toggle" onClick={() => setShowPaxDropdown(true)}>{totalGuest()} {t("Travelers") }</Button>
                  <PaxDropdown />
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={2} className="searchBtn">
            <div className="mb-4">
              <label className="d-none d-lg-block">&nbsp;</label>
              <Button variant="warning" className="hmform-btn" onClick={()=>srchTour()}><FontAwesomeIcon icon={solid('search')} className="fn16 mt-n1 align-middle" /> {t("Search")}</Button>
            </div>
          </Col>
        </Row>
      </div>
    : 
    <div className="modifycol">
      <Container> 
        {modifyCollapse ? 
        ''
        : 
        <>
          <div className="fn15 d-lg-flex justify-content-between align-items-center d-none">
            <div className="py-1">
                {selectedDestination[0].cityName}, {selectedDestination[0].countryName} &nbsp;|&nbsp; {format(serviceDate, 'dd MMM yyyy')} &nbsp;|&nbsp; {totalGuest()} Guest(s)
            </div>
            <div className="py-2"><Button onClick={() => setModifyCollapse(!modifyCollapse)} aria-controls="modifyCollapse" variant="warning" className="fn13"><strong>{t("ModifySearch")}</strong></Button></div>
          </div>
          <div>
            <ButtonGroup size="sm" className="w-100 py-2 d-lg-none d-inline-flex">
              <Button variant="outline-secondary" onClick={() => filterOpen(true)}><FontAwesomeIcon icon={solid('filter')} className="green" /> {t("Filter")}</Button>
              <Button variant="outline-secondary" onClick={() => setModifyCollapse(!modifyCollapse)}><FontAwesomeIcon icon={solid('search')} className="green" /> {t("Search")}</Button>
            </ButtonGroup>
          </div>
        </>
        }
        <Collapse in={modifyCollapse}>
          <div className="position-relative pt-3">
            <Button variant="link" onClick={() => setModifyCollapse(!modifyCollapse)} className="crossBtn p-0"><FontAwesomeIcon icon={regular('times-circle')} className="text-dark" /></Button>
            <Button variant="link" onClick={() => setModifyCollapse(!modifyCollapse)} className="crossBtn1 d-none d-lg-block p-0"><FontAwesomeIcon icon={regular('times-circle')} className="text-dark" /></Button>
            <Row className="gx-2">
              <Col lg={5} md={6}>
                <div className="my-2">
                  <label className="fn13">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} className="green" /> {t("Entercitycountryname")}</label>
                  <AsyncTypeahead clearButton
                    highlightOnlyResult={true}
                    filterBy={filterBy}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey={(option) => `${option.cityName}, ${option.countryName}`}
                    minLength={3}
                    onSearch={handleSearch}
                    options={options}
                    placeholder={t('pleaseEnterDestination')}
                    className="typeHeadDropdown"
                    selected={selectedDestination}
                    onChange={setSelectedDestination}
                    inputProps={{
                      className: 'fn13',
                    }}
                  />
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="my-2">
                  <div className="designCalendar">
                    <label className="fn13">&nbsp;<FontAwesomeIcon icon={solid('calendar-alt')} className="green" /> {t("Date")}</label>
                    <DatePicker className="fn13 form-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={serviceDate} minDate={new Date()} onChange={ (date) => setServiceDate(date)} />
                  </div>
                </div>
              </Col>
              <Col lg={3} md={12} className="passdropdown">
                <div className="my-2">
                  <label className="fn13">&nbsp; {t("Noguests")}</label>
                  <Button variant="light" className="w-100 text-start dropdown-toggle bg-white border fn13 arroBtn" onClick={() => setShowPaxDropdown(true)}>{totalGuest()} {t("Travelers") }</Button>
                  <PaxDropdown />
                </div>
              </Col>
              <Col lg={1} sm={3}>
                <div className="my-2">
                  <label className="d-none d-md-block fn13">&nbsp;</label>
                  <Button variant="warning" className="w-100 fn13 mb-3" onClick={()=>srchTour()}>{t("Search")}</Button>
                </div>
              </Col>
            </Row>
          </div>
        </Collapse>   
      </Container>
    </div>
    }
    </>
  );
}
export default TourModifySearch;