import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Form, Card, Button, Collapse, Accordion} from 'react-bootstrap';
import {doFilterSort} from "../../store/actions/transfer";
import ReactSlider from 'react-slider';

const TransferFilter = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getCurrency = useSelector((state) => state.currency);
  const [filterCollapse, setFilterCollapse] = useState(true);
  useEffect(() => {
    let w = window.innerWidth;
    if (w < 960) {
      setFilterCollapse(false)
    }
  }, []);

  useEffect(() => {
    setFilterCollapse(props.filterChoose)
  }, [props]);

  const OrgTrnsfrResult = useSelector((state) => state.transferList.transferResOrgObj);
  const TrnsfrFilterSort = useSelector((state) => state.transferFltrSort.transferFilterSort);

  const [classNone, setClassNone] = useState('d-none');
  const [priceFilter, setPriceFilter] = useState(OrgTrnsfrResult.transfers && OrgTrnsfrResult.transfers.transfer.length && [Number(parseFloat(OrgTrnsfrResult.transfers.transfer[0].vehicleDetails.vehicleDetail[0].rate.supplierTotalNet -1).toFixed(2)), Number(parseFloat(OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail[0].rate.supplierTotalNet + 1).toFixed(2))]);
  const [minPrice, setMinPrice] =useState(OrgTrnsfrResult.transfers && OrgTrnsfrResult.transfers.transfer.length && Number(parseFloat(OrgTrnsfrResult.transfers.transfer[0].vehicleDetails.vehicleDetail[0].rate.supplierTotalNet -1).toFixed(2)));
  const [maxPrice, setMaxPrice] =useState(OrgTrnsfrResult.transfers && OrgTrnsfrResult.transfers.transfer.length && Number(parseFloat(OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail[OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail.length - 1].rate.supplierTotalNet + 1).toFixed(2)));

  useEffect(() => {
    setPriceFilter(OrgTrnsfrResult.transfers && OrgTrnsfrResult.transfers.transfer.length && [Number(parseFloat(OrgTrnsfrResult.transfers.transfer[0].vehicleDetails.vehicleDetail[0].rate.supplierTotalNet -1).toFixed(2)), Number(parseFloat(OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail[OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail.length - 1].rate.supplierTotalNet + 1).toFixed(2))])
    setMinPrice(OrgTrnsfrResult.transfers && OrgTrnsfrResult.transfers.transfer.length && Number(parseFloat(OrgTrnsfrResult.transfers.transfer[0].vehicleDetails.vehicleDetail[0].rate.supplierTotalNet -1).toFixed(2)));
    setMaxPrice(OrgTrnsfrResult.transfers && OrgTrnsfrResult.transfers.transfer.length && Number(parseFloat(OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail[OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail.length - 1].rate.supplierTotalNet + 1).toFixed(2)));
  }, [getCurrency]);

  const [arrVehicleType, setArrVehicleType] = useState([]);

  const [typeVehicle, setTypeVehicle] = useState([]);

  useEffect(() => {
    filterSort();
    
  }, [priceFilter, typeVehicle]);

  useEffect(()=>{
    if(OrgTrnsfrResult.transfers && OrgTrnsfrResult.transfers.transfer.length > 0){
      getVehicleTypes(OrgTrnsfrResult.transfers.transfer);
    }
  },[OrgTrnsfrResult]);

  const getVehicleTypes = (data) => {
    let items = [...arrVehicleType];
    let vehiclecode = '', vehiclename = '', price = 0, currency = '', vehiclevalue;
    let prevehicle, keyparts, preprice = 0, precurrency = '', runningcount = 0, totalnumresult = 0;
    for (let i = 0; i < data.length; i++) {
      for (var j = 0; j < data[i].vehicleDetails.vehicleDetail.length; j++) {
        price = (Number(data[i].vehicleDetails.vehicleDetail[j].rate.supplierTotalNet)).toFixed(2);

        if (data[i].vehicleDetails.vehicleDetail[j].vehicle !== null) {
          vehiclecode = data[i].vehicleDetails.vehicleDetail[j].vehicle.code;
          vehiclename = data[i].vehicleDetails.vehicleDetail[j].vehicle.text == '' ? 'N.A.' : data[i].vehicleDetails.vehicleDetail[j].vehicle.text;
          vehiclevalue = vehiclename + '|' + price + '|' + currency;

          var isVehicleCodeInArray = items.filter(function (item) { return item.key == vehiclecode });

          if (isVehicleCodeInArray == 0) {
            items.push({
              key: vehiclecode, value: vehiclevalue + '|1'
            });
          } else {
            prevehicle = items.filter(function (item) { if (item.key == vehiclecode) { return item } });
            keyparts = prevehicle[0].value.split('|');
            preprice = keyparts[1];
            precurrency = keyparts[2];
            runningcount = keyparts[3];
            totalnumresult = parseInt(runningcount) + parseInt(1);

            if (Number(price) < Number(preprice)) {
              preprice = price;
            }
            vehiclevalue = vehiclename + '|' + preprice + '|' + precurrency;

            var vehicleindex = items.indexOf(prevehicle[0]);

            if (vehicleindex != -1) {
              items.splice(vehicleindex, 1);
            }
            items.push({
                key: vehiclecode, value: vehiclevalue + '|' + totalnumresult
            });
          }
        }
      }
    }
    setArrVehicleType(items)
  }

  const vehicleTypeChange = (e)=> {
    if(e.target.checked === true){
      setTypeVehicle([...typeVehicle, e.target.value]);
    }
    else if(e.target.checked === false){
      let freshArray = typeVehicle.filter(val => val !== e.target.value);
      setTypeVehicle([...freshArray]);
    }
  };

  const filterSort = () =>{
    let transferFilters = {
      typeVehicle: typeVehicle,
      priceFilter: priceFilter
    }
    let tranfrFilterSorts = { srtVal: '0', srchTxt: ''}
    let obj = { 'transferFilters': transferFilters, 'transferFilterSort': TrnsfrFilterSort ? TrnsfrFilterSort : tranfrFilterSorts}
    dispatch(doFilterSort(OrgTrnsfrResult, obj));
  };

  const reset = () =>{
    setPriceFilter([Number(parseFloat(OrgTrnsfrResult.transfers.transfer[0].vehicleDetails.vehicleDetail[0].rate.supplierTotalNet -1).toFixed(2)), Number(parseFloat(OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail[OrgTrnsfrResult.transfers.transfer[OrgTrnsfrResult.transfers.transfer.length - 1].vehicleDetails.vehicleDetail.length - 1].rate.supplierTotalNet + 1).toFixed(2))])
    setTypeVehicle([]);
    let transferFilters = {typeVehicle: [], priceFilter: []}
    let transferFilterSort = {srtVal: '0', srchTxt: ''}
    let obj = { 'transferFilters': transferFilters, 'transferFilterSort': transferFilterSort}
    dispatch(doFilterSort(OrgTrnsfrResult, obj));
  }

  return(
    <>
    {OrgTrnsfrResult.transfers && OrgTrnsfrResult.transfers.transfer.length > 0 ? 
      <div className="d-lg-table-cell align-top mainContent">
        <div className="leftFilter">
          <div className="sidebarinner">
            <Collapse in={filterCollapse} id="leftCollapse" className="position-relative">
              <div>
              <Button onClick={() => props.filterClose(false)} variant='link' className="crossBtn mt-2 p-0"><FontAwesomeIcon icon={solid('times-circle')} className="text-danger" /></Button>
                <div className="bg-white px-2 py-3 border-bottom">
                    <span className="fn16 blue align-middle"><strong>{t("FilterYourSearch")}</strong></span> &nbsp; <Button variant='link' className="badge bg-light text-dark float-lg-end fn14 fw-normal" onClick={reset}>{t("ResetAll")}</Button>
                </div>

                <Accordion alwaysOpen defaultActiveKey={['0','1']}>
                  <Accordion.Item as={Card} eventKey="0">
                    <Accordion.Header as={Card.Header}>{t("Price")}</Accordion.Header>
                    <Accordion.Body as={Card.Body}>
                      <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        defaultValue={priceFilter}
                        min={minPrice}
                        max={maxPrice}
                        value={priceFilter}
                        onChange={event => setPriceFilter(event)}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                        renderThumb={(props) => <div {...props}></div>}
                        />
                      <div className="my-1 text-muted d-flex justify-content-between fn12">
                        <span>{getCurrency.currency} {priceFilter[0]}</span>
                        <span>{getCurrency.currency} {priceFilter[1]}</span>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item as={Card} eventKey="1">
                    <Accordion.Header as={Card.Header}>{t("VehicleType")}</Accordion.Header>
                    <Accordion.Body as={Card.Body}>
                      <div>
                        {arrVehicleType && arrVehicleType.length > 0 &&
                          <>
                          { arrVehicleType.map((vt, i) => {
                            return (
                            <div key={i} className={`moreBox ${i > 14 ? classNone :''}`}>
                              <Form.Check id={`chkVehicleType${i}`}>
                                <Form.Check.Input value={vt.key} onChange={e => vehicleTypeChange(e)} checked={typeVehicle.includes(vt.key)} />
                                <Form.Check.Label className='mb-0 w-100 fn13'>
                                  <span className="w-100 d-flex justify-content-between">
                                    <span>{vt.value.split("|")[0]}</span>
                                  </span>
                                </Form.Check.Label>
                              </Form.Check>
                            </div>
                            )
                          })
                          }
                          { arrVehicleType.length > 14 && 
                            <div>
                              <Button onClick={()=> classNone==='d-none' ? setClassNone('d-block') : setClassNone('d-none')} variant='link' className='p-0 fn13'>
                                {classNone==='d-none' ? 'Show more': 'Show less'} 
                              </Button>
                            </div>
                          }
                          </>
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      : ''
    }
    </>
  );
}
export default TransferFilter;