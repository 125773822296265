import React, {useEffect, useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios'
import { useNavigate, createSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Container, Row, Col, Button, Dropdown, Form, Collapse, ButtonGroup} from 'react-bootstrap';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker, { registerLocale } from "react-datepicker";
import {doTransferSearchOnLoad, doLocalTransferLocations} from "../../store/actions/transfer";
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar-SA";
registerLocale("en", en);
registerLocale("ar", ar);

const TransferModifySearch = ({Type, TransferReq, filterOpen}) => {
  const dispatch = useDispatch();
  const { t} = useTranslation();
  const navigate = useNavigate();
  const getLanguage = useSelector((state) => state.language)
  const [modifyCollapse, setModifyCollapse] = useState(false);

  const filterBy = () => true;
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([{
    "destination": "JEDDAH,Saudi Arabia", "cityCode": "JED", "countryCode": "966", "cityName": "JEDDAH", "countryName": "Saudi Arabia", "countryISOCode": "SA"
  }]);

  const [selectedDestination, setSelectedDestination] = useState(options);
  const [pickupDate, setPickupDate] = useState(TransferReq !== '' ? new Date(TransferReq.SearchParameter.PickupDetails.PickupDate) : new Date());
  const [pickupHour, setPickupHour] = useState(TransferReq !== '' ? TransferReq.qry.pickupHour : '0');
  const [pickupMin, setPickupMin] = useState(TransferReq !== '' ? TransferReq.qry.pickupMin : '0');
  const [returnDate, setReturnDate] = useState(TransferReq !== '' ? new Date(TransferReq.SearchParameter.returnDate) : new Date());
  const [returnHour, setReturnHour] = useState('0');
  const [returnMin, setReturnMin] = useState('0');
  const [isSingleDate, setIsSingleDate] = useState(false);
  
  const endRef = useRef();
  const disableTo = () => {
    if(isSingleDate){
      setReturnDate(new Date(new Date(pickupDate)))
      endRef.current.setOpen(true);    
    }
  };
  
  const handleSearch = async (query) => {
    setIsLoading(true);
    setOptions([]);
    const res = await axios.post(process.env.REACT_APP_DESTINATION_PREDICTIONS, {
      "text": query,
      "customercode": "KZN"
    })
    setOptions(res.data.data);
    setIsLoading(false);
  };

  const [transferType, setTransferType] = useState('1');
  const [pickupLoc, setPickupLoc] = useState(TransferReq !== '' ? JSON.parse(TransferReq.qry.pickupLoc) : {location:'0'});
  const [pickupLocOption, setPickupLocOption] = useState([]);
  const [dropLoc, setDropLoc] = useState(TransferReq !== '' ? JSON.parse(TransferReq.qry.dropLoc) : {location:'0'});
  const [dropLocOption, setDropLocOption] = useState([]);
  const [customerCode, setCustomerCode] = useState('3');
  const [pickupAddress, setPickupAddress] = useState(TransferReq !== '' ? TransferReq.SearchParameter.PickupDetails.Address : '');
  const [pickupGIATACode, setPickupGIATACode] = useState(TransferReq !== '' ? JSON.parse(TransferReq.qry.pickupLoc).locationCode : '');
  const [pickupFullAddress, setPickupFullAddress] = useState('');
  const [pickupGooglePlacesCode, setPickupGooglePlacesCode] = useState('');
  const [pickupGeo, setPickupGeo] = useState('');
  const [pickupCity, setPickupCity] = useState('');
  const [pickupISOCountryCode, setPickupISOCountryCode] = useState('');
  const [pickupType, setPickupType] = useState('Airport');
  const [dropoffAddress, setDropoffAddress] = useState('');
  const [dropoffFullAddress, setDropoffFullAddress] = useState('');
  const [dropoffGooglePlacesCode, setDropoffGooglePlacesCode] = useState('');
  const [dropoffGeo, setDropoffGeo] = useState('');
  const [dropoffCity, setDropoffCity] = useState('');
  const [dropoffISOCountryCode, setDropoffISOCountryCode] = useState('');
  const [dropoffType, setDropoffType] = useState('Hotel');
  const [dropoffGIATACode, setDropoffGIATACode] = useState(TransferReq !== '' ? JSON.parse(TransferReq.qry.dropLoc).locationCode : '');

  const transferTypeChange = (e) =>{
    setTransferType(e.target.value);
    setPickupLoc({location:'0'});
    setDropLoc({location:'0'});
    if(selectedDestination) {
      switch (e.target.value) {
        case "0":  
        setPickupLocOption([]);
        setDropLocOption([]);
        setIsSingleDate(false)
        break;

        case "1": // Arrival
        PopulateTransferLocLocal("pickupLoc", customerCode, selectedDestination[0].countryCode, 1, 1);
        PopulateTransferLocLocal("dropoffLoc", customerCode, selectedDestination[0].countryCode, 0, 1);
        setIsSingleDate(false)
        setPickupType('Airport')
        setDropoffType('Hotel')
        break;

        case "2": // Departure
        PopulateTransferLocLocal("pickupLoc", customerCode, selectedDestination[0].countryCode, 1, 2);
        PopulateTransferLocLocal("dropoffLoc", customerCode, selectedDestination[0].countryCode, 0, 2);
        setIsSingleDate(false)
        setPickupType('Hotel')
        setDropoffType('Airport')
        break;

        case "3": // Arrival/Departure
        PopulateTransferLocLocal("pickupLoc", customerCode, selectedDestination[0].countryCode, 1, 3);
        PopulateTransferLocLocal("dropoffLoc", customerCode, selectedDestination[0].countryCode, 0, 3);
        setIsSingleDate(true)
        setPickupType('Airport')
        setDropoffType('Hotel')
        break;

        case "4": // Inter City
        PopulateTransferLocLocal("pickupLoc", customerCode, selectedDestination[0].countryCode, 1, 4);
        PopulateTransferLocLocal("dropoffLoc", customerCode, selectedDestination[0].countryCode, 0, 4);
        setIsSingleDate(false)
        setPickupType('Address')
        setDropoffType('Address')
        break;

      }
    }
    else{
      setTransferType('0')
      setPickupLocOption([])
      setDropLocOption([])
      toast.error("Please Select The Destination!",{theme: "colored"})
    }
  }

  useEffect(()=>{
    PopulateTransferLocLocal("pickupLoc", customerCode, selectedDestination[0].countryCode, 1, 1);
    PopulateTransferLocLocal("dropoffLoc", customerCode, selectedDestination[0].countryCode, 0, 1);
  },[selectedDestination]);

  const PopulateTransferLocLocal = async(id, customerCode, countryCode, locationType, transferType) =>{
    let transferLocLocalObj = {
      "CustomerCode": customerCode,
      "CountryCode": countryCode,
      "LocationType": locationType,
      "TransferType": transferType
    }
    let response = dispatch(doLocalTransferLocations(transferLocLocalObj));
    let localTransferLocations = await response
    if(id==='pickupLoc'){
      setPickupLocOption(localTransferLocations)
    }
    else{
      setDropLocOption(localTransferLocations)
    }
  }

  const pickupLocChange = (e) =>{
    let value = JSON.parse(e.target.value)
    setPickupLoc(value)
    setPickupAddress(value.location)
    setPickupGIATACode(value.locationCode)
  }

  const dropoffLocChange = (e) =>{
    let value = JSON.parse(e.target.value)
    setDropLoc(value)
    setDropoffAddress(value.location)
    setDropoffGIATACode(value.locationCode)
  }

  const [showPaxDropdown, setShowPaxDropdown] = useState(false);
  const [adtVal, setAdtVal] = useState(TransferReq !== '' ? TransferReq.qry.adults : "1");
  const [chdVal, setChdVal] = useState(TransferReq !== '' ? TransferReq.qry.children : "0");
  const [chdAgesArr, setChdAgesArr] = useState(TransferReq !== '' ? TransferReq.qry.ca.split(',') : []);
  
  const PaxDropdown = () => {
    const updateChildAges = (value) =>{
      setChdVal(value)
      let chdAgesArrNew = []
      let chdCount = parseInt(value)
      if (parseInt(chdCount) > 0) {
        for (var count = 0; count < chdCount; count++) {
          chdAgesArrNew.push("2")
        }
      }
      setChdAgesArr(chdAgesArrNew)
    }

    const chdAgeChange = (e,index) =>{
      let items = [...chdAgesArr];
      items[index] = e.target.value
      setChdAgesArr(items)
    }
  
    const doneClick = () => {
      setShowPaxDropdown(false)              
    }

    return(
      <>
        <Dropdown align='end' show={showPaxDropdown}>
          <Dropdown.Toggle className="w-100 p-0 h-0 invisible"></Dropdown.Toggle>
          <Dropdown.Menu className="fn14 dropArrow px-2 w-300" flip={false}>
            <div>
              <Row className="gx-2">
                <Col xs={6} className="mb-2">
                  <label>&nbsp; {t("adult")}</label>
                  <Form.Select size="sm" value={adtVal} onChange={(e) => setAdtVal(e.target.value)}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                  </Form.Select>
                </Col>
                <Col xs={6} className="mb-2">
                  <label>&nbsp;{t("childern")}</label>
                  <Form.Select size="sm" value={chdVal} onChange={(e)=> updateChildAges(e.target.value)}>
                    <option value="0">-</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                  </Form.Select>
                </Col>
              </Row>
              {parseInt(chdVal) > 0 &&
              <Row className="gx-1">
                <Col xs={12}>
                  <label>&nbsp;{t("ageChildern")}</label>
                </Col>
                {chdAgesArr.map((age, index) => (
                  <Col xs={3} className="mb-2" key={index}>
                    <Form.Select size="sm" value={chdAgesArr[index]} onChange={(e) => chdAgeChange(e,index)}>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                      <option value="13">13</option>
                      <option value="14">14</option>
                      <option value="15">15</option>
                      <option value="16">16</option>
                      <option value="17">17</option>
                      <option value="18">18</option>
                    </Form.Select>
                  </Col>
                ))}  
              </Row>
              }
              <Dropdown.Divider />
            </div>
            <Row className="gx-2 justify-content-end">
              <Col xs="auto">
                <Button variant="primary" size="sm" onClick={()=> doneClick()}>{t("done")}</Button>
              </Col>
            </Row>
          </Dropdown.Menu>
        </Dropdown> 
      </>
    )
  }

  const totalGuest = () => {
    let guest = 0
    guest = guest + parseInt(adtVal) + parseInt(chdVal)
    return guest
  }

  const validate = () => {
    let status = true
    //let destiCode = (selectedDestination.length ? selectedDestination.giDestinationId : '')
    let selectedCity = (selectedDestination.length ? selectedDestination[0].cityName : '')
    if (selectedCity === '') {
      status = false
      toast.error("Please Select The Destination!",{theme: "colored"})
      return false
    }
    if (transferType === '0') {
      status = false
      toast.error("Please Select Direction",{theme: "colored"})
      return false
    }
    if (pickupLoc.location === '0' || pickupGIATACode === '') {
      status = false
      toast.error("Please Select Pickup Location",{theme: "colored"})
      return false
    }
    if (dropLoc.location === '0' || dropoffGIATACode === '') {
      status = false
      toast.error("Please Select Dropoff Location",{theme: "colored"})
      return false
    }
    if (pickupDate === '' || pickupDate === null) {
      status = false
      toast.error("Please Select Pickup Date/Time",{theme: "colored"})
      return false
    }
    if(isSingleDate){
      if (returnDate === '' || returnDate === null) {
        status = false
        toast.error("Please Select Return Date/Time",{theme: "colored"})
        return false
      }
    }
    return status
  }

  const srchTransfer = () => {
    let allowMe = validate();
    if (allowMe) {
      let qry = {
        destinationCode: selectedDestination.length ? selectedDestination[0].cityCode : TransferReq.SearchParameter.cityCode,
        transferType: transferType,
        pickupAddress: pickupAddress,
        pickupFullAddress: pickupFullAddress,
        pickupGooglePlacesCode: pickupGooglePlacesCode,
        pickupGeo: pickupGeo,
        pickupCity: pickupCity,
        pickupISOCountryCode: pickupISOCountryCode,
        pickupType: pickupType,
        dropoffAddress: dropoffAddress,
        dropoffFullAddress: dropoffFullAddress,
        dropoffGooglePlacesCode: dropoffGooglePlacesCode,
        dropoffGeo: dropoffGeo,
        dropoffCity: dropoffCity,
        dropoffISOCountryCode: dropoffISOCountryCode,
        dropoffType: dropoffType,
        pickupDate: format(pickupDate, 'yyyy-MM-dd'),
        returnDate: isSingleDate === false ? "" : format(returnDate, 'yyyy-MM-dd'),
        pickupTime: pickupHour+':'+pickupMin,
        pickupHour: pickupHour,
        pickupMin: pickupMin,
        returnTime: isSingleDate === false ? "" : returnHour+':'+returnMin,
        returnHour: isSingleDate === false ? "" : returnHour,
        returnMin: isSingleDate === false ? "" : returnMin,
        pickupGIATACode: pickupGIATACode,
        dropoffGIATACode: dropoffGIATACode,
        pickupLoc: JSON.stringify(pickupLoc),
        dropLoc: JSON.stringify(dropLoc),
        pickupLocOption: JSON.stringify(pickupLocOption),
        dropLocOption: JSON.stringify(dropLocOption),
        isSingleDate: isSingleDate,
        adults: adtVal,
        children: chdVal,
        ca: ''
      }

      qry['ca'] = chdAgesArr.map(function (e) {
        return e;
      }).join(",")

      let transferSrchObj = {
        "SearchParameter": {
          "DestinationCode": selectedDestination.length ? selectedDestination[0].cityCode : TransferReq.SearchParameter.DestinationCode,
          "TransferType": isSingleDate === false ? "OneWay" : "RoundTrip",
          "PickupDetails": {
            "Address": pickupAddress,
            "Type": pickupType,
            "Geo": pickupGeo,
            "GooglePlacesCode": pickupGooglePlacesCode,
            "PickupDate": format(pickupDate, 'yyyy-MM-dd'),
            "PickupTime": pickupHour+':'+pickupMin,
            "GIATACode": pickupGIATACode
          },
          "DropoffDetails": {
            "Address": dropoffAddress,
            "Type": dropoffType,
            "Geo": dropoffGeo,
            "GooglePlacesCode": dropoffGooglePlacesCode,
            "PickupDate": "",
            "PickupTime": "",
            "GIATACode": dropoffGIATACode
          },
          "Currency": process.env.REACT_APP_CURRENCY,
          "Adults": parseInt(adtVal),
        }
      }

      if (parseInt(chdVal) > 0) {
        let childrenObj = {}
        let arrChildAges = []
        let indx = 0
        for (var k = 0; k < chdAgesArr.length; k++) {
          indx = indx + 1
          let ageObj = {}
          ageObj.Identifier = indx
          ageObj.Text = chdAgesArr[k]
          arrChildAges.push(ageObj)
        }
        childrenObj.Count = parseInt(chdVal)
        childrenObj.ChildAge = arrChildAges;
        transferSrchObj.SearchParameter.Children = childrenObj
      }

      transferSrchObj.qry = qry

      if (Type === 'result') {
        navigate({
          pathname: "/transferListing",
          search: createSearchParams(qry).toString(),
        })
        dispatch(doTransferSearchOnLoad(transferSrchObj));
      } 
      else {
        navigate({
          pathname: "/transferListing",
          search: createSearchParams(qry).toString(),
        })
      }

    }
  }
  
  return(
    <>
    <ToastContainer />
    {Type === 'landing' ?
      <div className="searchColumn">
        <Row className="gx-2">
          <Col lg={6} className="d-none">
            <div className="mb-4">
                <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} /> {t("Destination")}</label>
                <AsyncTypeahead clearButton
                  highlightOnlyResult={true}
                  filterBy={filterBy}
                  id="async-example"
                  isLoading={isLoading}
                  labelKey={(option) => `${option.cityName}, ${option.countryName}`}
                  minLength={3}
                  onSearch={handleSearch}
                  options={options}
                  placeholder={t('pleaseEnterDestination')}
                  className="typeHeadDropdown"
                  selected={selectedDestination}
                  onChange={setSelectedDestination}
                  inputProps={{
                    className: 'hmform-control',
                  }}
                />
            </div>
          </Col>

          <Col lg={5}>
            <div className="mb-4">
              <label className="text-white">&nbsp; {t("Direction")}</label>
              <Form.Select className="hmform-control" value={transferType} onChange={(e) => transferTypeChange(e)}>
                <option value="0">{t("SelectDirection")}</option>
                <option value="1">{t("OnewayAtoH")}</option>
                <option value="2">{t("OnewayHtoA")}</option>
                <option value="3">{t("RoundTrip1")}</option>
                <option value="4">{t("Innercity")}</option>
              </Form.Select>
            </div>
          </Col>

          <Col lg={7}>
            <Row className="gx-2">
              <Col md={6}>
                <div className="mb-4">
                  <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} /> {t("Pick-upLocation")}</label>
                  <Form.Select className="hmform-control" value={JSON.stringify(pickupLoc)} onChange={(e) => pickupLocChange(e)}>
                    <option value={JSON.stringify({location:'0'})}>{t("SelectPickupLocation")}</option>
                    {pickupLocOption.map((p, index) => ( 
                      p.active && <option key={index} value={JSON.stringify(p)}>{p.location}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} /> {t("Dropp-offLocation")}</label>
                  <Form.Select className="hmform-control" value={JSON.stringify(dropLoc)} onChange={(e) => dropoffLocChange(e)}>
                    <option value={JSON.stringify({location:'0'})}>{t("SelectDropoffLocation")}</option>
                    {dropLocOption.map((d, index) => ( 
                      d.active && <option key={index} value={JSON.stringify(d)}>{d.location}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        
        <Row className="gx-2">
          <Col lg={3}>
            <div className="mb-4">
              <div className="fifthcol designCalendar">
                  <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('calendar-alt')} /> {t("PickupDate")}</label>
                  <DatePicker className="form-control hmform-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={pickupDate} minDate={new Date()} onChange={ (date) => setPickupDate(date)} onCalendarClose={disableTo} />
              </div>
              <div className="fifthcol">
                <div>
                  <div className="fifthcol">
                    <label className="text-white">&nbsp; {t("Hour")}</label>
                    <Form.Select className="hmform-control" value={pickupHour} onChange={(e) => setPickupHour(e.target.value)}>
                      <option value="0">00</option><option value="01">01</option><option value="02">02</option><option value="03">03</option><option value="04">04</option><option value="05">05</option><option value="06">06</option><option value="07">07</option><option value="08">08</option><option value="09">09</option><option value="10">10</option><option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option><option value="21">21</option><option value="22">22</option><option value="23">23</option>
                    </Form.Select>
                  </div>
                  <div className="fifthcol">
                    <label className="text-white">&nbsp; {t("Minute")}</label>
                    <Form.Select className="hmform-control" value={pickupMin} onChange={(e) => setPickupMin(e.target.value)}>
                      <option value="0">00</option><option value="05">05</option><option value="10">10</option><option value="15">15</option><option value="20">20</option><option value="25">25</option><option value="30">30</option><option value="35">35</option><option value="40">40</option><option value="45">45</option><option value="50">50</option><option value="55">55</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          {isSingleDate &&
          <Col lg={3}>
            <div className="mb-4">
              <div className="fifthcol designCalendar">
                  <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('calendar-alt')} /> {t("Return")}</label>
                  <DatePicker className="form-control hmform-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={returnDate} minDate={new Date(new Date(pickupDate))} onChange={(date) => setReturnDate(date)} ref={endRef} />
              </div>
              <div className="fifthcol">
                <div>
                  <div className="fifthcol">
                    <label className="text-white">&nbsp; {t("Hour")}</label>
                    <Form.Select className="hmform-control" value={returnHour} onChange={(e) => setReturnHour(e.target.value)}>
                      <option value="0">00</option><option value="01">01</option><option value="02">02</option><option value="03">03</option><option value="04">04</option><option value="05">05</option><option value="06">06</option><option value="07">07</option><option value="08">08</option><option value="09">09</option><option value="10">10</option><option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option><option value="21">21</option><option value="22">22</option><option value="23">23</option>
                    </Form.Select>
                  </div>
                  <div className="fifthcol">
                    <label className="text-white">&nbsp; {t("Minute")}</label>
                    <Form.Select className="hmform-control" value={returnMin} onChange={(e) => setReturnMin(e.target.value)}>
                      <option value="0">00</option><option value="05">05</option><option value="10">10</option><option value="15">15</option><option value="20">20</option><option value="25">25</option><option value="30">30</option><option value="35">35</option><option value="40">40</option><option value="45">45</option><option value="50">50</option><option value="55">55</option>
                    </Form.Select>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          }
          <Col lg={3} className="passdropdown">
            <div className="mb-4">
              <label className="text-white">&nbsp; {t("Noguests")}</label>
              <Button variant="light" className="hmform-control w-100 text-start dropdown-toggle" onClick={() => setShowPaxDropdown(true)}>{totalGuest()} {t("Travelers") }</Button>
              <PaxDropdown />
            </div>
          </Col>
          <Col lg={2} className="searchBtn">
            <div className="mb-4">
              <label className="d-none d-lg-block">&nbsp;</label>
              <Button variant="warning" className="hmform-btn" onClick={()=>srchTransfer()}><FontAwesomeIcon icon={solid('search')} className="fn16 mt-n1 align-middle" /> {t("Search")}</Button>
            </div>
          </Col>
        </Row>
      </div>
    : 
    <div className="modifycol">
      <Container> 
        {modifyCollapse ? 
        ''
        : 
        <>
          <div className="fn15 d-lg-flex justify-content-between align-items-center d-none">
            <div className="py-1">
              {pickupLoc.location} 
              {isSingleDate ?
              <FontAwesomeIcon icon={solid('exchange-alt')} className="fn12 mx-1" />
              :
              <FontAwesomeIcon icon={solid('long-arrow-alt-right')} className="fn12 mx-1" />
              }
              
              {dropLoc.location} 
              &nbsp;|&nbsp; {format(pickupDate, 'dd MMM yyyy')}  @{pickupHour}:{pickupMin}  
              {isSingleDate &&
              <span>- {format(returnDate, 'dd MMM yyyy')} @{returnHour}:{returnMin}</span>
              }
              &nbsp;|&nbsp; {totalGuest()} {t("Travellers")}
            </div>
            <div className="py-2"><Button onClick={() => setModifyCollapse(!modifyCollapse)} aria-controls="modifyCollapse" variant="warning" className="fn13"><strong>{t("ModifySearch")}</strong></Button></div>
          </div>
          <div>
            <ButtonGroup size="sm" className="w-100 py-2 d-lg-none d-inline-flex">
              <Button variant="outline-secondary" onClick={() => filterOpen(true)}><FontAwesomeIcon icon={solid('filter')} className="green" /> {t("Filter")}</Button>
              <Button variant="outline-secondary" onClick={() => setModifyCollapse(!modifyCollapse)}><FontAwesomeIcon icon={solid('search')} className="green" /> {t("Search")}</Button>
            </ButtonGroup>
          </div>
        </>
        }
        <Collapse in={modifyCollapse}>
          <div className="position-relative pt-3">
            <Button variant="link" onClick={() => setModifyCollapse(!modifyCollapse)} className="crossBtn p-0"><FontAwesomeIcon icon={regular('times-circle')} className="text-dark" /></Button>
            <Button variant="link" onClick={() => setModifyCollapse(!modifyCollapse)} className="crossBtn1 d-none d-lg-block p-0"><FontAwesomeIcon icon={regular('times-circle')} className="text-dark" /></Button>
            <Row className="gx-2">
              <Col lg={6} className="d-none">
                <div className="my-2">
                  <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} /> {t("Destination")}</label>
                  <AsyncTypeahead clearButton
                    highlightOnlyResult={true}
                    filterBy={filterBy}
                    id="async-example"
                    isLoading={isLoading}
                    labelKey={(option) => `${option.cityName}, ${option.countryName}`}
                    minLength={3}
                    onSearch={handleSearch}
                    options={options}
                    placeholder={t('pleaseEnterDestination')}
                    className="typeHeadDropdown"
                    selected={selectedDestination}
                    onChange={setSelectedDestination}
                    inputProps={{
                      className: 'hmform-control',
                    }}
                  />
                </div>
              </Col>

              <Col md={5}>
                <div className="my-2">
                  <label className="fn13">&nbsp; {t("Direction")}</label>
                  <Form.Select className="fn13" value={transferType} onChange={(e) => transferTypeChange(e)}>
                    <option value="0">{t("SelectDirection")}</option>
                    <option value="1">{t("OnewayAtoH")}</option>
                    <option value="2">{t("OnewayHtoA")}</option>
                    <option value="3">{t("RoundTrip1")}</option>
                    <option value="4">{t("Innercity")}</option>
                  </Form.Select>
                </div>
              </Col>

              <Col md={7}>
                <Row className="gx-2">
                  <Col md={6}>
                    <div className="my-2">
                      <label className="fn13">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} className="green" /> {t("Pick-upLocation")}</label>
                      <Form.Select className="fn13" value={JSON.stringify(pickupLoc)} onChange={(e) => pickupLocChange(e)}>
                        <option value={JSON.stringify({location:'0'})}>{t("SelectPickupLocation")}</option>
                        {pickupLocOption.map((p, index) => ( 
                          p.active && <option key={index} value={JSON.stringify(p)}>{p.location}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>

                  <Col md={6}>
                    <div className="my-2">
                      <label className="fn13">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} className="green" /> {t("Dropp-offLocation")}</label>
                      <Form.Select className="fn13" value={JSON.stringify(dropLoc)} onChange={(e) => dropoffLocChange(e)}>
                        <option value={JSON.stringify({location:'0'})}>{t("SelectDropoffLocation")}</option>
                        {dropLocOption.map((d, index) => ( 
                          d.active && <option key={index} value={JSON.stringify(d)}>{d.location}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
              </Col>
              
            </Row>

            <Row className="gx-2">
              <Col lg={3}>
                <div className="my-2">
                  <div className="fifthcol designCalendar">
                      <label className="fn13">&nbsp;<FontAwesomeIcon icon={solid('calendar-alt')} className="green" /> {t("PickupDate")}</label>
                      <DatePicker className="form-control fn13" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={pickupDate} minDate={new Date()} onChange={ (date) => setPickupDate(date)} onCalendarClose={disableTo} />
                  </div>
                  <div className="fifthcol">
                    <div>
                      <div className="fifthcol">
                        <label className="fn13">&nbsp; {t("Hour")}</label>
                        <Form.Select className="fn13" value={pickupHour} onChange={(e) => setPickupHour(e.target.value)}>
                          <option value="0">00</option><option value="01">01</option><option value="02">02</option><option value="03">03</option><option value="04">04</option><option value="05">05</option><option value="06">06</option><option value="07">07</option><option value="08">08</option><option value="09">09</option><option value="10">10</option><option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option><option value="21">21</option><option value="22">22</option><option value="23">23</option>
                        </Form.Select>
                      </div>
                      <div className="fifthcol">
                        <label className="fn13">&nbsp; {t("Minute")}</label>
                        <Form.Select className="fn13" value={pickupMin} onChange={(e) => setPickupMin(e.target.value)}>
                          <option value="0">00</option><option value="05">05</option><option value="10">10</option><option value="15">15</option><option value="20">20</option><option value="25">25</option><option value="30">30</option><option value="35">35</option><option value="40">40</option><option value="45">45</option><option value="50">50</option><option value="55">55</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              {isSingleDate &&
              <Col lg={3}>
                <div className="my-2">
                  <div className="fifthcol designCalendar">
                      <label className="fn13">&nbsp;<FontAwesomeIcon icon={solid('calendar-alt')} /> {t("Return")}</label>
                      <DatePicker className="form-control hmform-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={returnDate} minDate={new Date(new Date(pickupDate))} onChange={(date) => setReturnDate(date)} ref={endRef} />
                  </div>
                  <div className="fifthcol">
                    <div>
                      <div className="fifthcol">
                        <label className="fn13">&nbsp; {t("Hour")}</label>
                        <Form.Select className="hmform-control" value={returnHour} onChange={(e) => setReturnHour(e.target.value)}>
                          <option value="0">00</option><option value="01">01</option><option value="02">02</option><option value="03">03</option><option value="04">04</option><option value="05">05</option><option value="06">06</option><option value="07">07</option><option value="08">08</option><option value="09">09</option><option value="10">10</option><option value="11">11</option><option value="12">12</option><option value="13">13</option><option value="14">14</option><option value="15">15</option><option value="16">16</option><option value="17">17</option><option value="18">18</option><option value="19">19</option><option value="20">20</option><option value="21">21</option><option value="22">22</option><option value="23">23</option>
                        </Form.Select>
                      </div>
                      <div className="fifthcol">
                        <label className="fn13">&nbsp; {t("Minute")}</label>
                        <Form.Select className="hmform-control" value={returnMin} onChange={(e) => setReturnMin(e.target.value)}>
                          <option value="0">00</option><option value="05">05</option><option value="10">10</option><option value="15">15</option><option value="20">20</option><option value="25">25</option><option value="30">30</option><option value="35">35</option><option value="40">40</option><option value="45">45</option><option value="50">50</option><option value="55">55</option>
                        </Form.Select>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              }
              <Col lg={3} className="passdropdown">
                <div className="my-2">
                  <label className="fn13">&nbsp; {t("Noguests")}</label>
                  <Button variant="light" className="w-100 text-start dropdown-toggle bg-white border fn13 arroBtn" onClick={() => setShowPaxDropdown(true)}>{totalGuest()} {t("Travelers") }</Button>
                  <PaxDropdown />
                </div>
              </Col>
              <Col lg={2}>
                <div className="my-2">
                  <label className="d-none d-md-block fn13">&nbsp;</label>
                  <Button variant="warning" className="fn13 px-4 mb-3" onClick={()=>srchTransfer()}> {t("Search")}</Button>
                </div>
              </Col>
            </Row>

          </div>
        </Collapse>   
      </Container>
    </div>
    }
    </>
  );
}
export default TransferModifySearch;