import ApiService from './api.service'

const HotelService = {
  doHotelSearch: async function (reqObj) {
    const requestData = {
        method: 'post',
        data: null,
        url: '/api/Hotel/Search',
    }
    try {
      let tempReqObj = {
        "CustomerCode": reqObj.CustomerCode,
        "SearchParameter": {
            "CityName": reqObj.SearchParameter.selectedCity,
            "CountryName": reqObj.SearchParameter.selectedCountry,
            "DestinationCode": reqObj.SearchParameter.DestinationCode,
            "CountryCode": reqObj.SearchParameter.CountryCode,
            "CheckInDate": reqObj.SearchParameter.CheckInDate,
            "CheckOutDate": reqObj.SearchParameter.CheckOutDate,
            "Currency": reqObj.SearchParameter.Currency,
            "Nationality": "QA",
            "Rooms": {}
        }
      }
      let room = []
      reqObj.SearchParameter.paxInfoArr.forEach((v, i) => {
          let paxObj = {
            Adult: v.adtVal,
            RoomIdentifier: parseInt(i + 1)
          }
          if (v.chdVal > 0) {
            let chdArr = [];
            v.chdAgesArr.forEach((val, indx) => {
                if (parseInt(val.chdAgeVal) > 0) {
                    chdArr.push({
                        Identifier: parseInt(indx + 1),
                        Text: val.chdAgeVal
                    })
                }
            })
            if (v.chdVal > 0) {
                paxObj.Children = {
                    Count: v.chdVal,
                    ChildAge: chdArr
                }
            }
          }
          room.push(paxObj)
      })
      tempReqObj.SearchParameter.Rooms.Room = room
      requestData.data = tempReqObj
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  getHotelDetailsStaticData: async function (htlCode) {
    const requestData = {
        method: 'post',
        data: {"systemId": htlCode},
        baseURL: 'https://static.giinfotech.ae',
        url: '/MasterHotels/v2',
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doHotelRmDtlsRq: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: null,
      url: '/api/Hotel/CombinedRoomDetails',
    }
    try {
      let tempReqObj = {
        "SessionId": reqObj.qry.sessionId,
        "SearchParameter": {
          "CityName": reqObj.qry.city,
          "CountryName": reqObj.qry.countryName,
          "Currency": process.env.REACT_APP_CURRENCY,
          "HotelCode": reqObj.qry.hotelId,
          "CheckInDate": reqObj.qry.chk_in,
          "CheckOutDate": reqObj.qry.chk_out,
          "Rooms": {}
        }
      }

      let room = []
      reqObj.paxInfoArr.forEach((v, i) => {
        let paxObj = {
          Adult: v.adtVal,
          RoomIdentifier: parseInt(i + 1)
        }

        if (v.chdVal > 0) {
          let chdArr = [];

          v.chdAgesArr.forEach((val, indx) => {
            if (parseInt(val.chdAgeVal) > 0) {
              chdArr.push({
                  Identifier: parseInt(indx + 1),
                  Text: val.chdAgeVal
              })
            }
          })
          
          if (v.chdVal > 0) {
            paxObj.Children = {
              Count: v.chdVal,
              ChildAge: chdArr
            }
          }
        }
        room.push(paxObj)
      })
      tempReqObj.SearchParameter.Rooms.Room = room
      requestData.data = tempReqObj
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doHotelCancelDtlsRq: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: reqObj,
      url: '/api/Hotel/CancellationPolicy',
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doHotelFareBreackupRq: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: reqObj,
      url: '/api/Hotel/PriceBreakup',
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doReprice: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: null,
      url: '/api/Hotel/Reprice',
    }
    try {
      let tempReqObj = {
        "SessionId": reqObj.sId,
        "CustomerCode": 7,
        "SearchParameter": {
          "CityName": reqObj.cityName,
          "CountryName": reqObj.countryName,
          "GroupCode": reqObj.gp,
          "HotelCode": reqObj.hId,
          "Currency": process.env.REACT_APP_CURRENCY,
          "CheckInDate": reqObj.chkIn,
          "CheckOutDate": reqObj.chkOut,
          "RateKeys": {
            "RateKey": reqObj.rt.includes('*') ? reqObj.rt.split('*') : []
          }
        }
      }
      if (tempReqObj.SearchParameter.RateKeys.RateKey.length === 0) {
        tempReqObj.SearchParameter.RateKeys.RateKey.push(reqObj.rt)
      }
      requestData.data = tempReqObj
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

}
export default HotelService
export { HotelService }