import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useSearchParams } from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {doTourSearchOnLoad} from "../../store/actions/tour";
import TourModifySearch from '../../components/tour/ModifySearch';
import DummyTourResult from '../../components/tour/DummyResults';
import TourFilter from '../../components/tour/Filter';
import TourResult from '../../components/tour/Results';

const TourListing = () => {
    const dispatch = useDispatch();
    const [searchparams] = useSearchParams();
    const qry = Object.fromEntries([...searchparams])

    let tourSrchObj = {
        "CustomerCode": "",
        "SearchParameter": {
            "DestinationCode": qry.destinationCode,
            "CountryCode": qry.countryCode,
            "selectedCity": qry.selectedCity,
            "selectedCountry": qry.selectedCountry,
            "ServiceDate": qry.serviceDate,
            "Currency": process.env.REACT_APP_CURRENCY,
            "Adults": parseInt(qry.adults),
        }
    }
    if (parseInt(qry.children) > 0) {
        let childrenObj = {}
        let arrChildAges = []
        let indx = 0
        let chdAgesArr = qry.ca.split(',');
        for (var k = 0; k < chdAgesArr.length; k++) {
            indx = indx + 1
            let ageObj = {}
            ageObj.Identifier = indx
            ageObj.Text = chdAgesArr[k]
            arrChildAges.push(ageObj)
        }
        childrenObj.Count = parseInt(qry.children)
        childrenObj.ChildAge = arrChildAges;
        tourSrchObj.SearchParameter.Children = childrenObj
    }
    tourSrchObj.qry = qry

    useEffect(()=>{
        dispatch(doTourSearchOnLoad(tourSrchObj));
    },[dispatch]);

    const getTourRes = useSelector((state) => state.tourList);

    const [filterChoose, setFilterChoose] = useState(false);
    const chooseFilter = (val) => {
        setFilterChoose(val)
    };

  return (
    <>
    <DefaultLayout>
        <div className="innermiddle">
            <TourModifySearch Type={'result'} TourReq={tourSrchObj} filterOpen={(val) => chooseFilter(val)} />
            <Container>
                <div className="d-table w-100">
                    {getTourRes ?
                    <>
                        {getTourRes.tourResObj ? 
                        <>
                        <TourFilter filterChoose={filterChoose} filterClose={(val) => chooseFilter(val)} />
                        <TourResult /> 
                        </> : 
                        <DummyTourResult />
                        }
                    </>
                    :
                    <>
                        <DummyTourResult />
                    </>
                    }
                </div>
            </Container>
        </div>
    </DefaultLayout>
    </>
  )
}

export default TourListing
