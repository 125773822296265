import ApiService from './api.service'

const BookingService = {

  getBookingDtls: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: {"BookingNo": reqObj,},
      url: '/api/Common/RetrieveBooking',
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },


}
export default BookingService
export { BookingService }