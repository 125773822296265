import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import {Container, Button} from 'react-bootstrap';
import {useLocation } from 'react-router-dom';
import { format } from 'date-fns';
//import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {getBookingDtls} from "../../store/actions/booking";
import {getHtlDtlsStaticData} from "../../store/actions/hotel";
import HotelBooking from '../../components/booking/HotelBooking';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BookingConfirmation = () => {
    const dispatch = useDispatch();
    //const {t} = useTranslation();
     const qry = useLocation(); 

    useEffect(()=> {
        getBookingDtlsCall()
    }, []);

    const [bDtls, setBDtls] = useState(null);

    const getBookingDtlsCall = async () => {
      if(qry.state){
        let res = dispatch(getBookingDtls(qry.state.qry.bId));
        let response = await res
        if (response && response.hotels && response.hotels[0] && response.hotels[0].hotelDetail && response.hotels[0].hotelDetail.productCode) {
          dispatch(getHtlDtlsStaticData(response.hotels[0].hotelDetail.productCode));
        }
        setBDtls(response)
      }
      else{
        toast.error("Something Wrong !!",{theme: "colored"});
      }
    }

    const htlStatData = useSelector((state) => state.hotelDetails);

    const printBtn = () =>{ 
      let content = document.getElementById("divcontents");
      let pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
    }
    
  return (
    <>
    <ToastContainer />
    <DefaultLayout>
      <div className="innermiddle mt-4">
        <Container>
        { bDtls && bDtls.bookingDetail ?
          <div className="fullbox rounded mb-3">
            <div className="bg-light p-2 mb-2 d-flex justify-content-between">
              <span className="fn24 text-success"><FontAwesomeIcon icon={solid('check-circle')} /> Your booking is done</span> 
              <Button variant="outline-dark" size="sm" type="button" onClick={printBtn}><FontAwesomeIcon icon={solid('print')} /> Print</Button>
            </div>
            <p className="li21">Your Trip Id is <strong>{bDtls.bookingDetail.bookingId}</strong>. All details will be sent to {bDtls.hotels[0].paxDetails[0].emailId}<br />
              <span className="text-muted">Thank you for booking with us. Use the above trip id in all communication with us.</span>
            </p>
            <iframe title="ifmcontentstoprint" id="ifmcontentstoprint" style={{height:'0px', width:'0px', position:'absolute'}}></iframe>
            <div id="divcontents" className="amentiData">
              <table width="800" cellSpacing="0" cellPadding="0" align="center" style={{background:'#FFF', border:'1px solid #e1e1e1', fontFamily:'Arial, Helvetica, sans-serif', fontSize:'13px', color:'#000', minWidth:'100%', maxWidth:'100%'}}>
                <tbody>
                  <tr>
                    <td>
                      <table width="100%" cellSpacing="0" cellPadding="10" className="cellpad10">
                        <tbody>
                            <tr style={{background:'#06448f', fontSize:'18px', color:'#FFF'}}>
                                <td>Booking Details</td>
                                { bDtls.hotels && bDtls.hotels[0] && bDtls.hotels[0].hotelDetail &&
                                  <td align="right" className="text-end">Confirmation Number: {bDtls.hotels[0].hotelDetail.supplierConfirmationNumber}</td>
                                }
                            </tr>
                        </tbody>
                      </table>
                      <table width="100%" cellSpacing="0" cellPadding="10" style={{borderBottom:'1px solid #dfdede'}} className="cellpad10">
                        <tbody>
                          <tr>
                            <td width="50%">
                              <table cellSpacing="0" cellPadding="2" className="cellpad2">
                                <tbody>
                                    <tr>
                                      <td>Booking Date:</td>
                                      <td><strong>&nbsp;{format(new Date(bDtls.bookingDetail.bookingDate), 'EEE, dd MMM yyyy')}</strong></td>
                                    </tr>
                                    <tr>
                                      <td>Status:</td>
                                      <td><strong>&nbsp;{bDtls.bookingDetail.bookingStatus===2 && 'Confirmed' }</strong></td>
                                    </tr>
                                    <tr>
                                      <td>Lead Guest Name:</td>
                                      <td><strong>&nbsp;{bDtls.bookingDetail.leadPaxName}</strong></td>
                                    </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {bDtls.hotels && bDtls.hotels[0] && bDtls.hotels[0].hotelDetail &&
                        <>
                        <HotelBooking bDtls={bDtls} htlStatData={htlStatData} />
                        </>
                      }

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          :
          <div className="text-center my-5">
            <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
            <div className="fn21">No Booking Found</div>
          </div>
        }
        </Container>
      </div>       
    </DefaultLayout>
    </>
  )
}

export default BookingConfirmation
