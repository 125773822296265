import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Row, Col, Button, Form, InputGroup, OverlayTrigger, Tooltip, Pagination} from 'react-bootstrap';
import {doFilterSort} from "../../store/actions/hotel";
import { useSearchParams, createSearchParams } from 'react-router-dom';

const HotelResult = () => {
  const dispatch = useDispatch();
  const { t} = useTranslation();
  const getCurrency = useSelector((state) => state.currency)
  const OrgHtlResult = useSelector((state) => state.hotelList.htlResOrgObj);
  const HtlResult = useSelector((state) => state.hotelList.htlResObj);
 
  const HtlFilter = useSelector((state) => state.htlFilter.htlFltr);
  const HtlFilterSort = useSelector((state) => state.htlFltrSort.htlFilterSort);

  const srtTxt = (val) =>{
    let htlFilterSort = {
      srtVal: HtlFilterSort ? HtlFilterSort.srtVal : "0",
      srchTxt: val
    }
    let obj = { 'htlFilters': HtlFilter, 'htlFilterSort': htlFilterSort}
    dispatch(doFilterSort(OrgHtlResult, obj));   
  };

  const srtVal = (val) =>{
    let htlFilterSort = {
      srtVal: val,
      srchTxt: HtlFilterSort ? HtlFilterSort.srchTxt : ""
    }
    let obj = { 'htlFilters': HtlFilter, 'htlFilterSort': htlFilterSort}
    dispatch(doFilterSort(OrgHtlResult, obj));  
  };

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(50);  
  const [pagesCount, setPagesCount] = useState(0);

  useEffect(()=>{
    setPagesCount(Math.ceil(HtlResult.hotels.hotelDetail.length / pageSize))
    setCurrentPage(0)
  },[HtlResult]);

  const handleClick = (inde) => {
    setCurrentPage(inde)
  };
  const [searchparams] = useSearchParams();
  const selectRoom = (sessionId, pCode, pName) => {
    let qry = Object.fromEntries([...searchparams])
    qry.sessionId = sessionId
    qry.hotelId = pCode
    qry.hotelName = pName
    qry.uniqueId = sessionId + '_' + pCode
    window.open(`/hotelDetails?${createSearchParams(qry).toString()}`)
  }

  return( 
    <>
      {HtlResult.hotels.hotelDetail && HtlResult.hotels.hotelDetail.length ? 
        <div className="d-lg-table-cell align-top rightResult">
          <div className="text-end mb-1">{t("TotalResultFound")}: {OrgHtlResult.hotels.hotelDetail.length}</div>
          <div className="sortTop sticky-top">
            <Row className="justify-content-between gx-2">
              <Col md={3} xs={4} className="mb-1">
                <Form.Select size="sm" onChange={event => srtVal(event.target.value)} value={HtlFilterSort.srtVal}>
                  <option value="0">{t("SortBy")}</option>
                  <option value="plth">{t("PriceLowHigh")}</option>
                  <option value="phtl">{t("PriceHighLow")}</option>
                </Form.Select>
              </Col>
              <Col md={5} xs={8} className="mb-1">
                <InputGroup>
                    <Form.Control className="border-end-0 fn13" placeholder={t('SearchLocationHotelAddress')} value={HtlFilterSort.srchTxt} onChange={event => srtTxt(event.target.value)} />
                    <InputGroup.Text className="bg-white"><FontAwesomeIcon icon={solid('search')} className="fn14" /></InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </div> 
          {pagesCount >= 2 &&
            <Pagination size='sm' className="justify-content-center mb-3">
              <Pagination.First disabled={currentPage <= 0} onClick={() => handleClick(0)} />
              <Pagination.Prev disabled={currentPage <= 0} onClick={() => handleClick(currentPage - 1)} />
              
              {[...Array(pagesCount)].map((page, i) => 
                <Pagination.Item active={i === currentPage} key={i} onClick={() => handleClick(i)}>
                    {i + 1}
                </Pagination.Item>
              )}

              <Pagination.Next disabled={currentPage >= pagesCount - 1} onClick={() => handleClick(currentPage + 1)} />
              <Pagination.Last disabled={currentPage >= pagesCount-1} onClick={() => handleClick(pagesCount-1)} />
            </Pagination>
          }
          {
            HtlResult.hotels.hotelDetail.slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize
            ).map((v, i) => {
              return (
                <div key={i} className="htlboxcol">
                  <Row className="gx-2">
                    <Col md={10}>
                      <div className="d-flex flex-row">
                        <div className="hotelImg rounded-start curpointer" onClick={()=>selectRoom(HtlResult.generalInfo.sessionId, v.productcode, v.productname)}>
                          {v.thumbnailimage ? 
                          <img src={`https://static.giinfotech.ae/media${v.thumbnailimage}`} alt={v.productname} />
                          :
                          <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                          }
                        </div>
                        <div className="ps-3 pe-1 pt-2 w-100 htlright">
                          <Row className="gx-2 h-100">
                            <Col md={12} className="align-self-center">
                              <h3 className="nametitle blue curpointer" onClick={()=>selectRoom(HtlResult.generalInfo.sessionId, v.productcode, v.productname)}>{v.productname}</h3>
                              <div className="text-muted li21 mb-2">{v.address}, {v.city}<br />
                                {
                                  v.attractions && 
                                  <div>
                                    {v.attractions.distance && <div><FontAwesomeIcon icon={solid('map-marker-alt')} className="fn12" />&nbsp; {v.attractions.distance} from {v.attractions.name}</div>}
                                  </div>
                                }
                              </div>
                              <ul className="list-inline m-0">
                                  <li className={"list-inline-item " + (v.amenities && v.amenities.includes('Internet access' || 'WLAN access') ? 'active': 'nonActive')}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Internet</Tooltip>}><i className="icon14 bg-wifi"></i></OverlayTrigger>
                                  </li>
                                  <li className={"list-inline-item " + (v.amenities && v.amenities.includes('swimmimg pool') ? 'active': 'nonActive')}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Swimming Pool</Tooltip>}><i className="icon14 bg-swimmming"></i></OverlayTrigger>
                                  </li>
                                  <li className={"list-inline-item " + (v.amenities && v.amenities.includes('Car Park') ? 'active': 'nonActive')}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Parking</Tooltip>}><i className="icon14 bg-parking"></i></OverlayTrigger>
                                  </li>
                                  <li className={"list-inline-item " + (v.amenities && v.amenities.includes('Restaurant(s)') ? 'active': 'nonActive')}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Restaurant</Tooltip>}><i className="icon14 bg-restaurants"></i></OverlayTrigger>
                                  </li>
                                  <li className={"list-inline-item " + (v.amenities && v.amenities.includes('Gym') ? 'active': 'nonActive')}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Fitness Facility</Tooltip>}><i className="icon14 bg-gym"></i></OverlayTrigger>
                                  </li>
                                  <li className={"list-inline-item " + (v.amenities && v.amenities.includes('TV') ? 'active': 'nonActive')}>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>Television</Tooltip>}><i className="icon14 bg-tv"></i></OverlayTrigger>
                                  </li>
                              </ul>
                            </Col>
                            <Col md={12} className="mt-1 mb-2 align-self-end text-lg-end">
                                <span className="boxRating">
                                  {
                                    Array.apply(null, { length:parseInt(v.starrating)}).map((e, i) => (
                                      <FontAwesomeIcon key={i} icon={solid('star')} className="stargold" />
                                    ))
                                  }
                                </span> &nbsp;&nbsp; 
                                <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${v.tripAdvisorRating}.0-13387-4.png`} alt="tripadvisor" />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>

                    <Col md={2} className="border-start d-flex">
                      <div className="align-self-center mx-md-auto ms-auto text-center d-flex d-md-block px-2">
                          <div className="mb-md-1 mt-1 mx-2 mx-md-0"><span className="nametitle blue d-md-block mb-0">{getCurrency.currency} {parseFloat(v.minprice).toFixed(2)}</span></div>
                          <div className="mb-2"><Button variant="warning" size="sm" onClick={()=>selectRoom(HtlResult.generalInfo.sessionId, v.productcode, v.productname)}>{t("SelectRoom")}</Button></div>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })
          }

          {pagesCount >= 2 &&
            <Pagination size='sm' className="justify-content-center mb-3">
              <Pagination.First disabled={currentPage <= 0} onClick={() => handleClick(0)} />
              <Pagination.Prev disabled={currentPage <= 0} onClick={() => handleClick(currentPage - 1)} />
              
              {[...Array(pagesCount)].map((page, i) => 
                <Pagination.Item active={i === currentPage} key={i} onClick={() => handleClick(i)}>
                    {i + 1}
                </Pagination.Item>
              )}

              <Pagination.Next disabled={currentPage >= pagesCount - 1} onClick={() => handleClick(currentPage + 1)} />
              <Pagination.Last disabled={currentPage >= pagesCount-1} onClick={() => handleClick(pagesCount-1)} />
            </Pagination>
          }
          
        </div> :
        <div className="d-lg-table-cell align-top rightResult"> 
          <div className="text-center my-5">
            <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
            <div className="fn21">{t("NoResultFound")}</div>
          </div>
        </div>
      }
    </> 
  );
}
export default HotelResult;