import {HotelService} from "../../services/hotel.service";
import {UtilityService} from "../../services/utility.service";
import {
    RESET_HOTEL_RES, SET_HOTELRES, SET_OGHOTELRES, 
    RESET_HOTEL_FILTER, SET_HOTELFILTER, SET_HOTELFILTERSORT, 
    RESET_HOTEL_DETAILS_RES, SET_HOTEL_DETAILS_RES, 
    RESET_HOTEL_RMDETAILS_RES, SET_HOTEL_RMDETAILS_RES, 
    RESET_HOTEL_CANCELDATA_RES, SET_HOTEL_CANCELDATA_RES, 
    RESET_HOTEL_FAREBREAKUPDATA_RES, SET_HOTEL_FAREBREAKUPDATA_RES,
    RESET_HOTEL_REPRICE_RES, SET_HOTEL_REPRICE_RES } from "../types/hotelTypes";
import store from '../store';
import cloneDeep from 'lodash/cloneDeep';

export const doHotelSearchOnLoad = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_HOTEL_RES})
            dispatch({ type: RESET_HOTEL_FILTER})
            
            const response = await HotelService.doHotelSearch(reqObj);
            let rootCurrency =  store.getState().currency.currency
            let rootExchangeRate =  store.getState().exchangeRate.exchangerates

            if (rootCurrency !== process.env.REACT_APP_CURRENCY) {
                UtilityService.conHtlListSelectedCur(response, rootCurrency, rootExchangeRate)
            }

            response.hotels.amenities = []
            let amenities = [
                "Restaurant(s) with air conditioning",
                "Restaurant(s) with high-chairs",
                "Air conditioning (centrally regulated)",
                "Air conditioning",
                "Hotel Safe",
                "Currency Exchange",
                "Cafe",
                "Restaurant(s)",
                "Conference Room",
                "WLAN access",
                "Room Service",
                "Laundry Service",
                "Medical Assistance",
                "Car Park",
                "Bathroom",
                "Shower",
                "Direct dial telephone",
                "Satellite/cable TV",
                "Internet access",
                "Minibar",
                "Fridge",
                "Air conditioning (centrally regulated)",
                "Safe",
                "Lounge",
                "TV",
                "Double Bed"
            ]
            response.hotels.amenities = amenities
            dispatch({ type: SET_OGHOTELRES, payload: response })
            dispatch({ type: SET_HOTELRES, payload: response })
            
        } catch (err) {
            console.log("Hotel Listing Error", err);
        }
    }
}

export const doFilterSort = (res, obj) => {
    let htlResult = cloneDeep(res)
    const fltrResPr = htlResult.hotels.hotelDetail.filter((o) => {
      let status = []
      if (obj.htlFilters.priceFilter.length > 0) {
          status.push(o.minprice >= obj.htlFilters.priceFilter[0] && o.minprice <= obj.htlFilters.priceFilter[1])
      }
      let statusVar = status.includes(false)
      return !statusVar
    });

    const fltrResSt = fltrResPr.filter((o) => {
      let status = []
      if (obj.htlFilters.startRating.length > 0) {
          status.push(obj.htlFilters.startRating.includes(o.starrating))
        } 
      let statusVar = status.includes(false)
      return !statusVar
    });

    if(obj.htlFilterSort.srtVal !== '0'){
        switch (obj.htlFilterSort.srtVal) {
            case 'plth':
                fltrResSt.sort((a, b) => parseFloat(a.minprice) - parseFloat(b.minprice))
                break
            case 'phtl':
                fltrResSt.sort((a, b) => parseFloat(b.minprice) - parseFloat(a.minprice))
                break

        }
    }

    if (obj.htlFilterSort.srchTxt !== '') {
        const srtTxtRes = fltrResSt.filter((pdt) => {
          return pdt.productname.toLowerCase().includes(obj.htlFilterSort.srchTxt.toLowerCase())
        })
        htlResult.hotels.hotelDetail = srtTxtRes
    }
    else{
        htlResult.hotels.hotelDetail = fltrResSt
    }

    return async (dispatch) => {
        try {
            dispatch({ type: SET_HOTELFILTER, payload: obj.htlFilters })
            dispatch({ type: SET_HOTELFILTERSORT, payload: obj.htlFilterSort })
            dispatch({ type: SET_HOTELRES, payload: htlResult })
        }  catch (err) {
            console.log("Hotel Filter Error", err);
        }
    }
}

export const getHtlDtlsStaticData = (htlCode) => {
    return async (dispatch) => {
        try {
            //dispatch({ type: RESET_HOTEL_DETAILS_RES})
            const response = await HotelService.getHotelDetailsStaticData(htlCode);
            dispatch({ type: SET_HOTEL_DETAILS_RES, payload: response })
        } catch (err) {
            console.log("Hotel Details Error", err);
        }
    }
}

export const getHtlRmDtls = (reqObj) => {
    return async (dispatch) => {
        try {
            //dispatch({ type: RESET_HOTEL_RMDETAILS_RES})
            const response = await HotelService.doHotelRmDtlsRq(reqObj);
            let rootCurrency =  store.getState().currency.currency
            let rootExchangeRate =  store.getState().exchangeRate.exchangerates

            if (rootCurrency !== process.env.REACT_APP_CURRENCY) {
                UtilityService.conHtlRmDtlsSelectedCur(response, rootCurrency, rootExchangeRate)
            }

            sessionStorage.setItem("hotelStore", JSON.stringify({"hotelRmDetails": response}))
            //let hotelRmDetails = JSON.parse(sessionStorage.getItem('hotelStore')).hotelRmDetails;
            dispatch({ type: SET_HOTEL_RMDETAILS_RES, payload: response })
        } catch (err) {
            console.log("Hotel Details Error", err);
        }
    }
}

export const getCancellationData = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_HOTEL_CANCELDATA_RES})
            const response = await HotelService.doHotelCancelDtlsRq(reqObj);
            let rootCurrency =  store.getState().currency.currency
            let rootExchangeRate =  store.getState().exchangeRate.exchangerates
            if (rootCurrency !== process.env.REACT_APP_CURRENCY) {
                UtilityService.conHtlCanPolicySelectedCur(response, rootCurrency, rootExchangeRate)
            }
            dispatch({ type: SET_HOTEL_CANCELDATA_RES, payload: response })
        } catch (err) {
            console.log("Hotel Cancellation Policy Error", err);
        }
    }
}

export const getFareBreackupData = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_HOTEL_FAREBREAKUPDATA_RES})
            const response = await HotelService.doHotelFareBreackupRq(reqObj);
            let rootCurrency =  store.getState().currency.currency
            let rootExchangeRate =  store.getState().exchangeRate.exchangerates
            if (rootCurrency !== process.env.REACT_APP_CURRENCY) {
                UtilityService.conHtlFareBrkSelectedCur(response, rootCurrency, rootExchangeRate)
            }
            dispatch({ type: SET_HOTEL_FAREBREAKUPDATA_RES, payload: response })
        } catch (err) {
            console.log("Hotel Fare Breackup Error", err);
        }
    }
}

export const checkReprice = (reqObj, pId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_HOTEL_REPRICE_RES})
            let bookNowObj = reqObj.htlBookNowObj
            bookNowObj.pid = pId.pid
            let bookNowObjRs = {}
            if (bookNowObj.rp === 'R' || bookNowObj.rp === '' || bookNowObj.rp === null) {
                bookNowObjRs = await HotelService.doReprice(bookNowObj)
            }
            else if (bookNowObj.rp === 'N') {
                bookNowObj.type = 'cc'
                bookNowObjRs = await HotelService.doPbCcRq(bookNowObj)
                bookNowObjRs.hotel = {}
                bookNowObjRs.hotel.rooms = null
                bookNowObjRs.hotel.rooms = bookNowObjRs.rooms
                delete bookNowObjRs.rooms
                bookNowObjRs.hotel.rooms.net = bookNowObj.rmData.prices.netAmountOg !== undefined ? bookNowObj.rmData.prices.netAmountOg : bookNowObj.rmData.prices.netAmount
                bookNowObjRs.hotel.rooms.gross = bookNowObj.rmData.prices.grossAmountOg !== undefined ? bookNowObj.rmData.prices.grossAmountOg : bookNowObj.rmData.prices.grossAmount
                bookNowObjRs.hotel.rooms.tax = bookNowObj.rmData.prices.taxOg !== undefined ? bookNowObj.rmData.prices.taxOg : bookNowObj.rmData.prices.tax
                bookNowObjRs.hotel.rooms.discount = bookNowObj.rmData.prices.discountOg !== undefined ? bookNowObj.rmData.prices.discountOg : bookNowObj.rmData.prices.discount
                bookNowObjRs.hotel.rooms.taxType = bookNowObj.rmData.prices.taxType
                bookNowObjRs.hotel.rooms.priceObj = bookNowObj.rmData.prices

                bookNowObjRs.hotel.rooms.room.forEach((v) => {
                    bookNowObj.rmData.roomDescription.forEach((k) => {
                        v.price = {}
                        v.price.net = k.net
                        v.price.gross = k.gross
                        v.price.tax = k.tax
                        v.adult = k.adult
                        v.children = k.children
                    })
                })
            }

            let hotelDetails =  store.getState().hotelDetails.htlDtlObj
            
            bookNowObjRs.pid = bookNowObj.pid
            bookNowObjRs.systemId = hotelDetails.data.systemId
            bookNowObjRs.name = hotelDetails.data.name
            bookNowObjRs.address = hotelDetails.data.address
            bookNowObjRs.rating = hotelDetails.data.rating
            bookNowObjRs.tripAdvisorRating = hotelDetails.data.tripAdvisorRating
            //bookNowObjRs.imageUrl = hotelDetails.data.imageUrl
            bookNowObjRs.address = hotelDetails.data.address
            bookNowObjRs.giDestinationId = hotelDetails.data.giDestinationId
            bookNowObjRs.imgUrl = hotelDetails.data.imageUrl
            bookNowObjRs.selectedRmDtls = bookNowObj

            let rootCurrency =  store.getState().currency.currency
            let rootExchangeRate =  store.getState().exchangeRate.exchangerates
            if (rootCurrency !== process.env.REACT_APP_CURRENCY) {
                UtilityService.conHtlTrvlerSelectedCur(bookNowObjRs, rootCurrency, rootExchangeRate)
            }
            dispatch({ type: SET_HOTEL_REPRICE_RES, payload: bookNowObjRs })
            
        } catch (err) {
            console.log("Hotel Fare Breackup Error", err);
        }
    }
}