import { 
  RESET_HOTEL_RES, SET_HOTELRES, SET_OGHOTELRES, 
  RESET_HOTEL_FILTER, SET_HOTELFILTER, SET_HOTELFILTERSORT, 
  RESET_HOTEL_DETAILS_RES, SET_HOTEL_DETAILS_RES, 
  RESET_HOTEL_RMDETAILS_RES, SET_HOTEL_RMDETAILS_RES, 
  RESET_HOTEL_CANCELDATA_RES, SET_HOTEL_CANCELDATA_RES,
  RESET_HOTEL_FAREBREAKUPDATA_RES,
  SET_HOTEL_FAREBREAKUPDATA_RES,
  RESET_HOTEL_REPRICE_RES,
  SET_HOTEL_REPRICE_RES
} from "../types/hotelTypes";
  
const intialState = {
  htlResObj:null,
  htlResOrgObj:null,
  htlFltr: {
    startRating: [],
    priceFilter: []
  },
  htlFilterSort : {
    srtVal: '0',
    srchTxt: ''
  },
  htlDtlObj:null,
  htlRmDtlObj:null,
  htlCancelDtlObj:null,
  htlFareBrkupObj:null,
  htlRepriceObj:null,
};

export const hotelListResultReducer = (state = intialState.htlResObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_RES:
      return { ...state, htlResObj: null, htlResOrgObj: null };
    case SET_OGHOTELRES:
      return { ...state, htlResOrgObj: payload };
    case SET_HOTELRES:
      return { ...state, htlResObj: payload };
    
    default:
      return state;
  }
};

export const htlFltrReducer = (state = intialState.htlFltr, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_FILTER:
      return { ...state, htlFltr:{startRating: [],priceFilter: []}, htlFilterSort:{srtVal: '0',srchTxt: ''} };
    case SET_HOTELFILTER:
      return {...state, htlFltr: payload}  
    default:
      return state;
  }
};

export const htlFltrSortReducer = (state = intialState.htlFilterSort, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_FILTER:
      return { ...state, htlFltr:{startRating: [],priceFilter: []}, htlFilterSort:{srtVal: '0',srchTxt: ''} };
    case SET_HOTELFILTERSORT:
      return {...state, htlFilterSort: payload}  
    default:
      return state;
  }
};

export const hotelDetailsReducer = (state = intialState.htlDtlObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_DETAILS_RES:
      return { ...state, htlDtlObj: null};
    case SET_HOTEL_DETAILS_RES:
      return { ...state, htlDtlObj: payload };
    default:
      return state;
  }
};

export const hotelRmDetailsReducer = (state = intialState.htlRmDtlObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_RMDETAILS_RES:
      return { ...state, htlRmDtlObj: null};
    case SET_HOTEL_RMDETAILS_RES:
      return { ...state, htlRmDtlObj: payload };
    default:
      return state;
  }
};

export const hotelCancelDataReducer = (state = intialState.htlCancelDtlObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_CANCELDATA_RES:
      return { ...state, htlCancelDtlObj: null};
    case SET_HOTEL_CANCELDATA_RES:
      return { ...state, htlCancelDtlObj: payload };
    default:
      return state;
  }
};

export const hotelFareBreackupDataReducer = (state = intialState.htlFareBrkupObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_FAREBREAKUPDATA_RES:
      return { ...state, htlFareBrkupObj: null};
    case SET_HOTEL_FAREBREAKUPDATA_RES:
      return { ...state, htlFareBrkupObj: payload };
    default:
      return state;
  }
};

export const hotelRepriceDataReducer = (state = intialState.htlRepriceObj, { type, payload }) => {
  switch (type) {
    case RESET_HOTEL_REPRICE_RES:
      return { ...state, htlRepriceObj: null};
    case SET_HOTEL_REPRICE_RES:
      return { ...state, htlRepriceObj: payload };
    default:
      return state;
  }
};


