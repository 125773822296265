import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useSearchParams } from 'react-router-dom';
import {Container, Modal, Row, Col} from 'react-bootstrap';
import {doHotelSearchOnLoad} from "../../store/actions/hotel";
import HotelModifySearch from '../../components/hotel/ModifySearch';
import DummyHotelResult from '../../components/hotel/DummyResults';
import HotelFilter from '../../components/hotel/Filter';
import HotelResult from '../../components/hotel/Results';
import MapResult from '../../components/hotel/MapResults';

const HotelListing = () => {
    const dispatch = useDispatch();
    const [searchparams] = useSearchParams();
    const [showMapModal, setShowMapModal] = useState(false);

    const qry = Object.fromEntries([...searchparams])
    let htlSrchObj = {
        "CustomerCode": "",
        "SearchParameter": {
            "DestinationCode": qry.dest_code != null ? qry.dest_code : 0,
            "CountryCode": qry.country != null ? qry.country : 0,
            "selectedCity": qry.city != null ? qry.city : '',
            "selectedCountry": qry.countryName != null ? qry.countryName : '',
            "CheckInDate": qry.chk_in != null ? qry.chk_in : '',
            "CheckOutDate": qry.chk_out != null ? qry.chk_out : '',
            "Currency": process.env.REACT_APP_CURRENCY,
            "Nationality": "QATARI",
            "paxInfoArr": []
        }
    }
    let indx = 0
    for (var k = 0; k < qry.num_rooms; k++) {
        indx = indx + 1
        htlSrchObj.SearchParameter.paxInfoArr.push({
            idAdt: `adt${k}`,
            idChd: `chd${k}`,
            idDelBtn: `delRoom${k}`,
            adtVal: qry['adults' + indx],
            chdVal: qry['children' + indx],
            chdAgesArr: [{
                idchdAges: 'chdAges0',
                chdAgeVal: qry['ca' + indx].replace(/,/g, '')[0],
                disabled: Number(qry['ca' + indx][0]) > 0 ? false : true
            },
            {
                idchdAges: 'chdAges1',
                chdAgeVal: qry['ca' + indx].replace(/,/g, '')[1],
                disabled: Number(qry['ca' + indx][1]) > 0 ? false : true
            },
            {
                idchdAges: 'chdAges2',
                chdAgeVal: qry['ca' + indx].replace(/,/g, '')[2],
                disabled: Number(qry['ca' + indx][2]) > 0 ? false : true
            },
            {
                idchdAges: 'chdAges3',
                chdAgeVal: qry['ca' + indx].replace(/,/g, '')[3],
                disabled: Number(qry['ca' + indx][3]) > 0 ? false : true
            }]
        })
    }

    useEffect(()=>{
        dispatch(doHotelSearchOnLoad(htlSrchObj));
    },[dispatch]);


    const getHtlRes = useSelector((state) => state.hotelList)
    const sendMap = (data) => {
        setShowMapModal(data.Modal)
    }

    const [filterChoose, setFilterChoose] = useState(false);
    const chooseFilter = (val) => {
        setFilterChoose(val)
    };

  return (
    <>
    <DefaultLayout>
        <div className="innermiddle">
            <HotelModifySearch Type={'result'} HtlReq={htlSrchObj} sendMap={sendMap} filterOpen={(val) => chooseFilter(val)} />
            <Container>
                <div className="d-table w-100">
                    {getHtlRes ?
                    <>
                        {getHtlRes.htlResObj ? 
                        <>
                        <HotelFilter sendMap={sendMap} filterChoose={filterChoose} filterClose={(val) => chooseFilter(val)} />
                        <HotelResult />
                        </> : 
                        <DummyHotelResult />
                        }
                    </>
                    :
                    <>
                        <DummyHotelResult />
                    </>
                    }
                </div>
            </Container>
        </div>

        <Modal show={showMapModal} onHide={() => setShowMapModal(false)} fullscreen={true}>
            <Modal.Header closeButton>
                <Modal.Title>Map View</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getHtlRes ?
                    <Row style={{minHeight:"100%"}}>
                        {getHtlRes.htlResObj ? 
                        <>
                            <Col className="mapLeftFilter">
                                <HotelFilter filterChoose={filterChoose} filterClose={(val) => chooseFilter(val)} />
                            </Col>
                            <Col className="p-0 mapRight">
                                <MapResult />
                            </Col>
                        </>
                        :
                        ''
                        }
                    </Row>
                    : ''
                 }
            </Modal.Body>
        </Modal>
    </DefaultLayout>
    </>
  )
}

export default HotelListing
