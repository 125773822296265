import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Form, Card, Button, Collapse, Accordion} from 'react-bootstrap';
import {doFilterSort} from "../../store/actions/tour";
import ReactSlider from 'react-slider';

const TourFilter = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getCurrency = useSelector((state) => state.currency);
  const [filterCollapse, setFilterCollapse] = useState(true);
  useEffect(() => {
    let w = window.innerWidth;
    if (w < 960) {
      setFilterCollapse(false)
    }
  }, []);

  useEffect(() => {
    setFilterCollapse(props.filterChoose)
  }, [props]);

  const OrgTourResult = useSelector((state) => state.tourList.tourResOrgObj);
  const TourFilterSort = useSelector((state) => state.tourFltrSort.tourFilterSort);

  const [classNone, setClassNone] = useState('d-none');
  const [priceFilter, setPriceFilter] = useState(OrgTourResult.tours.length && [Number(parseFloat(OrgTourResult.tours[0].minPrice -1).toFixed(2)), Number(parseFloat(OrgTourResult.tours[OrgTourResult.tours.length - 1].minPrice + 1).toFixed(2))]);
  const [minPrice, setMinPrice] =useState(OrgTourResult.tours.length && Number(parseFloat(OrgTourResult.tours[0].minPrice -1).toFixed(2)));
  const [maxPrice, setMaxPrice] =useState(OrgTourResult.tours.length && Number(parseFloat(OrgTourResult.tours[OrgTourResult.tours.length - 1].minPrice + 1).toFixed(2)));

  const [arrTypeCat, setArrTypeCat] = useState([]);

  useEffect(() => {
    setPriceFilter(OrgTourResult.tours.length && [Number(parseFloat(OrgTourResult.tours[0].minPrice -1).toFixed(2)), Number(parseFloat(OrgTourResult.tours[OrgTourResult.tours.length - 1].minPrice + 1).toFixed(2))])
    setMinPrice(OrgTourResult.tours.length && Number(parseFloat(OrgTourResult.tours[0].minPrice -1).toFixed(2)));
    setMaxPrice(OrgTourResult.tours.length && Number(parseFloat(OrgTourResult.tours[OrgTourResult.tours.length - 1].minPrice + 1).toFixed(2)));
  }, [getCurrency]);

  const [typeCat, setTypeCat] = useState([]);

  useEffect(() => {
    filterSort();
  }, [priceFilter, typeCat]);

  useEffect(()=>{
    if(OrgTourResult && OrgTourResult.tours && OrgTourResult.tours.length){
      getTypes(OrgTourResult.tours);
    }
  },[OrgTourResult]);

  const getTypes = (data) => {
    let items = [...arrTypeCat];
    let ret = {}
    for (let i = 0; i < data.length; i++) {
      let typename = data[i].type;
      ret[typename] = {
        key: typename,
        count: ret[typename] && ret[typename].count ? ret[typename].count + 1 : 1
      }
    }
    items.push(Object.values(ret));
    setArrTypeCat(items)
  }

  const typeChange = (e)=>{
    if(e.target.checked === true){
      setTypeCat([...typeCat, e.target.value]);
    }
    else if(e.target.checked === false){
      let freshArray = typeCat.filter(val => val !== e.target.value);
      setTypeCat([...freshArray]);
    }
  };

  const filterSort = () =>{
    let tourFilters = {
      typeCat: typeCat,
      priceFilter: priceFilter
    }
    let tourFilterSorts = { srtVal: '0', srchTxt: ''}
    let obj = { 'tourFilters': tourFilters, 'tourFilterSort': TourFilterSort ? TourFilterSort : tourFilterSorts}
    dispatch(doFilterSort(OrgTourResult, obj));
  };

  const reset = () =>{
    setPriceFilter([Number(parseFloat(OrgTourResult.tours[0].minPrice -1).toFixed(2)), Number(parseFloat(OrgTourResult.tours[OrgTourResult.tours.length - 1].minPrice + 1).toFixed(2))])
    setTypeCat([]);
    let tourFilters = {typeCat: [], priceFilter: []}
    let tourFilterSorts = {srtVal: '0', srchTxt: ''}
    let obj = { 'tourFilters': tourFilters, 'tourFilterSort': tourFilterSorts}
    dispatch(doFilterSort(OrgTourResult, obj));
  }

  return(
    <>
    {OrgTourResult.tours && OrgTourResult.tours.length ? 
      <div className="d-lg-table-cell align-top mainContent">
        <div className="leftFilter">
          <div className="sidebarinner">
            <Collapse in={filterCollapse} id="leftCollapse" className="position-relative">
              <div>
                <Button onClick={() => props.filterClose(false)} variant='link' className="crossBtn mt-2 p-0"><FontAwesomeIcon icon={solid('times-circle')} className="text-danger" /></Button>
                <div className="bg-white px-2 py-3 border-bottom">
                    <span className="fn16 blue align-middle"><strong>{t("FilterYourSearch")}</strong></span> &nbsp; <Button variant='link' className="badge bg-light text-dark float-lg-end fn14 fw-normal" onClick={reset}>{t("ResetAll")}</Button>
                </div>
              
                <Accordion alwaysOpen defaultActiveKey={['0','1']}>
                  <Accordion.Item as={Card} eventKey="0">
                    <Accordion.Header as={Card.Header}>{t("Price")}</Accordion.Header>
                    <Accordion.Body as={Card.Body}>
                      <ReactSlider
                        className="horizontal-slider"
                        thumbClassName="example-thumb"
                        trackClassName="example-track"
                        defaultValue={priceFilter}
                        min={minPrice}
                        max={maxPrice}
                        value={priceFilter}
                        onChange={event => setPriceFilter(event)}
                        ariaLabel={['Lower thumb', 'Upper thumb']}
                        ariaValuetext={state => `Thumb value ${state.valueNow}`}
                        renderThumb={(props) => <div {...props}></div>}
                        />
                      <div className="my-1 text-muted d-flex justify-content-between fn12">
                        <span>{getCurrency.currency} {OrgTourResult.tours.length && parseFloat(OrgTourResult.tours[0].minPrice -1).toFixed(2)}</span>
                        <span>{getCurrency.currency} {OrgTourResult.tours.length && parseFloat(OrgTourResult.tours[OrgTourResult.tours.length - 1].minPrice + 1).toFixed(2)}</span>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item as={Card} eventKey="1">
                    <Accordion.Header as={Card.Header}>{t("Category")}</Accordion.Header>
                    <Accordion.Body as={Card.Body}>
                      <div>
                        {arrTypeCat && arrTypeCat.length &&
                          <>
                          { arrTypeCat[0].map((ct, i) => {
                            return (
                            <div key={i} className={`moreBox ${i > 9 ? classNone :''}`}>
                              <Form.Check id={`ct${i}`}>
                                <Form.Check.Input value={ct.key} onChange={e => typeChange(e)} checked={typeCat.includes(ct.key)} />
                                <Form.Check.Label className='mb-0 w-100 fn13'>
                                  <span className="w-100 d-flex justify-content-between">
                                    <span>{ct.key}</span>
                                    <span>({ct.count})</span>
                                  </span>
                                </Form.Check.Label>
                              </Form.Check>
                            </div>
                            )
                          })
                          }

                          <>
                          { arrTypeCat.length > 9 && 
                            <div>
                              <Button onClick={()=> classNone==='d-none' ? setClassNone('d-block') : setClassNone('d-none')} variant='link' className='p-0 fn13'>
                                {classNone==='d-none' ? 'Show more': 'Show less'} 
                              </Button>
                            </div>
                          }
                          </>
                          </>
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      : ''
    }
    </>
  );
}
export default TourFilter;