export const RESET_HOTEL_RES = "RESET_HOTEL_RES";
export const SET_HOTELRES = "SET_HOTELRES";
export const SET_OGHOTELRES = "SET_OGHOTELRES";
export const RESET_HOTEL_FILTER = "RESET_HOTEL_FILTER";
export const SET_HOTELFILTER = "SET_HOTELFILTER";
export const SET_HOTELFILTERSORT = "SET_HOTELFILTERSORT";
export const RESET_HOTEL_DETAILS_RES = "RESET_HOTEL_DETAILS_RES";
export const SET_HOTEL_DETAILS_RES = "SET_HOTEL_DETAILS_RES";
export const RESET_HOTEL_RMDETAILS_RES = "RESET_HOTEL_RMDETAILS_RES";
export const SET_HOTEL_RMDETAILS_RES = "SET_HOTEL_RMDETAILS_RES";
export const RESET_HOTEL_CANCELDATA_RES = "RESET_HOTEL_CANCELDATA_RES";
export const SET_HOTEL_CANCELDATA_RES = "SET_HOTEL_CANCELDATA_RES";
export const RESET_HOTEL_FAREBREAKUPDATA_RES = "RESET_HOTEL_FAREBREAKUPDATA_RES";
export const SET_HOTEL_FAREBREAKUPDATA_RES = "SET_HOTEL_FAREBREAKUPDATA_RES";
export const RESET_HOTEL_REPRICE_RES = "RESET_HOTEL_REPRICE_RES";
export const SET_HOTEL_REPRICE_RES = "SET_HOTEL_REPRICE_RES";






