import React from "react"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
class DefaultLayout extends React.Component {
  render(){
    return (
      <>
        <Header />
          <main>{this.props.children}</main>
        <Footer />
      </>
    )
  }
}
export default DefaultLayout;