import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Row, Col, Form, Card, InputGroup} from 'react-bootstrap';
const DummyTourResult = () => {
  const { t} = useTranslation();
  return(
    <>
    <div className="d-lg-table-cell align-top">
      <div className="leftFilter d-none d-lg-block">
          <Card>
            <Card.Header>{t("Price")} <span className="cardArrow"><FontAwesomeIcon icon={solid('chevron-down')} /></span></Card.Header>
            <Card.Body style={{height:"90px"}}>
              <div className="dumwave">
                <div className="anim anim1"></div>
                <div className="anim anim2"></div>
                <div className="anim anim3"></div>
                <div className="anim anim4"></div>
                <div className="anim anim5"></div>
              </div>
            </Card.Body>     
          </Card>

          <Card>
            <Card.Header>{t("Category")} <span className="cardArrow"><FontAwesomeIcon icon={solid('chevron-down')} /></span></Card.Header>
            <Card.Body style={{height:"90px"}}>
              <div className="dumwave">
                <div className="anim anim1"></div>
                <div className="anim anim2"></div>
                <div className="anim anim3"></div>
                <div className="anim anim4"></div>
                <div className="anim anim5"></div>
              </div>
            </Card.Body>     
          </Card>
      </div>
    </div>
    <div className="d-lg-table-cell align-top rightResult">
         <div className="sortTop sticky-top mt-3 mt-lg-0">
            <Row className="justify-content-between gx-2">
              <Col md={3} xs={4} className="mb-1">
                <Form.Select size="sm" disabled>
                  <option>{t("SortBy")}</option>
                </Form.Select>
              </Col>
              <Col md={5} xs={8} className="mb-1">
                <InputGroup>
                  <Form.Control className="border-end-0 fn13" placeholder={t('EnterExcursionName')} disabled />
                  <InputGroup.Text><FontAwesomeIcon icon={solid('search')} className="fn14" /></InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
        </div> 
        {
          Array.apply(null, { length:15}).map((e, i) => (
          <div className="htlboxcol" key={i}>
              <Row className="gx-2">
                <Col md={10}>
                  <div className="d-flex flex-row">
                    <div className="hotelImg rounded-left d-flex noView">
                      <div className="align-self-center mx-auto text-center d-flex d-sm-block px-2">
                        <div className="dumwave">
                          <div className="anim anim1"></div>
                          <div className="anim anim2"></div>
                          <div className="anim anim3"></div>
                          <div className="anim anim4"></div>
                          <div className="anim anim5"></div>
                        </div>
                      </div>
                    </div>
                    <div className="ps-3 pe-1 pt-2 w-100 htlright">
                      <Row className="gx-2 h-100">
                        <Col md={12} className="align-self-center">
                          <div className="dummyDiv" style={{width:"75%", height:"27px"}}></div>
                          <div className="dummyDiv" style={{height:"20px", width:"45%"}}></div>
                        </Col>
                        <Col md="12">
                          <div className="dummyDiv d-inline-block me-1" style={{width:"20px", height:"20px"}}></div>
                          <div className="dummyDiv d-inline-block me-1" style={{width:"20px", height:"20px"}}></div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col md={2} className="border-start d-flex">
                  <div className="align-self-center mx-md-auto ms-auto text-center d-flex d-md-block px-2">
                    <div className="dumwave mx-2 mx-md-0">
                      <div className="anim anim1"></div>
                      <div className="anim anim2"></div>
                      <div className="anim anim3"></div>
                      <div className="anim anim4"></div>
                      <div className="anim anim5"></div>
                    </div>
                  </div>
                </Col>
              </Row>
          </div>
          ))
        }
    </div>
    </>
  );
}
export default DummyTourResult;