import { combineReducers } from "redux";
import { exchangeRateReducer,currencyReducer, languageReducer } from "./common";
import { hotelListResultReducer, htlFltrReducer, htlFltrSortReducer,hotelDetailsReducer, hotelRmDetailsReducer, hotelCancelDataReducer, hotelFareBreackupDataReducer, hotelRepriceDataReducer} from "./hotel";
import { tourListResultReducer, tourFltrReducer, tourFltrSortReducer, tourDetailsReducer, tourOptionsReducer} from "./tour";
import { transferListResultReducer, transferFltrReducer, transferFltrSortReducer,} from "./transfer";
import { preBookingReducer } from "./payment";

const reducers = combineReducers({
  exchangeRate: exchangeRateReducer,
  currency: currencyReducer,
  language: languageReducer,
  hotelList: hotelListResultReducer,
  htlFilter: htlFltrReducer,
  htlFltrSort: htlFltrSortReducer,
  hotelDetails: hotelDetailsReducer,
  hotelRmDetails: hotelRmDetailsReducer,
  hotelCancelData: hotelCancelDataReducer,
  hotelFareBreackupData: hotelFareBreackupDataReducer,
  hotelRepriceData: hotelRepriceDataReducer,
  preBooking: preBookingReducer,
  tourList: tourListResultReducer,
  tourFilter: tourFltrReducer,
  tourFltrSort: tourFltrSortReducer,
  tourDetails: tourDetailsReducer,
  tourOptions: tourOptionsReducer,
  transferList: transferListResultReducer,
  transferFilter: transferFltrReducer,
  transferFltrSort: transferFltrSortReducer,
});
export default reducers;