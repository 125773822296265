import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {Container, Row, Col, Button, Form, Collapse} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import { format } from 'date-fns';
import DefaultLayout from '../../layouts/DefaultLayout';
import {ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TourFareBreakup from '../../components/tour/FareBreakup';
import EmailSection from '../../components/traveller/EmailSection';
import PaymentSection from '../../components/traveller/PaymentSection';
import {doTourDetails} from "../../store/actions/tour";

const TourItinerary = () => {
    const query = useLocation(); 
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const getCurrency = useSelector((state) => state.currency)

    let tourDetailObj = null
    if(query && query.state && query.state.reqObj){
        let qry = query.state.reqObj
        tourDetailObj = {
            "SessionId": qry.sessionId,
            "SearchParameter": {
                "DestinationCode": qry.destinationCode,
                "CountryCode": qry.countryCode,
                "ServiceDate": qry.serviceDate,
                "Currency": process.env.REACT_APP_CURRENCY,
                "GroupCode": qry.groupCode,
                "TourCode": qry.tourCode,
                "Adults": parseInt(qry.adults),
            }
        }
        if (parseInt(qry.children) > 0) {
            let childrenObj = {}
            let arrChildAges = []
            let indx = 0
            let chdAgesArr = qry.ca.split(',');
            for (var k = 0; k < chdAgesArr.length; k++) {
                indx = indx + 1
                let ageObj = {}
                ageObj.Identifier = indx
                ageObj.Text = chdAgesArr[k]
                arrChildAges.push(ageObj)
            }
            childrenObj.Count = parseInt(qry.children)
            childrenObj.ChildAge = arrChildAges;
            tourDetailObj.SearchParameter.Children = childrenObj
        }

    }

    useEffect(()=>{
        dispatch(doTourDetails(tourDetailObj));
    },[dispatch]);

    const TourDetails = useSelector((state) => state.tourDetails)
    const TourOptinDetails = (query && query.state && query.state.tourOptinDetails ? query.state.tourOptinDetails : null);
    console.log("TourOptinDetails", TourOptinDetails)
    // const getCurrency = useSelector((state) => state.currency);
    // const TourDetails = (qry && qry.state && qry.state.tourDetails ? qry.state.TourDetails.tourDtlObjDtlObj : null);
    // const TourOptinDetails = (qry && qry.state && qry.state.tourOptinDetails ? qry.state.tourOptinDetails : null);
    // const TourDetReqObj = (qry && qry.state && qry.state.tourDetReqObj ? qry.state.tourDetReqObj : null);

    const totalGuest = () => {
        let guest = 0
        guest = guest + parseInt(tourDetailObj.SearchParameter.Adults) + (tourDetailObj.SearchParameter.Children ? parseInt(tourDetailObj.SearchParameter.Children.Count) : 0)
        return guest
    };

    const [travellerObj, setTravellerObj] = useState({
        email: "",
        password: "",
        title: "",
        fName: "",
        lName: "",
        mob: "",
        hvPasswrd: false,
        allowOffrs: true
    });

    const [errTravellerObj, setErrTravellerObj] = useState({
        email: "",
        title: "",
        fName: "",
        lName: "",
        mob: "",
    });

    const [openL, setOpenL] = useState(true);
    const [activeItem, setActiveItem] = useState('emailCol');

    const setActive = (menuItem) => {
        if(menuItem==="travellerCol"){
            if(travellerObj.email !=="" && travellerObj.mob !==""){
             setActiveItem(menuItem)
            }
            else{
                return false
            }
        }
        else{
            setActiveItem(menuItem)
        }
    };

    const isActive = (menuItem) => {
        return activeItem === menuItem
    };

    const travelOpen = (travObj) => {
        const travItems = {...travellerObj};
        travItems.email = travObj.email;
        travItems.mob = travObj.phone;
        setTravellerObj(travItems)
        setActiveItem("travellerCol")
    };

    const titleChange = (e) => {
        const travItems = {...travellerObj};
        travItems.title = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.title === ""){
            errTravItems.title = 'Please Select Title'
        }
        else{
            errTravItems.title = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    };

    const fNameChange = (e) => {
        const travItems = {...travellerObj};
        travItems.fName = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.fName === ""){
            errTravItems.fName = 'Please Enter Your First Name'
        }
        else{
            errTravItems.fName = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    };

    const lNameChange = (e) => {
        const travItems = {...travellerObj};
        travItems.lName = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.lName === ""){
            errTravItems.lName = 'Please Enter Your Last Name'
        }
        else{
            errTravItems.lName = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    };
    
    const travValidation = () => {
        const errTravItems = {...errTravellerObj};
        if(travellerObj.title === ""){
            errTravItems.title = 'Please Select Title'
        }
        if(travellerObj.fName === ""){
            errTravItems.fName = 'Please Enter Your First Name'
        }
        if(travellerObj.lName === ""){
            errTravItems.lName = 'Please Enter Your Last Name'
        }
        setErrTravellerObj(errTravItems)

        if(travellerObj.title && travellerObj.fName && travellerObj.lName){
            return true;
        }
        else{
            return false;
        }
    };

    const [srvCode, setSrvCode] = useState(null);

    const payFortSubmit = () => {
        let valid = travValidation()
        if(valid){
            setSrvCode('4')
        }
        else{
            setSrvCode(null)
            return false; 
        }
    };

    const paymentGateway = process.env.REACT_APP_PAYMENT_GATEWAY
   
  return (
    <>
    <ToastContainer />
    <DefaultLayout>
        <div className="innermiddle mt-4">
        
        {TourDetails && TourDetails.tourDtlObj ?
            <Container> 
                <Row>
                    <Col className="mb-2" lg={{span: 8, order: 1}} xs={{order: 2}}>
                        <div className="bg-white rounded shadow-sm shadow-lg-none">
                            <Row className="mb-1">
                                <Col>
                                    <div className="d-sm-flex flex-row">
                                        <div className="hotelImg rounded-start noView d-none d-sm-block">
                                            {TourDetails.tourDtlObj.tour.imagePath ? 
                                            <img src={TourDetails.tourDtlObj.tour.imagePath} alt={TourDetails.tourDtlObj.tour.name} />
                                            :
                                            <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                                            } 
                                        </div>
                                        <div className="ps-sm-3 pe-1 pt-2 w-100 arhotelItenDetail">
                                            <h3 className="nametitle blue">{TourDetails.tourDtlObj.tour.name}</h3>
                                            <div className="mb-1">
                                                <span className="boxRating">
                                                    {
                                                        Array.apply(null, { length:parseInt(TourDetails.tourDtlObj.tour.rating)}).map((e, i) => (
                                                        <FontAwesomeIcon key={i} icon={solid('star')} className="stargold" />
                                                        ))
                                                    }
                                                </span> &nbsp;&nbsp;
                                                {TourDetails.tourDtlObj.tour.reviewCount && TourDetails.tourDtlObj.tour.reviewCount !== 0 ?
                                                    <span className="text-muted fn13">&nbsp; ({TourDetails.tourDtlObj.tour.reviewCount} Review)</span>
                                                    : null
                                                }
                                            </div>
                                            <div>
                                                <ul className="list-inline mb-2">
                                                    <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={regular('user')} className="fn14" /> &nbsp;{totalGuest()} Pax &nbsp;&nbsp;</li>
                                                    <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={regular('clock')} className="fn14" /> &nbsp;{TourDetails.tourDtlObj.tour.duration}</li>
                                                </ul>
                                            </div>
                                            <div className="fn15 blue my-1"><strong>{t("Date")}: </strong> {format(new Date(tourDetailObj.SearchParameter.ServiceDate), 'dd MMM yyyy')}</div>
                                            <ul className="bulletList">
                                                {TourDetails.tourDtlObj.tour.duration && 
                                                    <li><Row className="gx-2"><strong className="col-4">{t("Duration1")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.duration}</span></Row></li>
                                                }
                                                {TourDetails.tourDtlObj.tour.tourInfo.departurePoint && 
                                                    <li><Row className="gx-2"><strong className="col-4">{t("DeparturePoint")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.tourInfo.departurePoint}</span></Row></li>
                                                }
                                                {TourDetails.tourDtlObj.tour.tourInfo.reportingTime && 
                                                    <li><Row className="gx-2"><strong className="col-4">{t("ReportingPoint")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.tourInfo.reportingTime}</span></Row></li>
                                                }
                                                {TourDetails.tourDtlObj.tour.tourInfo.tourLanguage && 
                                                    <li><Row className="gx-2"><strong className="col-4">{t("TourLanguage")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.tourInfo.tourLanguage}</span></Row></li>
                                                }
                                                {TourDetails.tourDtlObj.tour.tourInfo.meal && 
                                                    <li><Row className="gx-2"><strong className="col-4">{t("Meals")}:</strong> <span className="col-8">{TourDetails.tourDtlObj.tour.tourInfo.meal}</span></Row></li>
                                                }
                                            </ul>
                                            <div className="fn12 my-2 text-muted"><strong>Note:</strong> If tour is booked without transfers, please confirm the tour location & timings with our customer support team if required.</div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <div className="px-lg-3 pb-md-3 pt-1">
                                <h2 className="fn24 blue">
                                    {paymentGateway !== '4' ? t("BookSimpleSteps3")  : t("BookSimpleSteps2") }
                                </h2>
                            </div>

                            <div className="nav nav-tabs nav-justified stepNav">
                                <Button onClick={() => setActive('emailCol')} variant="link" className={"nav-link " + (isActive('travellerCol') || isActive('paymentCol') ? 'active' : '')}>
                                    <span className="stepTxt">
                                        <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                        &nbsp;{t("EmailAddress")}
                                    </span>
                                </Button>
                                <Button onClick={() => setActive('travellerCol')} variant="link" className={"nav-link " + (!isActive('travellerCol') && !isActive('paymentCol') ? 'disabled' : '' || isActive('paymentCol') ? 'active':'')}>
                                    <span className="stepTxt">
                                        <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                        &nbsp;{t("TravellersInfo")}
                                    </span>
                                </Button>
                                {paymentGateway !== '4' && 
                                <Button onClick={() => setActive('paymentCol')} variant="link" className={"nav-link " + (!isActive('paymentCol') ? 'disabled' : '')}>
                                    <span className="stepTxt">
                                        <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                        &nbsp;{t("Payment")}
                                    </span>
                                </Button>
                                }
                            </div>

                            <div className="tab-content">
                                <div className={"tab-pane fade " + (isActive('emailCol') && 'active show')}>
                                    <EmailSection toggleTrav={travelOpen} />
                                </div>
                                <div className={"tab-pane fade " + (isActive('travellerCol') && 'active show')}>
                                    <div className="pt-lg-4 px-lg-4 p-0">
                                        <Row className="mb-3 gx-2">
                                            <Form.Label className="col-md-2 align-self-center text-sm-end mb-lg-3"><strong>{t("NameOfGuest")}<sup className="text-danger">*</sup></strong></Form.Label>
                                            <Col md={10}>
                                                <Row className="gx-2">
                                                    <Col sm={2} className="mb-3">
                                                        <Form.Select className='mb-1' value={travellerObj.title} onChange={(e) => titleChange(e)}>
                                                            <option value="">--Title--</option>
                                                            <option value="Mr">Mr</option>
                                                            <option value="Miss">Miss</option>
                                                            <option value="Mrs">Mrs</option>
                                                        </Form.Select>
                                                        <div className="text-danger fn12">{errTravellerObj.title}</div>
                                                    </Col>
                                                    <Col sm={5} className="mb-3">
                                                        <Form.Control className={'mb-1 ' + (errTravellerObj.fName && 'is-invalid')} value={travellerObj.fName} onChange={(e) => fNameChange(e)} placeholder={t('FirstName')} />
                                                        <div className="text-danger fn12">{errTravellerObj.fName}</div>
                                                    </Col>
                                                    <Col sm={5} className="mb-3">
                                                        <Form.Control className={'mb-1 ' + (errTravellerObj.lName && 'is-invalid')} value={travellerObj.lName} onChange={(e) => lNameChange(e)} placeholder={t('LastName')} />
                                                        <div className="text-danger fn12">{errTravellerObj.lName}</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    {paymentGateway === '4' ?
                                    <div className="text-end resFixedBtn"><Button onClick={()=>payFortSubmit()} variant="success" size="lg" className="rounded-0">&nbsp; Pay&nbsp;
                                    {TourOptinDetails.localField ?
                                    <>{getCurrency.currency} {parseFloat(TourOptinDetails.localField.totalNet).toFixed(2)}</>
                                    :
                                    <>{getCurrency.currency} {parseFloat(TourOptinDetails.finalAmount).toFixed(2)}</>
                                    }
                                    </Button></div>
                                    : 
                                    <div className="text-end resFixedBtn"><Button variant="success" size="lg" className="rounded-0">&nbsp;{t("Continue")} &nbsp; <FontAwesomeIcon icon={solid('long-arrow-alt-right')} className="align-middle fn18" /></Button></div>
                                    }
                                </div>
                                <div className={"tab-pane fade " + (isActive('paymentCol') && 'active show')}>
                                    <PaymentSection bookingObj={TourOptinDetails} tourReq={query.state.reqObj} travObj={travellerObj} srvCodeObj={srvCode} />
                                </div>
                            </div>

                        </div>
                    </Col>
                    <TourFareBreakup FtourOptinDetails={TourOptinDetails} FtourDetReqObj={tourDetailObj} />
                </Row>
            </Container> 
            :
            <Container>
                <Row>
                    <Col lg={{span:8, order: 1}} xs={{order: 2}} className="mb-2">
                        <div className="bg-white rounded shadow-sm shadow-lg-none">
                            <div className="d-sm-flex flex-row">
                                <div className="hotelImg rounded-start noView d-none d-sm-block">
                                    <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                                </div>
                                <div className="ps-sm-3 pe-1 pt-2 w-100 arhotelItenDetail">
                                    <h3 className="nametitle blue"><div className="dummyDiv" style={{Width:'70%', Height:'27px'}}></div></h3>
                                    <div className="dummyDiv" style={{Width:'45%', Height:'20px'}}></div>
                                    <div>
                                        <div className="dumwave">
                                            <div className="anim anim1"></div>
                                            <div className="anim anim2"></div>
                                            <div className="anim anim3"></div>
                                            <div className="anim anim4"></div>
                                            <div className="anim anim5"></div>
                                        </div>
                                    </div>
                                    <div className="dummyDiv" style={{Width:'30%', Height:'20px'}}></div>
                                </div>
                            </div>  
                        </div>
                    </Col>
                    <Col lg={{span:4, order:2}} xs={{order:1}} className="mb-2">
                        <div className="bg-white rounded shadow-sm sticky-top shadow-lg-none">
                            <h3 className="nametitle blue plr10 pt-3 pb-1 plrlg0">{t("FareBreakup")} <Button size="sm" variant="link" className="fn14 text-warning d-inlineblock d-lg-none" onClick={() => setOpenL(!openL)}> (Show Details) </Button></h3>
                            <Collapse in={openL}>
                                <div>
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td>Pax</td>
                                                <td className="text-end">
                                                    <div className="dumwave">
                                                        <div className="anim anim1"></div>
                                                        <div className="anim anim2"></div>
                                                        <div className="anim anim3"></div>
                                                        <div className="anim anim4"></div>
                                                        <div className="anim anim5"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td>{t("Tax")} </td>
                                                <td className="text-end">
                                                    <div className="dumwave">
                                                        <div className="anim anim1"></div>
                                                        <div className="anim anim2"></div>
                                                        <div className="anim anim3"></div>
                                                        <div className="anim anim4"></div>
                                                        <div className="anim anim5"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Collapse>
                            <table className="table mb-0">
                                <tbody>
                                    <tr className="bg-light">
                                        <td><strong>{t("TotalAmount")}</strong></td>
                                        <td className="text-end">
                                            <div className="dumwave">
                                                <div className="anim anim1"></div>
                                                <div className="anim anim2"></div>
                                                <div className="anim anim3"></div>
                                                <div className="anim anim4"></div>
                                                <div className="anim anim5"></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
        }
        </div>
    </DefaultLayout>
    </>
  )
}

export default TourItinerary
