import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useSearchParams } from 'react-router-dom';
import {Container} from 'react-bootstrap';
import {doTransferSearchOnLoad} from "../../store/actions/transfer";
import TransferModifySearch from '../../components/transfer/ModifySearch';
import DummyTransferResult from '../../components/tour/DummyResults';
import TransferFilter from '../../components/transfer/Filter';
import TransferResult from '../../components/transfer/Results';

const TransferListing = () => {
    const dispatch = useDispatch();
    const [searchparams] = useSearchParams();
    const qry = Object.fromEntries([...searchparams])

    let transferSrchObj = {
        "SearchParameter": {
            "DestinationCode": qry.destinationCode,
            "TransferType": qry.isSingleDate === false || qry.isSingleDate === 'false' ? "OneWay" : "RoundTrip",
            "PickupDetails": {
                "Address": qry.pickupAddress,
                "Type": qry.pickupType,
                "Geo": qry.pickupGeo,
                "GooglePlacesCode": qry.pickupGooglePlacesCode,
                "PickupDate": qry.pickupDate,
                "PickupTime": qry.pickupTime,
                "GIATACode": qry.pickupGIATACode
            },
            "DropoffDetails": {
                "Address": qry.dropoffAddress,
                "Type": qry.dropoffType,
                "Geo": qry.dropoffGeo,
                "GooglePlacesCode": qry.dropoffGooglePlacesCode,
                "PickupDate": "",
                "PickupTime": "",
                "GIATACode": qry.dropoffGIATACode
            },
            "Currency": process.env.REACT_APP_CURRENCY,
            "Adults": parseInt(qry.adults),
        }
    }
    if (parseInt(qry.children) > 0) {        
        let childrenObj = {}
        let arrAges = []
        let indx = 0
        let chdAgesArr = qry.ca.split(',');
        for (var k = 0; k < chdAgesArr.length; k++) {
            indx = indx + 1
            let ageObj = {}
            ageObj.Identifier = indx
            ageObj.Text = chdAgesArr[k]
            arrAges.push(ageObj)
        }
        childrenObj.Count = parseInt(qry.children)
        childrenObj.ChildAge = arrAges;
        transferSrchObj.SearchParameter.Children = childrenObj
    }
    transferSrchObj.qry = qry
    
    useEffect(()=>{
        dispatch(doTransferSearchOnLoad(transferSrchObj));
    },[dispatch]);

    const getTransferRes = useSelector((state) => state.transferList);

    const [filterChoose, setFilterChoose] = useState(false);
    const chooseFilter = (val) => {
        setFilterChoose(val)
    };

  return (
    <>
    <DefaultLayout>
        <div className="innermiddle">
            <TransferModifySearch Type={'result'} TransferReq={transferSrchObj} filterOpen={(val) => chooseFilter(val)} />
            <Container>
                <div className="d-md-table w-100">
                    {getTransferRes ?
                    <>
                        {getTransferRes.transferResObj ? 
                        <>
                        <TransferFilter filterChoose={filterChoose} filterClose={(val) => chooseFilter(val)} />
                        <TransferResult /> 
                        </> : 
                        <DummyTransferResult />
                        }
                    </>
                    :
                    <>
                        <DummyTransferResult />
                    </>
                    }
                </div>
            </Container>
        </div>
    </DefaultLayout>
    </>
  )
}

export default TransferListing
