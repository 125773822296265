import ApiService from './api.service'

const TransferService = {
  getLocalTransferLocations: async function (reqObj) {
    const requestData = {
      method: 'post',
      url: '/api/Common/GetLocalTransferLocations',
      data: reqObj,
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doTransferSearch: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: null,
      url: '/api/Transfer/Search',
    }
    try {
      let tempReqObj = {
        "SearchParameter": {
          "TransferType": reqObj.SearchParameter.TransferType,
          "PickupDetails": {
              "Address": reqObj.SearchParameter.PickupDetails.Address,
              "Type": reqObj.SearchParameter.PickupDetails.Type,
              "Geo": reqObj.SearchParameter.PickupDetails.Geo,
              "GooglePlacesCode": reqObj.SearchParameter.PickupDetails.GooglePlacesCode,
              "PickupDate": reqObj.SearchParameter.PickupDetails.PickupDate,
              "PickupTime": reqObj.SearchParameter.PickupDetails.PickupTime,
              "GIATACode": reqObj.SearchParameter.PickupDetails.GIATACode,
          },
          "DropoffDetails": {
              "Address": reqObj.SearchParameter.DropoffDetails.Address,
              "Type": reqObj.SearchParameter.DropoffDetails.Type,
              "Geo": reqObj.SearchParameter.DropoffDetails.Geo,
              "GooglePlacesCode": reqObj.SearchParameter.DropoffDetails.GooglePlacesCode,
              "PickupDate": reqObj.SearchParameter.DropoffDetails.PickupDate === "" ? "" : reqObj.SearchParameter.DropoffDetails.PickupDate,
              "PickupTime": reqObj.SearchParameter.DropoffDetails.PickupTime,
              "GIATACode": reqObj.SearchParameter.DropoffDetails.GIATACode,
          },
          "Currency": reqObj.SearchParameter.Currency,
          "Adults": reqObj.SearchParameter.Adults,
          "Children": reqObj.SearchParameter.Children,
          "TassProField": {
              "CustomerCode": 7,
              "RegionId": 8,
              "SupplierCodeFK": 3583,
              "H2HCode": 138,
              "H2HName": "LOCAL"
          }
        }
      }
      requestData.data = tempReqObj
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doCheckTime: async function (reqObj) {
    const requestData = {
      method: 'post',
      url: '/api/Transfer/CheckTime',
      data: reqObj,
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

}
export default TransferService
export { TransferService }