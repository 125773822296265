import React, {useEffect, useState } from "react";
import { useSearchParams, useNavigate, createSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col, Button, Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {getCancellationData, getFareBreackupData} from "../../store/actions/hotel";
import { format, differenceInDays  } from 'date-fns';
const RoomDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const getCurrency = useSelector((state) => state.currency)
  const HtlRmDetailsRedx = useSelector((state) => state.hotelRmDetails);
  const [HtlRmDetails, setHtlRmDetails] = useState(null);

  useEffect(()=>{
    setHtlRmDetails(JSON.parse(sessionStorage.getItem('hotelStore')));
  },[HtlRmDetailsRedx]);

  const HtlCancelData = useSelector((state) => state.hotelCancelData);
  const HtlFBData = useSelector((state) => state.hotelFareBreackupData);
  const [searchparams] = useSearchParams();
  const qry = Object.fromEntries([...searchparams]);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [rmNameModal, setRmNameModal] = useState('');
  const [rmPriceModal, setRmPriceModal] = useState('');
  const [rmRateBasisModal, setRmRateBasisModal] = useState('');
  
  const cancelPolicy =(e,rateKey, grpCode, rmName, price, rateBasis, rateType) => {
    setRmNameModal(rmName);
    setRmPriceModal(price);
    setRmRateBasisModal(rateBasis);
    e.preventDefault()
    let reqObj = {
      "SessionId": qry.sessionId,
      "SearchParameter": {
        "CityName": qry.city,
        "CountryName": qry.countryName,
        "Currency": process.env.REACT_APP_CURRENCY,
        "HotelCode": qry.hotelId,
        "GroupCode": grpCode,
        "CheckInDate": qry.chk_in,
        "CheckOutDate": qry.chk_out,
        "RateKeys": {
          "RateKey": rateKey.includes('*') ? rateKey.split('*') : []
        }
      }
    }
    if (reqObj.SearchParameter.RateKeys.RateKey.length === 0) {
      reqObj.SearchParameter.RateKeys.RateKey.push(rateKey)
    }
    dispatch(getCancellationData(reqObj));
    setShowCancelModal(true)
  };
  
  const [showFbModal, setShowFbModal] = useState(false);

  const fareBreackup =(e,rateKey, grpCode, rmName, price, rateBasis, rateType) => {
    setRmNameModal(rmName);
    setRmPriceModal(price);
    setRmRateBasisModal(rateBasis);
    e.preventDefault()
    let reqObj = {
      "SessionId": qry.sessionId,
      "SearchParameter": {
        "CityName": qry.city,
        "CountryName": qry.countryName,
        "Currency": process.env.REACT_APP_CURRENCY,
        "HotelCode": qry.hotelId,
        "GroupCode": grpCode,
        "CheckInDate": qry.chk_in,
        "CheckOutDate": qry.chk_out,
        "RateKeys": {
          "RateKey": rateKey.includes('*') ? rateKey.split('*') : []
        }
      }
    }
    if (reqObj.SearchParameter.RateKeys.RateKey.length === 0) {
      reqObj.SearchParameter.RateKeys.RateKey.push(rateKey)
    }
    dispatch(getFareBreackupData(reqObj));
    setShowFbModal(true)
  };
  
  const bookNow = (sessionId, reprice, rateKey, groupcode, hId, rmName, rBasisName, val) => {
    let bookNowObj = {
      'sId': sessionId,
      'rp': reprice,
      'rt': rateKey,
      'gp': groupcode,
      'hId': hId,
      'rmName': rmName,
      'rBasisName': rBasisName,
      'rmData': val,
      'chkIn': qry.chk_in,
      'chkOut': qry.chk_out,
      'nights': 0,
      "cityName": qry.city,
      "countryName": qry.countryName,
    }
    
    let start = new Date(qry.chk_in);
    let end = new Date(qry.chk_out);
    let nights = differenceInDays(end, start);
    bookNowObj.nights = nights

    let newQry = {
      'pid': bookNowObj.sId + '_' + bookNowObj.rt
    }
    navigate(`/hotelItinerary?${createSearchParams(newQry).toString()}`,{state:{htlBookNowObj:bookNowObj}})
    // navigate({
    //   pathname: "/hotelItinerary",
    //   search: createSearchParams(newQry).toString(),
    // })
  };

  const capitalizeFirst = str => {
    let titleCase = str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
    return titleCase;
  };

  return( 
    <>
    { HtlRmDetails && HtlRmDetails.hotelRmDetails ? 
    <div>
      <div className="fn18 text-dark mb-2"><strong>{t("SelectRoom")}</strong></div>
      <div className="roomRow fn16 bg-light shadow-none border-top d-none d-md-block">
          <Row>
              <Col md={6} className="py-2 blue">{t("RoomInfo")}</Col>
              <Col md={3} className="py-2 blue border-left text-capitalize">{t("options")}</Col>
              <Col md={3} className="py-2 blue border-left text-center">{t("Price")}</Col>
          </Row>
      </div>
      {HtlRmDetails.hotelRmDetails.hotel ?
        <>
        {HtlRmDetails.hotelRmDetails.hotel.rooms.map((v, i) => ( 
          <div className="roomRow" key={i}>
            <Row className="gx-2">
              <Col md={6} className="py-3 border-bottom" style={{backgroundColor:"#fbfbfb"}}>
                <div className="fn16 mb-1">{capitalizeFirst(v.roomName)} </div>
              </Col>
              <Col md={6}>
                {v.mappedRooms.map((val, i) => ( 
                  <Row className="border-bottom gx-2" key={i}>
                    <Col className="border-left d-flex">
                      <div className="align-self-center">
                        <p className="mb-1"><strong>{val.rateBasisName}</strong></p>
                        <div className="mb-1 fn12">
                          <a href="#" className="cancelLink" onClick={(e) => cancelPolicy(e,val.rateKey,val.groupcode,v.roomName,val.prices.netAmount,val.rateBasisName,val.rateType)}>{t("CancellationPolicy")}</a>
                        </div>
                      </div>
                    </Col>
                    <Col className="border-left d-flex">
                      <div className="align-self-center mx-auto text-center p-2 py-3">
                        <div className="blue mb-1">
                          <span className="nametitle d-block blue mb-0 text-nowrap">
                            {getCurrency.currency} {parseFloat(val.prices.netAmount).toFixed(2)} &nbsp;
                            <a href="#" className="cancelLink" onClick={(e) => fareBreackup(e,val.rateKey,val.groupcode,v.roomName,val.prices.netAmount,val.rateBasisName,val.rateType)}>
                              <FontAwesomeIcon icon={solid('info-circle')} className="align-top" />
                            </a>
                          </span>
                          <span className="fn12 align-top">
                            {val.rateType === 'Non Refundable' || val.rateType === 'Non-Refundable' ? 
                              <span className="text-danger">{t("NonRefundable")}</span>
                              : val.rateType === 'Refundable' ? 
                              <span className="text-success">{t("Refundable")}</span>
                              : <span>{val.rateType}</span>
                            }
                          </span>
                        </div>
                        <Button variant="warning" size="sm" className="text-uppercase" onClick={() => bookNow(HtlRmDetails.hotelRmDetails.generalInfo.sessionId,val.reprice,val.rateKey,val.groupcode,HtlRmDetails.hotelRmDetails.hotel.code,v.roomName,val.rateBasisName,val) }>{t("BookNow")}</Button>
                      </div>
                    </Col>
                  </Row>
                ))
                }
              </Col>
            </Row>
          </div>
        ))
        }
        </>
        :
        <div className="text-center my-5">
          <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
          <div className="fn21">{t("NoResultFound")}</div>
        </div>
      }
      

      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="fn16">{t("CancellationPolicy")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
          { HtlCancelData && HtlCancelData.htlCancelDtlObj ?
          <>
            <div className="d-flex justify-content-between fn16 mb-3 blue">
              <div>{capitalizeFirst(rmNameModal)} ({capitalizeFirst(rmRateBasisModal)})</div>
              <div className="text-end">{getCurrency.currency} {parseFloat(rmPriceModal).toFixed(2)} <span className="fn12 align-top"><span className="text-success">Refundable</span></span></div>
            </div>
            {HtlCancelData.htlCancelDtlObj.rooms &&
            <>
            {HtlCancelData.htlCancelDtlObj.rooms.room.map((v, i) => ( 
            <div className="table-responsive" key={i}>
              <table className="table table-bordered">
                <thead>
                  <tr className="bg-light">
                    <th>{t("From")}</th>
                    <th>{t("To")}</th>
                    <th className="text-center">{t("Percentage")}(%)</th>
                    <th className="text-center">{t("Nights")}</th>
                    <th>{t("Fixed")}</th>
                  </tr>
                </thead>
                {v.policies.policy.map((k, i) => ( 
                <tbody key={i}>
                  {k.condition.map((m, i) => ( 
                  <tr key={i}>
                    <td>{format(new Date(m.fromDate), 'dd MMM yyyy')}<br /> {m.fromDate.split('T')[1].includes('+') ? m.fromDate.split('T')[1].split('+')[0]: m.fromDate.split('T')[1]}</td>
                    <td>{format(new Date(m.toDate), 'dd MMM yyyy')}<br />  {m.toDate.split('T')[1].includes('+') ? m.toDate.split('T')[1].split('+')[0]: m.toDate.split('T')[1]}</td>
                    <td className="text-center">{m.percentage}</td>
                    <td className="text-center">{m.nights}</td>
                    <td>{m.fixed}</td>
                  </tr>
                  ))
                  }
                </tbody>
                ))
                }
              </table>
            </div>
            ))
            }
            </>
            
            }
            
          </>
          : "Loading..."
          }
          </>
        </Modal.Body>
      </Modal>


      <Modal show={showFbModal} onHide={() => setShowFbModal(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title className="fn16">Fare Breakup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
          { HtlFBData && HtlFBData.htlFareBrkupObj ?
          <>
            <div className="d-flex justify-content-between fn16 mb-3 blue">
              <div>{capitalizeFirst(rmNameModal)} ({capitalizeFirst(rmRateBasisModal)})</div>
              <div className="text-end">{getCurrency.currency} {parseFloat(rmPriceModal).toFixed(2)} <span className="fn12 align-top"><span className="text-success">Refundable</span></span></div>
            </div>
            <Row>
              <Col sm={4}>
                <p className="mb-1"><strong>{t("FareSummary")}</strong></p>
                <table className="table table-bordered">
                  <tbody>
                    <tr className="bg-light">
                        <th><strong>{t("Category")}</strong></th>
                        <th className="text-end"><strong>{t("Fare")} ({getCurrency.currency})</strong></th>
                    </tr>
                    <tr>
                        <td>{t("TotalFare")}</td>
                        <td className="text-end">{parseFloat(rmPriceModal).toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col sm={8}>
                <p className="mb-1"><strong>&nbsp;</strong></p>
                <div className="table-responsive">
                  {HtlFBData.htlFareBrkupObj.priceBreakdown.map((a, i) => ( 
                  <table className="table table-bordered text-center table-nonfluid tablePad0" key={i}>
                    <tbody>
                    <tr>
                      {a.dateRange.map((b, i) => ( 
                      <td key={i}>
                        <div className="bg-light px-3 py-2 border-bottom">
                        {format(new Date(b.fromDate), 'dd MMM')}
                          <br /> {format(new Date(b.fromDate), 'eee')}
                        </div>
                        <div className="p-3">
                          {parseFloat(b.text).toFixed(2)}
                        </div>
                      </td>
                      ))
                      }
                    </tr>
                    </tbody>
                  </table>
                  ))
                  }
                </div>
              </Col>
            </Row>
            
          </>
          : "Loading..."
          }
          </>
        </Modal.Body>
      </Modal>

    </div>
    :
     <div className="text-center blue my-3">
          <span className="fn21 align-top pt-1 d-inline-block"><strong>{t("GettingCheapestRoomRatesForYou")}..</strong></span>&nbsp; 
          <div className="dumwave align-top">
              <div className="anim anim1" style={{backgroundColor:"#06448f",marginRight:"3px"}}></div>
              <div className="anim anim2" style={{backgroundColor:"#06448f",marginRight:"3px"}}></div>
              <div className="anim anim3" style={{backgroundColor:"#06448f",marginRight:"3px"}}></div>
          </div>
      </div>
    }
    </> 
  );
}
export default RoomDetails;