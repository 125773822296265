import ApiService from './api.service'

const PaymentService = {
  doPreBooking: async function (reqObj) {
    const requestData = {
        method: 'post',
        data: reqObj,
        url: '/api/Booking/PreBooking',
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doPayment: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: reqObj,
      url: '/api/Payment/DoPayment',
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

  doCnfmBooking: async function (reqObj) {
    const requestData = {
      method: 'post',
      data: reqObj,
      url: '/api/Booking/ConfirmBooking',
    }
    try {
      const response = await ApiService.customPromiseRequest(requestData)
      return response.data
    } catch (error) {}
  },

}
export default PaymentService
export { PaymentService }