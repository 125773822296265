import React, {useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {Container, Row, Col, Button, Form, Collapse, InputGroup} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import { format } from 'date-fns';
import DefaultLayout from '../../layouts/DefaultLayout';
import {ToastContainer, toast } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";

import TransferFareBreakup from '../../components/transfer/FareBreakup';
import EmailSection from '../../components/traveller/EmailSection';
import PaymentSection from '../../components/traveller/PaymentSection';
import {doTransferCheckTime} from "../../store/actions/transfer";
import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar-SA";
registerLocale("en", en);
registerLocale("ar", ar);

const TransferItinerary = () => {
    const { register, formState: { errors },  handleSubmit } = useForm({mode: "all"});

    const query = useLocation(); 
    //console.log("query", query)
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const getLanguage = useSelector((state) => state.language)
    const getCurrency = useSelector((state) => state.currency);
    const [reqObj, setReqObj] = useState(null);
    const [transferDetails, setTransferDetails] = useState(null);
    const [vehicleDetail, setVehicleDetail] = useState(null);
    
    useEffect(()=>{
        if(query && query.state && query.state.reqObj){
            setReqObj(query.state.reqObj)
            setTransferDetails(query.state.reqObj.transferDetails);
            setVehicleDetail(query.state.reqObj.transferOption);
        }
    },[query]);
   
    // console.log("reqObj", reqObj)
    console.log("transferDetails", transferDetails)
    // console.log("vehicleDetail", vehicleDetail)

    const totalGuest = () => {
        let guest = 0
        guest = guest + parseInt(reqObj.adults) +  parseInt(reqObj.children)
        return guest
    };

    const [travellerObj, setTravellerObj] = useState({
        email: "",
        password: "",
        title: "",
        fName: "",
        lName: "",
        mob: "",
        hvPasswrd: false,
        allowOffrs: true
    });

    const [errTravellerObj, setErrTravellerObj] = useState({
        email: "",
        title: "",
        fName: "",
        lName: "",
        mob: "",
    });

    const [openL, setOpenL] = useState(true);
    const [activeItem, setActiveItem] = useState('emailCol');

    const setActive = (menuItem) => {
        if(menuItem==="travellerCol"){
            if(travellerObj.email !=="" && travellerObj.mob !==""){
             setActiveItem(menuItem)
            }
            else{
                return false
            }
        }
        else{
            setActiveItem(menuItem)
        }
    };

    const isActive = (menuItem) => {
        return activeItem === menuItem
    };

    const travelOpen = (travObj) => {
        const travItems = {...travellerObj};
        travItems.email = travObj.email;
        travItems.mob = travObj.phone;
        setTravellerObj(travItems)
        setActiveItem("travellerCol")
    };

    const titleChange = (e) => {
        const travItems = {...travellerObj};
        travItems.title = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.title === ""){
            errTravItems.title = 'Please Select Title'
        }
        else{
            errTravItems.title = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    };

    const fNameChange = (e) => {
        const travItems = {...travellerObj};
        travItems.fName = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.fName === ""){
            errTravItems.fName = 'Please Enter Your First Name'
        }
        else{
            errTravItems.fName = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    };

    const lNameChange = (e) => {
        const travItems = {...travellerObj};
        travItems.lName = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.lName === ""){
            errTravItems.lName = 'Please Enter Your Last Name'
        }
        else{
            errTravItems.lName = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    };
    
    const travValidation = () => {
        const errTravItems = {...errTravellerObj};
        if(travellerObj.title === ""){
            errTravItems.title = 'Please Select Title'
        }
        if(travellerObj.fName === ""){
            errTravItems.fName = 'Please Enter Your First Name'
        }
        if(travellerObj.lName === ""){
            errTravItems.lName = 'Please Enter Your Last Name'
        }
        setErrTravellerObj(errTravItems)

        if(travellerObj.title && travellerObj.fName && travellerObj.lName){
            return true;
        }
        else{
            return false;
        }
    };

    const paxDtlSubmit = () => {
        let valid = travValidation()
        if(valid){
            setActiveItem("onwardCol")
        }
        else{
            return false; 
        }
    };

    const isInterAirport = () => {
        let fromType = reqObj.pickupType
        let toType = reqObj.dropoffType
        return fromType === 'Airport' && toType === 'Airport' ? 1 : 0
    };

    const isInterRailStation = () => {
        let fromType = reqObj.pickupType
        let toType = reqObj.dropoffType
        return fromType === 'RailStation' && toType === 'RailStation' ? 1 : 0
    };

    const departureType = () => {
        let toType = reqObj.dropoffType
        if (toType === 'RailStation') {
            return 'Train'
        } else if (toType === 'Airport') {
            return 'Flight'
        } else if (toType === 'Hotel') {
            return 'Hotel'
        } else {
            return ''
        }
    };

    const arrivalType = () => {
        let fromType = reqObj.pickupType
        if (fromType === 'RailStation') {
            return 'Train'
        } else if (fromType === 'Airport') {
            return 'Flight'
        } else if (fromType === 'Hotel') {
            return 'Hotel'
        } else {
            return ''
        }
    };

    const [onwardPickupDate, setOnwardPickupDate] = useState(query && query.state && query.state.reqObj && query.state.reqObj.pickupDate ? new Date(query.state.reqObj.pickupDate) : null );
    const [onwardPickupTime, setOnwardPickupTime] = useState('');
    const [onwardCarrier, setOnwardCarrier] = useState('');
    const [onwardFlightNumber, setOnwardFlightNumber] = useState('');
    const [onwardTerminal, setOnwardTerminal] = useState('');
    const [onwardTrainNumber, setOnwardTrainNumber] = useState('');
    const [onwardWagonNumber, setOnwardWagonNumber] = useState('');
    const [onwardDropoffDate, setOnwardDropoffDate] = useState(query && query.state && query.state.reqObj && query.state.reqObj.pickupDate ? new Date(query.state.reqObj.pickupDate) : null );
    const [onwardDropoffTime, setOnwardDropoffTime] = useState('');
    const [onwardDropoffCarrier, setOnwardDropoffCarrier] = useState('');
    const [onwardDropoffFlightNumber, setOnwardDropoffFlightNumber] = useState('');
    const [onwardDropoffTerminal, setOnwardDropoffTerminal] = useState('');
    const [returnCarrier, setReturnCarrier] = useState('');
    const [returnFlightNumber, setReturnFlightNumber] = useState('');
    const [returnTerminal, setReturnTerminal] = useState('');
    const [returnTrainNumber, setReturnTrainNumber] = useState('');
    const [returnWagonNumber, setReturnWagonNumber] = useState('');
    const [returnDropoffDate, setReturnDropoffDate] = useState(query && query.state && query.state.reqObj && query.state.reqObj.returnDate !=='' ? new Date(query.state.reqObj.returnDate) : null);
    const [returnDropoffTime, setReturnDropoffTime] = useState('');
    const [returnDropoffCarrier, setReturnDropoffCarrier] = useState('');
    const [returnDropoffFlightNumber, setReturnDropoffFlightNumber] = useState('');
    const [returnDropoffTerminal, setReturnDropoffTerminal] = useState('');
    const [returnPickupDate, setReturnPickupDate] = useState(query && query.state && query.state.reqObj && query.state.reqObj.returnDate !=='' ? new Date(query.state.reqObj.returnDate) : null);
    const [returnPickupTime, setReturnPickupTime] = useState('');

    
    const [srvCode, setSrvCode] = useState(null);
    const [transferDetailsObj, setTransferDetailsObj] = useState(null);
    

    const onSubmit = (data) => {
        getCheckTime().then((isValid) => {
            console.log("isValid", isValid)                 
            if (isValid) {
                payFortSubmit()
            } else {
                return isValid
            }
        })
    };
    const getCheckTime = async () => {
        let resp = false;
        let departDate = '', departTime = '', pickupDate = '', pickupTime = '', isReturn = 0, totype = ''
        isReturn = reqObj.isSingleDate === "true" || reqObj.isSingleDate === true ? 1 : 0
        totype = reqObj.dropoffType
        if (isReturn) {
            departDate = format(returnDropoffDate, 'yyyy-MM-dd')
            departTime = returnDropoffTime
            pickupDate = format(returnPickupDate, 'yyyy-MM-dd')
            pickupTime = returnPickupTime
        } else {
            departDate = format(onwardDropoffDate, 'yyyy-MM-dd')
            departTime = onwardDropoffTime
            pickupDate = format(onwardPickupDate, 'yyyy-MM-dd')
            pickupTime = onwardPickupTime
        }
        if (pickupDate !== "" && (pickupTime !== "" && pickupTime !== '0')) {
            let transferCheckTimeObj = {
                "SessionId": reqObj.sId,
                "SearchParameter": {
                    "IsReturn": isReturn,
                    "GroupCode": reqObj.gp,
                    "RateKey": reqObj.rt,
                    "DepartureTime": departDate !== '' && departTime !== '' && departTime !== '0' ? format(new Date(departDate + ' ' + departTime), "yyyy-MM-dd HH:mm") : '',
                    "PickupTime": pickupDate !== '' && pickupTime !== '' && pickupTime !== '0' ? format(new Date(pickupDate + ' ' + pickupTime), "yyyy-MM-dd HH:mm") : '',
                }
            }
            let response = dispatch(doTransferCheckTime(transferCheckTimeObj));
            let res = await response
            console.log("res", res)
            if (res.status === false) {
                toast.error(res.message,{theme: "colored"});
            } else {
                resp = true;
                console.log(resp)
            }
        } else {
            if ((totype === 'Airport' || totype === 'RailStation') && (departDate === "" || departTime === "0")) {
                toast.error('Please select departure date/time',{theme: "colored"});
            } else if (pickupDate === "" || pickupTime === "") {
                toast.error('Please select pickup date/time',{theme: "colored"});
            }
        }
        return resp
        //setSrvCode('3')
    };

    
    const payFortSubmit = () => {
        setTransferDetailsObj({
            additionalLocations: [],
            onwardAddress: '',
            onwardGeo: '',
            onwardCarrier: onwardCarrier,
            onwardFlightNumber: onwardFlightNumber,
            onwardTerminal: onwardTerminal,
            onwardTrainNumber: onwardTrainNumber,
            onwardWagonNumber: onwardWagonNumber,
            onwardDropoffAddress: '',
            onwardDropoffGeo: '',
            onwardDropoffDate: onwardDropoffDate !== null ? format(onwardDropoffDate, 'yyyy-MM-dd') : '',
            onwardDropoffTime: onwardDropoffTime,
            onwardDropoffCarrier: onwardDropoffCarrier,
            onwardDropoffFlightNumber: onwardDropoffFlightNumber,
            onwardDropoffTerminal: onwardDropoffTerminal,   
            onwardPickupDate: onwardPickupDate !== null ? format(onwardPickupDate, 'yyyy-MM-dd') : '',
            onwardPickupTime: onwardPickupTime,
            returnDropoffAddress: '',
            returnDropoffGeo: '',
            returnCarrier: returnCarrier,
            returnFlightNumber: returnFlightNumber,
            returnTerminal: returnTerminal,
            returnTrainNumber: returnTrainNumber,
            returnWagonNumber: returnWagonNumber,
            returnAddress: '',
            returnGeo: '',
            returnDropoffDate: returnDropoffDate !== null ? format(returnDropoffDate, 'yyyy-MM-dd') : '',
            returnDropoffTime: returnDropoffTime,
            returnDropoffCarrier: returnDropoffCarrier,
            returnDropoffFlightNumber: returnDropoffFlightNumber,
            returnDropoffTerminal: returnDropoffTerminal,
            returnPickupDate: returnPickupDate !== null ? format(returnPickupDate, 'yyyy-MM-dd') : '',
            returnPickupTime: returnPickupTime,
            currId: ''
        });
        setSrvCode('3')
    };

    const paymentGateway = process.env.REACT_APP_PAYMENT_GATEWAY
   
  return (
    <>
    <ToastContainer />
    <DefaultLayout>
        <div className="innermiddle mt-4">
        {transferDetails ?
        <Container> 
            <Row>
                <Col className="mb-2" lg={{span: 8, order: 1}} xs={{order: 2}}>
                    <div className="bg-white rounded shadow-sm shadow-lg-none">
                        <Row className="mb-1">
                            <Col>
                                <div className="d-sm-flex flex-row">
                                    <div className="hotelImg rounded-start noView d-none d-sm-block">
                                        {transferDetails.images && transferDetails.images.image.length > 0 ? 
                                        <img src={transferDetails.images.image[0].imagePath} alt={transferDetails.name} />
                                        :
                                        <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                                        } 
                                    </div>
                                    <div className="ps-sm-3 pe-1 pt-2 w-100 arhotelItenDetail">
                                        <h3 className="nametitle blue mb-2">{transferDetails.name} ({vehicleDetail.vehicle.text} and similar)</h3>
                                        <div>
                                            <ul className="list-inline mb-3">
                                                <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={solid('user')} className="fn14" /> &nbsp;{totalGuest()} Pax &nbsp;&nbsp;</li>
                                                <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={solid('briefcase')} className="fn14" /> &nbsp;{vehicleDetail.noOfBags}</li>
                                            </ul>
                                        </div>

                                        <Row className="gx-4 gy-2 mb-2">
                                            <Col xs="auto"><strong>{t("From")}:</strong> {transferDetails.origin.address}</Col>
                                            <Col xs="auto"><strong>{t("To")}:</strong> {transferDetails.destination.address}</Col>
                                            <Col xs="auto"><strong>{t("Date")}:</strong> {format(new Date(reqObj.pickupDate), 'dd MMM yyyy')}</Col>
                                        </Row>
                                        {reqObj.isSingleDate && reqObj.isSingleDate === "true" &&
                                        <Row className="gx-4 gy-2 mb-2">
                                            <Col xs="auto"><strong>{t("From")}:</strong> {transferDetails.destination.address}</Col>
                                            <Col xs="auto"><strong>{t("To")}:</strong> {transferDetails.origin.address}</Col>
                                            <Col xs="auto"><strong>{t("Date")}:</strong> {format(new Date(reqObj.returnDate), 'dd MMM yyyy')}</Col>
                                        </Row>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <div className="px-lg-3 pb-md-3 pt-1">
                            <h2 className="fn24 blue">
                                {paymentGateway !== '4' ? t("BookSimpleSteps4")  : t("BookSimpleSteps3") }
                            </h2>
                        </div>

                        <div className="nav nav-tabs nav-justified stepNav">
                            <Button onClick={() => setActive('emailCol')} variant="link" className={"nav-link " + (isActive('travellerCol') || isActive('onwardCol') || isActive('paymentCol') ? 'active' : '')}>
                                <span className="stepTxt">
                                    <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                    &nbsp;{t("EmailAddress")}
                                </span>
                            </Button>
                            <Button onClick={() => setActive('travellerCol')} variant="link" className={"nav-link " + (!isActive('travellerCol') && !isActive('onwardCol') && !isActive('paymentCol') ? 'disabled' : '' || isActive('onwardCol') || isActive('paymentCol') ? 'active':'')}>
                                <span className="stepTxt">
                                    <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                    &nbsp;{t("TravellersInfo")}
                                </span>
                            </Button>
                            <Button onClick={() => setActive('onwardCol')} variant="link" className={"nav-link " + (!isActive('onwardCol') && !isActive('paymentCol') ? 'disabled' : '' || isActive('paymentCol') ? 'active':'')}>
                                <span className="stepTxt">
                                    <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                    &nbsp;Onward Details
                                </span>
                            </Button>


                            {paymentGateway !== '4' && 
                            <Button onClick={() => setActive('paymentCol')} variant="link" className={"nav-link " + (!isActive('paymentCol') ? 'disabled' : '')}>
                                <span className="stepTxt">
                                    <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                    &nbsp;{t("Payment")}
                                </span>
                            </Button>
                            }
                        </div>

                        <div className="tab-content">
                            <div className={"tab-pane fade " + (isActive('emailCol') && 'active show')}>
                                <EmailSection toggleTrav={travelOpen} />
                            </div>
                            <div className={"tab-pane fade " + (isActive('travellerCol') && 'active show')}>
                                <div className="pt-lg-4 px-lg-4 p-0">
                                    <Row className="mb-3 gx-2">
                                        <Form.Label className="col-md-2 align-self-center text-sm-end mb-lg-3"><strong>{t("NameOfGuest")}<sup className="text-danger">*</sup></strong></Form.Label>
                                        <Col md={10}>
                                            <Row className="gx-2">
                                                <Col sm={2} className="mb-3">
                                                    <Form.Select className='mb-1' value={travellerObj.title} onChange={(e) => titleChange(e)}>
                                                        <option value="">--Title--</option>
                                                        <option value="Mr">Mr</option>
                                                        <option value="Miss">Miss</option>
                                                        <option value="Mrs">Mrs</option>
                                                    </Form.Select>
                                                    <div className="text-danger fn12">{errTravellerObj.title}</div>
                                                </Col>
                                                <Col sm={5} className="mb-3">
                                                    <Form.Control className={'mb-1 ' + (errTravellerObj.fName && 'is-invalid')} value={travellerObj.fName} onChange={(e) => fNameChange(e)} placeholder={t('FirstName')} />
                                                    <div className="text-danger fn12">{errTravellerObj.fName}</div>
                                                </Col>
                                                <Col sm={5} className="mb-3">
                                                    <Form.Control className={'mb-1 ' + (errTravellerObj.lName && 'is-invalid')} value={travellerObj.lName} onChange={(e) => lNameChange(e)} placeholder={t('LastName')} />
                                                    <div className="text-danger fn12">{errTravellerObj.lName}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="d-flex justify-content-between resFixedBtn">
                                    <Button onClick={() => setActive('emailCol')} variant="light" size="lg" className="rounded-0"><FontAwesomeIcon icon={solid('long-arrow-alt-left')} className="align-middle fn18" /> &nbsp;{t("Back")} &nbsp;</Button>
                                    <Button onClick={() => paxDtlSubmit()}  variant="success" size="lg" className="rounded-0">&nbsp;{t("Continue")} &nbsp; <FontAwesomeIcon icon={solid('long-arrow-alt-right')} className="align-middle fn18" /></Button>
                                </div>
                            </div>

                            <div className={"tab-pane fade " + (isActive('onwardCol') && 'active show')}>
                                <div className="pt-lg-4 px-lg-4 p-0">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div>
                                            <Row className="mb-3 gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Pickup Point<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={reqObj.pickupAddress} readOnly />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Dropoff Point<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={reqObj.dropoffAddress} readOnly />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>{arrivalType()} {reqObj.pickupType !== 'Airport' && reqObj.pickupType !== 'RailStation' ? 'Pickup' : 'Arrival' } Date<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <InputGroup>
                                                                <input type="hidden" {...register('onwardPickupDate', { required: true })} value={onwardPickupDate !== null ? onwardPickupDate:''} />
                                                                <DatePicker className={'form-control radius-right-0 ' + (errors.onwardPickupDate && 'is-invalid')} locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={onwardPickupDate} minDate={new Date(new Date(reqObj.pickupDate).setDate(new Date(reqObj.pickupDate).getDate() - 1))} maxDate={new Date(new Date(reqObj.pickupDate).setDate(new Date(reqObj.pickupDate).getDate() + 1))} onChange={ (date) => setOnwardPickupDate(date)} />
                                                                <InputGroup.Text className="bg-white fn14 green"><FontAwesomeIcon icon={solid('calendar-alt')} /></InputGroup.Text>
                                                            </InputGroup>
                                                            {errors.onwardPickupDate && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>{arrivalType()} {reqObj.pickupType !== 'Airport' && reqObj.pickupType !== 'RailStation' ? 'Pickup' : 'Arrival'} Time<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Select className={errors.onwardPickupTime && 'is-invalid'} {...register('onwardPickupTime', { required: true })} value={onwardPickupTime} onChange={(e) => setOnwardPickupTime(e.target.value)}>
                                                                <option value="">Choose Time</option>
                                                                <option value="0:0">0:0</option>
                                                                <option value="0:5">0:5</option>
                                                                <option value="0:10">0:10</option>
                                                                <option value="0:15">0:15</option>
                                                                <option value="0:20">0:20</option>
                                                                <option value="0:25">0:25</option>
                                                                <option value="0:30">0:30</option>
                                                                <option value="0:35">0:35</option>
                                                                <option value="0:40">0:40</option>
                                                                <option value="0:45">0:45</option>
                                                                <option value="0:50">0:50</option>
                                                                <option value="0:55">0:55</option>
                                                                <option value="1:0">1:0</option>
                                                                <option value="1:5">1:5</option>
                                                                <option value="1:10">1:10</option>
                                                                <option value="1:15">1:15</option>
                                                                <option value="1:20">1:20</option>
                                                                <option value="1:25">1:25</option>
                                                                <option value="1:30">1:30</option>
                                                                <option value="1:35">1:35</option>
                                                                <option value="1:40">1:40</option>
                                                                <option value="1:45">1:45</option>
                                                                <option value="1:50">1:50</option>
                                                                <option value="1:55">1:55</option>
                                                                <option value="2:0">2:0</option>
                                                                <option value="2:5">2:5</option>
                                                                <option value="2:10">2:10</option>
                                                                <option value="2:15">2:15</option>
                                                                <option value="2:20">2:20</option>
                                                                <option value="2:25">2:25</option>
                                                                <option value="2:30">2:30</option>
                                                                <option value="2:35">2:35</option>
                                                                <option value="2:40">2:40</option>
                                                                <option value="2:45">2:45</option>
                                                                <option value="2:50">2:50</option>
                                                                <option value="2:55">2:55</option>
                                                                <option value="3:0">3:0</option>
                                                                <option value="3:5">3:5</option>
                                                                <option value="3:10">3:10</option>
                                                                <option value="3:15">3:15</option>
                                                                <option value="3:20">3:20</option>
                                                                <option value="3:25">3:25</option>
                                                                <option value="3:30">3:30</option>
                                                                <option value="3:35">3:35</option>
                                                                <option value="3:40">3:40</option>
                                                                <option value="3:45">3:45</option>
                                                                <option value="3:50">3:50</option>
                                                                <option value="3:55">3:55</option>
                                                                <option value="4:0">4:0</option>
                                                                <option value="4:5">4:5</option>
                                                                <option value="4:10">4:10</option>
                                                                <option value="4:15">4:15</option>
                                                                <option value="4:20">4:20</option>
                                                                <option value="4:25">4:25</option>
                                                                <option value="4:30">4:30</option>
                                                                <option value="4:35">4:35</option>
                                                                <option value="4:40">4:40</option>
                                                                <option value="4:45">4:45</option>
                                                                <option value="4:50">4:50</option>
                                                                <option value="4:55">4:55</option>
                                                                <option value="5:0">5:0</option>
                                                                <option value="5:5">5:5</option>
                                                                <option value="5:10">5:10</option>
                                                                <option value="5:15">5:15</option>
                                                                <option value="5:20">5:20</option>
                                                                <option value="5:25">5:25</option>
                                                                <option value="5:30">5:30</option>
                                                                <option value="5:35">5:35</option>
                                                                <option value="5:40">5:40</option>
                                                                <option value="5:45">5:45</option>
                                                                <option value="5:50">5:50</option>
                                                                <option value="5:55">5:55</option>
                                                                <option value="6:0">6:0</option>
                                                                <option value="6:5">6:5</option>
                                                                <option value="6:10">6:10</option>
                                                                <option value="6:15">6:15</option>
                                                                <option value="6:20">6:20</option>
                                                                <option value="6:25">6:25</option>
                                                                <option value="6:30">6:30</option>
                                                                <option value="6:35">6:35</option>
                                                                <option value="6:40">6:40</option>
                                                                <option value="6:45">6:45</option>
                                                                <option value="6:50">6:50</option>
                                                                <option value="6:55">6:55</option>
                                                                <option value="7:0">7:0</option>
                                                                <option value="7:5">7:5</option>
                                                                <option value="7:10">7:10</option>
                                                                <option value="7:15">7:15</option>
                                                                <option value="7:20">7:20</option>
                                                                <option value="7:25">7:25</option>
                                                                <option value="7:30">7:30</option>
                                                                <option value="7:35">7:35</option>
                                                                <option value="7:40">7:40</option>
                                                                <option value="7:45">7:45</option>
                                                                <option value="7:50">7:50</option>
                                                                <option value="7:55">7:55</option>
                                                                <option value="8:0">8:0</option>
                                                                <option value="8:5">8:5</option>
                                                                <option value="8:10">8:10</option>
                                                                <option value="8:15">8:15</option>
                                                                <option value="8:20">8:20</option>
                                                                <option value="8:25">8:25</option>
                                                                <option value="8:30">8:30</option>
                                                                <option value="8:35">8:35</option>
                                                                <option value="8:40">8:40</option>
                                                                <option value="8:45">8:45</option>
                                                                <option value="8:50">8:50</option>
                                                                <option value="8:55">8:55</option>
                                                                <option value="9:0">9:0</option>
                                                                <option value="9:5">9:5</option>
                                                                <option value="9:10">9:10</option>
                                                                <option value="9:15">9:15</option>
                                                                <option value="9:20">9:20</option>
                                                                <option value="9:25">9:25</option>
                                                                <option value="9:30">9:30</option>
                                                                <option value="9:35">9:35</option>
                                                                <option value="9:40">9:40</option>
                                                                <option value="9:45">9:45</option>
                                                                <option value="9:50">9:50</option>
                                                                <option value="9:55">9:55</option>
                                                                <option value="10:0">10:0</option>
                                                                <option value="10:5">10:5</option>
                                                                <option value="10:10">10:10</option>
                                                                <option value="10:15">10:15</option>
                                                                <option value="10:20">10:20</option>
                                                                <option value="10:25">10:25</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="10:35">10:35</option>
                                                                <option value="10:40">10:40</option>
                                                                <option value="10:45">10:45</option>
                                                                <option value="10:50">10:50</option>
                                                                <option value="10:55">10:55</option>
                                                                <option value="11:0">11:0</option>
                                                                <option value="11:5">11:5</option>
                                                                <option value="11:10">11:10</option>
                                                                <option value="11:15">11:15</option>
                                                                <option value="11:20">11:20</option>
                                                                <option value="11:25">11:25</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="11:35">11:35</option>
                                                                <option value="11:40">11:40</option>
                                                                <option value="11:45">11:45</option>
                                                                <option value="11:50">11:50</option>
                                                                <option value="11:55">11:55</option>
                                                                <option value="12:0">12:0</option>
                                                                <option value="12:5">12:5</option>
                                                                <option value="12:10">12:10</option>
                                                                <option value="12:15">12:15</option>
                                                                <option value="12:20">12:20</option>
                                                                <option value="12:25">12:25</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="12:35">12:35</option>
                                                                <option value="12:40">12:40</option>
                                                                <option value="12:45">12:45</option>
                                                                <option value="12:50">12:50</option>
                                                                <option value="12:55">12:55</option>
                                                                <option value="13:0">13:0</option>
                                                                <option value="13:5">13:5</option>
                                                                <option value="13:10">13:10</option>
                                                                <option value="13:15">13:15</option>
                                                                <option value="13:20">13:20</option>
                                                                <option value="13:25">13:25</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="13:35">13:35</option>
                                                                <option value="13:40">13:40</option>
                                                                <option value="13:45">13:45</option>
                                                                <option value="13:50">13:50</option>
                                                                <option value="13:55">13:55</option>
                                                                <option value="14:0">14:0</option>
                                                                <option value="14:5">14:5</option>
                                                                <option value="14:10">14:10</option>
                                                                <option value="14:15">14:15</option>
                                                                <option value="14:20">14:20</option>
                                                                <option value="14:25">14:25</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="14:35">14:35</option>
                                                                <option value="14:40">14:40</option>
                                                                <option value="14:45">14:45</option>
                                                                <option value="14:50">14:50</option>
                                                                <option value="14:55">14:55</option>
                                                                <option value="15:0">15:0</option>
                                                                <option value="15:5">15:5</option>
                                                                <option value="15:10">15:10</option>
                                                                <option value="15:15">15:15</option>
                                                                <option value="15:20">15:20</option>
                                                                <option value="15:25">15:25</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="15:35">15:35</option>
                                                                <option value="15:40">15:40</option>
                                                                <option value="15:45">15:45</option>
                                                                <option value="15:50">15:50</option>
                                                                <option value="15:55">15:55</option>
                                                                <option value="16:0">16:0</option>
                                                                <option value="16:5">16:5</option>
                                                                <option value="16:10">16:10</option>
                                                                <option value="16:15">16:15</option>
                                                                <option value="16:20">16:20</option>
                                                                <option value="16:25">16:25</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="16:35">16:35</option>
                                                                <option value="16:40">16:40</option>
                                                                <option value="16:45">16:45</option>
                                                                <option value="16:50">16:50</option>
                                                                <option value="16:55">16:55</option>
                                                                <option value="17:0">17:0</option>
                                                                <option value="17:5">17:5</option>
                                                                <option value="17:10">17:10</option>
                                                                <option value="17:15">17:15</option>
                                                                <option value="17:20">17:20</option>
                                                                <option value="17:25">17:25</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="17:35">17:35</option>
                                                                <option value="17:40">17:40</option>
                                                                <option value="17:45">17:45</option>
                                                                <option value="17:50">17:50</option>
                                                                <option value="17:55">17:55</option>
                                                                <option value="18:0">18:0</option>
                                                                <option value="18:5">18:5</option>
                                                                <option value="18:10">18:10</option>
                                                                <option value="18:15">18:15</option>
                                                                <option value="18:20">18:20</option>
                                                                <option value="18:25">18:25</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="18:35">18:35</option>
                                                                <option value="18:40">18:40</option>
                                                                <option value="18:45">18:45</option>
                                                                <option value="18:50">18:50</option>
                                                                <option value="18:55">18:55</option>
                                                                <option value="19:0">19:0</option>
                                                                <option value="19:5">19:5</option>
                                                                <option value="19:10">19:10</option>
                                                                <option value="19:15">19:15</option>
                                                                <option value="19:20">19:20</option>
                                                                <option value="19:25">19:25</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="19:35">19:35</option>
                                                                <option value="19:40">19:40</option>
                                                                <option value="19:45">19:45</option>
                                                                <option value="19:50">19:50</option>
                                                                <option value="19:55">19:55</option>
                                                                <option value="20:0">20:0</option>
                                                                <option value="20:5">20:5</option>
                                                                <option value="20:10">20:10</option>
                                                                <option value="20:15">20:15</option>
                                                                <option value="20:20">20:20</option>
                                                                <option value="20:25">20:25</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="20:35">20:35</option>
                                                                <option value="20:40">20:40</option>
                                                                <option value="20:45">20:45</option>
                                                                <option value="20:50">20:50</option>
                                                                <option value="20:55">20:55</option>
                                                                <option value="21:0">21:0</option>
                                                                <option value="21:5">21:5</option>
                                                                <option value="21:10">21:10</option>
                                                                <option value="21:15">21:15</option>
                                                                <option value="21:20">21:20</option>
                                                                <option value="21:25">21:25</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="21:35">21:35</option>
                                                                <option value="21:40">21:40</option>
                                                                <option value="21:45">21:45</option>
                                                                <option value="21:50">21:50</option>
                                                                <option value="21:55">21:55</option>
                                                                <option value="22:0">22:0</option>
                                                                <option value="22:5">22:5</option>
                                                                <option value="22:10">22:10</option>
                                                                <option value="22:15">22:15</option>
                                                                <option value="22:20">22:20</option>
                                                                <option value="22:25">22:25</option>
                                                                <option value="22:30">22:30</option>
                                                                <option value="22:35">22:35</option>
                                                                <option value="22:40">22:40</option>
                                                                <option value="22:45">22:45</option>
                                                                <option value="22:50">22:50</option>
                                                                <option value="22:55">22:55</option>
                                                                <option value="23:0">23:0</option>
                                                                <option value="23:5">23:5</option>
                                                                <option value="23:10">23:10</option>
                                                                <option value="23:15">23:15</option>
                                                                <option value="23:20">23:20</option>
                                                                <option value="23:25">23:25</option>
                                                                <option value="23:30">23:30</option>
                                                                <option value="23:35">23:35</option>
                                                                <option value="23:40">23:40</option>
                                                                <option value="23:45">23:45</option>
                                                                <option value="23:50">23:50</option>
                                                                <option value="23:55">23:55</option>
                                                            </Form.Select>
                                                            {errors.onwardPickupTime && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        
                                            {reqObj.pickupType === 'Airport' &&
                                            <>
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Carrier<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Select className={errors.onwardCarrier && 'is-invalid'} {...register('onwardCarrier', {required:true})} value={onwardCarrier} onChange={(e) => setOnwardCarrier(e.target.value)}>
                                                                <option value="">Select Airline</option>
                                                                <option value="1T">1TIME AIRLINE</option>
                                                                <option value="2N">2P</option>
                                                                <option value="7L">A B AIRLINES</option>
                                                                <option value="ZY">ADA AIR</option>
                                                                <option value="II">ADI DOMESTIC AIRLINES</option>
                                                                <option value="JP">ADRIA AIRWAYS-AIRLINE OF SLOVENIA</option>
                                                                <option value="A3">AEGEAN AVIATION</option>
                                                                <option value="WL">AEORPERLAS AIRLINES</option>
                                                                <option value="RE">AER ARANN EXPRESS</option>
                                                                <option value="EI">AER LINGUS LIMITED</option>
                                                                <option value="EE">AERO AIRLINES</option>
                                                                <option value="JR">AERO CALIFORNIA</option>
                                                                <option value="ML">AERO COSTA RICA</option>
                                                                <option value="02">AERO HONDURAS</option>
                                                                <option value="YP">AERO LLOYD</option>
                                                                <option value="Z9">AERO ZAMBIA</option>
                                                                <option value="SU">AEROFLOT-RUSSIAN INTL AIRLINES</option>
                                                                <option value="AR">AEROLINEAS ARGENTINAS</option>
                                                                <option value="BQ">AEROMAR</option>
                                                                <option value="AM">AEROMEXICO</option>
                                                                <option value="5D">AEROMEXICO CONNECT</option>
                                                                <option value="QO">AEROMEXPRESS S.A. DE C.V.</option>
                                                                <option value="PL">AEROPERU - EMPRESA DE TRANSPORTES</option>
                                                                <option value="VH">AEROPOSTAL</option>
                                                                <option value="VE">AEROVIAS VENEZOLANAS, S.A.</option>
                                                                <option value="Y2">AFRICAN JOINT AIR SERVICES</option>
                                                                <option value="8U">AFRIQIHYA AIRWAYS</option>
                                                                <option value="ZI">AIGLE AZUR</option>
                                                                <option value="RK">AIR AFRIQUE</option>
                                                                <option value="AH">AIR ALGERIE</option>
                                                                <option value="A6">AIR ALPS AVIATION</option>
                                                                <option value="G9">AIR ARABIA</option>
                                                                <option value="D7">AIR ASIA</option>
                                                                <option value="4L">AIR ASTANA</option>
                                                                <option value="KC">AIR ASTANA</option>
                                                                <option value="CC">AIR ATLANTA ICELANDIC</option>
                                                                <option value="LU">AIR ATLANTIC DOMINICANA</option>
                                                                <option value="UU">AIR AUSTRAL</option>
                                                                <option value="BT">AIR BALTIC CORPORATION S/A</option>
                                                                <option value="8B">AIR BENIN</option>
                                                                <option value="AB">AIR BERLIN BMBTT &amp; CO LUFTVERKEHR</option>
                                                                <option value="BP">AIR BOTSWANA CORPORATION</option>
                                                                <option value="2J">AIR BURKINA</option>
                                                                <option value="TY">AIR CALEDONIE</option>
                                                                <option value="SB">AIR CALEDONIE INTERNATIONAL</option>
                                                                <option value="AC">AIR CANADA</option>
                                                                <option value="XC">AIR CARRIBBEAN</option>
                                                                <option value="CV">AIR CHATHAMS</option>
                                                                <option value="CA">AIR CHINA INTERNATIONAL CORPORATION</option>
                                                                <option value="YN">AIR CREEBEC</option>
                                                                <option value="UX">AIR EUROPA LINEAS AEREAS, S.A.</option>
                                                                <option value="XV">AIR EXPRESS</option>
                                                                <option value="OF">AIR FINLAND</option>
                                                                <option value="GS">AIR FOYLE LTD/GRANT AVIATION, INC</option>
                                                                <option value="AF">AIR FRANCE</option>
                                                                <option value="GN">AIR GABON</option>
                                                                <option value="DA">AIR GEORGIA</option>
                                                                <option value="ZX">AIR GEORGIAN</option>
                                                                <option value="JG">AIR GREECE</option>
                                                                <option value="LD">AIR HONG KONG</option>
                                                                <option value="NY">AIR ICELAND</option>
                                                                <option value="3H">AIR INUIT</option>
                                                                <option value="I9">AIR ITALY</option>
                                                                <option value="VU">AIR IVOIRE</option>
                                                                <option value="JM">AIR JAMAICA</option>
                                                                <option value="V6">AIR JET</option>
                                                                <option value="9Y">AIR KAZAKSTAN</option>
                                                                <option value="JS">AIR KORYO</option>
                                                                <option value="UE">AIR LA</option>
                                                                <option value="QL">AIR LESOTHO (PTY) LTD.</option>
                                                                <option value="VD">AIR LIBERTE</option>
                                                                <option value="FU">AIR LITTORAL</option>
                                                                <option value="NX">AIR MACAU COMPANY LIMITED</option>
                                                                <option value="MD">AIR MADAGASCAR</option>
                                                                <option value="QM">AIR MALAWI LIMITED</option>
                                                                <option value="L6">AIR MALDIVES LIMITED</option>
                                                                <option value="KM">AIR MALTA</option>
                                                                <option value="CW">AIR MARSHALL ISLANDS, INC.</option>
                                                                <option value="MK">AIR MAURITIUS</option>
                                                                <option value="DR">AIR MEDITERRANEE</option>
                                                                <option value="MC">AIR MOBILITY COMMAND</option>
                                                                <option value="3R">AIR MOLDOVA INTERNATIONAL S.A.</option>
                                                                <option value="9U">AIR MOLDOVA INTL</option>
                                                                <option value="SW">AIR NAMIBIA</option>
                                                                <option value="ON">AIR NAURU</option>
                                                                <option value="N1">AIR NEW GUINEA</option>
                                                                <option value="NZ">AIR NEW ZEALAND LIMITED</option>
                                                                <option value="EL">AIR NIPPON CO. LTD.</option>
                                                                <option value="PX">AIR NIUGINI PTY LIMITED DBA</option>
                                                                <option value="4N">AIR NORTH</option>
                                                                <option value="M3">AIR NORWAY</option>
                                                                <option value="YW">AIR NOSTRUM L.A.M.S.A.</option>
                                                                <option value="QK">AIR NOVA</option>
                                                                <option value="AP">AIR ONE</option>
                                                                <option value="8K">AIR OSTRAVA</option>
                                                                <option value="A7">AIR PLUS COMET</option>
                                                                <option value="RY">AIR RWANDA</option>
                                                                <option value="5W">AIR SAN JUAN/CHARTAIR</option>
                                                                <option value="7W">AIR SASK AVIATION 1991</option>
                                                                <option value="HM">AIR SEYCHELLES LIMITED</option>
                                                                <option value="YI">AIR SHUNSHINE INC.</option>
                                                                <option value="BM">AIR SICILIA</option>
                                                                <option value="4D">AIR SINAI</option>
                                                                <option value="GM">AIR SLOVAKIA BWJ, LTD.</option>
                                                                <option value="CT">AIR SOFIA</option>
                                                                <option value="PJ">AIR ST. PIERRE</option>
                                                                <option value="ZP">AIR ST.THOMAS</option>
                                                                <option value="VT">AIR TAHITI</option>
                                                                <option value="TN">AIR TAHITI NUI</option>
                                                                <option value="TC">AIR TANZANIA CORPORATION</option>
                                                                <option value="YT">AIR TOGO</option>
                                                                <option value="TS">AIR TRANSAT</option>
                                                                <option value="3N">AIR URGA</option>
                                                                <option value="DO">AIR VALLEE S.P.A./AIR VIRGINIA, INC</option>
                                                                <option value="NF">AIR VANUATU (OPERATIONS) LIMITED</option>
                                                                <option value="VL">AIR VIA BULGARIAN AIRWAYS</option>
                                                                <option value="6G">AIR WALES</option>
                                                                <option value="ZW">AIR WISCONSIN</option>
                                                                <option value="RL">AIR WORLD AVIATION</option>
                                                                <option value="QC">AIR ZAIRE</option>
                                                                <option value="UM">AIR ZIMBABWE CORPORATION</option>
                                                                <option value="AI">AIR-INDIA LIMITED</option>
                                                                <option value="A9">AIRCOMPANY AIRZENA GEORGIAN AIRLINE</option>
                                                                <option value="QP">AIRKENYA AVIATION LTD</option>
                                                                <option value="A5">AIRLINAIR</option>
                                                                <option value="ND">AIRLINE PTY LTD</option>
                                                                <option value="RT">AIRLINES OF SOUTH AUSTRALIA</option>
                                                                <option value="FL">AIRTRAN AIRWAYS, INC</option>
                                                                <option value="6L">AKLAK</option>
                                                                <option value="AS">ALASKA AIRLINES INC.</option>
                                                                <option value="KO">ALASKA CENTRAL EXPRESS</option>
                                                                <option value="LV">ALBANIAN AIRLINES MAK S.H.P.K.</option>
                                                                <option value="A2">ALITALIA</option>
                                                                <option value="XM">ALITALIA EXPRESS</option>
                                                                <option value="NH">ALL NIPPON AIRWAYS CO. LTD.</option>
                                                                <option value="G4">ALLEGIANT AIR</option>
                                                                <option value="1O">ALLEGRO</option>
                                                                <option value="CD">ALLIANCE AIR</option>
                                                                <option value="9I">ALLIANCE AIR</option>
                                                                <option value="AQ">ALOHA AIRLINES INC.</option>
                                                                <option value="E8">ALPI EAGLES S.P.A.</option>
                                                                <option value="5A">ALPINE AVIATION, INC.</option>
                                                                <option value="HP">AMERICA WEST AIRLINES INC.</option>
                                                                <option value="AA">AMERICAN AIRLINES</option>
                                                                <option value="AX">AMERICAN CONNECTION</option>
                                                                <option value="MQ">AMERICAN EAGLE</option>
                                                                <option value="MV">AMRENIAN INTERNATIONAL AIRWAYS</option>
                                                                <option value="2V">AMTRACK</option>
                                                                <option value="8G">ANGEL AIRLINES</option>
                                                                <option value="AN">ANSETT AUSTRALIA</option>
                                                                <option value="ZQ">ANSETT NEW ZEALAND</option>
                                                                <option value="HO">ANTINEA AIRLINES</option>
                                                                <option value="IW">AOM-MINERVE S.A.</option>
                                                                <option value="N2">AREOLINEAS INTERNACIONALES</option>
                                                                <option value="FG">ARIANA AFGHAN AIRLINES</option>
                                                                <option value="OR">ARKEFLY</option>
                                                                <option value="IZ">ARKIA - ISRAELI AIRLINES LTD</option>
                                                                <option value="JW">ARROW AIR, INC</option>
                                                                <option value="0Z">ASIANA AIRLINES</option>
                                                                <option value="oZ">ASIANA AIRLINES</option>
                                                                <option value="OZ">ASIANA AIRLINES INC</option>
                                                                <option value="C5">ASOCIADOS LATINOAMERICANOS DE TR</option>
                                                                <option value="9T">ATHABASKA AIRWAYS LTD</option>
                                                                <option value="RC">ATLANTIC AIRWAYS FAROE ISLANDS</option>
                                                                <option value="EV">ATLANTIC SOUTHEAST AIRLINES</option>
                                                                <option value="8A">ATLAS BLUE</option>
                                                                <option value="IQ">AUGSBURG-AIRWAYS GMBH</option>
                                                                <option value="GR">AURIGNY</option>
                                                                <option value="AU">AUSTRAL</option>
                                                                <option value="OS">AUSTRIAN AIRLINES</option>
                                                                <option value="RX">AVIA EXPRESS</option>
                                                                <option value="OC">AVIACION DEL NOROESTE S.A. DE C.V.</option>
                                                                <option value="AO">AVIACION Y COMERCIO S.A. (AVIACO)</option>
                                                                <option value="T5">AVIACOMPANY TURKMENISTAN</option>
                                                                <option value="6A">AVIACSA</option>
                                                                <option value="GU">AVIATECA, S.A.</option>
                                                                <option value="DF">AVIOSARDA S.R.L.</option>
                                                                <option value="2Q">AVITRANS</option>
                                                                <option value="A1">AVRO</option>
                                                                <option value="J2">AZAL AZERBAIJA</option>
                                                                <option value="ZS">AZZURRA AIR S.P.A.</option>
                                                                <option value="9B">ACCESSRAIL</option>
                                                                <option value="31">AEROLINEAS DEL SUR</option>
                                                                <option value="I5">AIR ASIA INDIA</option>
                                                                <option value="ED">AIR BLUE</option>
                                                                <option value="TX">AIR CARAIBES</option>
                                                                <option value="EN">AIR DOLOMITI</option>
                                                                <option value="IX">AIR INDIA EXPRESS</option>
                                                                <option value="IG">AIR ITALY</option>
                                                                <option value="Q9">AIR LIAISON</option>
                                                                <option value="2P">AIR PHILIPPINES</option>
                                                                <option value="GZ">AIR RAROTONGA</option>
                                                                <option value="WB">AIR RWANDA</option>
                                                                <option value="6O">AIR SATELLITE</option>
                                                                <option value="JU">AIR SERBIA</option>
                                                                <option value="6V">AIR VEGAS</option>
                                                                <option value="8T">AIR TENDI</option>
                                                                <option value="AK">AIRASIA X</option>
                                                                <option value="PA">AIRBLUE</option>
                                                                <option value="UJ">ALMASRIA AIRLINES</option>
                                                                <option value="AZ">ALITALIA</option>
                                                                <option value="TZ">AMERICAN TRANS AIR</option>
                                                                <option value="JH">AMERIJET INC.</option>
                                                                <option value="R7">ASERCA</option>
                                                                <option value="5Y">ATLAS AIR</option>
                                                                <option value="KK">ATLASGLOBAL</option>
                                                                <option value="AV">AVIANCA</option>
                                                                <option value="O6">AVIANCA BRAZIL</option>
                                                                <option value="A4">AZIMUTH AIRLINE</option>
                                                                <option value="AD">AZUL LINHAS AEREAS BRASILEIRAS</option>
                                                                <option value="JA">B&amp;H AIRLINES</option>
                                                                <option value="TH">BA CITIEXPRESS</option>
                                                                <option value="UP">BAHAMASAIR</option>
                                                                <option value="LZ">BALKAN - BULGARIAN AIRLINES</option>
                                                                <option value="PG">BANGKOK AIRWAY CO. LTD</option>
                                                                <option value="JV">BEARSKIN AIRLINES</option>
                                                                <option value="4T">BELAIR AIRLINES AG</option>
                                                                <option value="B2">BELAVIA</option>
                                                                <option value="B3">BELLVIEW AIRLINES LTD.</option>
                                                                <option value="CH">BEMIDJI AIRLINES</option>
                                                                <option value="J8">BERJAYA AIR SDN. BHD.</option>
                                                                <option value="GQ">BIG SKY AIRLINES</option>
                                                                <option value="BG">BIMAN BANGLADESH AIRLINES</option>
                                                                <option value="NT">BINTER CANARIAS</option>
                                                                <option value="B1">BIRDY AIRLINES</option>
                                                                <option value="KF">BLUE 1</option>
                                                                <option value="JO">BLUE AIR</option>
                                                                <option value="0B">BLUE AIR</option>
                                                                <option value="BC">BLUE ISLANDS</option>
                                                                <option value="6B">BLUE SCANDINAVA AB/BAXTER AVIATION</option>
                                                                <option value="5Q">BQB LINEAS AEREAS</option>
                                                                <option value="B7">BRA</option>
                                                                <option value="BU">BRAATHENS SOUTH AMERICAN AND</option>
                                                                <option value="DB">BRIT AIR</option>
                                                                <option value="BH">BRITANNIA</option>
                                                                <option value="BF">BRITANY FERRIES</option>
                                                                <option value="BA">BRITISH AIRWAYS P.L.C.</option>
                                                                <option value="RR">BRITISH ARMY</option>
                                                                <option value="BD">BRITISH MIDLAND AIRWAYS LTD.</option>
                                                                <option value="BY">BRITTANNIA AIRWAYS LTD.</option>
                                                                <option value="SN">BRUSSELS AIRLINES</option>
                                                                <option value="U4">BURYAT AIRLINE</option>
                                                                <option value="5E">BUSINESS AVIATION SVCS EINDHOVEN</option>
                                                                <option value="ID">BATIK AIR</option>
                                                                <option value="OB">BOLIVIANA DE AVIACION</option>
                                                                <option value="1X">BRANSON AIR EXPRESS</option>
                                                                <option value="BS">BRITISH HELICOPTERS</option>
                                                                <option value="CK">CALEDONIAN AIRLINES</option>
                                                                <option value="K6">CAMBODIA ANGKOR AIR</option>
                                                                <option value="UY">CAMEROON AIRLINES</option>
                                                                <option value="FH">CAMPANIA HISPANO IRLANDESA DE AVIA</option>
                                                                <option value="2T">CANADA 3000 AIRLINE LIMITED</option>
                                                                <option value="CP">CANADIAN AIRLINES INTL LIMITED</option>
                                                                <option value="5T">CANADIAN NORTH AIRLINE</option>
                                                                <option value="C6">CANJET AIRLINES</option>
                                                                <option value="NN">CARDINAL AIRLINES LIMITED</option>
                                                                <option value="V3">CARPATAIR</option>
                                                                <option value="CX">CATHAY PACIFIC AIRWAYS LTD.</option>
                                                                <option value="KX">CAYMAN AIRWAYS LTD</option>
                                                                <option value="5J">CEBU PACIFIC AIR</option>
                                                                <option value="9M">CENTRAL MOUNTAIN AIR LTD</option>
                                                                <option value="C0">CENTRALWINGS</option>
                                                                <option value="C1">CFLCO CHARTER</option>
                                                                <option value="M6">CHALAIR</option>
                                                                <option value="MG">CHAMPION AIR</option>
                                                                <option value="RP">CHAUTAUQUA</option>
                                                                <option value="C8">CHICAGO EXPRESS AIRLINES, INC</option>
                                                                <option value="CI">CHINA AIRLINES</option>
                                                                <option value="MU">CHINA EASTERN AIRLINES</option>
                                                                <option value="CJ">CHINA NORTHERN AIRLINES</option>
                                                                <option value="WH">CHINA NORTHWEST AIRLINES</option>
                                                                <option value="CZ">CHINA SOUTHERN AIRLINES</option>
                                                                <option value="SZ">CHINA SOUTHWEST AIRLINES</option>
                                                                <option value="3O">CHINA YUNNAN AIRLINES</option>
                                                                <option value="QI">CIMBER AIR</option>
                                                                <option value="C9">CIRRUS AIR</option>
                                                                <option value="CF">CITY AIRLINE</option>
                                                                <option value="WX">CITYJET</option>
                                                                <option value="XG">CLICKAIR</option>
                                                                <option value="BX">COAST AIR K.S.</option>
                                                                <option value="9L">COLGAN AIR</option>
                                                                <option value="OH">COMAIR, INC</option>
                                                                <option value="XK">COMPAGNIE AERIENNE CORSE</option>
                                                                <option value="E9">COMPAGNIE AFRICAINE D AVIATION</option>
                                                                <option value="5L">COMPANIA BOLIVIANA DE TRANS AEREO</option>
                                                                <option value="DE">CONDOR FLUGDIENST GMBTT</option>
                                                                <option value="EO">CONGO AIRLINES/EXPRESS ONE INTL INC</option>
                                                                <option value="04">CONTINENTAL CONNECTION</option>
                                                                <option value="CS">CONTINENTAL MICRONESIA, INC.</option>
                                                                <option value="3C">CORPORATE AIRLINES</option>
                                                                <option value="C2">CORPORATE EXPRESS</option>
                                                                <option value="SS">CORSE AIR INTERNATIONAL</option>
                                                                <option value="OU">CROATIA AIRLINES</option>
                                                                <option value="X5">CRONUS AIRLINES</option>
                                                                <option value="QE">CROSSAIR</option>
                                                                <option value="CY">CYPRUS AIRWAYS</option>
                                                                <option value="OK">CZECH AIRLINES A.S. , CSA</option>
                                                                <option value="MO">CALM AIR</option>
                                                                <option value="BW">CARIBBEAN</option>
                                                                <option value="DG">CEBGO</option>
                                                                <option value="OP">CHALKS  AIRWAYS</option>
                                                                <option value="CO">COBALT AIR</option>
                                                                <option value="CM">COPA</option>
                                                                <option value="CU">CUBANA</option>
                                                                <option value="YK">CYPRUS TURKISH</option>
                                                                <option value="D3">DAALLO AIRLINES</option>
                                                                <option value="6P">DAC AIR S.A.</option>
                                                                <option value="D4">DAMAL AIRLINES</option>
                                                                <option value="DX">DANISH AIR TRANSPORT</option>
                                                                <option value="V5">DANUBE WINGS</option>
                                                                <option value="7X">DASSAULT AVIATION</option>
                                                                <option value="6X">DASSAULT AVIATION</option>
                                                                <option value="2X">DASSAULT AVIATION</option>
                                                                <option value="2G">DEBONAIR AIRWAYS LTD.</option>
                                                                <option value="DL">DELTA AIR LINES INC.</option>
                                                                <option value="03">DELTA CONNECTION</option>
                                                                <option value="05">DELTA SHUTTLE</option>
                                                                <option value="3D">DENIM AIR</option>
                                                                <option value="D1">DIRECT AIR (MYRTLE BEACH)</option>
                                                                <option value="Z6">DNIEPROAVIA</option>
                                                                <option value="D9">DONAVIA</option>
                                                                <option value="NE">DOWNEAST FLYING SERVICE, INC</option>
                                                                <option value="KB">DRUK AIR</option>
                                                                <option value="7D">DONBASSAERO</option>
                                                                <option value="KA">DRAGONAIR</option>
                                                                <option value="9H">DUTCH ANTILLES EXPRESS</option>
                                                                <option value="ZN">EAGLE AIRLINES</option>
                                                                <option value="FE">EAGLE CANYON AIRLINES INC</option>
                                                                <option value="4S">EAST WEST TRAVEL TRADE LINKS LTD.</option>
                                                                <option value="T3">EASTERN AIRWAYS</option>
                                                                <option value="DK">EASTLAND AIR</option>
                                                                <option value="W9">EASTWIND AIRLINES, INC.</option>
                                                                <option value="U2">EASYJET</option>
                                                                <option value="DS">EASYJET SWITZERLAND SA</option>
                                                                <option value="EU">ECUATORIANA DE AVIACION S.A.</option>
                                                                <option value="MS">EGYPTAIR</option>
                                                                <option value="LY">EL AL ISRAEL AIRLINES LTD.</option>
                                                                <option value="EK">EMIRATES</option>
                                                                <option value="EM">EMPIRE AIRLINES</option>
                                                                <option value="E0">EOS AIRLINES</option>
                                                                <option value="7H">ERA AVIATION</option>
                                                                <option value="ET">ETHIOPIAN AIRLINES ENTERPRISE</option>
                                                                <option value="EY">ETIHAD AIRLINE</option>
                                                                <option value="RN">EURALAIR</option>
                                                                <option value="UI">EUROCYPRIA AIRLINES</option>
                                                                <option value="GJ">EUROFLY</option>
                                                                <option value="5O">EUROPE AIRPOST</option>
                                                                <option value="E7">EUROPEAN AIR</option>
                                                                <option value="EA">EUROPEAN AIR EXPRESS</option>
                                                                <option value="QY">EUROPEAN AIR TRANSPORT</option>
                                                                <option value="2H">EUROPEAN AIRLINES</option>
                                                                <option value="9F">EUROSTAR (U.K.) LTD</option>
                                                                <option value="EW">EUROWINGS AG</option>
                                                                <option value="BR">EVA AIRWAYS CORPORATION</option>
                                                                <option value="XE">EXPRESS JET</option>
                                                                <option value="EX">EXPRESS ONE - SW</option>
                                                                <option value="ZE">EASTAR JET</option>
                                                                <option value="WK">EDELWEISS AIR</option>
                                                                <option value="E4">ENTER AIR</option>
                                                                <option value="EG">ERNEST AIRLINES</option>
                                                                <option value="ES">ESTELAR</option>
                                                                <option value="4X">F AIRLINES B.V.</option>
                                                                <option value="LK">FAIRLINES</option>
                                                                <option value="6F">FALCON AIR</option>
                                                                <option value="F2">FALCON AIR EXPRESS, INC.</option>
                                                                <option value="IH">FALCON AVIATION AB</option>
                                                                <option value="FC">FALCON EXPRESS CARGO AIRLINES/TAL</option>
                                                                <option value="FX">FEDEX</option>
                                                                <option value="AY">FINNAIR OY</option>
                                                                <option value="7F">FIRST AIR</option>
                                                                <option value="8F">FISCHER AIR</option>
                                                                <option value="8N">FLAGSHIP AIRLINES, INC DBA AMERICAN</option>
                                                                <option value="FK">FLAMENCO AIRWAYS, INC.</option>
                                                                <option value="OT">FLIGHT SAFETY</option>
                                                                <option value="YC">FLIGHT WEST AIRLINES PTY LTD.</option>
                                                                <option value="BE">FLY BE</option>
                                                                <option value="SH">FLY ME SWEEDEN AB</option>
                                                                <option value="HG">FLY NIKI</option>
                                                                <option value="F7">FLYBABOO</option>
                                                                <option value="FZ">FLYDUBAI</option>
                                                                <option value="HK">FOUR STAR AVIATION</option>
                                                                <option value="F9">FRONTIER AIRLINES, INC</option>
                                                                <option value="2F">FRONTIER FLYING SERVICE</option>
                                                                <option value="EF">FAR EASTERN AIR TRANSPORT</option>
                                                                <option value="FN">FASTJET</option>
                                                                <option value="FJ">FIJI AIRWAYS</option>
                                                                <option value="FY">FIREFLY</option>
                                                                <option value="F8">FLAIR AIRLINES</option>
                                                                <option value="FA">FLYSAFAIR</option>
                                                                <option value="XY">FLYNAS</option>
                                                                <option value="G7">GANDAS AIRLINES</option>
                                                                <option value="GA">GARUDA INDONESIA</option>
                                                                <option value="GT">GB AIRWAYS LTD.</option>
                                                                <option value="3P">GEORGIAN ARLNS</option>
                                                                <option value="ST">GERMANIA FLUGGESELLSCHAFT/YANDA</option>
                                                                <option value="4U">GERMANWINGS</option>
                                                                <option value="GP">GESTAIR S.A.</option>
                                                                <option value="GH">GHANA AIRWAYS CORP.</option>
                                                                <option value="B4">GLOBESPAN</option>
                                                                <option value="G8">GO AIR</option>
                                                                <option value="06">GO!</option>
                                                                <option value="DC">GOLDEN AIR FLYG AB</option>
                                                                <option value="GW">GOLDEN WEST AIRLINES, INC</option>
                                                                <option value="DV">GORDA AERO SERVICES, INC/ROTTNEST</option>
                                                                <option value="8L">GRAND INTERNATIONAL AIRWAYS, INC.</option>
                                                                <option value="ZK">GREAT LAKES</option>
                                                                <option value="GL">GREENLANDAIR</option>
                                                                <option value="GF">GULF AIR COMPANY G.S.C.</option>
                                                                <option value="3M">GULFSTREAM INTL AIRLINES, INC</option>
                                                                <option value="GY">GUYANA AIRWAYS</option>
                                                                <option value="GC">GAMBIA</option>
                                                                <option value="G3">GOL TRANSPORTES A�REOS</option>
                                                                <option value="GB">GREAT BARRIER</option>
                                                                <option value="HR">HAHN AIR BUSINESSLINE</option>
                                                                <option value="H1">HAHN AIR SYSTEMS</option>
                                                                <option value="HU">HAINAN AIRLINES</option>
                                                                <option value="WD">HAITIAN AVIATION</option>
                                                                <option value="HI">HAMBURG INTERNATIONAL</option>
                                                                <option value="X3">HAPAG LLOYD EXPRESS</option>
                                                                <option value="HF">HAPAG LLOYD FLUGGESELLSCHAFT</option>
                                                                <option value="H3">HARBOUR AIR LTD</option>
                                                                <option value="HQ">HARMONY AIRWAYS</option>
                                                                <option value="HA">HAWAIIAN AIRLINES</option>
                                                                <option value="LE">HELGOLAND ARLNS</option>
                                                                <option value="EC">HELI INTER RIVIERA</option>
                                                                <option value="YO">HELI-AIR-MONACO</option>
                                                                <option value="JB">HELIJET AIRWAYS</option>
                                                                <option value="YQ">HELIKOPTERSERVICE, EUROAIR</option>
                                                                <option value="T4">HELLAS JET</option>
                                                                <option value="2L">HELVETIC</option>
                                                                <option value="DU">HEMUS AIR</option>
                                                                <option value="UD">HEX AIR</option>
                                                                <option value="HS">HIGHLAND AIR AB</option>
                                                                <option value="H9">HIMALAYA AIRLINES</option>
                                                                <option value="HX">HK EXPRESS</option>
                                                                <option value="HD">HOKKAIDO INTERNATIONAL AIRLINES</option>
                                                                <option value="QX">HORIZON AIR</option>
                                                                <option value="KH">HORIZON AIRLINES</option>
                                                                <option value="AG">HUNTING CARGO AIRLINES</option>
                                                                <option value="H6">HAGELAND AVIATION SERVICES INC.</option>
                                                                <option value="4R">HAMBURG</option>
                                                                <option value="MR">HUNNU AIR</option>
                                                                <option value="IB">IBERIA -LINEAS AEREAS DE ESPANA</option>
                                                                <option value="FI">ICELANDAIR</option>
                                                                <option value="6U">ICL EXPRESS</option>
                                                                <option value="IK">IMAIR</option>
                                                                <option value="DH">INDEPENDENCE AIR.</option>
                                                                <option value="IC">INDIAN AIRLINES</option>
                                                                <option value="6E">INDIGO</option>
                                                                <option value="2Y">INDO AIRLINES</option>
                                                                <option value="IM">INTENSIVE AIR (PTY) LTD</option>
                                                                <option value="D6">INTER-AVIATION SVCS DBA INTER-AIR</option>
                                                                <option value="M4">INTERIMPEX - AVIOIMPEX</option>
                                                                <option value="40">INTERJET</option>
                                                                <option value="IR">IRAN AIR</option>
                                                                <option value="B9">IRAN AIR TOURS</option>
                                                                <option value="EP">IRAN ASSEMAN AIRLINES</option>
                                                                <option value="IA">IRAQI AIRWAYS</option>
                                                                <option value="IF">IRISH FERRIES</option>
                                                                <option value="WP">ISLAND AIR</option>
                                                                <option value="IS">ISLAND AIRLINES, INC</option>
                                                                <option value="2S">ISLAND EXPRESS</option>
                                                                <option value="HH">ISLANDFLUG</option>
                                                                <option value="CN">ISLANDS NATIONAIR</option>
                                                                <option value="WC">ISLENA AIRLINES</option>
                                                                <option value="6H">ISRAIR AIRLINES &amp; TOURISM LTD</option>
                                                                <option value="IL">ISTANBUL AIRLINES A.S.</option>
                                                                <option value="B8">ITALAIR</option>
                                                                <option value="I2">IBERIA EXPRESS</option>
                                                                <option value="QZ">INDONESIA AIRASIA</option>
                                                                <option value="XT">INDONESIA AIRASIA X</option>
                                                                <option value="J1">J.M.C.</option>
                                                                <option value="JC">JAL JAPAN AIRLINES</option>
                                                                <option value="NS">JANLIN PTY LTD DBA CAPE NY AIR</option>
                                                                <option value="3X">JAPAN AIR COMMUTER CO. LTD.</option>
                                                                <option value="JD">JAPAN AIR SYSTEM CO., LTD.</option>
                                                                <option value="JL">JAPAN AIRLINES COMPANY LTD.</option>
                                                                <option value="NU">JAPAN TRANSOCEAN AIR CO. LTD.</option>
                                                                <option value="J9">JAZEERA AIRWAYS</option>
                                                                <option value="QD">JC INTERNATIONAL AIRLINES</option>
                                                                <option value="JY">JERSEY EUROPEAN AIRWAYS LTD.</option>
                                                                <option value="9W">JET AIRWAYS (INDIA) LIMITED</option>
                                                                <option value="QJ">JET AIRWAYS, INC.</option>
                                                                <option value="PP">JET AVIATION BUSINESS JETS AG</option>
                                                                <option value="LF">JET TRAIN CORPORATION</option>
                                                                <option value="LS">JET2</option>
                                                                <option value="8J">JET4YOU</option>
                                                                <option value="B6">JETBLUE AIRWAYS</option>
                                                                <option value="2U">JOINT STOCK COMPANY AIR CO. KARAT</option>
                                                                <option value="J5">JOINT-STOCK COMPANY SOCHI AIRLINE</option>
                                                                <option value="7C">JEJU AIR CO. LTD.</option>
                                                                <option value="S2">JET LITE</option>
                                                                <option value="JQ">JETSTAR AIRWAYS</option>
                                                                <option value="3K">JETSTAR ASIA AIRWAYS</option>
                                                                <option value="GK">JETSTAR JAPAN</option>
                                                                <option value="3J">JUBBA AIRWAYS</option>
                                                                <option value="KT">KAMPUCHEA AIRLINES</option>
                                                                <option value="5R">KARTHAGO AIRLINES</option>
                                                                <option value="K4">KAZAKHSTAN AIRLINES</option>
                                                                <option value="KD">KD AVIA</option>
                                                                <option value="KQ">KENYA AIRWAYS</option>
                                                                <option value="BZ">KEYSTONE AIR SERVICE LTD.</option>
                                                                <option value="Y9">KISH AIR</option>
                                                                <option value="KP">KIWI INTERNATIONAL AIR LINES</option>
                                                                <option value="WA">KLM CITYHOPPER</option>
                                                                <option value="KL">KLM ROYAL DUTCH AIRLINES</option>
                                                                <option value="KE">KOREAN AIR LINES CO. LTD.</option>
                                                                <option value="6K">KORSAR ARLNS</option>
                                                                <option value="KU">KUWAIT AIRWAYS</option>
                                                                <option value="RQ">KAM AIR</option>
                                                                <option value="MN">KULULA</option>
                                                                <option value="JF">L.A.B. FLYING SERVICE INC</option>
                                                                <option value="7Z">L.B. LIMITED</option>
                                                                <option value="WJ">LABRADOR AIRWAYS</option>
                                                                <option value="UC">LADECO S.A. D/B/A LADECO AIRLINES</option>
                                                                <option value="TM">LAM - LINHAS AEREAS DE MOCAMBIQUE</option>
                                                                <option value="4M">LAN ARGENTINA</option>
                                                                <option value="XL">LAN ECUADOR</option>
                                                                <option value="BN">LANDAIR INTERNATIONAL AIRLINES</option>
                                                                <option value="OI">LANDMARK AVIATION</option>
                                                                <option value="ZZ">LANE AVIATION</option>
                                                                <option value="LP">LANPERU</option>
                                                                <option value="QV">LAO AVIATION</option>
                                                                <option value="NG">LAUDA AIR LUFTFAHRT AG</option>
                                                                <option value="A0">LAVION</option>
                                                                <option value="LI">LIAT (1974) LTD.</option>
                                                                <option value="LR">LINEAS AEREAS COSTARRICENSES S.A.</option>
                                                                <option value="MJ">LINEAS AEREAS PRIVADAS ARGENTINAS</option>
                                                                <option value="TE">LITHUANIAN AIRLINES</option>
                                                                <option value="LB">LLOYD AEREO BOLIVIANO S.A. (LAB)</option>
                                                                <option value="LC">LOGANAIR</option>
                                                                <option value="LO">LOT - POLISH AIRLINES</option>
                                                                <option value="XU">LOTUS AIRLINE (TAS)</option>
                                                                <option value="XO">LTE INTERNATIONAL</option>
                                                                <option value="LT">LTU INTERNATIONAL AIRWAYS</option>
                                                                <option value="CL">LUFTHANSA CITYLINE GMBH</option>
                                                                <option value="LG">LUXAIR</option>
                                                                <option value="XR">LUXOR AIR (LXO)</option>
                                                                <option value="L7">LVIV AIRLINES</option>
                                                                <option value="L1">LYNX</option>
                                                                <option value="LA">LATAM AIRLINES</option>
                                                                <option value="LN">LIBYAN ARAB</option>
                                                                <option value="Z8">LINEA AEREA AMASZONAS</option>
                                                                <option value="P8">LINHAS AEREAS</option>
                                                                <option value="JT">LION AIR</option>
                                                                <option value="LM">LOGANAIR</option>
                                                                <option value="HE">LUFTFAHRT GESELLSCHAFT</option>
                                                                <option value="LH">LUFTHANSA</option>
                                                                <option value="IN">MACEDONIAN AIRLINES</option>
                                                                <option value="DM">MAERSK AIR A/S</option>
                                                                <option value="H5">MAGADAN AIRLINES</option>
                                                                <option value="W5">MAHAN AIR</option>
                                                                <option value="MH">MALAYSIAN AIRLINE</option>
                                                                <option value="MA">MALEV HUNGARIAN AIRLINES PUBLIC LTD</option>
                                                                <option value="TF">MALMO AVIATION</option>
                                                                <option value="AE">MANDARIN AIRLINES</option>
                                                                <option value="GO">MARITIME GLOBAL AIRLINES</option>
                                                                <option value="MP">MARTINAIR HOLLAND N.V.</option>
                                                                <option value="6M">MAVERICK AIRWAYS CORPORATION</option>
                                                                <option value="MW">MAYA AIRWAYS</option>
                                                                <option value="YV">MESA AIRLINES</option>
                                                                <option value="XJ">MESABA AIRLINES</option>
                                                                <option value="OW">METAVIA AIRLINES (PTY) LTD</option>
                                                                <option value="MX">MEXICANA DE AVIACION</option>
                                                                <option value="LL">MIAMI AIR INTERNATIONAL</option>
                                                                <option value="OM">MIAT-MONGOLIAN</option>
                                                                <option value="ME">MIDDLE EAST AIRLINES AIRLIBAN</option>
                                                                <option value="JI">MIDWAY AIRLINES</option>
                                                                <option value="YX">MIDWEST AIRLINES</option>
                                                                <option value="ZV">MIDWEST EXPRESS</option>
                                                                <option value="YF">MILITARY CHARTER</option>
                                                                <option value="N3">MIRANIA ROMANIAN</option>
                                                                <option value="MB">MNG AIRLIENS CARGO/EXECAIRE INC</option>
                                                                <option value="5Z">MOKULELE FLIGHT SERVICE</option>
                                                                <option value="2M">MOLDAVIAN ARLNS</option>
                                                                <option value="ZB">MONARCH AIRLINES</option>
                                                                <option value="YM">MONTENEGRO AIRLINES</option>
                                                                <option value="ZR">MUK AIR</option>
                                                                <option value="8I">MY WAY AIRLINES</option>
                                                                <option value="UB">MYANMAR ARWYS INTL</option>
                                                                <option value="OD">MALINDO AIRWAYS</option>
                                                                <option value="RI">MANDALA</option>
                                                                <option value="JE">MANGO AIRLINES</option>
                                                                <option value="MY">MAYA ISLAND AIR</option>
                                                                <option value="HC">NASKE-AIR</option>
                                                                <option value="K7">NATIONAL AIRCOMPANY SAKHA AVIA</option>
                                                                <option value="YJ">NATIONAL AIRLINES CHILE</option>
                                                                <option value="NC">NATIONAL JET SYSTEMS PTY/NORTHERN</option>
                                                                <option value="CE">NATIONWIDE AIRLINES (PTY) LTD</option>
                                                                <option value="5C">NATURE AIR</option>
                                                                <option value="1I">NETJETS AVIATION</option>
                                                                <option value="9X">NEW AXIS AIRWAYS</option>
                                                                <option value="EJ">NEW ENGLAND AIRLINES</option>
                                                                <option value="6Y">NICA</option>
                                                                <option value="WT">NIGERIA AIRWAYS LTD.</option>
                                                                <option value="KZ">NIPPON CARGO AIRLINES</option>
                                                                <option value="5N">NORDAVIA RA SMARTAVIA</option>
                                                                <option value="NA">NORTH AMERICAN</option>
                                                                <option value="J3">NORTHWESTERN AIRWAYS</option>
                                                                <option value="DY">NORWEGIAN</option>
                                                                <option value="BJ">NOUVELAIR</option>
                                                                <option value="NV">NTW AIR</option>
                                                                <option value="9K">NANTUCKET</option>
                                                                <option value="NO">NEOSAIR</option>
                                                                <option value="RA">NEPAL AIRWAYS</option>
                                                                <option value="NP">NILE AIR</option>
                                                                <option value="DD">NOK AIR</option>
                                                                <option value="XW">NOKSCOOT</option>
                                                                <option value="N7">NORDIC REGIONAL</option>
                                                                <option value="N4">NORDWIND AIRLINES</option>
                                                                <option value="HW">NORTH WRIGHT AIR</option>
                                                                <option value="NW">NORTHWEST  INC.</option>
                                                                <option value="D8">NORWEGIAN AIR INTERNATIONAL</option>
                                                                <option value="DI">NORWEGIAN AIR UK</option>
                                                                <option value="UQ">OCONNOR-MT. GAMBIER S AIRLINES</option>
                                                                <option value="OL">OLT-OSTFRIESISCHE LUFTRANSPORT</option>
                                                                <option value="OA">OLYMPIC AIRWAYS</option>
                                                                <option value="WY">OMAN AVIATION SERVICES CO. (SAOG)</option>
                                                                <option value="X9">OMNI AIR INTERNATIONAL</option>
                                                                <option value="8Q">ONUR AIR TASIMACILIK A/S</option>
                                                                <option value="NQ">ORBI GEORGIAN AIRWAYS</option>
                                                                <option value="RF">ORD AIR CHARTER PTY. LTD</option>
                                                                <option value="OX">ORIENT THAI AIRLINES</option>
                                                                <option value="0X">ORIENT THAI AIRLINES</option>
                                                                <option value="XX">OTHER</option>
                                                                <option value="GX">PACIFIC AIRWAYS CORP (PACIFICAIR)</option>
                                                                <option value="8P">PACIFIC COASTAL AIRLINES LIMITED</option>
                                                                <option value="9J">PACIFIC ISLAND AVIATION</option>
                                                                <option value="2W">PACIFIC MIDLAND AIRLINES LTD.</option>
                                                                <option value="LW">PACIFIC WINGS</option>
                                                                <option value="PK">PAKISTAN INTERNATIONAL AIRLINES</option>
                                                                <option value="PV">PAN AIR LINEAS AEREAS S.A.</option>
                                                                <option value="KW">PAN AMERICAN WORLD AIRWAYS, INC.</option>
                                                                <option value="PQ">PANAFRICAN AIRWAYS</option>
                                                                <option value="BK">PARADISE ISLAND AIRLINES, INC DBA</option>
                                                                <option value="P3">PASSAREDO TRANSPORTES AEREOS</option>
                                                                <option value="P1">PEACE AIR</option>
                                                                <option value="PC">PEGASUS AIRLINES</option>
                                                                <option value="9P">PELANGI AIRWAYS SDN. BHD.</option>
                                                                <option value="7V">PELICAN AIR SERVICES</option>
                                                                <option value="UW">PERIMETER AIRLINES</option>
                                                                <option value="PR">PHILIPPINE AIRLINES, INC.</option>
                                                                <option value="9R">PHUKET AIR</option>
                                                                <option value="9E">PINNACLE</option>
                                                                <option value="PO">POLAR AIR CARGO</option>
                                                                <option value="PH">POLYNESIAN LIMITED</option>
                                                                <option value="PD">PORTER AIRLINES</option>
                                                                <option value="NI">PORTUGALIA-COMPANHIA PORTUGUESA DE</option>
                                                                <option value="T9">PRIME AIR, INC(D.B.A TRANSMERIDIAN)</option>
                                                                <option value="00">PRIVATE PLANE</option>
                                                                <option value="P9">PROAIR</option>
                                                                <option value="HN">PROTHEUS HELICOPTERES</option>
                                                                <option value="PB">PROVINCIAL AIRLINES</option>
                                                                <option value="BO">PT BOURAQ</option>
                                                                <option value="MZ">PT. MERPATI NUSANTARA</option>
                                                                <option value="FV">PULKOVO AVIATION ENTERPRISE</option>
                                                                <option value="BL">PACIFIC</option>
                                                                <option value="MM">PEACH AVIATION LIMITED</option>
                                                                <option value="KS">PENAIR</option>
                                                                <option value="PE">PEOPLE EXPRESS</option>
                                                                <option value="Z2">PHILIPPINES AIRASIA</option>
                                                                <option value="PU">PLUNA</option>
                                                                <option value="DP">POBEDA AIRLINES</option>
                                                                <option value="PW">PRECISION</option>
                                                                <option value="PF">PRIMERA AIR</option>
                                                                <option value="FB">PROVINCIAL AIRWAYS</option>
                                                                <option value="QF">QANTAS AIRWAYS LTD.</option>
                                                                <option value="QR">QATAR AIRWAYS (W.L.L.)</option>
                                                                <option value="WZ">RED WINGS AIRLINES</option>
                                                                <option value="RV">REEVE ALEUTIAN AIRWAYS INC</option>
                                                                <option value="7S">REGION AIR PTE. LTD.</option>
                                                                <option value="VM">REGIONAL AIRLINES</option>
                                                                <option value="QQ">RENO AIR INC.</option>
                                                                <option value="WE">RHEINTALFLUG SEEWALD</option>
                                                                <option value="GV">RIGA AIRLINES</option>
                                                                <option value="WQ">ROMAVIA</option>
                                                                <option value="AT">ROYAL AIR MAROC</option>
                                                                <option value="QN">ROYAL AVIATION INC</option>
                                                                <option value="BI">ROYAL BRUNEI AIRLINES SDN BHD</option>
                                                                <option value="RJ">ROYAL JORDANIAN</option>
                                                                <option value="ZC">ROYAL SWAZI NATIONAL AIRWAYS CORP.</option>
                                                                <option value="WR">ROYAL TONGAN AIRLINES</option>
                                                                <option value="RD">RYAN INTERNATIONAL</option>
                                                                <option value="FR">RYANAIR LTD.</option>
                                                                <option value="ZL">REGIONAL EXPRESS</option>
                                                                <option value="7R">RUSLINE</option>
                                                                <option value="4Z">SA AIRLINK</option>
                                                                <option value="HZ">SAKHALINSKIE AVIATRASSY</option>
                                                                <option value="E5">SAMARA AIRLINES</option>
                                                                <option value="S3">SANTA BARBARA AIRLINES C.A.</option>
                                                                <option value="N5">SARDAIRLIEN - SOC. COOP. A.R.L.</option>
                                                                <option value="SP">SATA - AIR ACORES</option>
                                                                <option value="S4">SATA INTERNATIONAL</option>
                                                                <option value="SV">SAUDI ARABIAN AIRLINES</option>
                                                                <option value="SK">SCANDINAVIAN AIRLINES SYSTEM (SAS)</option>
                                                                <option value="3L">SCENIC AIR, INC.</option>
                                                                <option value="AW">SCHREINER AIRWAY BV</option>
                                                                <option value="CB">SCOT AIRWAYS</option>
                                                                <option value="BB">SEABORNE AVIATION</option>
                                                                <option value="VC">SERICIOS AVENSA</option>
                                                                <option value="RZ">SERVICIOS AEREAS NATIONALES S.A.</option>
                                                                <option value="FS">SERVICIOS DE TRANSPORTES AEREOS</option>
                                                                <option value="UG">SEVENAIR</option>
                                                                <option value="D2">SEVERSTAL</option>
                                                                <option value="NL">SHAHEEN AIR INTERNATIONAL</option>
                                                                <option value="SC">SHANDONG AIRLINES</option>
                                                                <option value="7Q">SHOROUK AIR</option>
                                                                <option value="3S">SHUSWAP FLIGHT CENTER LTD</option>
                                                                <option value="S5">SHUTTLE AMERICA</option>
                                                                <option value="TB">SHUTTLE INC DBA USAIR SHUTTLE</option>
                                                                <option value="S7">SIBERIA ARLNS</option>
                                                                <option value="3U">SICHUAN AIRLINES</option>
                                                                <option value="LJ">SIERRA NATIONAL AIRLINES</option>
                                                                <option value="SI">SIERRA PACIFIC</option>
                                                                <option value="MI">SILKAIR (S) PTE LTD</option>
                                                                <option value="SQ">SINGAPORE AIRLINES</option>
                                                                <option value="OO">SKY WEST AIRLINES</option>
                                                                <option value="5P">SKYEUROPE AILINES HUNGARY AS</option>
                                                                <option value="5G">SKYSERVICE</option>
                                                                <option value="JZ">SKYWAYS AB</option>
                                                                <option value="6Q">SLOVAK AIRLINES</option>
                                                                <option value="QS">SMART WINGS</option>
                                                                <option value="Q7">SOBELAIR</option>
                                                                <option value="EH">SOCIEDAD ECUATORIANA DE TRANSPORTES</option>
                                                                <option value="PN">SOCIETE NOUVELLE AIR MARTINIQUE</option>
                                                                <option value="OY">SODER AIR</option>
                                                                <option value="8R">SOL LINEAS AEREAS</option>
                                                                <option value="IE">SOLOMON AIRLINES</option>
                                                                <option value="SA">SOUTH AFRICAN AIRWAYS</option>
                                                                <option value="S9">SOUTHEAST AIRLINES</option>
                                                                <option value="SJ">SOUTHERN AIR TRANSPORT</option>
                                                                <option value="WN">SOUTHWEST AIRLINES</option>
                                                                <option value="JK">SPANAIR S.A.</option>
                                                                <option value="SG">SPICEJET</option>
                                                                <option value="NK">SPIRIT AIRLINES</option>
                                                                <option value="UL">SRI LANKAN AIRLINES</option>
                                                                <option value="T8">STA TRANS AFRICAN AIRLINES</option>
                                                                <option value="YD">STATE ENTERPRISE GOMELAVIA</option>
                                                                <option value="NB">STERLING EUROPEAN AIRLINES A/S</option>
                                                                <option value="SD">SUDAN AIRWAYS CO. LTD.</option>
                                                                <option value="BV">SUN AIR</option>
                                                                <option value="EZ">SUN AIR OF SCANDINAVIA</option>
                                                                <option value="SY">SUN COUNTRY AIRLINES</option>
                                                                <option value="XQ">SUN EXPRESS</option>
                                                                <option value="SM">SUN WORLD INTERNATIONAL</option>
                                                                <option value="PI">SUNFLOWER AIRLINES LTD.</option>
                                                                <option value="JX">SUNJET INTERNATIONAL</option>
                                                                <option value="WG">SUNWING</option>
                                                                <option value="K0">SUNWING AIRLINES</option>
                                                                <option value="PY">SURINAM AIRWAYS LTD.</option>
                                                                <option value="08">SWAN AERO</option>
                                                                <option value="S1">SWANBERG AIR</option>
                                                                <option value="HJ">SWEDEWAYS AIR LINES AB</option>
                                                                <option value="LX">SWISS AIR LINES</option>
                                                                <option value="SR">SWISS AIR TRANS CO. LTD. (SWISSAIR)</option>
                                                                <option value="RB">SYRIAN ARAB AIRLINES</option>
                                                                <option value="4Q">SAFI AIRWAYS</option>
                                                                <option value="OV">SALAM AIR</option>
                                                                <option value="9N">SATENA</option>
                                                                <option value="YR">SCENIC  INC.</option>
                                                                <option value="TR">SCOOT TIGERAIR</option>
                                                                <option value="DN">SENEGAL AIRLINES</option>
                                                                <option value="FM">SHANGHAI</option>
                                                                <option value="ZH">SHENZHEN</option>
                                                                <option value="FT">SIEM REAP AIRWAYS</option>
                                                                <option value="H2">SKY AIRLINE</option>
                                                                <option value="SX">SKY WORK AIRLINES</option>
                                                                <option value="RU">SKYKING TURKS AND CAICOS AIRWAYS</option>
                                                                <option value="YG">SOUTH</option>
                                                                <option value="9C">SPRING AIRLINES</option>
                                                                <option value="TW">T'WAY AIR</option>
                                                                <option value="DT">TAAG - LINHAS AEREAS DE ANGOLA</option>
                                                                <option value="TA">TACA INTERNATIONAL AIRLINES, S.A.</option>
                                                                <option value="VR">TACV</option>
                                                                <option value="PZ">TAM - TRANSPORTES AEREOS DEL</option>
                                                                <option value="JJ">TAM AIRLINES</option>
                                                                <option value="GG">TAMAIR</option>
                                                                <option value="EQ">TAME</option>
                                                                <option value="TP">TAP - AIR PORTUGAL</option>
                                                                <option value="RO">TAROM - ROMANIAN AIR TRANSPORT</option>
                                                                <option value="T6">TAVREY AIRCOMPANY</option>
                                                                <option value="TG">THAI AIRWAYS INTERNATIONAL PUBLIC</option>
                                                                <option value="NM">THE MOUNT COOK GROUP LTD.</option>
                                                                <option value="FQ">THOMAS COOK AIRLINE</option>
                                                                <option value="MT">THOMAS COOKE AIRLINES</option>
                                                                <option value="KI">TIME AIR, INC</option>
                                                                <option value="ZT">TITAN AIRWAYS</option>
                                                                <option value="TI">TOL AIR SERVICES, INC</option>
                                                                <option value="FF">TOWER AIR INC.</option>
                                                                <option value="P6">TRANS AIR</option>
                                                                <option value="7T">TRANS COTE INC</option>
                                                                <option value="TL">TRANS MEDITERRANEAN AIRWAYS</option>
                                                                <option value="6N">TRANS TRAVEL</option>
                                                                <option value="UN">TRANSAERO AIRLINES</option>
                                                                <option value="GE">TRANSASIA AIRWAYS</option>
                                                                <option value="HV">TRANSAVIA AIRLINES</option>
                                                                <option value="AL">TRANSAVIAEXPORT CARGO AIRLINES</option>
                                                                <option value="4P">TRANSPORTES AEREAS DEL PERU, S.A.</option>
                                                                <option value="GD">TRANSPORTES AEREOS EJECUTIVOS S.A.</option>
                                                                <option value="TQ">TRANSWEDE AIRWAYS</option>
                                                                <option value="01">TRAVEL CITY (XL LEISURE GROUP)</option>
                                                                <option value="TU">TUNIS AIR</option>
                                                                <option value="QW">TURK &amp; CAICOS AIRWAYS</option>
                                                                <option value="TK">TURKISH AIRLINES INC.</option>
                                                                <option value="U8">TUS AIR</option>
                                                                <option value="T7">TWIN JET</option>
                                                                <option value="VO">TYROLEAN ARWYS</option>
                                                                <option value="7M">TYUMEN ARLNS</option>
                                                                <option value="FO">TASMANIA</option>
                                                                <option value="T2">THAI AIR</option>
                                                                <option value="FD">THAI AIRASIA</option>
                                                                <option value="SL">THAI LION AIR</option>
                                                                <option value="VZ">THAI VIETJET AIR</option>
                                                                <option value="TT">TIGER AIRWAYS</option>
                                                                <option value="IT">TIGERAIR TAIWAN</option>
                                                                <option value="TO">TRANSAVIA FRANCE</option>
                                                                <option value="PM">TROPIC AIR</option>
                                                                <option value="WI">U-LAND AIRLINES</option>
                                                                <option value="PS">UKRAINE INTERNATIONAL AIRLINES</option>
                                                                <option value="UA">UNITED AIRLINES, INC.</option>
                                                                <option value="UV">UNIVERSAL AIRWAYS INC.</option>
                                                                <option value="UZ">UP AIRWAYS</option>
                                                                <option value="5X">UPS</option>
                                                                <option value="U6">URAL AIRLINES</option>
                                                                <option value="US">US AIRWAYS, INC.</option>
                                                                <option value="U5">USA 3000</option>
                                                                <option value="U7">USA JET</option>
                                                                <option value="QU">UTAIR UKRAINE</option>
                                                                <option value="HY">UZBEKISTAN HAVO YULLARY</option>
                                                                <option value="UT">UTAIR</option>
                                                                <option value="J7">VALUJET AIRLINES</option>
                                                                <option value="NJ">VANGUARD AIRLINES</option>
                                                                <option value="RG">VARIG S.A.</option>
                                                                <option value="VP">VIACAO AEREA SAO PAULO, S.A. (VASP)</option>
                                                                <option value="VI">VIEQUES AIR LINK, INC.</option>
                                                                <option value="VN">VIETNAM AIRLINES CORPORATION</option>
                                                                <option value="VK">VIKING AIRLINES</option>
                                                                <option value="VQ">VINTAGE PROPS AND JETS</option>
                                                                <option value="9V">VIP AIR</option>
                                                                <option value="VX">VIRGIN AMERICA</option>
                                                                <option value="VS">VIRGIN ATLANTIC</option>
                                                                <option value="TV">VIRGIN EXPRESS</option>
                                                                <option value="V2">VISION AIRLINES</option>
                                                                <option value="UK">VISTARA</option>
                                                                <option value="VB">VIVAAEROBUS</option>
                                                                <option value="VG">VLM VLAAMSE</option>
                                                                <option value="41">VOLARIS</option>
                                                                <option value="G6">VOLGA AIRLINES</option>
                                                                <option value="V7">VOLOTEA</option>
                                                                <option value="4V">VOYAGEUR AIRWAYS</option>
                                                                <option value="VY">VUELING AIRLINES</option>
                                                                <option value="V4">VIEQUES AIR LINK</option>
                                                                <option value="VJ">VIETJET AIR</option>
                                                                <option value="DJ">VIRGIN AUSTRALIA</option>
                                                                <option value="VA">VIRGIN AUSTRALIA</option>
                                                                <option value="VV">VIVA AIR PERU</option>
                                                                <option value="Y4">VOLARIS</option>
                                                                <option value="WU">WALK IN</option>
                                                                <option value="8O">WEST COAST AIR</option>
                                                                <option value="OE">WESTAIR COMMUTER AIRLINES, INC</option>
                                                                <option value="W7">WESTERN PACIFIC</option>
                                                                <option value="WS">WESTJET AIRLINES LTD.</option>
                                                                <option value="WF">WIDEROES FLYVESELSKAP A/S</option>
                                                                <option value="IV">WIND JET</option>
                                                                <option value="RM">WINGS WEST AIRLINES, INC</option>
                                                                <option value="WM">WINWARD ISLANDS</option>
                                                                <option value="W6">WIZZ AIR</option>
                                                                <option value="WW">WOW AIR</option>
                                                                <option value="PT">WEST AIR SWEDEN</option>
                                                                <option value="P5">WINGO</option>
                                                                <option value="K5">WINGS OF ALASKA</option>
                                                                <option value="WO">WORLD AIRWAYS</option>
                                                                <option value="SE">XL AIRWAYS FRANCE</option>
                                                                <option value="MF">XIAMEN AIR</option>
                                                                <option value="XP">XTRA AIRWAYS</option>
                                                                <option value="IY">YEMENIA YEMEN AIRWAYS</option>
                                                                <option value="4Y">YUTE AIR ALASKA, INC.</option>
                                                                <option value="R3">YAKUTIA AIRLINES</option>
                                                                <option value="OQ">ZAMBIAN EXPRESSS AIRWAYS (1995) LTD</option>
                                                                <option value="Z7">ZIMBABWE EXPRESS AIRLINES</option>
                                                                <option value="C4">ZIMEX AVIATION</option>
                                                                <option value="ZM">ZOOM</option>
                                                                <option value="Z4">ZOOM AIRLINES</option>
                                                                <option value="F3">FLYADEAL</option>
                                                            </Form.Select>
                                                            {errors.onwardCarrier && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Flight Number<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control className={errors.onwardFlightNumber && 'is-invalid'} {...register('onwardFlightNumber', {required:true})} value={onwardFlightNumber} onChange={(e) => setOnwardFlightNumber(e.target.value)} />
                                                            {errors.onwardFlightNumber && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="gx-2 align-items-center">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Terminal</strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={onwardTerminal} onChange={(e) => setOnwardTerminal(e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            </>
                                            }

                                            {reqObj.pickupType === 'RailStation' &&
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Train Number<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control className={errors.onwardTrainNumber && 'is-invalid'} {...register('onwardTrainNumber', {required:true})} value={onwardTrainNumber} onChange={(e) => setOnwardTrainNumber(e.target.value)} />
                                                            {errors.onwardTrainNumber && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Wagon Number</strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={onwardWagonNumber} onChange={(e) => setOnwardWagonNumber(e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            }

                                            {reqObj.dropoffType === 'Airport' || reqObj.dropoffType === 'RailStation' || isInterRailStation === 1 || isInterAirport === 1 ?
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>{departureType()} {reqObj.dropoffType !== 'Airport' && reqObj.dropoffType !== 'RailStation' ? 'Dropoff' : 'Departure'} Date<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <InputGroup>
                                                                <input type="hidden" {...register('onwardDropoffDate', { required: true })} value={onwardDropoffDate !== null ? onwardDropoffDate : ''} />
                                                                <DatePicker className={'form-control radius-right-0 ' + (errors.onwardDropoffDate && 'is-invalid')} locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={onwardDropoffDate} minDate={new Date(new Date(reqObj.pickupDate).setDate(new Date(reqObj.pickupDate).getDate() - 1))} maxDate={new Date(new Date(reqObj.pickupDate).setDate(new Date(reqObj.pickupDate).getDate() + 1))} onChange={ (date) => setOnwardDropoffDate(date)} />
                                                                <InputGroup.Text className="bg-white fn14 green"><FontAwesomeIcon icon={solid('calendar-alt')} /></InputGroup.Text>
                                                            </InputGroup>
                                                            {errors.onwardDropoffDate && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>{departureType()} {reqObj.dropoffType !== 'Airport' && reqObj.dropoffType !== 'RailStation' ? 'Dropoff' : 'Departure'} Time<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Select className={errors.onwardDropoffTime && 'is-invalid'} {...register('onwardDropoffTime', { required: true })} value={onwardDropoffTime} onChange={(e) => setOnwardDropoffTime(e.target.value)}>
                                                                <option value="">Choose Time</option>
                                                                <option value="0:0">0:0</option>
                                                                <option value="0:5">0:5</option>
                                                                <option value="0:10">0:10</option>
                                                                <option value="0:15">0:15</option>
                                                                <option value="0:20">0:20</option>
                                                                <option value="0:25">0:25</option>
                                                                <option value="0:30">0:30</option>
                                                                <option value="0:35">0:35</option>
                                                                <option value="0:40">0:40</option>
                                                                <option value="0:45">0:45</option>
                                                                <option value="0:50">0:50</option>
                                                                <option value="0:55">0:55</option>
                                                                <option value="1:0">1:0</option>
                                                                <option value="1:5">1:5</option>
                                                                <option value="1:10">1:10</option>
                                                                <option value="1:15">1:15</option>
                                                                <option value="1:20">1:20</option>
                                                                <option value="1:25">1:25</option>
                                                                <option value="1:30">1:30</option>
                                                                <option value="1:35">1:35</option>
                                                                <option value="1:40">1:40</option>
                                                                <option value="1:45">1:45</option>
                                                                <option value="1:50">1:50</option>
                                                                <option value="1:55">1:55</option>
                                                                <option value="2:0">2:0</option>
                                                                <option value="2:5">2:5</option>
                                                                <option value="2:10">2:10</option>
                                                                <option value="2:15">2:15</option>
                                                                <option value="2:20">2:20</option>
                                                                <option value="2:25">2:25</option>
                                                                <option value="2:30">2:30</option>
                                                                <option value="2:35">2:35</option>
                                                                <option value="2:40">2:40</option>
                                                                <option value="2:45">2:45</option>
                                                                <option value="2:50">2:50</option>
                                                                <option value="2:55">2:55</option>
                                                                <option value="3:0">3:0</option>
                                                                <option value="3:5">3:5</option>
                                                                <option value="3:10">3:10</option>
                                                                <option value="3:15">3:15</option>
                                                                <option value="3:20">3:20</option>
                                                                <option value="3:25">3:25</option>
                                                                <option value="3:30">3:30</option>
                                                                <option value="3:35">3:35</option>
                                                                <option value="3:40">3:40</option>
                                                                <option value="3:45">3:45</option>
                                                                <option value="3:50">3:50</option>
                                                                <option value="3:55">3:55</option>
                                                                <option value="4:0">4:0</option>
                                                                <option value="4:5">4:5</option>
                                                                <option value="4:10">4:10</option>
                                                                <option value="4:15">4:15</option>
                                                                <option value="4:20">4:20</option>
                                                                <option value="4:25">4:25</option>
                                                                <option value="4:30">4:30</option>
                                                                <option value="4:35">4:35</option>
                                                                <option value="4:40">4:40</option>
                                                                <option value="4:45">4:45</option>
                                                                <option value="4:50">4:50</option>
                                                                <option value="4:55">4:55</option>
                                                                <option value="5:0">5:0</option>
                                                                <option value="5:5">5:5</option>
                                                                <option value="5:10">5:10</option>
                                                                <option value="5:15">5:15</option>
                                                                <option value="5:20">5:20</option>
                                                                <option value="5:25">5:25</option>
                                                                <option value="5:30">5:30</option>
                                                                <option value="5:35">5:35</option>
                                                                <option value="5:40">5:40</option>
                                                                <option value="5:45">5:45</option>
                                                                <option value="5:50">5:50</option>
                                                                <option value="5:55">5:55</option>
                                                                <option value="6:0">6:0</option>
                                                                <option value="6:5">6:5</option>
                                                                <option value="6:10">6:10</option>
                                                                <option value="6:15">6:15</option>
                                                                <option value="6:20">6:20</option>
                                                                <option value="6:25">6:25</option>
                                                                <option value="6:30">6:30</option>
                                                                <option value="6:35">6:35</option>
                                                                <option value="6:40">6:40</option>
                                                                <option value="6:45">6:45</option>
                                                                <option value="6:50">6:50</option>
                                                                <option value="6:55">6:55</option>
                                                                <option value="7:0">7:0</option>
                                                                <option value="7:5">7:5</option>
                                                                <option value="7:10">7:10</option>
                                                                <option value="7:15">7:15</option>
                                                                <option value="7:20">7:20</option>
                                                                <option value="7:25">7:25</option>
                                                                <option value="7:30">7:30</option>
                                                                <option value="7:35">7:35</option>
                                                                <option value="7:40">7:40</option>
                                                                <option value="7:45">7:45</option>
                                                                <option value="7:50">7:50</option>
                                                                <option value="7:55">7:55</option>
                                                                <option value="8:0">8:0</option>
                                                                <option value="8:5">8:5</option>
                                                                <option value="8:10">8:10</option>
                                                                <option value="8:15">8:15</option>
                                                                <option value="8:20">8:20</option>
                                                                <option value="8:25">8:25</option>
                                                                <option value="8:30">8:30</option>
                                                                <option value="8:35">8:35</option>
                                                                <option value="8:40">8:40</option>
                                                                <option value="8:45">8:45</option>
                                                                <option value="8:50">8:50</option>
                                                                <option value="8:55">8:55</option>
                                                                <option value="9:0">9:0</option>
                                                                <option value="9:5">9:5</option>
                                                                <option value="9:10">9:10</option>
                                                                <option value="9:15">9:15</option>
                                                                <option value="9:20">9:20</option>
                                                                <option value="9:25">9:25</option>
                                                                <option value="9:30">9:30</option>
                                                                <option value="9:35">9:35</option>
                                                                <option value="9:40">9:40</option>
                                                                <option value="9:45">9:45</option>
                                                                <option value="9:50">9:50</option>
                                                                <option value="9:55">9:55</option>
                                                                <option value="10:0">10:0</option>
                                                                <option value="10:5">10:5</option>
                                                                <option value="10:10">10:10</option>
                                                                <option value="10:15">10:15</option>
                                                                <option value="10:20">10:20</option>
                                                                <option value="10:25">10:25</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="10:35">10:35</option>
                                                                <option value="10:40">10:40</option>
                                                                <option value="10:45">10:45</option>
                                                                <option value="10:50">10:50</option>
                                                                <option value="10:55">10:55</option>
                                                                <option value="11:0">11:0</option>
                                                                <option value="11:5">11:5</option>
                                                                <option value="11:10">11:10</option>
                                                                <option value="11:15">11:15</option>
                                                                <option value="11:20">11:20</option>
                                                                <option value="11:25">11:25</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="11:35">11:35</option>
                                                                <option value="11:40">11:40</option>
                                                                <option value="11:45">11:45</option>
                                                                <option value="11:50">11:50</option>
                                                                <option value="11:55">11:55</option>
                                                                <option value="12:0">12:0</option>
                                                                <option value="12:5">12:5</option>
                                                                <option value="12:10">12:10</option>
                                                                <option value="12:15">12:15</option>
                                                                <option value="12:20">12:20</option>
                                                                <option value="12:25">12:25</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="12:35">12:35</option>
                                                                <option value="12:40">12:40</option>
                                                                <option value="12:45">12:45</option>
                                                                <option value="12:50">12:50</option>
                                                                <option value="12:55">12:55</option>
                                                                <option value="13:0">13:0</option>
                                                                <option value="13:5">13:5</option>
                                                                <option value="13:10">13:10</option>
                                                                <option value="13:15">13:15</option>
                                                                <option value="13:20">13:20</option>
                                                                <option value="13:25">13:25</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="13:35">13:35</option>
                                                                <option value="13:40">13:40</option>
                                                                <option value="13:45">13:45</option>
                                                                <option value="13:50">13:50</option>
                                                                <option value="13:55">13:55</option>
                                                                <option value="14:0">14:0</option>
                                                                <option value="14:5">14:5</option>
                                                                <option value="14:10">14:10</option>
                                                                <option value="14:15">14:15</option>
                                                                <option value="14:20">14:20</option>
                                                                <option value="14:25">14:25</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="14:35">14:35</option>
                                                                <option value="14:40">14:40</option>
                                                                <option value="14:45">14:45</option>
                                                                <option value="14:50">14:50</option>
                                                                <option value="14:55">14:55</option>
                                                                <option value="15:0">15:0</option>
                                                                <option value="15:5">15:5</option>
                                                                <option value="15:10">15:10</option>
                                                                <option value="15:15">15:15</option>
                                                                <option value="15:20">15:20</option>
                                                                <option value="15:25">15:25</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="15:35">15:35</option>
                                                                <option value="15:40">15:40</option>
                                                                <option value="15:45">15:45</option>
                                                                <option value="15:50">15:50</option>
                                                                <option value="15:55">15:55</option>
                                                                <option value="16:0">16:0</option>
                                                                <option value="16:5">16:5</option>
                                                                <option value="16:10">16:10</option>
                                                                <option value="16:15">16:15</option>
                                                                <option value="16:20">16:20</option>
                                                                <option value="16:25">16:25</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="16:35">16:35</option>
                                                                <option value="16:40">16:40</option>
                                                                <option value="16:45">16:45</option>
                                                                <option value="16:50">16:50</option>
                                                                <option value="16:55">16:55</option>
                                                                <option value="17:0">17:0</option>
                                                                <option value="17:5">17:5</option>
                                                                <option value="17:10">17:10</option>
                                                                <option value="17:15">17:15</option>
                                                                <option value="17:20">17:20</option>
                                                                <option value="17:25">17:25</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="17:35">17:35</option>
                                                                <option value="17:40">17:40</option>
                                                                <option value="17:45">17:45</option>
                                                                <option value="17:50">17:50</option>
                                                                <option value="17:55">17:55</option>
                                                                <option value="18:0">18:0</option>
                                                                <option value="18:5">18:5</option>
                                                                <option value="18:10">18:10</option>
                                                                <option value="18:15">18:15</option>
                                                                <option value="18:20">18:20</option>
                                                                <option value="18:25">18:25</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="18:35">18:35</option>
                                                                <option value="18:40">18:40</option>
                                                                <option value="18:45">18:45</option>
                                                                <option value="18:50">18:50</option>
                                                                <option value="18:55">18:55</option>
                                                                <option value="19:0">19:0</option>
                                                                <option value="19:5">19:5</option>
                                                                <option value="19:10">19:10</option>
                                                                <option value="19:15">19:15</option>
                                                                <option value="19:20">19:20</option>
                                                                <option value="19:25">19:25</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="19:35">19:35</option>
                                                                <option value="19:40">19:40</option>
                                                                <option value="19:45">19:45</option>
                                                                <option value="19:50">19:50</option>
                                                                <option value="19:55">19:55</option>
                                                                <option value="20:0">20:0</option>
                                                                <option value="20:5">20:5</option>
                                                                <option value="20:10">20:10</option>
                                                                <option value="20:15">20:15</option>
                                                                <option value="20:20">20:20</option>
                                                                <option value="20:25">20:25</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="20:35">20:35</option>
                                                                <option value="20:40">20:40</option>
                                                                <option value="20:45">20:45</option>
                                                                <option value="20:50">20:50</option>
                                                                <option value="20:55">20:55</option>
                                                                <option value="21:0">21:0</option>
                                                                <option value="21:5">21:5</option>
                                                                <option value="21:10">21:10</option>
                                                                <option value="21:15">21:15</option>
                                                                <option value="21:20">21:20</option>
                                                                <option value="21:25">21:25</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="21:35">21:35</option>
                                                                <option value="21:40">21:40</option>
                                                                <option value="21:45">21:45</option>
                                                                <option value="21:50">21:50</option>
                                                                <option value="21:55">21:55</option>
                                                                <option value="22:0">22:0</option>
                                                                <option value="22:5">22:5</option>
                                                                <option value="22:10">22:10</option>
                                                                <option value="22:15">22:15</option>
                                                                <option value="22:20">22:20</option>
                                                                <option value="22:25">22:25</option>
                                                                <option value="22:30">22:30</option>
                                                                <option value="22:35">22:35</option>
                                                                <option value="22:40">22:40</option>
                                                                <option value="22:45">22:45</option>
                                                                <option value="22:50">22:50</option>
                                                                <option value="22:55">22:55</option>
                                                                <option value="23:0">23:0</option>
                                                                <option value="23:5">23:5</option>
                                                                <option value="23:10">23:10</option>
                                                                <option value="23:15">23:15</option>
                                                                <option value="23:20">23:20</option>
                                                                <option value="23:25">23:25</option>
                                                                <option value="23:30">23:30</option>
                                                                <option value="23:35">23:35</option>
                                                                <option value="23:40">23:40</option>
                                                                <option value="23:45">23:45</option>
                                                                <option value="23:50">23:50</option>
                                                                <option value="23:55">23:55</option>
                                                            </Form.Select>
                                                            {errors.onwardDropoffTime && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            :
                                            ''
                                            }

                                            {reqObj.dropoffType === 'Airport' || isInterAirport === 1 ?
                                            <>
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Carrier<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Select className={errors.onwardDropoffCarrier && 'is-invalid'} {...register('onwardDropoffCarrier', { required: true })} value={onwardDropoffCarrier} onChange={(e) => setOnwardDropoffCarrier(e.target.value)}>
                                                                <option value="">Select Airline</option>
                                                                <option value="1T">1TIME AIRLINE</option>
                                                                <option value="2N">2P</option>
                                                                <option value="7L">A B AIRLINES</option>
                                                                <option value="ZY">ADA AIR</option>
                                                                <option value="II">ADI DOMESTIC AIRLINES</option>
                                                                <option value="JP">ADRIA AIRWAYS-AIRLINE OF SLOVENIA</option>
                                                                <option value="A3">AEGEAN AVIATION</option>
                                                                <option value="WL">AEORPERLAS AIRLINES</option>
                                                                <option value="RE">AER ARANN EXPRESS</option>
                                                                <option value="EI">AER LINGUS LIMITED</option>
                                                                <option value="EE">AERO AIRLINES</option>
                                                                <option value="JR">AERO CALIFORNIA</option>
                                                                <option value="ML">AERO COSTA RICA</option>
                                                                <option value="02">AERO HONDURAS</option>
                                                                <option value="YP">AERO LLOYD</option>
                                                                <option value="Z9">AERO ZAMBIA</option>
                                                                <option value="SU">AEROFLOT-RUSSIAN INTL AIRLINES</option>
                                                                <option value="AR">AEROLINEAS ARGENTINAS</option>
                                                                <option value="BQ">AEROMAR</option>
                                                                <option value="AM">AEROMEXICO</option>
                                                                <option value="5D">AEROMEXICO CONNECT</option>
                                                                <option value="QO">AEROMEXPRESS S.A. DE C.V.</option>
                                                                <option value="PL">AEROPERU - EMPRESA DE TRANSPORTES</option>
                                                                <option value="VH">AEROPOSTAL</option>
                                                                <option value="VE">AEROVIAS VENEZOLANAS, S.A.</option>
                                                                <option value="Y2">AFRICAN JOINT AIR SERVICES</option>
                                                                <option value="8U">AFRIQIHYA AIRWAYS</option>
                                                                <option value="ZI">AIGLE AZUR</option>
                                                                <option value="RK">AIR AFRIQUE</option>
                                                                <option value="AH">AIR ALGERIE</option>
                                                                <option value="A6">AIR ALPS AVIATION</option>
                                                                <option value="G9">AIR ARABIA</option>
                                                                <option value="D7">AIR ASIA</option>
                                                                <option value="4L">AIR ASTANA</option>
                                                                <option value="KC">AIR ASTANA</option>
                                                                <option value="CC">AIR ATLANTA ICELANDIC</option>
                                                                <option value="LU">AIR ATLANTIC DOMINICANA</option>
                                                                <option value="UU">AIR AUSTRAL</option>
                                                                <option value="BT">AIR BALTIC CORPORATION S/A</option>
                                                                <option value="8B">AIR BENIN</option>
                                                                <option value="AB">AIR BERLIN BMBTT &amp; CO LUFTVERKEHR</option>
                                                                <option value="BP">AIR BOTSWANA CORPORATION</option>
                                                                <option value="2J">AIR BURKINA</option>
                                                                <option value="TY">AIR CALEDONIE</option>
                                                                <option value="SB">AIR CALEDONIE INTERNATIONAL</option>
                                                                <option value="AC">AIR CANADA</option>
                                                                <option value="XC">AIR CARRIBBEAN</option>
                                                                <option value="CV">AIR CHATHAMS</option>
                                                                <option value="CA">AIR CHINA INTERNATIONAL CORPORATION</option>
                                                                <option value="YN">AIR CREEBEC</option>
                                                                <option value="UX">AIR EUROPA LINEAS AEREAS, S.A.</option>
                                                                <option value="XV">AIR EXPRESS</option>
                                                                <option value="OF">AIR FINLAND</option>
                                                                <option value="GS">AIR FOYLE LTD/GRANT AVIATION, INC</option>
                                                                <option value="AF">AIR FRANCE</option>
                                                                <option value="GN">AIR GABON</option>
                                                                <option value="DA">AIR GEORGIA</option>
                                                                <option value="ZX">AIR GEORGIAN</option>
                                                                <option value="JG">AIR GREECE</option>
                                                                <option value="LD">AIR HONG KONG</option>
                                                                <option value="NY">AIR ICELAND</option>
                                                                <option value="3H">AIR INUIT</option>
                                                                <option value="I9">AIR ITALY</option>
                                                                <option value="VU">AIR IVOIRE</option>
                                                                <option value="JM">AIR JAMAICA</option>
                                                                <option value="V6">AIR JET</option>
                                                                <option value="9Y">AIR KAZAKSTAN</option>
                                                                <option value="JS">AIR KORYO</option>
                                                                <option value="UE">AIR LA</option>
                                                                <option value="QL">AIR LESOTHO (PTY) LTD.</option>
                                                                <option value="VD">AIR LIBERTE</option>
                                                                <option value="FU">AIR LITTORAL</option>
                                                                <option value="NX">AIR MACAU COMPANY LIMITED</option>
                                                                <option value="MD">AIR MADAGASCAR</option>
                                                                <option value="QM">AIR MALAWI LIMITED</option>
                                                                <option value="L6">AIR MALDIVES LIMITED</option>
                                                                <option value="KM">AIR MALTA</option>
                                                                <option value="CW">AIR MARSHALL ISLANDS, INC.</option>
                                                                <option value="MK">AIR MAURITIUS</option>
                                                                <option value="DR">AIR MEDITERRANEE</option>
                                                                <option value="MC">AIR MOBILITY COMMAND</option>
                                                                <option value="3R">AIR MOLDOVA INTERNATIONAL S.A.</option>
                                                                <option value="9U">AIR MOLDOVA INTL</option>
                                                                <option value="SW">AIR NAMIBIA</option>
                                                                <option value="ON">AIR NAURU</option>
                                                                <option value="N1">AIR NEW GUINEA</option>
                                                                <option value="NZ">AIR NEW ZEALAND LIMITED</option>
                                                                <option value="EL">AIR NIPPON CO. LTD.</option>
                                                                <option value="PX">AIR NIUGINI PTY LIMITED DBA</option>
                                                                <option value="4N">AIR NORTH</option>
                                                                <option value="M3">AIR NORWAY</option>
                                                                <option value="YW">AIR NOSTRUM L.A.M.S.A.</option>
                                                                <option value="QK">AIR NOVA</option>
                                                                <option value="AP">AIR ONE</option>
                                                                <option value="8K">AIR OSTRAVA</option>
                                                                <option value="A7">AIR PLUS COMET</option>
                                                                <option value="RY">AIR RWANDA</option>
                                                                <option value="5W">AIR SAN JUAN/CHARTAIR</option>
                                                                <option value="7W">AIR SASK AVIATION 1991</option>
                                                                <option value="HM">AIR SEYCHELLES LIMITED</option>
                                                                <option value="YI">AIR SHUNSHINE INC.</option>
                                                                <option value="BM">AIR SICILIA</option>
                                                                <option value="4D">AIR SINAI</option>
                                                                <option value="GM">AIR SLOVAKIA BWJ, LTD.</option>
                                                                <option value="CT">AIR SOFIA</option>
                                                                <option value="PJ">AIR ST. PIERRE</option>
                                                                <option value="ZP">AIR ST.THOMAS</option>
                                                                <option value="VT">AIR TAHITI</option>
                                                                <option value="TN">AIR TAHITI NUI</option>
                                                                <option value="TC">AIR TANZANIA CORPORATION</option>
                                                                <option value="YT">AIR TOGO</option>
                                                                <option value="TS">AIR TRANSAT</option>
                                                                <option value="3N">AIR URGA</option>
                                                                <option value="DO">AIR VALLEE S.P.A./AIR VIRGINIA, INC</option>
                                                                <option value="NF">AIR VANUATU (OPERATIONS) LIMITED</option>
                                                                <option value="VL">AIR VIA BULGARIAN AIRWAYS</option>
                                                                <option value="6G">AIR WALES</option>
                                                                <option value="ZW">AIR WISCONSIN</option>
                                                                <option value="RL">AIR WORLD AVIATION</option>
                                                                <option value="QC">AIR ZAIRE</option>
                                                                <option value="UM">AIR ZIMBABWE CORPORATION</option>
                                                                <option value="AI">AIR-INDIA LIMITED</option>
                                                                <option value="A9">AIRCOMPANY AIRZENA GEORGIAN AIRLINE</option>
                                                                <option value="QP">AIRKENYA AVIATION LTD</option>
                                                                <option value="A5">AIRLINAIR</option>
                                                                <option value="ND">AIRLINE PTY LTD</option>
                                                                <option value="RT">AIRLINES OF SOUTH AUSTRALIA</option>
                                                                <option value="FL">AIRTRAN AIRWAYS, INC</option>
                                                                <option value="6L">AKLAK</option>
                                                                <option value="AS">ALASKA AIRLINES INC.</option>
                                                                <option value="KO">ALASKA CENTRAL EXPRESS</option>
                                                                <option value="LV">ALBANIAN AIRLINES MAK S.H.P.K.</option>
                                                                <option value="A2">ALITALIA</option>
                                                                <option value="XM">ALITALIA EXPRESS</option>
                                                                <option value="NH">ALL NIPPON AIRWAYS CO. LTD.</option>
                                                                <option value="G4">ALLEGIANT AIR</option>
                                                                <option value="1O">ALLEGRO</option>
                                                                <option value="CD">ALLIANCE AIR</option>
                                                                <option value="9I">ALLIANCE AIR</option>
                                                                <option value="AQ">ALOHA AIRLINES INC.</option>
                                                                <option value="E8">ALPI EAGLES S.P.A.</option>
                                                                <option value="5A">ALPINE AVIATION, INC.</option>
                                                                <option value="HP">AMERICA WEST AIRLINES INC.</option>
                                                                <option value="AA">AMERICAN AIRLINES</option>
                                                                <option value="AX">AMERICAN CONNECTION</option>
                                                                <option value="MQ">AMERICAN EAGLE</option>
                                                                <option value="MV">AMRENIAN INTERNATIONAL AIRWAYS</option>
                                                                <option value="2V">AMTRACK</option>
                                                                <option value="8G">ANGEL AIRLINES</option>
                                                                <option value="AN">ANSETT AUSTRALIA</option>
                                                                <option value="ZQ">ANSETT NEW ZEALAND</option>
                                                                <option value="HO">ANTINEA AIRLINES</option>
                                                                <option value="IW">AOM-MINERVE S.A.</option>
                                                                <option value="N2">AREOLINEAS INTERNACIONALES</option>
                                                                <option value="FG">ARIANA AFGHAN AIRLINES</option>
                                                                <option value="OR">ARKEFLY</option>
                                                                <option value="IZ">ARKIA - ISRAELI AIRLINES LTD</option>
                                                                <option value="JW">ARROW AIR, INC</option>
                                                                <option value="0Z">ASIANA AIRLINES</option>
                                                                <option value="oZ">ASIANA AIRLINES</option>
                                                                <option value="OZ">ASIANA AIRLINES INC</option>
                                                                <option value="C5">ASOCIADOS LATINOAMERICANOS DE TR</option>
                                                                <option value="9T">ATHABASKA AIRWAYS LTD</option>
                                                                <option value="RC">ATLANTIC AIRWAYS FAROE ISLANDS</option>
                                                                <option value="EV">ATLANTIC SOUTHEAST AIRLINES</option>
                                                                <option value="8A">ATLAS BLUE</option>
                                                                <option value="IQ">AUGSBURG-AIRWAYS GMBH</option>
                                                                <option value="GR">AURIGNY</option>
                                                                <option value="AU">AUSTRAL</option>
                                                                <option value="OS">AUSTRIAN AIRLINES</option>
                                                                <option value="RX">AVIA EXPRESS</option>
                                                                <option value="OC">AVIACION DEL NOROESTE S.A. DE C.V.</option>
                                                                <option value="AO">AVIACION Y COMERCIO S.A. (AVIACO)</option>
                                                                <option value="T5">AVIACOMPANY TURKMENISTAN</option>
                                                                <option value="6A">AVIACSA</option>
                                                                <option value="GU">AVIATECA, S.A.</option>
                                                                <option value="DF">AVIOSARDA S.R.L.</option>
                                                                <option value="2Q">AVITRANS</option>
                                                                <option value="A1">AVRO</option>
                                                                <option value="J2">AZAL AZERBAIJA</option>
                                                                <option value="ZS">AZZURRA AIR S.P.A.</option>
                                                                <option value="9B">ACCESSRAIL</option>
                                                                <option value="31">AEROLINEAS DEL SUR</option>
                                                                <option value="I5">AIR ASIA INDIA</option>
                                                                <option value="ED">AIR BLUE</option>
                                                                <option value="TX">AIR CARAIBES</option>
                                                                <option value="EN">AIR DOLOMITI</option>
                                                                <option value="IX">AIR INDIA EXPRESS</option>
                                                                <option value="IG">AIR ITALY</option>
                                                                <option value="Q9">AIR LIAISON</option>
                                                                <option value="2P">AIR PHILIPPINES</option>
                                                                <option value="GZ">AIR RAROTONGA</option>
                                                                <option value="WB">AIR RWANDA</option>
                                                                <option value="6O">AIR SATELLITE</option>
                                                                <option value="JU">AIR SERBIA</option>
                                                                <option value="6V">AIR VEGAS</option>
                                                                <option value="8T">AIR TENDI</option>
                                                                <option value="AK">AIRASIA X</option>
                                                                <option value="PA">AIRBLUE</option>
                                                                <option value="UJ">ALMASRIA AIRLINES</option>
                                                                <option value="AZ">ALITALIA</option>
                                                                <option value="TZ">AMERICAN TRANS AIR</option>
                                                                <option value="JH">AMERIJET INC.</option>
                                                                <option value="R7">ASERCA</option>
                                                                <option value="5Y">ATLAS AIR</option>
                                                                <option value="KK">ATLASGLOBAL</option>
                                                                <option value="AV">AVIANCA</option>
                                                                <option value="O6">AVIANCA BRAZIL</option>
                                                                <option value="A4">AZIMUTH AIRLINE</option>
                                                                <option value="AD">AZUL LINHAS AEREAS BRASILEIRAS</option>
                                                                <option value="JA">B&amp;H AIRLINES</option>
                                                                <option value="TH">BA CITIEXPRESS</option>
                                                                <option value="UP">BAHAMASAIR</option>
                                                                <option value="LZ">BALKAN - BULGARIAN AIRLINES</option>
                                                                <option value="PG">BANGKOK AIRWAY CO. LTD</option>
                                                                <option value="JV">BEARSKIN AIRLINES</option>
                                                                <option value="4T">BELAIR AIRLINES AG</option>
                                                                <option value="B2">BELAVIA</option>
                                                                <option value="B3">BELLVIEW AIRLINES LTD.</option>
                                                                <option value="CH">BEMIDJI AIRLINES</option>
                                                                <option value="J8">BERJAYA AIR SDN. BHD.</option>
                                                                <option value="GQ">BIG SKY AIRLINES</option>
                                                                <option value="BG">BIMAN BANGLADESH AIRLINES</option>
                                                                <option value="NT">BINTER CANARIAS</option>
                                                                <option value="B1">BIRDY AIRLINES</option>
                                                                <option value="KF">BLUE 1</option>
                                                                <option value="JO">BLUE AIR</option>
                                                                <option value="0B">BLUE AIR</option>
                                                                <option value="BC">BLUE ISLANDS</option>
                                                                <option value="6B">BLUE SCANDINAVA AB/BAXTER AVIATION</option>
                                                                <option value="5Q">BQB LINEAS AEREAS</option>
                                                                <option value="B7">BRA</option>
                                                                <option value="BU">BRAATHENS SOUTH AMERICAN AND</option>
                                                                <option value="DB">BRIT AIR</option>
                                                                <option value="BH">BRITANNIA</option>
                                                                <option value="BF">BRITANY FERRIES</option>
                                                                <option value="BA">BRITISH AIRWAYS P.L.C.</option>
                                                                <option value="RR">BRITISH ARMY</option>
                                                                <option value="BD">BRITISH MIDLAND AIRWAYS LTD.</option>
                                                                <option value="BY">BRITTANNIA AIRWAYS LTD.</option>
                                                                <option value="SN">BRUSSELS AIRLINES</option>
                                                                <option value="U4">BURYAT AIRLINE</option>
                                                                <option value="5E">BUSINESS AVIATION SVCS EINDHOVEN</option>
                                                                <option value="ID">BATIK AIR</option>
                                                                <option value="OB">BOLIVIANA DE AVIACION</option>
                                                                <option value="1X">BRANSON AIR EXPRESS</option>
                                                                <option value="BS">BRITISH HELICOPTERS</option>
                                                                <option value="CK">CALEDONIAN AIRLINES</option>
                                                                <option value="K6">CAMBODIA ANGKOR AIR</option>
                                                                <option value="UY">CAMEROON AIRLINES</option>
                                                                <option value="FH">CAMPANIA HISPANO IRLANDESA DE AVIA</option>
                                                                <option value="2T">CANADA 3000 AIRLINE LIMITED</option>
                                                                <option value="CP">CANADIAN AIRLINES INTL LIMITED</option>
                                                                <option value="5T">CANADIAN NORTH AIRLINE</option>
                                                                <option value="C6">CANJET AIRLINES</option>
                                                                <option value="NN">CARDINAL AIRLINES LIMITED</option>
                                                                <option value="V3">CARPATAIR</option>
                                                                <option value="CX">CATHAY PACIFIC AIRWAYS LTD.</option>
                                                                <option value="KX">CAYMAN AIRWAYS LTD</option>
                                                                <option value="5J">CEBU PACIFIC AIR</option>
                                                                <option value="9M">CENTRAL MOUNTAIN AIR LTD</option>
                                                                <option value="C0">CENTRALWINGS</option>
                                                                <option value="C1">CFLCO CHARTER</option>
                                                                <option value="M6">CHALAIR</option>
                                                                <option value="MG">CHAMPION AIR</option>
                                                                <option value="RP">CHAUTAUQUA</option>
                                                                <option value="C8">CHICAGO EXPRESS AIRLINES, INC</option>
                                                                <option value="CI">CHINA AIRLINES</option>
                                                                <option value="MU">CHINA EASTERN AIRLINES</option>
                                                                <option value="CJ">CHINA NORTHERN AIRLINES</option>
                                                                <option value="WH">CHINA NORTHWEST AIRLINES</option>
                                                                <option value="CZ">CHINA SOUTHERN AIRLINES</option>
                                                                <option value="SZ">CHINA SOUTHWEST AIRLINES</option>
                                                                <option value="3O">CHINA YUNNAN AIRLINES</option>
                                                                <option value="QI">CIMBER AIR</option>
                                                                <option value="C9">CIRRUS AIR</option>
                                                                <option value="CF">CITY AIRLINE</option>
                                                                <option value="WX">CITYJET</option>
                                                                <option value="XG">CLICKAIR</option>
                                                                <option value="BX">COAST AIR K.S.</option>
                                                                <option value="9L">COLGAN AIR</option>
                                                                <option value="OH">COMAIR, INC</option>
                                                                <option value="XK">COMPAGNIE AERIENNE CORSE</option>
                                                                <option value="E9">COMPAGNIE AFRICAINE D AVIATION</option>
                                                                <option value="5L">COMPANIA BOLIVIANA DE TRANS AEREO</option>
                                                                <option value="DE">CONDOR FLUGDIENST GMBTT</option>
                                                                <option value="EO">CONGO AIRLINES/EXPRESS ONE INTL INC</option>
                                                                <option value="04">CONTINENTAL CONNECTION</option>
                                                                <option value="CS">CONTINENTAL MICRONESIA, INC.</option>
                                                                <option value="3C">CORPORATE AIRLINES</option>
                                                                <option value="C2">CORPORATE EXPRESS</option>
                                                                <option value="SS">CORSE AIR INTERNATIONAL</option>
                                                                <option value="OU">CROATIA AIRLINES</option>
                                                                <option value="X5">CRONUS AIRLINES</option>
                                                                <option value="QE">CROSSAIR</option>
                                                                <option value="CY">CYPRUS AIRWAYS</option>
                                                                <option value="OK">CZECH AIRLINES A.S. , CSA</option>
                                                                <option value="MO">CALM AIR</option>
                                                                <option value="BW">CARIBBEAN</option>
                                                                <option value="DG">CEBGO</option>
                                                                <option value="OP">CHALKS  AIRWAYS</option>
                                                                <option value="CO">COBALT AIR</option>
                                                                <option value="CM">COPA</option>
                                                                <option value="CU">CUBANA</option>
                                                                <option value="YK">CYPRUS TURKISH</option>
                                                                <option value="D3">DAALLO AIRLINES</option>
                                                                <option value="6P">DAC AIR S.A.</option>
                                                                <option value="D4">DAMAL AIRLINES</option>
                                                                <option value="DX">DANISH AIR TRANSPORT</option>
                                                                <option value="V5">DANUBE WINGS</option>
                                                                <option value="7X">DASSAULT AVIATION</option>
                                                                <option value="6X">DASSAULT AVIATION</option>
                                                                <option value="2X">DASSAULT AVIATION</option>
                                                                <option value="2G">DEBONAIR AIRWAYS LTD.</option>
                                                                <option value="DL">DELTA AIR LINES INC.</option>
                                                                <option value="03">DELTA CONNECTION</option>
                                                                <option value="05">DELTA SHUTTLE</option>
                                                                <option value="3D">DENIM AIR</option>
                                                                <option value="D1">DIRECT AIR (MYRTLE BEACH)</option>
                                                                <option value="Z6">DNIEPROAVIA</option>
                                                                <option value="D9">DONAVIA</option>
                                                                <option value="NE">DOWNEAST FLYING SERVICE, INC</option>
                                                                <option value="KB">DRUK AIR</option>
                                                                <option value="7D">DONBASSAERO</option>
                                                                <option value="KA">DRAGONAIR</option>
                                                                <option value="9H">DUTCH ANTILLES EXPRESS</option>
                                                                <option value="ZN">EAGLE AIRLINES</option>
                                                                <option value="FE">EAGLE CANYON AIRLINES INC</option>
                                                                <option value="4S">EAST WEST TRAVEL TRADE LINKS LTD.</option>
                                                                <option value="T3">EASTERN AIRWAYS</option>
                                                                <option value="DK">EASTLAND AIR</option>
                                                                <option value="W9">EASTWIND AIRLINES, INC.</option>
                                                                <option value="U2">EASYJET</option>
                                                                <option value="DS">EASYJET SWITZERLAND SA</option>
                                                                <option value="EU">ECUATORIANA DE AVIACION S.A.</option>
                                                                <option value="MS">EGYPTAIR</option>
                                                                <option value="LY">EL AL ISRAEL AIRLINES LTD.</option>
                                                                <option value="EK">EMIRATES</option>
                                                                <option value="EM">EMPIRE AIRLINES</option>
                                                                <option value="E0">EOS AIRLINES</option>
                                                                <option value="7H">ERA AVIATION</option>
                                                                <option value="ET">ETHIOPIAN AIRLINES ENTERPRISE</option>
                                                                <option value="EY">ETIHAD AIRLINE</option>
                                                                <option value="RN">EURALAIR</option>
                                                                <option value="UI">EUROCYPRIA AIRLINES</option>
                                                                <option value="GJ">EUROFLY</option>
                                                                <option value="5O">EUROPE AIRPOST</option>
                                                                <option value="E7">EUROPEAN AIR</option>
                                                                <option value="EA">EUROPEAN AIR EXPRESS</option>
                                                                <option value="QY">EUROPEAN AIR TRANSPORT</option>
                                                                <option value="2H">EUROPEAN AIRLINES</option>
                                                                <option value="9F">EUROSTAR (U.K.) LTD</option>
                                                                <option value="EW">EUROWINGS AG</option>
                                                                <option value="BR">EVA AIRWAYS CORPORATION</option>
                                                                <option value="XE">EXPRESS JET</option>
                                                                <option value="EX">EXPRESS ONE - SW</option>
                                                                <option value="ZE">EASTAR JET</option>
                                                                <option value="WK">EDELWEISS AIR</option>
                                                                <option value="E4">ENTER AIR</option>
                                                                <option value="EG">ERNEST AIRLINES</option>
                                                                <option value="ES">ESTELAR</option>
                                                                <option value="4X">F AIRLINES B.V.</option>
                                                                <option value="LK">FAIRLINES</option>
                                                                <option value="6F">FALCON AIR</option>
                                                                <option value="F2">FALCON AIR EXPRESS, INC.</option>
                                                                <option value="IH">FALCON AVIATION AB</option>
                                                                <option value="FC">FALCON EXPRESS CARGO AIRLINES/TAL</option>
                                                                <option value="FX">FEDEX</option>
                                                                <option value="AY">FINNAIR OY</option>
                                                                <option value="7F">FIRST AIR</option>
                                                                <option value="8F">FISCHER AIR</option>
                                                                <option value="8N">FLAGSHIP AIRLINES, INC DBA AMERICAN</option>
                                                                <option value="FK">FLAMENCO AIRWAYS, INC.</option>
                                                                <option value="OT">FLIGHT SAFETY</option>
                                                                <option value="YC">FLIGHT WEST AIRLINES PTY LTD.</option>
                                                                <option value="BE">FLY BE</option>
                                                                <option value="SH">FLY ME SWEEDEN AB</option>
                                                                <option value="HG">FLY NIKI</option>
                                                                <option value="F7">FLYBABOO</option>
                                                                <option value="FZ">FLYDUBAI</option>
                                                                <option value="HK">FOUR STAR AVIATION</option>
                                                                <option value="F9">FRONTIER AIRLINES, INC</option>
                                                                <option value="2F">FRONTIER FLYING SERVICE</option>
                                                                <option value="EF">FAR EASTERN AIR TRANSPORT</option>
                                                                <option value="FN">FASTJET</option>
                                                                <option value="FJ">FIJI AIRWAYS</option>
                                                                <option value="FY">FIREFLY</option>
                                                                <option value="F8">FLAIR AIRLINES</option>
                                                                <option value="FA">FLYSAFAIR</option>
                                                                <option value="XY">FLYNAS</option>
                                                                <option value="G7">GANDAS AIRLINES</option>
                                                                <option value="GA">GARUDA INDONESIA</option>
                                                                <option value="GT">GB AIRWAYS LTD.</option>
                                                                <option value="3P">GEORGIAN ARLNS</option>
                                                                <option value="ST">GERMANIA FLUGGESELLSCHAFT/YANDA</option>
                                                                <option value="4U">GERMANWINGS</option>
                                                                <option value="GP">GESTAIR S.A.</option>
                                                                <option value="GH">GHANA AIRWAYS CORP.</option>
                                                                <option value="B4">GLOBESPAN</option>
                                                                <option value="G8">GO AIR</option>
                                                                <option value="06">GO!</option>
                                                                <option value="DC">GOLDEN AIR FLYG AB</option>
                                                                <option value="GW">GOLDEN WEST AIRLINES, INC</option>
                                                                <option value="DV">GORDA AERO SERVICES, INC/ROTTNEST</option>
                                                                <option value="8L">GRAND INTERNATIONAL AIRWAYS, INC.</option>
                                                                <option value="ZK">GREAT LAKES</option>
                                                                <option value="GL">GREENLANDAIR</option>
                                                                <option value="GF">GULF AIR COMPANY G.S.C.</option>
                                                                <option value="3M">GULFSTREAM INTL AIRLINES, INC</option>
                                                                <option value="GY">GUYANA AIRWAYS</option>
                                                                <option value="GC">GAMBIA</option>
                                                                <option value="G3">GOL TRANSPORTES A�REOS</option>
                                                                <option value="GB">GREAT BARRIER</option>
                                                                <option value="HR">HAHN AIR BUSINESSLINE</option>
                                                                <option value="H1">HAHN AIR SYSTEMS</option>
                                                                <option value="HU">HAINAN AIRLINES</option>
                                                                <option value="WD">HAITIAN AVIATION</option>
                                                                <option value="HI">HAMBURG INTERNATIONAL</option>
                                                                <option value="X3">HAPAG LLOYD EXPRESS</option>
                                                                <option value="HF">HAPAG LLOYD FLUGGESELLSCHAFT</option>
                                                                <option value="H3">HARBOUR AIR LTD</option>
                                                                <option value="HQ">HARMONY AIRWAYS</option>
                                                                <option value="HA">HAWAIIAN AIRLINES</option>
                                                                <option value="LE">HELGOLAND ARLNS</option>
                                                                <option value="EC">HELI INTER RIVIERA</option>
                                                                <option value="YO">HELI-AIR-MONACO</option>
                                                                <option value="JB">HELIJET AIRWAYS</option>
                                                                <option value="YQ">HELIKOPTERSERVICE, EUROAIR</option>
                                                                <option value="T4">HELLAS JET</option>
                                                                <option value="2L">HELVETIC</option>
                                                                <option value="DU">HEMUS AIR</option>
                                                                <option value="UD">HEX AIR</option>
                                                                <option value="HS">HIGHLAND AIR AB</option>
                                                                <option value="H9">HIMALAYA AIRLINES</option>
                                                                <option value="HX">HK EXPRESS</option>
                                                                <option value="HD">HOKKAIDO INTERNATIONAL AIRLINES</option>
                                                                <option value="QX">HORIZON AIR</option>
                                                                <option value="KH">HORIZON AIRLINES</option>
                                                                <option value="AG">HUNTING CARGO AIRLINES</option>
                                                                <option value="H6">HAGELAND AVIATION SERVICES INC.</option>
                                                                <option value="4R">HAMBURG</option>
                                                                <option value="MR">HUNNU AIR</option>
                                                                <option value="IB">IBERIA -LINEAS AEREAS DE ESPANA</option>
                                                                <option value="FI">ICELANDAIR</option>
                                                                <option value="6U">ICL EXPRESS</option>
                                                                <option value="IK">IMAIR</option>
                                                                <option value="DH">INDEPENDENCE AIR.</option>
                                                                <option value="IC">INDIAN AIRLINES</option>
                                                                <option value="6E">INDIGO</option>
                                                                <option value="2Y">INDO AIRLINES</option>
                                                                <option value="IM">INTENSIVE AIR (PTY) LTD</option>
                                                                <option value="D6">INTER-AVIATION SVCS DBA INTER-AIR</option>
                                                                <option value="M4">INTERIMPEX - AVIOIMPEX</option>
                                                                <option value="40">INTERJET</option>
                                                                <option value="IR">IRAN AIR</option>
                                                                <option value="B9">IRAN AIR TOURS</option>
                                                                <option value="EP">IRAN ASSEMAN AIRLINES</option>
                                                                <option value="IA">IRAQI AIRWAYS</option>
                                                                <option value="IF">IRISH FERRIES</option>
                                                                <option value="WP">ISLAND AIR</option>
                                                                <option value="IS">ISLAND AIRLINES, INC</option>
                                                                <option value="2S">ISLAND EXPRESS</option>
                                                                <option value="HH">ISLANDFLUG</option>
                                                                <option value="CN">ISLANDS NATIONAIR</option>
                                                                <option value="WC">ISLENA AIRLINES</option>
                                                                <option value="6H">ISRAIR AIRLINES &amp; TOURISM LTD</option>
                                                                <option value="IL">ISTANBUL AIRLINES A.S.</option>
                                                                <option value="B8">ITALAIR</option>
                                                                <option value="I2">IBERIA EXPRESS</option>
                                                                <option value="QZ">INDONESIA AIRASIA</option>
                                                                <option value="XT">INDONESIA AIRASIA X</option>
                                                                <option value="J1">J.M.C.</option>
                                                                <option value="JC">JAL JAPAN AIRLINES</option>
                                                                <option value="NS">JANLIN PTY LTD DBA CAPE NY AIR</option>
                                                                <option value="3X">JAPAN AIR COMMUTER CO. LTD.</option>
                                                                <option value="JD">JAPAN AIR SYSTEM CO., LTD.</option>
                                                                <option value="JL">JAPAN AIRLINES COMPANY LTD.</option>
                                                                <option value="NU">JAPAN TRANSOCEAN AIR CO. LTD.</option>
                                                                <option value="J9">JAZEERA AIRWAYS</option>
                                                                <option value="QD">JC INTERNATIONAL AIRLINES</option>
                                                                <option value="JY">JERSEY EUROPEAN AIRWAYS LTD.</option>
                                                                <option value="9W">JET AIRWAYS (INDIA) LIMITED</option>
                                                                <option value="QJ">JET AIRWAYS, INC.</option>
                                                                <option value="PP">JET AVIATION BUSINESS JETS AG</option>
                                                                <option value="LF">JET TRAIN CORPORATION</option>
                                                                <option value="LS">JET2</option>
                                                                <option value="8J">JET4YOU</option>
                                                                <option value="B6">JETBLUE AIRWAYS</option>
                                                                <option value="2U">JOINT STOCK COMPANY AIR CO. KARAT</option>
                                                                <option value="J5">JOINT-STOCK COMPANY SOCHI AIRLINE</option>
                                                                <option value="7C">JEJU AIR CO. LTD.</option>
                                                                <option value="S2">JET LITE</option>
                                                                <option value="JQ">JETSTAR AIRWAYS</option>
                                                                <option value="3K">JETSTAR ASIA AIRWAYS</option>
                                                                <option value="GK">JETSTAR JAPAN</option>
                                                                <option value="3J">JUBBA AIRWAYS</option>
                                                                <option value="KT">KAMPUCHEA AIRLINES</option>
                                                                <option value="5R">KARTHAGO AIRLINES</option>
                                                                <option value="K4">KAZAKHSTAN AIRLINES</option>
                                                                <option value="KD">KD AVIA</option>
                                                                <option value="KQ">KENYA AIRWAYS</option>
                                                                <option value="BZ">KEYSTONE AIR SERVICE LTD.</option>
                                                                <option value="Y9">KISH AIR</option>
                                                                <option value="KP">KIWI INTERNATIONAL AIR LINES</option>
                                                                <option value="WA">KLM CITYHOPPER</option>
                                                                <option value="KL">KLM ROYAL DUTCH AIRLINES</option>
                                                                <option value="KE">KOREAN AIR LINES CO. LTD.</option>
                                                                <option value="6K">KORSAR ARLNS</option>
                                                                <option value="KU">KUWAIT AIRWAYS</option>
                                                                <option value="RQ">KAM AIR</option>
                                                                <option value="MN">KULULA</option>
                                                                <option value="JF">L.A.B. FLYING SERVICE INC</option>
                                                                <option value="7Z">L.B. LIMITED</option>
                                                                <option value="WJ">LABRADOR AIRWAYS</option>
                                                                <option value="UC">LADECO S.A. D/B/A LADECO AIRLINES</option>
                                                                <option value="TM">LAM - LINHAS AEREAS DE MOCAMBIQUE</option>
                                                                <option value="4M">LAN ARGENTINA</option>
                                                                <option value="XL">LAN ECUADOR</option>
                                                                <option value="BN">LANDAIR INTERNATIONAL AIRLINES</option>
                                                                <option value="OI">LANDMARK AVIATION</option>
                                                                <option value="ZZ">LANE AVIATION</option>
                                                                <option value="LP">LANPERU</option>
                                                                <option value="QV">LAO AVIATION</option>
                                                                <option value="NG">LAUDA AIR LUFTFAHRT AG</option>
                                                                <option value="A0">LAVION</option>
                                                                <option value="LI">LIAT (1974) LTD.</option>
                                                                <option value="LR">LINEAS AEREAS COSTARRICENSES S.A.</option>
                                                                <option value="MJ">LINEAS AEREAS PRIVADAS ARGENTINAS</option>
                                                                <option value="TE">LITHUANIAN AIRLINES</option>
                                                                <option value="LB">LLOYD AEREO BOLIVIANO S.A. (LAB)</option>
                                                                <option value="LC">LOGANAIR</option>
                                                                <option value="LO">LOT - POLISH AIRLINES</option>
                                                                <option value="XU">LOTUS AIRLINE (TAS)</option>
                                                                <option value="XO">LTE INTERNATIONAL</option>
                                                                <option value="LT">LTU INTERNATIONAL AIRWAYS</option>
                                                                <option value="CL">LUFTHANSA CITYLINE GMBH</option>
                                                                <option value="LG">LUXAIR</option>
                                                                <option value="XR">LUXOR AIR (LXO)</option>
                                                                <option value="L7">LVIV AIRLINES</option>
                                                                <option value="L1">LYNX</option>
                                                                <option value="LA">LATAM AIRLINES</option>
                                                                <option value="LN">LIBYAN ARAB</option>
                                                                <option value="Z8">LINEA AEREA AMASZONAS</option>
                                                                <option value="P8">LINHAS AEREAS</option>
                                                                <option value="JT">LION AIR</option>
                                                                <option value="LM">LOGANAIR</option>
                                                                <option value="HE">LUFTFAHRT GESELLSCHAFT</option>
                                                                <option value="LH">LUFTHANSA</option>
                                                                <option value="IN">MACEDONIAN AIRLINES</option>
                                                                <option value="DM">MAERSK AIR A/S</option>
                                                                <option value="H5">MAGADAN AIRLINES</option>
                                                                <option value="W5">MAHAN AIR</option>
                                                                <option value="MH">MALAYSIAN AIRLINE</option>
                                                                <option value="MA">MALEV HUNGARIAN AIRLINES PUBLIC LTD</option>
                                                                <option value="TF">MALMO AVIATION</option>
                                                                <option value="AE">MANDARIN AIRLINES</option>
                                                                <option value="GO">MARITIME GLOBAL AIRLINES</option>
                                                                <option value="MP">MARTINAIR HOLLAND N.V.</option>
                                                                <option value="6M">MAVERICK AIRWAYS CORPORATION</option>
                                                                <option value="MW">MAYA AIRWAYS</option>
                                                                <option value="YV">MESA AIRLINES</option>
                                                                <option value="XJ">MESABA AIRLINES</option>
                                                                <option value="OW">METAVIA AIRLINES (PTY) LTD</option>
                                                                <option value="MX">MEXICANA DE AVIACION</option>
                                                                <option value="LL">MIAMI AIR INTERNATIONAL</option>
                                                                <option value="OM">MIAT-MONGOLIAN</option>
                                                                <option value="ME">MIDDLE EAST AIRLINES AIRLIBAN</option>
                                                                <option value="JI">MIDWAY AIRLINES</option>
                                                                <option value="YX">MIDWEST AIRLINES</option>
                                                                <option value="ZV">MIDWEST EXPRESS</option>
                                                                <option value="YF">MILITARY CHARTER</option>
                                                                <option value="N3">MIRANIA ROMANIAN</option>
                                                                <option value="MB">MNG AIRLIENS CARGO/EXECAIRE INC</option>
                                                                <option value="5Z">MOKULELE FLIGHT SERVICE</option>
                                                                <option value="2M">MOLDAVIAN ARLNS</option>
                                                                <option value="ZB">MONARCH AIRLINES</option>
                                                                <option value="YM">MONTENEGRO AIRLINES</option>
                                                                <option value="ZR">MUK AIR</option>
                                                                <option value="8I">MY WAY AIRLINES</option>
                                                                <option value="UB">MYANMAR ARWYS INTL</option>
                                                                <option value="OD">MALINDO AIRWAYS</option>
                                                                <option value="RI">MANDALA</option>
                                                                <option value="JE">MANGO AIRLINES</option>
                                                                <option value="MY">MAYA ISLAND AIR</option>
                                                                <option value="HC">NASKE-AIR</option>
                                                                <option value="K7">NATIONAL AIRCOMPANY SAKHA AVIA</option>
                                                                <option value="YJ">NATIONAL AIRLINES CHILE</option>
                                                                <option value="NC">NATIONAL JET SYSTEMS PTY/NORTHERN</option>
                                                                <option value="CE">NATIONWIDE AIRLINES (PTY) LTD</option>
                                                                <option value="5C">NATURE AIR</option>
                                                                <option value="1I">NETJETS AVIATION</option>
                                                                <option value="9X">NEW AXIS AIRWAYS</option>
                                                                <option value="EJ">NEW ENGLAND AIRLINES</option>
                                                                <option value="6Y">NICA</option>
                                                                <option value="WT">NIGERIA AIRWAYS LTD.</option>
                                                                <option value="KZ">NIPPON CARGO AIRLINES</option>
                                                                <option value="5N">NORDAVIA RA SMARTAVIA</option>
                                                                <option value="NA">NORTH AMERICAN</option>
                                                                <option value="J3">NORTHWESTERN AIRWAYS</option>
                                                                <option value="DY">NORWEGIAN</option>
                                                                <option value="BJ">NOUVELAIR</option>
                                                                <option value="NV">NTW AIR</option>
                                                                <option value="9K">NANTUCKET</option>
                                                                <option value="NO">NEOSAIR</option>
                                                                <option value="RA">NEPAL AIRWAYS</option>
                                                                <option value="NP">NILE AIR</option>
                                                                <option value="DD">NOK AIR</option>
                                                                <option value="XW">NOKSCOOT</option>
                                                                <option value="N7">NORDIC REGIONAL</option>
                                                                <option value="N4">NORDWIND AIRLINES</option>
                                                                <option value="HW">NORTH WRIGHT AIR</option>
                                                                <option value="NW">NORTHWEST  INC.</option>
                                                                <option value="D8">NORWEGIAN AIR INTERNATIONAL</option>
                                                                <option value="DI">NORWEGIAN AIR UK</option>
                                                                <option value="UQ">OCONNOR-MT. GAMBIER S AIRLINES</option>
                                                                <option value="OL">OLT-OSTFRIESISCHE LUFTRANSPORT</option>
                                                                <option value="OA">OLYMPIC AIRWAYS</option>
                                                                <option value="WY">OMAN AVIATION SERVICES CO. (SAOG)</option>
                                                                <option value="X9">OMNI AIR INTERNATIONAL</option>
                                                                <option value="8Q">ONUR AIR TASIMACILIK A/S</option>
                                                                <option value="NQ">ORBI GEORGIAN AIRWAYS</option>
                                                                <option value="RF">ORD AIR CHARTER PTY. LTD</option>
                                                                <option value="OX">ORIENT THAI AIRLINES</option>
                                                                <option value="0X">ORIENT THAI AIRLINES</option>
                                                                <option value="XX">OTHER</option>
                                                                <option value="GX">PACIFIC AIRWAYS CORP (PACIFICAIR)</option>
                                                                <option value="8P">PACIFIC COASTAL AIRLINES LIMITED</option>
                                                                <option value="9J">PACIFIC ISLAND AVIATION</option>
                                                                <option value="2W">PACIFIC MIDLAND AIRLINES LTD.</option>
                                                                <option value="LW">PACIFIC WINGS</option>
                                                                <option value="PK">PAKISTAN INTERNATIONAL AIRLINES</option>
                                                                <option value="PV">PAN AIR LINEAS AEREAS S.A.</option>
                                                                <option value="KW">PAN AMERICAN WORLD AIRWAYS, INC.</option>
                                                                <option value="PQ">PANAFRICAN AIRWAYS</option>
                                                                <option value="BK">PARADISE ISLAND AIRLINES, INC DBA</option>
                                                                <option value="P3">PASSAREDO TRANSPORTES AEREOS</option>
                                                                <option value="P1">PEACE AIR</option>
                                                                <option value="PC">PEGASUS AIRLINES</option>
                                                                <option value="9P">PELANGI AIRWAYS SDN. BHD.</option>
                                                                <option value="7V">PELICAN AIR SERVICES</option>
                                                                <option value="UW">PERIMETER AIRLINES</option>
                                                                <option value="PR">PHILIPPINE AIRLINES, INC.</option>
                                                                <option value="9R">PHUKET AIR</option>
                                                                <option value="9E">PINNACLE</option>
                                                                <option value="PO">POLAR AIR CARGO</option>
                                                                <option value="PH">POLYNESIAN LIMITED</option>
                                                                <option value="PD">PORTER AIRLINES</option>
                                                                <option value="NI">PORTUGALIA-COMPANHIA PORTUGUESA DE</option>
                                                                <option value="T9">PRIME AIR, INC(D.B.A TRANSMERIDIAN)</option>
                                                                <option value="00">PRIVATE PLANE</option>
                                                                <option value="P9">PROAIR</option>
                                                                <option value="HN">PROTHEUS HELICOPTERES</option>
                                                                <option value="PB">PROVINCIAL AIRLINES</option>
                                                                <option value="BO">PT BOURAQ</option>
                                                                <option value="MZ">PT. MERPATI NUSANTARA</option>
                                                                <option value="FV">PULKOVO AVIATION ENTERPRISE</option>
                                                                <option value="BL">PACIFIC</option>
                                                                <option value="MM">PEACH AVIATION LIMITED</option>
                                                                <option value="KS">PENAIR</option>
                                                                <option value="PE">PEOPLE EXPRESS</option>
                                                                <option value="Z2">PHILIPPINES AIRASIA</option>
                                                                <option value="PU">PLUNA</option>
                                                                <option value="DP">POBEDA AIRLINES</option>
                                                                <option value="PW">PRECISION</option>
                                                                <option value="PF">PRIMERA AIR</option>
                                                                <option value="FB">PROVINCIAL AIRWAYS</option>
                                                                <option value="QF">QANTAS AIRWAYS LTD.</option>
                                                                <option value="QR">QATAR AIRWAYS (W.L.L.)</option>
                                                                <option value="WZ">RED WINGS AIRLINES</option>
                                                                <option value="RV">REEVE ALEUTIAN AIRWAYS INC</option>
                                                                <option value="7S">REGION AIR PTE. LTD.</option>
                                                                <option value="VM">REGIONAL AIRLINES</option>
                                                                <option value="QQ">RENO AIR INC.</option>
                                                                <option value="WE">RHEINTALFLUG SEEWALD</option>
                                                                <option value="GV">RIGA AIRLINES</option>
                                                                <option value="WQ">ROMAVIA</option>
                                                                <option value="AT">ROYAL AIR MAROC</option>
                                                                <option value="QN">ROYAL AVIATION INC</option>
                                                                <option value="BI">ROYAL BRUNEI AIRLINES SDN BHD</option>
                                                                <option value="RJ">ROYAL JORDANIAN</option>
                                                                <option value="ZC">ROYAL SWAZI NATIONAL AIRWAYS CORP.</option>
                                                                <option value="WR">ROYAL TONGAN AIRLINES</option>
                                                                <option value="RD">RYAN INTERNATIONAL</option>
                                                                <option value="FR">RYANAIR LTD.</option>
                                                                <option value="ZL">REGIONAL EXPRESS</option>
                                                                <option value="7R">RUSLINE</option>
                                                                <option value="4Z">SA AIRLINK</option>
                                                                <option value="HZ">SAKHALINSKIE AVIATRASSY</option>
                                                                <option value="E5">SAMARA AIRLINES</option>
                                                                <option value="S3">SANTA BARBARA AIRLINES C.A.</option>
                                                                <option value="N5">SARDAIRLIEN - SOC. COOP. A.R.L.</option>
                                                                <option value="SP">SATA - AIR ACORES</option>
                                                                <option value="S4">SATA INTERNATIONAL</option>
                                                                <option value="SV">SAUDI ARABIAN AIRLINES</option>
                                                                <option value="SK">SCANDINAVIAN AIRLINES SYSTEM (SAS)</option>
                                                                <option value="3L">SCENIC AIR, INC.</option>
                                                                <option value="AW">SCHREINER AIRWAY BV</option>
                                                                <option value="CB">SCOT AIRWAYS</option>
                                                                <option value="BB">SEABORNE AVIATION</option>
                                                                <option value="VC">SERICIOS AVENSA</option>
                                                                <option value="RZ">SERVICIOS AEREAS NATIONALES S.A.</option>
                                                                <option value="FS">SERVICIOS DE TRANSPORTES AEREOS</option>
                                                                <option value="UG">SEVENAIR</option>
                                                                <option value="D2">SEVERSTAL</option>
                                                                <option value="NL">SHAHEEN AIR INTERNATIONAL</option>
                                                                <option value="SC">SHANDONG AIRLINES</option>
                                                                <option value="7Q">SHOROUK AIR</option>
                                                                <option value="3S">SHUSWAP FLIGHT CENTER LTD</option>
                                                                <option value="S5">SHUTTLE AMERICA</option>
                                                                <option value="TB">SHUTTLE INC DBA USAIR SHUTTLE</option>
                                                                <option value="S7">SIBERIA ARLNS</option>
                                                                <option value="3U">SICHUAN AIRLINES</option>
                                                                <option value="LJ">SIERRA NATIONAL AIRLINES</option>
                                                                <option value="SI">SIERRA PACIFIC</option>
                                                                <option value="MI">SILKAIR (S) PTE LTD</option>
                                                                <option value="SQ">SINGAPORE AIRLINES</option>
                                                                <option value="OO">SKY WEST AIRLINES</option>
                                                                <option value="5P">SKYEUROPE AILINES HUNGARY AS</option>
                                                                <option value="5G">SKYSERVICE</option>
                                                                <option value="JZ">SKYWAYS AB</option>
                                                                <option value="6Q">SLOVAK AIRLINES</option>
                                                                <option value="QS">SMART WINGS</option>
                                                                <option value="Q7">SOBELAIR</option>
                                                                <option value="EH">SOCIEDAD ECUATORIANA DE TRANSPORTES</option>
                                                                <option value="PN">SOCIETE NOUVELLE AIR MARTINIQUE</option>
                                                                <option value="OY">SODER AIR</option>
                                                                <option value="8R">SOL LINEAS AEREAS</option>
                                                                <option value="IE">SOLOMON AIRLINES</option>
                                                                <option value="SA">SOUTH AFRICAN AIRWAYS</option>
                                                                <option value="S9">SOUTHEAST AIRLINES</option>
                                                                <option value="SJ">SOUTHERN AIR TRANSPORT</option>
                                                                <option value="WN">SOUTHWEST AIRLINES</option>
                                                                <option value="JK">SPANAIR S.A.</option>
                                                                <option value="SG">SPICEJET</option>
                                                                <option value="NK">SPIRIT AIRLINES</option>
                                                                <option value="UL">SRI LANKAN AIRLINES</option>
                                                                <option value="T8">STA TRANS AFRICAN AIRLINES</option>
                                                                <option value="YD">STATE ENTERPRISE GOMELAVIA</option>
                                                                <option value="NB">STERLING EUROPEAN AIRLINES A/S</option>
                                                                <option value="SD">SUDAN AIRWAYS CO. LTD.</option>
                                                                <option value="BV">SUN AIR</option>
                                                                <option value="EZ">SUN AIR OF SCANDINAVIA</option>
                                                                <option value="SY">SUN COUNTRY AIRLINES</option>
                                                                <option value="XQ">SUN EXPRESS</option>
                                                                <option value="SM">SUN WORLD INTERNATIONAL</option>
                                                                <option value="PI">SUNFLOWER AIRLINES LTD.</option>
                                                                <option value="JX">SUNJET INTERNATIONAL</option>
                                                                <option value="WG">SUNWING</option>
                                                                <option value="K0">SUNWING AIRLINES</option>
                                                                <option value="PY">SURINAM AIRWAYS LTD.</option>
                                                                <option value="08">SWAN AERO</option>
                                                                <option value="S1">SWANBERG AIR</option>
                                                                <option value="HJ">SWEDEWAYS AIR LINES AB</option>
                                                                <option value="LX">SWISS AIR LINES</option>
                                                                <option value="SR">SWISS AIR TRANS CO. LTD. (SWISSAIR)</option>
                                                                <option value="RB">SYRIAN ARAB AIRLINES</option>
                                                                <option value="4Q">SAFI AIRWAYS</option>
                                                                <option value="OV">SALAM AIR</option>
                                                                <option value="9N">SATENA</option>
                                                                <option value="YR">SCENIC  INC.</option>
                                                                <option value="TR">SCOOT TIGERAIR</option>
                                                                <option value="DN">SENEGAL AIRLINES</option>
                                                                <option value="FM">SHANGHAI</option>
                                                                <option value="ZH">SHENZHEN</option>
                                                                <option value="FT">SIEM REAP AIRWAYS</option>
                                                                <option value="H2">SKY AIRLINE</option>
                                                                <option value="SX">SKY WORK AIRLINES</option>
                                                                <option value="RU">SKYKING TURKS AND CAICOS AIRWAYS</option>
                                                                <option value="YG">SOUTH</option>
                                                                <option value="9C">SPRING AIRLINES</option>
                                                                <option value="TW">T'WAY AIR</option>
                                                                <option value="DT">TAAG - LINHAS AEREAS DE ANGOLA</option>
                                                                <option value="TA">TACA INTERNATIONAL AIRLINES, S.A.</option>
                                                                <option value="VR">TACV</option>
                                                                <option value="PZ">TAM - TRANSPORTES AEREOS DEL</option>
                                                                <option value="JJ">TAM AIRLINES</option>
                                                                <option value="GG">TAMAIR</option>
                                                                <option value="EQ">TAME</option>
                                                                <option value="TP">TAP - AIR PORTUGAL</option>
                                                                <option value="RO">TAROM - ROMANIAN AIR TRANSPORT</option>
                                                                <option value="T6">TAVREY AIRCOMPANY</option>
                                                                <option value="TG">THAI AIRWAYS INTERNATIONAL PUBLIC</option>
                                                                <option value="NM">THE MOUNT COOK GROUP LTD.</option>
                                                                <option value="FQ">THOMAS COOK AIRLINE</option>
                                                                <option value="MT">THOMAS COOKE AIRLINES</option>
                                                                <option value="KI">TIME AIR, INC</option>
                                                                <option value="ZT">TITAN AIRWAYS</option>
                                                                <option value="TI">TOL AIR SERVICES, INC</option>
                                                                <option value="FF">TOWER AIR INC.</option>
                                                                <option value="P6">TRANS AIR</option>
                                                                <option value="7T">TRANS COTE INC</option>
                                                                <option value="TL">TRANS MEDITERRANEAN AIRWAYS</option>
                                                                <option value="6N">TRANS TRAVEL</option>
                                                                <option value="UN">TRANSAERO AIRLINES</option>
                                                                <option value="GE">TRANSASIA AIRWAYS</option>
                                                                <option value="HV">TRANSAVIA AIRLINES</option>
                                                                <option value="AL">TRANSAVIAEXPORT CARGO AIRLINES</option>
                                                                <option value="4P">TRANSPORTES AEREAS DEL PERU, S.A.</option>
                                                                <option value="GD">TRANSPORTES AEREOS EJECUTIVOS S.A.</option>
                                                                <option value="TQ">TRANSWEDE AIRWAYS</option>
                                                                <option value="01">TRAVEL CITY (XL LEISURE GROUP)</option>
                                                                <option value="TU">TUNIS AIR</option>
                                                                <option value="QW">TURK &amp; CAICOS AIRWAYS</option>
                                                                <option value="TK">TURKISH AIRLINES INC.</option>
                                                                <option value="U8">TUS AIR</option>
                                                                <option value="T7">TWIN JET</option>
                                                                <option value="VO">TYROLEAN ARWYS</option>
                                                                <option value="7M">TYUMEN ARLNS</option>
                                                                <option value="FO">TASMANIA</option>
                                                                <option value="T2">THAI AIR</option>
                                                                <option value="FD">THAI AIRASIA</option>
                                                                <option value="SL">THAI LION AIR</option>
                                                                <option value="VZ">THAI VIETJET AIR</option>
                                                                <option value="TT">TIGER AIRWAYS</option>
                                                                <option value="IT">TIGERAIR TAIWAN</option>
                                                                <option value="TO">TRANSAVIA FRANCE</option>
                                                                <option value="PM">TROPIC AIR</option>
                                                                <option value="WI">U-LAND AIRLINES</option>
                                                                <option value="PS">UKRAINE INTERNATIONAL AIRLINES</option>
                                                                <option value="UA">UNITED AIRLINES, INC.</option>
                                                                <option value="UV">UNIVERSAL AIRWAYS INC.</option>
                                                                <option value="UZ">UP AIRWAYS</option>
                                                                <option value="5X">UPS</option>
                                                                <option value="U6">URAL AIRLINES</option>
                                                                <option value="US">US AIRWAYS, INC.</option>
                                                                <option value="U5">USA 3000</option>
                                                                <option value="U7">USA JET</option>
                                                                <option value="QU">UTAIR UKRAINE</option>
                                                                <option value="HY">UZBEKISTAN HAVO YULLARY</option>
                                                                <option value="UT">UTAIR</option>
                                                                <option value="J7">VALUJET AIRLINES</option>
                                                                <option value="NJ">VANGUARD AIRLINES</option>
                                                                <option value="RG">VARIG S.A.</option>
                                                                <option value="VP">VIACAO AEREA SAO PAULO, S.A. (VASP)</option>
                                                                <option value="VI">VIEQUES AIR LINK, INC.</option>
                                                                <option value="VN">VIETNAM AIRLINES CORPORATION</option>
                                                                <option value="VK">VIKING AIRLINES</option>
                                                                <option value="VQ">VINTAGE PROPS AND JETS</option>
                                                                <option value="9V">VIP AIR</option>
                                                                <option value="VX">VIRGIN AMERICA</option>
                                                                <option value="VS">VIRGIN ATLANTIC</option>
                                                                <option value="TV">VIRGIN EXPRESS</option>
                                                                <option value="V2">VISION AIRLINES</option>
                                                                <option value="UK">VISTARA</option>
                                                                <option value="VB">VIVAAEROBUS</option>
                                                                <option value="VG">VLM VLAAMSE</option>
                                                                <option value="41">VOLARIS</option>
                                                                <option value="G6">VOLGA AIRLINES</option>
                                                                <option value="V7">VOLOTEA</option>
                                                                <option value="4V">VOYAGEUR AIRWAYS</option>
                                                                <option value="VY">VUELING AIRLINES</option>
                                                                <option value="V4">VIEQUES AIR LINK</option>
                                                                <option value="VJ">VIETJET AIR</option>
                                                                <option value="DJ">VIRGIN AUSTRALIA</option>
                                                                <option value="VA">VIRGIN AUSTRALIA</option>
                                                                <option value="VV">VIVA AIR PERU</option>
                                                                <option value="Y4">VOLARIS</option>
                                                                <option value="WU">WALK IN</option>
                                                                <option value="8O">WEST COAST AIR</option>
                                                                <option value="OE">WESTAIR COMMUTER AIRLINES, INC</option>
                                                                <option value="W7">WESTERN PACIFIC</option>
                                                                <option value="WS">WESTJET AIRLINES LTD.</option>
                                                                <option value="WF">WIDEROES FLYVESELSKAP A/S</option>
                                                                <option value="IV">WIND JET</option>
                                                                <option value="RM">WINGS WEST AIRLINES, INC</option>
                                                                <option value="WM">WINWARD ISLANDS</option>
                                                                <option value="W6">WIZZ AIR</option>
                                                                <option value="WW">WOW AIR</option>
                                                                <option value="PT">WEST AIR SWEDEN</option>
                                                                <option value="P5">WINGO</option>
                                                                <option value="K5">WINGS OF ALASKA</option>
                                                                <option value="WO">WORLD AIRWAYS</option>
                                                                <option value="SE">XL AIRWAYS FRANCE</option>
                                                                <option value="MF">XIAMEN AIR</option>
                                                                <option value="XP">XTRA AIRWAYS</option>
                                                                <option value="IY">YEMENIA YEMEN AIRWAYS</option>
                                                                <option value="4Y">YUTE AIR ALASKA, INC.</option>
                                                                <option value="R3">YAKUTIA AIRLINES</option>
                                                                <option value="OQ">ZAMBIAN EXPRESSS AIRWAYS (1995) LTD</option>
                                                                <option value="Z7">ZIMBABWE EXPRESS AIRLINES</option>
                                                                <option value="C4">ZIMEX AVIATION</option>
                                                                <option value="ZM">ZOOM</option>
                                                                <option value="Z4">ZOOM AIRLINES</option>
                                                                <option value="F3">FLYADEAL</option>
                                                            </Form.Select>
                                                            {errors.onwardDropoffCarrier && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Flight Number<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control className={errors.onwardDropoffFlightNumber && 'is-invalid'} {...register('onwardDropoffFlightNumber', { required: true })} value={onwardDropoffFlightNumber} onChange={(e) => setOnwardDropoffFlightNumber(e.target.value)} />
                                                            {errors.onwardDropoffFlightNumber && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="gx-2 align-items-center">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Terminal</strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={onwardDropoffTerminal} onChange={(e) => setOnwardDropoffTerminal(e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            </>
                                            :
                                            ''
                                            }
                                        </div>
                                        
                                        <div>
                                            {reqObj.isSingleDate && reqObj.isSingleDate === "true" &&
                                            <>
                                            <p><strong className="fn16 blue">Return Details</strong></p>
                                            
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Pickup Point<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={reqObj.dropoffAddress} readOnly />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Dropoff Point<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={reqObj.pickupAddress} readOnly />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            {reqObj.dropoffType === 'Airport' || isInterAirport === 1 ?
                                            <>
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Carrier<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Select className={errors.returnCarrier && 'is-invalid'} {...register('returnCarrier', { required: true })} value={returnCarrier} onChange={(e) => setReturnCarrier(e.target.value)}>
                                                                <option value="">Select Airline</option>
                                                                <option value="1T">1TIME AIRLINE</option>
                                                                <option value="2N">2P</option>
                                                                <option value="7L">A B AIRLINES</option>
                                                                <option value="ZY">ADA AIR</option>
                                                                <option value="II">ADI DOMESTIC AIRLINES</option>
                                                                <option value="JP">ADRIA AIRWAYS-AIRLINE OF SLOVENIA</option>
                                                                <option value="A3">AEGEAN AVIATION</option>
                                                                <option value="WL">AEORPERLAS AIRLINES</option>
                                                                <option value="RE">AER ARANN EXPRESS</option>
                                                                <option value="EI">AER LINGUS LIMITED</option>
                                                                <option value="EE">AERO AIRLINES</option>
                                                                <option value="JR">AERO CALIFORNIA</option>
                                                                <option value="ML">AERO COSTA RICA</option>
                                                                <option value="02">AERO HONDURAS</option>
                                                                <option value="YP">AERO LLOYD</option>
                                                                <option value="Z9">AERO ZAMBIA</option>
                                                                <option value="SU">AEROFLOT-RUSSIAN INTL AIRLINES</option>
                                                                <option value="AR">AEROLINEAS ARGENTINAS</option>
                                                                <option value="BQ">AEROMAR</option>
                                                                <option value="AM">AEROMEXICO</option>
                                                                <option value="5D">AEROMEXICO CONNECT</option>
                                                                <option value="QO">AEROMEXPRESS S.A. DE C.V.</option>
                                                                <option value="PL">AEROPERU - EMPRESA DE TRANSPORTES</option>
                                                                <option value="VH">AEROPOSTAL</option>
                                                                <option value="VE">AEROVIAS VENEZOLANAS, S.A.</option>
                                                                <option value="Y2">AFRICAN JOINT AIR SERVICES</option>
                                                                <option value="8U">AFRIQIHYA AIRWAYS</option>
                                                                <option value="ZI">AIGLE AZUR</option>
                                                                <option value="RK">AIR AFRIQUE</option>
                                                                <option value="AH">AIR ALGERIE</option>
                                                                <option value="A6">AIR ALPS AVIATION</option>
                                                                <option value="G9">AIR ARABIA</option>
                                                                <option value="D7">AIR ASIA</option>
                                                                <option value="4L">AIR ASTANA</option>
                                                                <option value="KC">AIR ASTANA</option>
                                                                <option value="CC">AIR ATLANTA ICELANDIC</option>
                                                                <option value="LU">AIR ATLANTIC DOMINICANA</option>
                                                                <option value="UU">AIR AUSTRAL</option>
                                                                <option value="BT">AIR BALTIC CORPORATION S/A</option>
                                                                <option value="8B">AIR BENIN</option>
                                                                <option value="AB">AIR BERLIN BMBTT &amp; CO LUFTVERKEHR</option>
                                                                <option value="BP">AIR BOTSWANA CORPORATION</option>
                                                                <option value="2J">AIR BURKINA</option>
                                                                <option value="TY">AIR CALEDONIE</option>
                                                                <option value="SB">AIR CALEDONIE INTERNATIONAL</option>
                                                                <option value="AC">AIR CANADA</option>
                                                                <option value="XC">AIR CARRIBBEAN</option>
                                                                <option value="CV">AIR CHATHAMS</option>
                                                                <option value="CA">AIR CHINA INTERNATIONAL CORPORATION</option>
                                                                <option value="YN">AIR CREEBEC</option>
                                                                <option value="UX">AIR EUROPA LINEAS AEREAS, S.A.</option>
                                                                <option value="XV">AIR EXPRESS</option>
                                                                <option value="OF">AIR FINLAND</option>
                                                                <option value="GS">AIR FOYLE LTD/GRANT AVIATION, INC</option>
                                                                <option value="AF">AIR FRANCE</option>
                                                                <option value="GN">AIR GABON</option>
                                                                <option value="DA">AIR GEORGIA</option>
                                                                <option value="ZX">AIR GEORGIAN</option>
                                                                <option value="JG">AIR GREECE</option>
                                                                <option value="LD">AIR HONG KONG</option>
                                                                <option value="NY">AIR ICELAND</option>
                                                                <option value="3H">AIR INUIT</option>
                                                                <option value="I9">AIR ITALY</option>
                                                                <option value="VU">AIR IVOIRE</option>
                                                                <option value="JM">AIR JAMAICA</option>
                                                                <option value="V6">AIR JET</option>
                                                                <option value="9Y">AIR KAZAKSTAN</option>
                                                                <option value="JS">AIR KORYO</option>
                                                                <option value="UE">AIR LA</option>
                                                                <option value="QL">AIR LESOTHO (PTY) LTD.</option>
                                                                <option value="VD">AIR LIBERTE</option>
                                                                <option value="FU">AIR LITTORAL</option>
                                                                <option value="NX">AIR MACAU COMPANY LIMITED</option>
                                                                <option value="MD">AIR MADAGASCAR</option>
                                                                <option value="QM">AIR MALAWI LIMITED</option>
                                                                <option value="L6">AIR MALDIVES LIMITED</option>
                                                                <option value="KM">AIR MALTA</option>
                                                                <option value="CW">AIR MARSHALL ISLANDS, INC.</option>
                                                                <option value="MK">AIR MAURITIUS</option>
                                                                <option value="DR">AIR MEDITERRANEE</option>
                                                                <option value="MC">AIR MOBILITY COMMAND</option>
                                                                <option value="3R">AIR MOLDOVA INTERNATIONAL S.A.</option>
                                                                <option value="9U">AIR MOLDOVA INTL</option>
                                                                <option value="SW">AIR NAMIBIA</option>
                                                                <option value="ON">AIR NAURU</option>
                                                                <option value="N1">AIR NEW GUINEA</option>
                                                                <option value="NZ">AIR NEW ZEALAND LIMITED</option>
                                                                <option value="EL">AIR NIPPON CO. LTD.</option>
                                                                <option value="PX">AIR NIUGINI PTY LIMITED DBA</option>
                                                                <option value="4N">AIR NORTH</option>
                                                                <option value="M3">AIR NORWAY</option>
                                                                <option value="YW">AIR NOSTRUM L.A.M.S.A.</option>
                                                                <option value="QK">AIR NOVA</option>
                                                                <option value="AP">AIR ONE</option>
                                                                <option value="8K">AIR OSTRAVA</option>
                                                                <option value="A7">AIR PLUS COMET</option>
                                                                <option value="RY">AIR RWANDA</option>
                                                                <option value="5W">AIR SAN JUAN/CHARTAIR</option>
                                                                <option value="7W">AIR SASK AVIATION 1991</option>
                                                                <option value="HM">AIR SEYCHELLES LIMITED</option>
                                                                <option value="YI">AIR SHUNSHINE INC.</option>
                                                                <option value="BM">AIR SICILIA</option>
                                                                <option value="4D">AIR SINAI</option>
                                                                <option value="GM">AIR SLOVAKIA BWJ, LTD.</option>
                                                                <option value="CT">AIR SOFIA</option>
                                                                <option value="PJ">AIR ST. PIERRE</option>
                                                                <option value="ZP">AIR ST.THOMAS</option>
                                                                <option value="VT">AIR TAHITI</option>
                                                                <option value="TN">AIR TAHITI NUI</option>
                                                                <option value="TC">AIR TANZANIA CORPORATION</option>
                                                                <option value="YT">AIR TOGO</option>
                                                                <option value="TS">AIR TRANSAT</option>
                                                                <option value="3N">AIR URGA</option>
                                                                <option value="DO">AIR VALLEE S.P.A./AIR VIRGINIA, INC</option>
                                                                <option value="NF">AIR VANUATU (OPERATIONS) LIMITED</option>
                                                                <option value="VL">AIR VIA BULGARIAN AIRWAYS</option>
                                                                <option value="6G">AIR WALES</option>
                                                                <option value="ZW">AIR WISCONSIN</option>
                                                                <option value="RL">AIR WORLD AVIATION</option>
                                                                <option value="QC">AIR ZAIRE</option>
                                                                <option value="UM">AIR ZIMBABWE CORPORATION</option>
                                                                <option value="AI">AIR-INDIA LIMITED</option>
                                                                <option value="A9">AIRCOMPANY AIRZENA GEORGIAN AIRLINE</option>
                                                                <option value="QP">AIRKENYA AVIATION LTD</option>
                                                                <option value="A5">AIRLINAIR</option>
                                                                <option value="ND">AIRLINE PTY LTD</option>
                                                                <option value="RT">AIRLINES OF SOUTH AUSTRALIA</option>
                                                                <option value="FL">AIRTRAN AIRWAYS, INC</option>
                                                                <option value="6L">AKLAK</option>
                                                                <option value="AS">ALASKA AIRLINES INC.</option>
                                                                <option value="KO">ALASKA CENTRAL EXPRESS</option>
                                                                <option value="LV">ALBANIAN AIRLINES MAK S.H.P.K.</option>
                                                                <option value="A2">ALITALIA</option>
                                                                <option value="XM">ALITALIA EXPRESS</option>
                                                                <option value="NH">ALL NIPPON AIRWAYS CO. LTD.</option>
                                                                <option value="G4">ALLEGIANT AIR</option>
                                                                <option value="1O">ALLEGRO</option>
                                                                <option value="CD">ALLIANCE AIR</option>
                                                                <option value="9I">ALLIANCE AIR</option>
                                                                <option value="AQ">ALOHA AIRLINES INC.</option>
                                                                <option value="E8">ALPI EAGLES S.P.A.</option>
                                                                <option value="5A">ALPINE AVIATION, INC.</option>
                                                                <option value="HP">AMERICA WEST AIRLINES INC.</option>
                                                                <option value="AA">AMERICAN AIRLINES</option>
                                                                <option value="AX">AMERICAN CONNECTION</option>
                                                                <option value="MQ">AMERICAN EAGLE</option>
                                                                <option value="MV">AMRENIAN INTERNATIONAL AIRWAYS</option>
                                                                <option value="2V">AMTRACK</option>
                                                                <option value="8G">ANGEL AIRLINES</option>
                                                                <option value="AN">ANSETT AUSTRALIA</option>
                                                                <option value="ZQ">ANSETT NEW ZEALAND</option>
                                                                <option value="HO">ANTINEA AIRLINES</option>
                                                                <option value="IW">AOM-MINERVE S.A.</option>
                                                                <option value="N2">AREOLINEAS INTERNACIONALES</option>
                                                                <option value="FG">ARIANA AFGHAN AIRLINES</option>
                                                                <option value="OR">ARKEFLY</option>
                                                                <option value="IZ">ARKIA - ISRAELI AIRLINES LTD</option>
                                                                <option value="JW">ARROW AIR, INC</option>
                                                                <option value="0Z">ASIANA AIRLINES</option>
                                                                <option value="oZ">ASIANA AIRLINES</option>
                                                                <option value="OZ">ASIANA AIRLINES INC</option>
                                                                <option value="C5">ASOCIADOS LATINOAMERICANOS DE TR</option>
                                                                <option value="9T">ATHABASKA AIRWAYS LTD</option>
                                                                <option value="RC">ATLANTIC AIRWAYS FAROE ISLANDS</option>
                                                                <option value="EV">ATLANTIC SOUTHEAST AIRLINES</option>
                                                                <option value="8A">ATLAS BLUE</option>
                                                                <option value="IQ">AUGSBURG-AIRWAYS GMBH</option>
                                                                <option value="GR">AURIGNY</option>
                                                                <option value="AU">AUSTRAL</option>
                                                                <option value="OS">AUSTRIAN AIRLINES</option>
                                                                <option value="RX">AVIA EXPRESS</option>
                                                                <option value="OC">AVIACION DEL NOROESTE S.A. DE C.V.</option>
                                                                <option value="AO">AVIACION Y COMERCIO S.A. (AVIACO)</option>
                                                                <option value="T5">AVIACOMPANY TURKMENISTAN</option>
                                                                <option value="6A">AVIACSA</option>
                                                                <option value="GU">AVIATECA, S.A.</option>
                                                                <option value="DF">AVIOSARDA S.R.L.</option>
                                                                <option value="2Q">AVITRANS</option>
                                                                <option value="A1">AVRO</option>
                                                                <option value="J2">AZAL AZERBAIJA</option>
                                                                <option value="ZS">AZZURRA AIR S.P.A.</option>
                                                                <option value="9B">ACCESSRAIL</option>
                                                                <option value="31">AEROLINEAS DEL SUR</option>
                                                                <option value="I5">AIR ASIA INDIA</option>
                                                                <option value="ED">AIR BLUE</option>
                                                                <option value="TX">AIR CARAIBES</option>
                                                                <option value="EN">AIR DOLOMITI</option>
                                                                <option value="IX">AIR INDIA EXPRESS</option>
                                                                <option value="IG">AIR ITALY</option>
                                                                <option value="Q9">AIR LIAISON</option>
                                                                <option value="2P">AIR PHILIPPINES</option>
                                                                <option value="GZ">AIR RAROTONGA</option>
                                                                <option value="WB">AIR RWANDA</option>
                                                                <option value="6O">AIR SATELLITE</option>
                                                                <option value="JU">AIR SERBIA</option>
                                                                <option value="6V">AIR VEGAS</option>
                                                                <option value="8T">AIR TENDI</option>
                                                                <option value="AK">AIRASIA X</option>
                                                                <option value="PA">AIRBLUE</option>
                                                                <option value="UJ">ALMASRIA AIRLINES</option>
                                                                <option value="AZ">ALITALIA</option>
                                                                <option value="TZ">AMERICAN TRANS AIR</option>
                                                                <option value="JH">AMERIJET INC.</option>
                                                                <option value="R7">ASERCA</option>
                                                                <option value="5Y">ATLAS AIR</option>
                                                                <option value="KK">ATLASGLOBAL</option>
                                                                <option value="AV">AVIANCA</option>
                                                                <option value="O6">AVIANCA BRAZIL</option>
                                                                <option value="A4">AZIMUTH AIRLINE</option>
                                                                <option value="AD">AZUL LINHAS AEREAS BRASILEIRAS</option>
                                                                <option value="JA">B&amp;H AIRLINES</option>
                                                                <option value="TH">BA CITIEXPRESS</option>
                                                                <option value="UP">BAHAMASAIR</option>
                                                                <option value="LZ">BALKAN - BULGARIAN AIRLINES</option>
                                                                <option value="PG">BANGKOK AIRWAY CO. LTD</option>
                                                                <option value="JV">BEARSKIN AIRLINES</option>
                                                                <option value="4T">BELAIR AIRLINES AG</option>
                                                                <option value="B2">BELAVIA</option>
                                                                <option value="B3">BELLVIEW AIRLINES LTD.</option>
                                                                <option value="CH">BEMIDJI AIRLINES</option>
                                                                <option value="J8">BERJAYA AIR SDN. BHD.</option>
                                                                <option value="GQ">BIG SKY AIRLINES</option>
                                                                <option value="BG">BIMAN BANGLADESH AIRLINES</option>
                                                                <option value="NT">BINTER CANARIAS</option>
                                                                <option value="B1">BIRDY AIRLINES</option>
                                                                <option value="KF">BLUE 1</option>
                                                                <option value="JO">BLUE AIR</option>
                                                                <option value="0B">BLUE AIR</option>
                                                                <option value="BC">BLUE ISLANDS</option>
                                                                <option value="6B">BLUE SCANDINAVA AB/BAXTER AVIATION</option>
                                                                <option value="5Q">BQB LINEAS AEREAS</option>
                                                                <option value="B7">BRA</option>
                                                                <option value="BU">BRAATHENS SOUTH AMERICAN AND</option>
                                                                <option value="DB">BRIT AIR</option>
                                                                <option value="BH">BRITANNIA</option>
                                                                <option value="BF">BRITANY FERRIES</option>
                                                                <option value="BA">BRITISH AIRWAYS P.L.C.</option>
                                                                <option value="RR">BRITISH ARMY</option>
                                                                <option value="BD">BRITISH MIDLAND AIRWAYS LTD.</option>
                                                                <option value="BY">BRITTANNIA AIRWAYS LTD.</option>
                                                                <option value="SN">BRUSSELS AIRLINES</option>
                                                                <option value="U4">BURYAT AIRLINE</option>
                                                                <option value="5E">BUSINESS AVIATION SVCS EINDHOVEN</option>
                                                                <option value="ID">BATIK AIR</option>
                                                                <option value="OB">BOLIVIANA DE AVIACION</option>
                                                                <option value="1X">BRANSON AIR EXPRESS</option>
                                                                <option value="BS">BRITISH HELICOPTERS</option>
                                                                <option value="CK">CALEDONIAN AIRLINES</option>
                                                                <option value="K6">CAMBODIA ANGKOR AIR</option>
                                                                <option value="UY">CAMEROON AIRLINES</option>
                                                                <option value="FH">CAMPANIA HISPANO IRLANDESA DE AVIA</option>
                                                                <option value="2T">CANADA 3000 AIRLINE LIMITED</option>
                                                                <option value="CP">CANADIAN AIRLINES INTL LIMITED</option>
                                                                <option value="5T">CANADIAN NORTH AIRLINE</option>
                                                                <option value="C6">CANJET AIRLINES</option>
                                                                <option value="NN">CARDINAL AIRLINES LIMITED</option>
                                                                <option value="V3">CARPATAIR</option>
                                                                <option value="CX">CATHAY PACIFIC AIRWAYS LTD.</option>
                                                                <option value="KX">CAYMAN AIRWAYS LTD</option>
                                                                <option value="5J">CEBU PACIFIC AIR</option>
                                                                <option value="9M">CENTRAL MOUNTAIN AIR LTD</option>
                                                                <option value="C0">CENTRALWINGS</option>
                                                                <option value="C1">CFLCO CHARTER</option>
                                                                <option value="M6">CHALAIR</option>
                                                                <option value="MG">CHAMPION AIR</option>
                                                                <option value="RP">CHAUTAUQUA</option>
                                                                <option value="C8">CHICAGO EXPRESS AIRLINES, INC</option>
                                                                <option value="CI">CHINA AIRLINES</option>
                                                                <option value="MU">CHINA EASTERN AIRLINES</option>
                                                                <option value="CJ">CHINA NORTHERN AIRLINES</option>
                                                                <option value="WH">CHINA NORTHWEST AIRLINES</option>
                                                                <option value="CZ">CHINA SOUTHERN AIRLINES</option>
                                                                <option value="SZ">CHINA SOUTHWEST AIRLINES</option>
                                                                <option value="3O">CHINA YUNNAN AIRLINES</option>
                                                                <option value="QI">CIMBER AIR</option>
                                                                <option value="C9">CIRRUS AIR</option>
                                                                <option value="CF">CITY AIRLINE</option>
                                                                <option value="WX">CITYJET</option>
                                                                <option value="XG">CLICKAIR</option>
                                                                <option value="BX">COAST AIR K.S.</option>
                                                                <option value="9L">COLGAN AIR</option>
                                                                <option value="OH">COMAIR, INC</option>
                                                                <option value="XK">COMPAGNIE AERIENNE CORSE</option>
                                                                <option value="E9">COMPAGNIE AFRICAINE D AVIATION</option>
                                                                <option value="5L">COMPANIA BOLIVIANA DE TRANS AEREO</option>
                                                                <option value="DE">CONDOR FLUGDIENST GMBTT</option>
                                                                <option value="EO">CONGO AIRLINES/EXPRESS ONE INTL INC</option>
                                                                <option value="04">CONTINENTAL CONNECTION</option>
                                                                <option value="CS">CONTINENTAL MICRONESIA, INC.</option>
                                                                <option value="3C">CORPORATE AIRLINES</option>
                                                                <option value="C2">CORPORATE EXPRESS</option>
                                                                <option value="SS">CORSE AIR INTERNATIONAL</option>
                                                                <option value="OU">CROATIA AIRLINES</option>
                                                                <option value="X5">CRONUS AIRLINES</option>
                                                                <option value="QE">CROSSAIR</option>
                                                                <option value="CY">CYPRUS AIRWAYS</option>
                                                                <option value="OK">CZECH AIRLINES A.S. , CSA</option>
                                                                <option value="MO">CALM AIR</option>
                                                                <option value="BW">CARIBBEAN</option>
                                                                <option value="DG">CEBGO</option>
                                                                <option value="OP">CHALKS  AIRWAYS</option>
                                                                <option value="CO">COBALT AIR</option>
                                                                <option value="CM">COPA</option>
                                                                <option value="CU">CUBANA</option>
                                                                <option value="YK">CYPRUS TURKISH</option>
                                                                <option value="D3">DAALLO AIRLINES</option>
                                                                <option value="6P">DAC AIR S.A.</option>
                                                                <option value="D4">DAMAL AIRLINES</option>
                                                                <option value="DX">DANISH AIR TRANSPORT</option>
                                                                <option value="V5">DANUBE WINGS</option>
                                                                <option value="7X">DASSAULT AVIATION</option>
                                                                <option value="6X">DASSAULT AVIATION</option>
                                                                <option value="2X">DASSAULT AVIATION</option>
                                                                <option value="2G">DEBONAIR AIRWAYS LTD.</option>
                                                                <option value="DL">DELTA AIR LINES INC.</option>
                                                                <option value="03">DELTA CONNECTION</option>
                                                                <option value="05">DELTA SHUTTLE</option>
                                                                <option value="3D">DENIM AIR</option>
                                                                <option value="D1">DIRECT AIR (MYRTLE BEACH)</option>
                                                                <option value="Z6">DNIEPROAVIA</option>
                                                                <option value="D9">DONAVIA</option>
                                                                <option value="NE">DOWNEAST FLYING SERVICE, INC</option>
                                                                <option value="KB">DRUK AIR</option>
                                                                <option value="7D">DONBASSAERO</option>
                                                                <option value="KA">DRAGONAIR</option>
                                                                <option value="9H">DUTCH ANTILLES EXPRESS</option>
                                                                <option value="ZN">EAGLE AIRLINES</option>
                                                                <option value="FE">EAGLE CANYON AIRLINES INC</option>
                                                                <option value="4S">EAST WEST TRAVEL TRADE LINKS LTD.</option>
                                                                <option value="T3">EASTERN AIRWAYS</option>
                                                                <option value="DK">EASTLAND AIR</option>
                                                                <option value="W9">EASTWIND AIRLINES, INC.</option>
                                                                <option value="U2">EASYJET</option>
                                                                <option value="DS">EASYJET SWITZERLAND SA</option>
                                                                <option value="EU">ECUATORIANA DE AVIACION S.A.</option>
                                                                <option value="MS">EGYPTAIR</option>
                                                                <option value="LY">EL AL ISRAEL AIRLINES LTD.</option>
                                                                <option value="EK">EMIRATES</option>
                                                                <option value="EM">EMPIRE AIRLINES</option>
                                                                <option value="E0">EOS AIRLINES</option>
                                                                <option value="7H">ERA AVIATION</option>
                                                                <option value="ET">ETHIOPIAN AIRLINES ENTERPRISE</option>
                                                                <option value="EY">ETIHAD AIRLINE</option>
                                                                <option value="RN">EURALAIR</option>
                                                                <option value="UI">EUROCYPRIA AIRLINES</option>
                                                                <option value="GJ">EUROFLY</option>
                                                                <option value="5O">EUROPE AIRPOST</option>
                                                                <option value="E7">EUROPEAN AIR</option>
                                                                <option value="EA">EUROPEAN AIR EXPRESS</option>
                                                                <option value="QY">EUROPEAN AIR TRANSPORT</option>
                                                                <option value="2H">EUROPEAN AIRLINES</option>
                                                                <option value="9F">EUROSTAR (U.K.) LTD</option>
                                                                <option value="EW">EUROWINGS AG</option>
                                                                <option value="BR">EVA AIRWAYS CORPORATION</option>
                                                                <option value="XE">EXPRESS JET</option>
                                                                <option value="EX">EXPRESS ONE - SW</option>
                                                                <option value="ZE">EASTAR JET</option>
                                                                <option value="WK">EDELWEISS AIR</option>
                                                                <option value="E4">ENTER AIR</option>
                                                                <option value="EG">ERNEST AIRLINES</option>
                                                                <option value="ES">ESTELAR</option>
                                                                <option value="4X">F AIRLINES B.V.</option>
                                                                <option value="LK">FAIRLINES</option>
                                                                <option value="6F">FALCON AIR</option>
                                                                <option value="F2">FALCON AIR EXPRESS, INC.</option>
                                                                <option value="IH">FALCON AVIATION AB</option>
                                                                <option value="FC">FALCON EXPRESS CARGO AIRLINES/TAL</option>
                                                                <option value="FX">FEDEX</option>
                                                                <option value="AY">FINNAIR OY</option>
                                                                <option value="7F">FIRST AIR</option>
                                                                <option value="8F">FISCHER AIR</option>
                                                                <option value="8N">FLAGSHIP AIRLINES, INC DBA AMERICAN</option>
                                                                <option value="FK">FLAMENCO AIRWAYS, INC.</option>
                                                                <option value="OT">FLIGHT SAFETY</option>
                                                                <option value="YC">FLIGHT WEST AIRLINES PTY LTD.</option>
                                                                <option value="BE">FLY BE</option>
                                                                <option value="SH">FLY ME SWEEDEN AB</option>
                                                                <option value="HG">FLY NIKI</option>
                                                                <option value="F7">FLYBABOO</option>
                                                                <option value="FZ">FLYDUBAI</option>
                                                                <option value="HK">FOUR STAR AVIATION</option>
                                                                <option value="F9">FRONTIER AIRLINES, INC</option>
                                                                <option value="2F">FRONTIER FLYING SERVICE</option>
                                                                <option value="EF">FAR EASTERN AIR TRANSPORT</option>
                                                                <option value="FN">FASTJET</option>
                                                                <option value="FJ">FIJI AIRWAYS</option>
                                                                <option value="FY">FIREFLY</option>
                                                                <option value="F8">FLAIR AIRLINES</option>
                                                                <option value="FA">FLYSAFAIR</option>
                                                                <option value="XY">FLYNAS</option>
                                                                <option value="G7">GANDAS AIRLINES</option>
                                                                <option value="GA">GARUDA INDONESIA</option>
                                                                <option value="GT">GB AIRWAYS LTD.</option>
                                                                <option value="3P">GEORGIAN ARLNS</option>
                                                                <option value="ST">GERMANIA FLUGGESELLSCHAFT/YANDA</option>
                                                                <option value="4U">GERMANWINGS</option>
                                                                <option value="GP">GESTAIR S.A.</option>
                                                                <option value="GH">GHANA AIRWAYS CORP.</option>
                                                                <option value="B4">GLOBESPAN</option>
                                                                <option value="G8">GO AIR</option>
                                                                <option value="06">GO!</option>
                                                                <option value="DC">GOLDEN AIR FLYG AB</option>
                                                                <option value="GW">GOLDEN WEST AIRLINES, INC</option>
                                                                <option value="DV">GORDA AERO SERVICES, INC/ROTTNEST</option>
                                                                <option value="8L">GRAND INTERNATIONAL AIRWAYS, INC.</option>
                                                                <option value="ZK">GREAT LAKES</option>
                                                                <option value="GL">GREENLANDAIR</option>
                                                                <option value="GF">GULF AIR COMPANY G.S.C.</option>
                                                                <option value="3M">GULFSTREAM INTL AIRLINES, INC</option>
                                                                <option value="GY">GUYANA AIRWAYS</option>
                                                                <option value="GC">GAMBIA</option>
                                                                <option value="G3">GOL TRANSPORTES A�REOS</option>
                                                                <option value="GB">GREAT BARRIER</option>
                                                                <option value="HR">HAHN AIR BUSINESSLINE</option>
                                                                <option value="H1">HAHN AIR SYSTEMS</option>
                                                                <option value="HU">HAINAN AIRLINES</option>
                                                                <option value="WD">HAITIAN AVIATION</option>
                                                                <option value="HI">HAMBURG INTERNATIONAL</option>
                                                                <option value="X3">HAPAG LLOYD EXPRESS</option>
                                                                <option value="HF">HAPAG LLOYD FLUGGESELLSCHAFT</option>
                                                                <option value="H3">HARBOUR AIR LTD</option>
                                                                <option value="HQ">HARMONY AIRWAYS</option>
                                                                <option value="HA">HAWAIIAN AIRLINES</option>
                                                                <option value="LE">HELGOLAND ARLNS</option>
                                                                <option value="EC">HELI INTER RIVIERA</option>
                                                                <option value="YO">HELI-AIR-MONACO</option>
                                                                <option value="JB">HELIJET AIRWAYS</option>
                                                                <option value="YQ">HELIKOPTERSERVICE, EUROAIR</option>
                                                                <option value="T4">HELLAS JET</option>
                                                                <option value="2L">HELVETIC</option>
                                                                <option value="DU">HEMUS AIR</option>
                                                                <option value="UD">HEX AIR</option>
                                                                <option value="HS">HIGHLAND AIR AB</option>
                                                                <option value="H9">HIMALAYA AIRLINES</option>
                                                                <option value="HX">HK EXPRESS</option>
                                                                <option value="HD">HOKKAIDO INTERNATIONAL AIRLINES</option>
                                                                <option value="QX">HORIZON AIR</option>
                                                                <option value="KH">HORIZON AIRLINES</option>
                                                                <option value="AG">HUNTING CARGO AIRLINES</option>
                                                                <option value="H6">HAGELAND AVIATION SERVICES INC.</option>
                                                                <option value="4R">HAMBURG</option>
                                                                <option value="MR">HUNNU AIR</option>
                                                                <option value="IB">IBERIA -LINEAS AEREAS DE ESPANA</option>
                                                                <option value="FI">ICELANDAIR</option>
                                                                <option value="6U">ICL EXPRESS</option>
                                                                <option value="IK">IMAIR</option>
                                                                <option value="DH">INDEPENDENCE AIR.</option>
                                                                <option value="IC">INDIAN AIRLINES</option>
                                                                <option value="6E">INDIGO</option>
                                                                <option value="2Y">INDO AIRLINES</option>
                                                                <option value="IM">INTENSIVE AIR (PTY) LTD</option>
                                                                <option value="D6">INTER-AVIATION SVCS DBA INTER-AIR</option>
                                                                <option value="M4">INTERIMPEX - AVIOIMPEX</option>
                                                                <option value="40">INTERJET</option>
                                                                <option value="IR">IRAN AIR</option>
                                                                <option value="B9">IRAN AIR TOURS</option>
                                                                <option value="EP">IRAN ASSEMAN AIRLINES</option>
                                                                <option value="IA">IRAQI AIRWAYS</option>
                                                                <option value="IF">IRISH FERRIES</option>
                                                                <option value="WP">ISLAND AIR</option>
                                                                <option value="IS">ISLAND AIRLINES, INC</option>
                                                                <option value="2S">ISLAND EXPRESS</option>
                                                                <option value="HH">ISLANDFLUG</option>
                                                                <option value="CN">ISLANDS NATIONAIR</option>
                                                                <option value="WC">ISLENA AIRLINES</option>
                                                                <option value="6H">ISRAIR AIRLINES &amp; TOURISM LTD</option>
                                                                <option value="IL">ISTANBUL AIRLINES A.S.</option>
                                                                <option value="B8">ITALAIR</option>
                                                                <option value="I2">IBERIA EXPRESS</option>
                                                                <option value="QZ">INDONESIA AIRASIA</option>
                                                                <option value="XT">INDONESIA AIRASIA X</option>
                                                                <option value="J1">J.M.C.</option>
                                                                <option value="JC">JAL JAPAN AIRLINES</option>
                                                                <option value="NS">JANLIN PTY LTD DBA CAPE NY AIR</option>
                                                                <option value="3X">JAPAN AIR COMMUTER CO. LTD.</option>
                                                                <option value="JD">JAPAN AIR SYSTEM CO., LTD.</option>
                                                                <option value="JL">JAPAN AIRLINES COMPANY LTD.</option>
                                                                <option value="NU">JAPAN TRANSOCEAN AIR CO. LTD.</option>
                                                                <option value="J9">JAZEERA AIRWAYS</option>
                                                                <option value="QD">JC INTERNATIONAL AIRLINES</option>
                                                                <option value="JY">JERSEY EUROPEAN AIRWAYS LTD.</option>
                                                                <option value="9W">JET AIRWAYS (INDIA) LIMITED</option>
                                                                <option value="QJ">JET AIRWAYS, INC.</option>
                                                                <option value="PP">JET AVIATION BUSINESS JETS AG</option>
                                                                <option value="LF">JET TRAIN CORPORATION</option>
                                                                <option value="LS">JET2</option>
                                                                <option value="8J">JET4YOU</option>
                                                                <option value="B6">JETBLUE AIRWAYS</option>
                                                                <option value="2U">JOINT STOCK COMPANY AIR CO. KARAT</option>
                                                                <option value="J5">JOINT-STOCK COMPANY SOCHI AIRLINE</option>
                                                                <option value="7C">JEJU AIR CO. LTD.</option>
                                                                <option value="S2">JET LITE</option>
                                                                <option value="JQ">JETSTAR AIRWAYS</option>
                                                                <option value="3K">JETSTAR ASIA AIRWAYS</option>
                                                                <option value="GK">JETSTAR JAPAN</option>
                                                                <option value="3J">JUBBA AIRWAYS</option>
                                                                <option value="KT">KAMPUCHEA AIRLINES</option>
                                                                <option value="5R">KARTHAGO AIRLINES</option>
                                                                <option value="K4">KAZAKHSTAN AIRLINES</option>
                                                                <option value="KD">KD AVIA</option>
                                                                <option value="KQ">KENYA AIRWAYS</option>
                                                                <option value="BZ">KEYSTONE AIR SERVICE LTD.</option>
                                                                <option value="Y9">KISH AIR</option>
                                                                <option value="KP">KIWI INTERNATIONAL AIR LINES</option>
                                                                <option value="WA">KLM CITYHOPPER</option>
                                                                <option value="KL">KLM ROYAL DUTCH AIRLINES</option>
                                                                <option value="KE">KOREAN AIR LINES CO. LTD.</option>
                                                                <option value="6K">KORSAR ARLNS</option>
                                                                <option value="KU">KUWAIT AIRWAYS</option>
                                                                <option value="RQ">KAM AIR</option>
                                                                <option value="MN">KULULA</option>
                                                                <option value="JF">L.A.B. FLYING SERVICE INC</option>
                                                                <option value="7Z">L.B. LIMITED</option>
                                                                <option value="WJ">LABRADOR AIRWAYS</option>
                                                                <option value="UC">LADECO S.A. D/B/A LADECO AIRLINES</option>
                                                                <option value="TM">LAM - LINHAS AEREAS DE MOCAMBIQUE</option>
                                                                <option value="4M">LAN ARGENTINA</option>
                                                                <option value="XL">LAN ECUADOR</option>
                                                                <option value="BN">LANDAIR INTERNATIONAL AIRLINES</option>
                                                                <option value="OI">LANDMARK AVIATION</option>
                                                                <option value="ZZ">LANE AVIATION</option>
                                                                <option value="LP">LANPERU</option>
                                                                <option value="QV">LAO AVIATION</option>
                                                                <option value="NG">LAUDA AIR LUFTFAHRT AG</option>
                                                                <option value="A0">LAVION</option>
                                                                <option value="LI">LIAT (1974) LTD.</option>
                                                                <option value="LR">LINEAS AEREAS COSTARRICENSES S.A.</option>
                                                                <option value="MJ">LINEAS AEREAS PRIVADAS ARGENTINAS</option>
                                                                <option value="TE">LITHUANIAN AIRLINES</option>
                                                                <option value="LB">LLOYD AEREO BOLIVIANO S.A. (LAB)</option>
                                                                <option value="LC">LOGANAIR</option>
                                                                <option value="LO">LOT - POLISH AIRLINES</option>
                                                                <option value="XU">LOTUS AIRLINE (TAS)</option>
                                                                <option value="XO">LTE INTERNATIONAL</option>
                                                                <option value="LT">LTU INTERNATIONAL AIRWAYS</option>
                                                                <option value="CL">LUFTHANSA CITYLINE GMBH</option>
                                                                <option value="LG">LUXAIR</option>
                                                                <option value="XR">LUXOR AIR (LXO)</option>
                                                                <option value="L7">LVIV AIRLINES</option>
                                                                <option value="L1">LYNX</option>
                                                                <option value="LA">LATAM AIRLINES</option>
                                                                <option value="LN">LIBYAN ARAB</option>
                                                                <option value="Z8">LINEA AEREA AMASZONAS</option>
                                                                <option value="P8">LINHAS AEREAS</option>
                                                                <option value="JT">LION AIR</option>
                                                                <option value="LM">LOGANAIR</option>
                                                                <option value="HE">LUFTFAHRT GESELLSCHAFT</option>
                                                                <option value="LH">LUFTHANSA</option>
                                                                <option value="IN">MACEDONIAN AIRLINES</option>
                                                                <option value="DM">MAERSK AIR A/S</option>
                                                                <option value="H5">MAGADAN AIRLINES</option>
                                                                <option value="W5">MAHAN AIR</option>
                                                                <option value="MH">MALAYSIAN AIRLINE</option>
                                                                <option value="MA">MALEV HUNGARIAN AIRLINES PUBLIC LTD</option>
                                                                <option value="TF">MALMO AVIATION</option>
                                                                <option value="AE">MANDARIN AIRLINES</option>
                                                                <option value="GO">MARITIME GLOBAL AIRLINES</option>
                                                                <option value="MP">MARTINAIR HOLLAND N.V.</option>
                                                                <option value="6M">MAVERICK AIRWAYS CORPORATION</option>
                                                                <option value="MW">MAYA AIRWAYS</option>
                                                                <option value="YV">MESA AIRLINES</option>
                                                                <option value="XJ">MESABA AIRLINES</option>
                                                                <option value="OW">METAVIA AIRLINES (PTY) LTD</option>
                                                                <option value="MX">MEXICANA DE AVIACION</option>
                                                                <option value="LL">MIAMI AIR INTERNATIONAL</option>
                                                                <option value="OM">MIAT-MONGOLIAN</option>
                                                                <option value="ME">MIDDLE EAST AIRLINES AIRLIBAN</option>
                                                                <option value="JI">MIDWAY AIRLINES</option>
                                                                <option value="YX">MIDWEST AIRLINES</option>
                                                                <option value="ZV">MIDWEST EXPRESS</option>
                                                                <option value="YF">MILITARY CHARTER</option>
                                                                <option value="N3">MIRANIA ROMANIAN</option>
                                                                <option value="MB">MNG AIRLIENS CARGO/EXECAIRE INC</option>
                                                                <option value="5Z">MOKULELE FLIGHT SERVICE</option>
                                                                <option value="2M">MOLDAVIAN ARLNS</option>
                                                                <option value="ZB">MONARCH AIRLINES</option>
                                                                <option value="YM">MONTENEGRO AIRLINES</option>
                                                                <option value="ZR">MUK AIR</option>
                                                                <option value="8I">MY WAY AIRLINES</option>
                                                                <option value="UB">MYANMAR ARWYS INTL</option>
                                                                <option value="OD">MALINDO AIRWAYS</option>
                                                                <option value="RI">MANDALA</option>
                                                                <option value="JE">MANGO AIRLINES</option>
                                                                <option value="MY">MAYA ISLAND AIR</option>
                                                                <option value="HC">NASKE-AIR</option>
                                                                <option value="K7">NATIONAL AIRCOMPANY SAKHA AVIA</option>
                                                                <option value="YJ">NATIONAL AIRLINES CHILE</option>
                                                                <option value="NC">NATIONAL JET SYSTEMS PTY/NORTHERN</option>
                                                                <option value="CE">NATIONWIDE AIRLINES (PTY) LTD</option>
                                                                <option value="5C">NATURE AIR</option>
                                                                <option value="1I">NETJETS AVIATION</option>
                                                                <option value="9X">NEW AXIS AIRWAYS</option>
                                                                <option value="EJ">NEW ENGLAND AIRLINES</option>
                                                                <option value="6Y">NICA</option>
                                                                <option value="WT">NIGERIA AIRWAYS LTD.</option>
                                                                <option value="KZ">NIPPON CARGO AIRLINES</option>
                                                                <option value="5N">NORDAVIA RA SMARTAVIA</option>
                                                                <option value="NA">NORTH AMERICAN</option>
                                                                <option value="J3">NORTHWESTERN AIRWAYS</option>
                                                                <option value="DY">NORWEGIAN</option>
                                                                <option value="BJ">NOUVELAIR</option>
                                                                <option value="NV">NTW AIR</option>
                                                                <option value="9K">NANTUCKET</option>
                                                                <option value="NO">NEOSAIR</option>
                                                                <option value="RA">NEPAL AIRWAYS</option>
                                                                <option value="NP">NILE AIR</option>
                                                                <option value="DD">NOK AIR</option>
                                                                <option value="XW">NOKSCOOT</option>
                                                                <option value="N7">NORDIC REGIONAL</option>
                                                                <option value="N4">NORDWIND AIRLINES</option>
                                                                <option value="HW">NORTH WRIGHT AIR</option>
                                                                <option value="NW">NORTHWEST  INC.</option>
                                                                <option value="D8">NORWEGIAN AIR INTERNATIONAL</option>
                                                                <option value="DI">NORWEGIAN AIR UK</option>
                                                                <option value="UQ">OCONNOR-MT. GAMBIER S AIRLINES</option>
                                                                <option value="OL">OLT-OSTFRIESISCHE LUFTRANSPORT</option>
                                                                <option value="OA">OLYMPIC AIRWAYS</option>
                                                                <option value="WY">OMAN AVIATION SERVICES CO. (SAOG)</option>
                                                                <option value="X9">OMNI AIR INTERNATIONAL</option>
                                                                <option value="8Q">ONUR AIR TASIMACILIK A/S</option>
                                                                <option value="NQ">ORBI GEORGIAN AIRWAYS</option>
                                                                <option value="RF">ORD AIR CHARTER PTY. LTD</option>
                                                                <option value="OX">ORIENT THAI AIRLINES</option>
                                                                <option value="0X">ORIENT THAI AIRLINES</option>
                                                                <option value="XX">OTHER</option>
                                                                <option value="GX">PACIFIC AIRWAYS CORP (PACIFICAIR)</option>
                                                                <option value="8P">PACIFIC COASTAL AIRLINES LIMITED</option>
                                                                <option value="9J">PACIFIC ISLAND AVIATION</option>
                                                                <option value="2W">PACIFIC MIDLAND AIRLINES LTD.</option>
                                                                <option value="LW">PACIFIC WINGS</option>
                                                                <option value="PK">PAKISTAN INTERNATIONAL AIRLINES</option>
                                                                <option value="PV">PAN AIR LINEAS AEREAS S.A.</option>
                                                                <option value="KW">PAN AMERICAN WORLD AIRWAYS, INC.</option>
                                                                <option value="PQ">PANAFRICAN AIRWAYS</option>
                                                                <option value="BK">PARADISE ISLAND AIRLINES, INC DBA</option>
                                                                <option value="P3">PASSAREDO TRANSPORTES AEREOS</option>
                                                                <option value="P1">PEACE AIR</option>
                                                                <option value="PC">PEGASUS AIRLINES</option>
                                                                <option value="9P">PELANGI AIRWAYS SDN. BHD.</option>
                                                                <option value="7V">PELICAN AIR SERVICES</option>
                                                                <option value="UW">PERIMETER AIRLINES</option>
                                                                <option value="PR">PHILIPPINE AIRLINES, INC.</option>
                                                                <option value="9R">PHUKET AIR</option>
                                                                <option value="9E">PINNACLE</option>
                                                                <option value="PO">POLAR AIR CARGO</option>
                                                                <option value="PH">POLYNESIAN LIMITED</option>
                                                                <option value="PD">PORTER AIRLINES</option>
                                                                <option value="NI">PORTUGALIA-COMPANHIA PORTUGUESA DE</option>
                                                                <option value="T9">PRIME AIR, INC(D.B.A TRANSMERIDIAN)</option>
                                                                <option value="00">PRIVATE PLANE</option>
                                                                <option value="P9">PROAIR</option>
                                                                <option value="HN">PROTHEUS HELICOPTERES</option>
                                                                <option value="PB">PROVINCIAL AIRLINES</option>
                                                                <option value="BO">PT BOURAQ</option>
                                                                <option value="MZ">PT. MERPATI NUSANTARA</option>
                                                                <option value="FV">PULKOVO AVIATION ENTERPRISE</option>
                                                                <option value="BL">PACIFIC</option>
                                                                <option value="MM">PEACH AVIATION LIMITED</option>
                                                                <option value="KS">PENAIR</option>
                                                                <option value="PE">PEOPLE EXPRESS</option>
                                                                <option value="Z2">PHILIPPINES AIRASIA</option>
                                                                <option value="PU">PLUNA</option>
                                                                <option value="DP">POBEDA AIRLINES</option>
                                                                <option value="PW">PRECISION</option>
                                                                <option value="PF">PRIMERA AIR</option>
                                                                <option value="FB">PROVINCIAL AIRWAYS</option>
                                                                <option value="QF">QANTAS AIRWAYS LTD.</option>
                                                                <option value="QR">QATAR AIRWAYS (W.L.L.)</option>
                                                                <option value="WZ">RED WINGS AIRLINES</option>
                                                                <option value="RV">REEVE ALEUTIAN AIRWAYS INC</option>
                                                                <option value="7S">REGION AIR PTE. LTD.</option>
                                                                <option value="VM">REGIONAL AIRLINES</option>
                                                                <option value="QQ">RENO AIR INC.</option>
                                                                <option value="WE">RHEINTALFLUG SEEWALD</option>
                                                                <option value="GV">RIGA AIRLINES</option>
                                                                <option value="WQ">ROMAVIA</option>
                                                                <option value="AT">ROYAL AIR MAROC</option>
                                                                <option value="QN">ROYAL AVIATION INC</option>
                                                                <option value="BI">ROYAL BRUNEI AIRLINES SDN BHD</option>
                                                                <option value="RJ">ROYAL JORDANIAN</option>
                                                                <option value="ZC">ROYAL SWAZI NATIONAL AIRWAYS CORP.</option>
                                                                <option value="WR">ROYAL TONGAN AIRLINES</option>
                                                                <option value="RD">RYAN INTERNATIONAL</option>
                                                                <option value="FR">RYANAIR LTD.</option>
                                                                <option value="ZL">REGIONAL EXPRESS</option>
                                                                <option value="7R">RUSLINE</option>
                                                                <option value="4Z">SA AIRLINK</option>
                                                                <option value="HZ">SAKHALINSKIE AVIATRASSY</option>
                                                                <option value="E5">SAMARA AIRLINES</option>
                                                                <option value="S3">SANTA BARBARA AIRLINES C.A.</option>
                                                                <option value="N5">SARDAIRLIEN - SOC. COOP. A.R.L.</option>
                                                                <option value="SP">SATA - AIR ACORES</option>
                                                                <option value="S4">SATA INTERNATIONAL</option>
                                                                <option value="SV">SAUDI ARABIAN AIRLINES</option>
                                                                <option value="SK">SCANDINAVIAN AIRLINES SYSTEM (SAS)</option>
                                                                <option value="3L">SCENIC AIR, INC.</option>
                                                                <option value="AW">SCHREINER AIRWAY BV</option>
                                                                <option value="CB">SCOT AIRWAYS</option>
                                                                <option value="BB">SEABORNE AVIATION</option>
                                                                <option value="VC">SERICIOS AVENSA</option>
                                                                <option value="RZ">SERVICIOS AEREAS NATIONALES S.A.</option>
                                                                <option value="FS">SERVICIOS DE TRANSPORTES AEREOS</option>
                                                                <option value="UG">SEVENAIR</option>
                                                                <option value="D2">SEVERSTAL</option>
                                                                <option value="NL">SHAHEEN AIR INTERNATIONAL</option>
                                                                <option value="SC">SHANDONG AIRLINES</option>
                                                                <option value="7Q">SHOROUK AIR</option>
                                                                <option value="3S">SHUSWAP FLIGHT CENTER LTD</option>
                                                                <option value="S5">SHUTTLE AMERICA</option>
                                                                <option value="TB">SHUTTLE INC DBA USAIR SHUTTLE</option>
                                                                <option value="S7">SIBERIA ARLNS</option>
                                                                <option value="3U">SICHUAN AIRLINES</option>
                                                                <option value="LJ">SIERRA NATIONAL AIRLINES</option>
                                                                <option value="SI">SIERRA PACIFIC</option>
                                                                <option value="MI">SILKAIR (S) PTE LTD</option>
                                                                <option value="SQ">SINGAPORE AIRLINES</option>
                                                                <option value="OO">SKY WEST AIRLINES</option>
                                                                <option value="5P">SKYEUROPE AILINES HUNGARY AS</option>
                                                                <option value="5G">SKYSERVICE</option>
                                                                <option value="JZ">SKYWAYS AB</option>
                                                                <option value="6Q">SLOVAK AIRLINES</option>
                                                                <option value="QS">SMART WINGS</option>
                                                                <option value="Q7">SOBELAIR</option>
                                                                <option value="EH">SOCIEDAD ECUATORIANA DE TRANSPORTES</option>
                                                                <option value="PN">SOCIETE NOUVELLE AIR MARTINIQUE</option>
                                                                <option value="OY">SODER AIR</option>
                                                                <option value="8R">SOL LINEAS AEREAS</option>
                                                                <option value="IE">SOLOMON AIRLINES</option>
                                                                <option value="SA">SOUTH AFRICAN AIRWAYS</option>
                                                                <option value="S9">SOUTHEAST AIRLINES</option>
                                                                <option value="SJ">SOUTHERN AIR TRANSPORT</option>
                                                                <option value="WN">SOUTHWEST AIRLINES</option>
                                                                <option value="JK">SPANAIR S.A.</option>
                                                                <option value="SG">SPICEJET</option>
                                                                <option value="NK">SPIRIT AIRLINES</option>
                                                                <option value="UL">SRI LANKAN AIRLINES</option>
                                                                <option value="T8">STA TRANS AFRICAN AIRLINES</option>
                                                                <option value="YD">STATE ENTERPRISE GOMELAVIA</option>
                                                                <option value="NB">STERLING EUROPEAN AIRLINES A/S</option>
                                                                <option value="SD">SUDAN AIRWAYS CO. LTD.</option>
                                                                <option value="BV">SUN AIR</option>
                                                                <option value="EZ">SUN AIR OF SCANDINAVIA</option>
                                                                <option value="SY">SUN COUNTRY AIRLINES</option>
                                                                <option value="XQ">SUN EXPRESS</option>
                                                                <option value="SM">SUN WORLD INTERNATIONAL</option>
                                                                <option value="PI">SUNFLOWER AIRLINES LTD.</option>
                                                                <option value="JX">SUNJET INTERNATIONAL</option>
                                                                <option value="WG">SUNWING</option>
                                                                <option value="K0">SUNWING AIRLINES</option>
                                                                <option value="PY">SURINAM AIRWAYS LTD.</option>
                                                                <option value="08">SWAN AERO</option>
                                                                <option value="S1">SWANBERG AIR</option>
                                                                <option value="HJ">SWEDEWAYS AIR LINES AB</option>
                                                                <option value="LX">SWISS AIR LINES</option>
                                                                <option value="SR">SWISS AIR TRANS CO. LTD. (SWISSAIR)</option>
                                                                <option value="RB">SYRIAN ARAB AIRLINES</option>
                                                                <option value="4Q">SAFI AIRWAYS</option>
                                                                <option value="OV">SALAM AIR</option>
                                                                <option value="9N">SATENA</option>
                                                                <option value="YR">SCENIC  INC.</option>
                                                                <option value="TR">SCOOT TIGERAIR</option>
                                                                <option value="DN">SENEGAL AIRLINES</option>
                                                                <option value="FM">SHANGHAI</option>
                                                                <option value="ZH">SHENZHEN</option>
                                                                <option value="FT">SIEM REAP AIRWAYS</option>
                                                                <option value="H2">SKY AIRLINE</option>
                                                                <option value="SX">SKY WORK AIRLINES</option>
                                                                <option value="RU">SKYKING TURKS AND CAICOS AIRWAYS</option>
                                                                <option value="YG">SOUTH</option>
                                                                <option value="9C">SPRING AIRLINES</option>
                                                                <option value="TW">T'WAY AIR</option>
                                                                <option value="DT">TAAG - LINHAS AEREAS DE ANGOLA</option>
                                                                <option value="TA">TACA INTERNATIONAL AIRLINES, S.A.</option>
                                                                <option value="VR">TACV</option>
                                                                <option value="PZ">TAM - TRANSPORTES AEREOS DEL</option>
                                                                <option value="JJ">TAM AIRLINES</option>
                                                                <option value="GG">TAMAIR</option>
                                                                <option value="EQ">TAME</option>
                                                                <option value="TP">TAP - AIR PORTUGAL</option>
                                                                <option value="RO">TAROM - ROMANIAN AIR TRANSPORT</option>
                                                                <option value="T6">TAVREY AIRCOMPANY</option>
                                                                <option value="TG">THAI AIRWAYS INTERNATIONAL PUBLIC</option>
                                                                <option value="NM">THE MOUNT COOK GROUP LTD.</option>
                                                                <option value="FQ">THOMAS COOK AIRLINE</option>
                                                                <option value="MT">THOMAS COOKE AIRLINES</option>
                                                                <option value="KI">TIME AIR, INC</option>
                                                                <option value="ZT">TITAN AIRWAYS</option>
                                                                <option value="TI">TOL AIR SERVICES, INC</option>
                                                                <option value="FF">TOWER AIR INC.</option>
                                                                <option value="P6">TRANS AIR</option>
                                                                <option value="7T">TRANS COTE INC</option>
                                                                <option value="TL">TRANS MEDITERRANEAN AIRWAYS</option>
                                                                <option value="6N">TRANS TRAVEL</option>
                                                                <option value="UN">TRANSAERO AIRLINES</option>
                                                                <option value="GE">TRANSASIA AIRWAYS</option>
                                                                <option value="HV">TRANSAVIA AIRLINES</option>
                                                                <option value="AL">TRANSAVIAEXPORT CARGO AIRLINES</option>
                                                                <option value="4P">TRANSPORTES AEREAS DEL PERU, S.A.</option>
                                                                <option value="GD">TRANSPORTES AEREOS EJECUTIVOS S.A.</option>
                                                                <option value="TQ">TRANSWEDE AIRWAYS</option>
                                                                <option value="01">TRAVEL CITY (XL LEISURE GROUP)</option>
                                                                <option value="TU">TUNIS AIR</option>
                                                                <option value="QW">TURK &amp; CAICOS AIRWAYS</option>
                                                                <option value="TK">TURKISH AIRLINES INC.</option>
                                                                <option value="U8">TUS AIR</option>
                                                                <option value="T7">TWIN JET</option>
                                                                <option value="VO">TYROLEAN ARWYS</option>
                                                                <option value="7M">TYUMEN ARLNS</option>
                                                                <option value="FO">TASMANIA</option>
                                                                <option value="T2">THAI AIR</option>
                                                                <option value="FD">THAI AIRASIA</option>
                                                                <option value="SL">THAI LION AIR</option>
                                                                <option value="VZ">THAI VIETJET AIR</option>
                                                                <option value="TT">TIGER AIRWAYS</option>
                                                                <option value="IT">TIGERAIR TAIWAN</option>
                                                                <option value="TO">TRANSAVIA FRANCE</option>
                                                                <option value="PM">TROPIC AIR</option>
                                                                <option value="WI">U-LAND AIRLINES</option>
                                                                <option value="PS">UKRAINE INTERNATIONAL AIRLINES</option>
                                                                <option value="UA">UNITED AIRLINES, INC.</option>
                                                                <option value="UV">UNIVERSAL AIRWAYS INC.</option>
                                                                <option value="UZ">UP AIRWAYS</option>
                                                                <option value="5X">UPS</option>
                                                                <option value="U6">URAL AIRLINES</option>
                                                                <option value="US">US AIRWAYS, INC.</option>
                                                                <option value="U5">USA 3000</option>
                                                                <option value="U7">USA JET</option>
                                                                <option value="QU">UTAIR UKRAINE</option>
                                                                <option value="HY">UZBEKISTAN HAVO YULLARY</option>
                                                                <option value="UT">UTAIR</option>
                                                                <option value="J7">VALUJET AIRLINES</option>
                                                                <option value="NJ">VANGUARD AIRLINES</option>
                                                                <option value="RG">VARIG S.A.</option>
                                                                <option value="VP">VIACAO AEREA SAO PAULO, S.A. (VASP)</option>
                                                                <option value="VI">VIEQUES AIR LINK, INC.</option>
                                                                <option value="VN">VIETNAM AIRLINES CORPORATION</option>
                                                                <option value="VK">VIKING AIRLINES</option>
                                                                <option value="VQ">VINTAGE PROPS AND JETS</option>
                                                                <option value="9V">VIP AIR</option>
                                                                <option value="VX">VIRGIN AMERICA</option>
                                                                <option value="VS">VIRGIN ATLANTIC</option>
                                                                <option value="TV">VIRGIN EXPRESS</option>
                                                                <option value="V2">VISION AIRLINES</option>
                                                                <option value="UK">VISTARA</option>
                                                                <option value="VB">VIVAAEROBUS</option>
                                                                <option value="VG">VLM VLAAMSE</option>
                                                                <option value="41">VOLARIS</option>
                                                                <option value="G6">VOLGA AIRLINES</option>
                                                                <option value="V7">VOLOTEA</option>
                                                                <option value="4V">VOYAGEUR AIRWAYS</option>
                                                                <option value="VY">VUELING AIRLINES</option>
                                                                <option value="V4">VIEQUES AIR LINK</option>
                                                                <option value="VJ">VIETJET AIR</option>
                                                                <option value="DJ">VIRGIN AUSTRALIA</option>
                                                                <option value="VA">VIRGIN AUSTRALIA</option>
                                                                <option value="VV">VIVA AIR PERU</option>
                                                                <option value="Y4">VOLARIS</option>
                                                                <option value="WU">WALK IN</option>
                                                                <option value="8O">WEST COAST AIR</option>
                                                                <option value="OE">WESTAIR COMMUTER AIRLINES, INC</option>
                                                                <option value="W7">WESTERN PACIFIC</option>
                                                                <option value="WS">WESTJET AIRLINES LTD.</option>
                                                                <option value="WF">WIDEROES FLYVESELSKAP A/S</option>
                                                                <option value="IV">WIND JET</option>
                                                                <option value="RM">WINGS WEST AIRLINES, INC</option>
                                                                <option value="WM">WINWARD ISLANDS</option>
                                                                <option value="W6">WIZZ AIR</option>
                                                                <option value="WW">WOW AIR</option>
                                                                <option value="PT">WEST AIR SWEDEN</option>
                                                                <option value="P5">WINGO</option>
                                                                <option value="K5">WINGS OF ALASKA</option>
                                                                <option value="WO">WORLD AIRWAYS</option>
                                                                <option value="SE">XL AIRWAYS FRANCE</option>
                                                                <option value="MF">XIAMEN AIR</option>
                                                                <option value="XP">XTRA AIRWAYS</option>
                                                                <option value="IY">YEMENIA YEMEN AIRWAYS</option>
                                                                <option value="4Y">YUTE AIR ALASKA, INC.</option>
                                                                <option value="R3">YAKUTIA AIRLINES</option>
                                                                <option value="OQ">ZAMBIAN EXPRESSS AIRWAYS (1995) LTD</option>
                                                                <option value="Z7">ZIMBABWE EXPRESS AIRLINES</option>
                                                                <option value="C4">ZIMEX AVIATION</option>
                                                                <option value="ZM">ZOOM</option>
                                                                <option value="Z4">ZOOM AIRLINES</option>
                                                                <option value="F3">FLYADEAL</option>
                                                            </Form.Select>
                                                            {errors.returnCarrier && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Flight Number<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control className={errors.returnFlightNumber && 'is-invalid'} {...register('returnFlightNumber', {required:true})} value={returnFlightNumber} onChange={(e) => setReturnFlightNumber(e.target.value)} />
                                                            {errors.returnFlightNumber && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="gx-2 align-items-center">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Terminal</strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={returnTerminal} onChange={(e) => setReturnTerminal(e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            </>
                                            :
                                            ''
                                            }

                                            {reqObj.dropoffType === 'RailStation' || isInterRailStation == 1 &&
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Train Number<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control className={errors.returnTrainNumber && 'is-invalid'} {...register('returnTrainNumber', {required:true})} value={returnTrainNumber} onChange={(e) => setReturnTrainNumber(e.target.value)} />
                                                            {errors.returnTrainNumber && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Wagon Number</strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={returnWagonNumber} onChange={(e) => setReturnWagonNumber(e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            }

                                            {reqObj.pickupType === 'Airport' || reqObj.pickupType === 'RailStation' || isInterRailStation === 1 || isInterAirport === 1 ?
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>{arrivalType()} {reqObj.pickupType !== 'Airport' && reqObj.pickupType !== 'RailStation' ? 'Dropoff' : 'Departure'} Date<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <InputGroup>
                                                                <input type="hidden" {...register('returnDropoffDate', {required:true})} value={returnDropoffDate !== null ? returnDropoffDate:''} />
                                                                <DatePicker className={'form-control radius-right-0 ' + (errors.returnDropoffDate && 'is-invalid')} locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={returnDropoffDate} minDate={new Date(new Date(reqObj.returnDate).setDate(new Date(reqObj.returnDate).getDate() - 0))} maxDate={new Date(new Date(reqObj.returnDate).setDate(new Date(reqObj.returnDate).getDate() + 1))} onChange={ (date) => setReturnDropoffDate(date)} />
                                                                <InputGroup.Text className="bg-white fn14 green"><FontAwesomeIcon icon={solid('calendar-alt')} /></InputGroup.Text>
                                                            </InputGroup>
                                                            {errors.returnDropoffDate && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>{arrivalType()} {reqObj.pickupType !== 'Airport' && reqObj.pickupType !== 'RailStation' ? 'Dropoff' : 'Departure'} Time<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Select className={errors.returnDropoffTime && 'is-invalid'} {...register('returnDropoffTime', {required:true})} value={returnDropoffTime} onChange={(e) => setReturnDropoffTime(e.target.value)}>
                                                                <option value="">Choose Time</option>
                                                                <option value="0:0">0:0</option>
                                                                <option value="0:5">0:5</option>
                                                                <option value="0:10">0:10</option>
                                                                <option value="0:15">0:15</option>
                                                                <option value="0:20">0:20</option>
                                                                <option value="0:25">0:25</option>
                                                                <option value="0:30">0:30</option>
                                                                <option value="0:35">0:35</option>
                                                                <option value="0:40">0:40</option>
                                                                <option value="0:45">0:45</option>
                                                                <option value="0:50">0:50</option>
                                                                <option value="0:55">0:55</option>
                                                                <option value="1:0">1:0</option>
                                                                <option value="1:5">1:5</option>
                                                                <option value="1:10">1:10</option>
                                                                <option value="1:15">1:15</option>
                                                                <option value="1:20">1:20</option>
                                                                <option value="1:25">1:25</option>
                                                                <option value="1:30">1:30</option>
                                                                <option value="1:35">1:35</option>
                                                                <option value="1:40">1:40</option>
                                                                <option value="1:45">1:45</option>
                                                                <option value="1:50">1:50</option>
                                                                <option value="1:55">1:55</option>
                                                                <option value="2:0">2:0</option>
                                                                <option value="2:5">2:5</option>
                                                                <option value="2:10">2:10</option>
                                                                <option value="2:15">2:15</option>
                                                                <option value="2:20">2:20</option>
                                                                <option value="2:25">2:25</option>
                                                                <option value="2:30">2:30</option>
                                                                <option value="2:35">2:35</option>
                                                                <option value="2:40">2:40</option>
                                                                <option value="2:45">2:45</option>
                                                                <option value="2:50">2:50</option>
                                                                <option value="2:55">2:55</option>
                                                                <option value="3:0">3:0</option>
                                                                <option value="3:5">3:5</option>
                                                                <option value="3:10">3:10</option>
                                                                <option value="3:15">3:15</option>
                                                                <option value="3:20">3:20</option>
                                                                <option value="3:25">3:25</option>
                                                                <option value="3:30">3:30</option>
                                                                <option value="3:35">3:35</option>
                                                                <option value="3:40">3:40</option>
                                                                <option value="3:45">3:45</option>
                                                                <option value="3:50">3:50</option>
                                                                <option value="3:55">3:55</option>
                                                                <option value="4:0">4:0</option>
                                                                <option value="4:5">4:5</option>
                                                                <option value="4:10">4:10</option>
                                                                <option value="4:15">4:15</option>
                                                                <option value="4:20">4:20</option>
                                                                <option value="4:25">4:25</option>
                                                                <option value="4:30">4:30</option>
                                                                <option value="4:35">4:35</option>
                                                                <option value="4:40">4:40</option>
                                                                <option value="4:45">4:45</option>
                                                                <option value="4:50">4:50</option>
                                                                <option value="4:55">4:55</option>
                                                                <option value="5:0">5:0</option>
                                                                <option value="5:5">5:5</option>
                                                                <option value="5:10">5:10</option>
                                                                <option value="5:15">5:15</option>
                                                                <option value="5:20">5:20</option>
                                                                <option value="5:25">5:25</option>
                                                                <option value="5:30">5:30</option>
                                                                <option value="5:35">5:35</option>
                                                                <option value="5:40">5:40</option>
                                                                <option value="5:45">5:45</option>
                                                                <option value="5:50">5:50</option>
                                                                <option value="5:55">5:55</option>
                                                                <option value="6:0">6:0</option>
                                                                <option value="6:5">6:5</option>
                                                                <option value="6:10">6:10</option>
                                                                <option value="6:15">6:15</option>
                                                                <option value="6:20">6:20</option>
                                                                <option value="6:25">6:25</option>
                                                                <option value="6:30">6:30</option>
                                                                <option value="6:35">6:35</option>
                                                                <option value="6:40">6:40</option>
                                                                <option value="6:45">6:45</option>
                                                                <option value="6:50">6:50</option>
                                                                <option value="6:55">6:55</option>
                                                                <option value="7:0">7:0</option>
                                                                <option value="7:5">7:5</option>
                                                                <option value="7:10">7:10</option>
                                                                <option value="7:15">7:15</option>
                                                                <option value="7:20">7:20</option>
                                                                <option value="7:25">7:25</option>
                                                                <option value="7:30">7:30</option>
                                                                <option value="7:35">7:35</option>
                                                                <option value="7:40">7:40</option>
                                                                <option value="7:45">7:45</option>
                                                                <option value="7:50">7:50</option>
                                                                <option value="7:55">7:55</option>
                                                                <option value="8:0">8:0</option>
                                                                <option value="8:5">8:5</option>
                                                                <option value="8:10">8:10</option>
                                                                <option value="8:15">8:15</option>
                                                                <option value="8:20">8:20</option>
                                                                <option value="8:25">8:25</option>
                                                                <option value="8:30">8:30</option>
                                                                <option value="8:35">8:35</option>
                                                                <option value="8:40">8:40</option>
                                                                <option value="8:45">8:45</option>
                                                                <option value="8:50">8:50</option>
                                                                <option value="8:55">8:55</option>
                                                                <option value="9:0">9:0</option>
                                                                <option value="9:5">9:5</option>
                                                                <option value="9:10">9:10</option>
                                                                <option value="9:15">9:15</option>
                                                                <option value="9:20">9:20</option>
                                                                <option value="9:25">9:25</option>
                                                                <option value="9:30">9:30</option>
                                                                <option value="9:35">9:35</option>
                                                                <option value="9:40">9:40</option>
                                                                <option value="9:45">9:45</option>
                                                                <option value="9:50">9:50</option>
                                                                <option value="9:55">9:55</option>
                                                                <option value="10:0">10:0</option>
                                                                <option value="10:5">10:5</option>
                                                                <option value="10:10">10:10</option>
                                                                <option value="10:15">10:15</option>
                                                                <option value="10:20">10:20</option>
                                                                <option value="10:25">10:25</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="10:35">10:35</option>
                                                                <option value="10:40">10:40</option>
                                                                <option value="10:45">10:45</option>
                                                                <option value="10:50">10:50</option>
                                                                <option value="10:55">10:55</option>
                                                                <option value="11:0">11:0</option>
                                                                <option value="11:5">11:5</option>
                                                                <option value="11:10">11:10</option>
                                                                <option value="11:15">11:15</option>
                                                                <option value="11:20">11:20</option>
                                                                <option value="11:25">11:25</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="11:35">11:35</option>
                                                                <option value="11:40">11:40</option>
                                                                <option value="11:45">11:45</option>
                                                                <option value="11:50">11:50</option>
                                                                <option value="11:55">11:55</option>
                                                                <option value="12:0">12:0</option>
                                                                <option value="12:5">12:5</option>
                                                                <option value="12:10">12:10</option>
                                                                <option value="12:15">12:15</option>
                                                                <option value="12:20">12:20</option>
                                                                <option value="12:25">12:25</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="12:35">12:35</option>
                                                                <option value="12:40">12:40</option>
                                                                <option value="12:45">12:45</option>
                                                                <option value="12:50">12:50</option>
                                                                <option value="12:55">12:55</option>
                                                                <option value="13:0">13:0</option>
                                                                <option value="13:5">13:5</option>
                                                                <option value="13:10">13:10</option>
                                                                <option value="13:15">13:15</option>
                                                                <option value="13:20">13:20</option>
                                                                <option value="13:25">13:25</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="13:35">13:35</option>
                                                                <option value="13:40">13:40</option>
                                                                <option value="13:45">13:45</option>
                                                                <option value="13:50">13:50</option>
                                                                <option value="13:55">13:55</option>
                                                                <option value="14:0">14:0</option>
                                                                <option value="14:5">14:5</option>
                                                                <option value="14:10">14:10</option>
                                                                <option value="14:15">14:15</option>
                                                                <option value="14:20">14:20</option>
                                                                <option value="14:25">14:25</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="14:35">14:35</option>
                                                                <option value="14:40">14:40</option>
                                                                <option value="14:45">14:45</option>
                                                                <option value="14:50">14:50</option>
                                                                <option value="14:55">14:55</option>
                                                                <option value="15:0">15:0</option>
                                                                <option value="15:5">15:5</option>
                                                                <option value="15:10">15:10</option>
                                                                <option value="15:15">15:15</option>
                                                                <option value="15:20">15:20</option>
                                                                <option value="15:25">15:25</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="15:35">15:35</option>
                                                                <option value="15:40">15:40</option>
                                                                <option value="15:45">15:45</option>
                                                                <option value="15:50">15:50</option>
                                                                <option value="15:55">15:55</option>
                                                                <option value="16:0">16:0</option>
                                                                <option value="16:5">16:5</option>
                                                                <option value="16:10">16:10</option>
                                                                <option value="16:15">16:15</option>
                                                                <option value="16:20">16:20</option>
                                                                <option value="16:25">16:25</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="16:35">16:35</option>
                                                                <option value="16:40">16:40</option>
                                                                <option value="16:45">16:45</option>
                                                                <option value="16:50">16:50</option>
                                                                <option value="16:55">16:55</option>
                                                                <option value="17:0">17:0</option>
                                                                <option value="17:5">17:5</option>
                                                                <option value="17:10">17:10</option>
                                                                <option value="17:15">17:15</option>
                                                                <option value="17:20">17:20</option>
                                                                <option value="17:25">17:25</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="17:35">17:35</option>
                                                                <option value="17:40">17:40</option>
                                                                <option value="17:45">17:45</option>
                                                                <option value="17:50">17:50</option>
                                                                <option value="17:55">17:55</option>
                                                                <option value="18:0">18:0</option>
                                                                <option value="18:5">18:5</option>
                                                                <option value="18:10">18:10</option>
                                                                <option value="18:15">18:15</option>
                                                                <option value="18:20">18:20</option>
                                                                <option value="18:25">18:25</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="18:35">18:35</option>
                                                                <option value="18:40">18:40</option>
                                                                <option value="18:45">18:45</option>
                                                                <option value="18:50">18:50</option>
                                                                <option value="18:55">18:55</option>
                                                                <option value="19:0">19:0</option>
                                                                <option value="19:5">19:5</option>
                                                                <option value="19:10">19:10</option>
                                                                <option value="19:15">19:15</option>
                                                                <option value="19:20">19:20</option>
                                                                <option value="19:25">19:25</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="19:35">19:35</option>
                                                                <option value="19:40">19:40</option>
                                                                <option value="19:45">19:45</option>
                                                                <option value="19:50">19:50</option>
                                                                <option value="19:55">19:55</option>
                                                                <option value="20:0">20:0</option>
                                                                <option value="20:5">20:5</option>
                                                                <option value="20:10">20:10</option>
                                                                <option value="20:15">20:15</option>
                                                                <option value="20:20">20:20</option>
                                                                <option value="20:25">20:25</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="20:35">20:35</option>
                                                                <option value="20:40">20:40</option>
                                                                <option value="20:45">20:45</option>
                                                                <option value="20:50">20:50</option>
                                                                <option value="20:55">20:55</option>
                                                                <option value="21:0">21:0</option>
                                                                <option value="21:5">21:5</option>
                                                                <option value="21:10">21:10</option>
                                                                <option value="21:15">21:15</option>
                                                                <option value="21:20">21:20</option>
                                                                <option value="21:25">21:25</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="21:35">21:35</option>
                                                                <option value="21:40">21:40</option>
                                                                <option value="21:45">21:45</option>
                                                                <option value="21:50">21:50</option>
                                                                <option value="21:55">21:55</option>
                                                                <option value="22:0">22:0</option>
                                                                <option value="22:5">22:5</option>
                                                                <option value="22:10">22:10</option>
                                                                <option value="22:15">22:15</option>
                                                                <option value="22:20">22:20</option>
                                                                <option value="22:25">22:25</option>
                                                                <option value="22:30">22:30</option>
                                                                <option value="22:35">22:35</option>
                                                                <option value="22:40">22:40</option>
                                                                <option value="22:45">22:45</option>
                                                                <option value="22:50">22:50</option>
                                                                <option value="22:55">22:55</option>
                                                                <option value="23:0">23:0</option>
                                                                <option value="23:5">23:5</option>
                                                                <option value="23:10">23:10</option>
                                                                <option value="23:15">23:15</option>
                                                                <option value="23:20">23:20</option>
                                                                <option value="23:25">23:25</option>
                                                                <option value="23:30">23:30</option>
                                                                <option value="23:35">23:35</option>
                                                                <option value="23:40">23:40</option>
                                                                <option value="23:45">23:45</option>
                                                                <option value="23:50">23:50</option>
                                                                <option value="23:55">23:55</option>
                                                            </Form.Select>
                                                            {errors.returnDropoffTime && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            :
                                            ''
                                            }

                                            {reqObj.pickupType === 'Airport' || isInterAirport === 1 ?
                                            <>
                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Carrier<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Select className={errors.returnDropoffCarrier && 'is-invalid'} {...register('returnDropoffCarrier', {required:true})} value={returnDropoffCarrier} onChange={(e) => setReturnDropoffCarrier(e.target.value)}>
                                                                <option value="">Select Airline</option>
                                                                <option value="1T">1TIME AIRLINE</option>
                                                                <option value="2N">2P</option>
                                                                <option value="7L">A B AIRLINES</option>
                                                                <option value="ZY">ADA AIR</option>
                                                                <option value="II">ADI DOMESTIC AIRLINES</option>
                                                                <option value="JP">ADRIA AIRWAYS-AIRLINE OF SLOVENIA</option>
                                                                <option value="A3">AEGEAN AVIATION</option>
                                                                <option value="WL">AEORPERLAS AIRLINES</option>
                                                                <option value="RE">AER ARANN EXPRESS</option>
                                                                <option value="EI">AER LINGUS LIMITED</option>
                                                                <option value="EE">AERO AIRLINES</option>
                                                                <option value="JR">AERO CALIFORNIA</option>
                                                                <option value="ML">AERO COSTA RICA</option>
                                                                <option value="02">AERO HONDURAS</option>
                                                                <option value="YP">AERO LLOYD</option>
                                                                <option value="Z9">AERO ZAMBIA</option>
                                                                <option value="SU">AEROFLOT-RUSSIAN INTL AIRLINES</option>
                                                                <option value="AR">AEROLINEAS ARGENTINAS</option>
                                                                <option value="BQ">AEROMAR</option>
                                                                <option value="AM">AEROMEXICO</option>
                                                                <option value="5D">AEROMEXICO CONNECT</option>
                                                                <option value="QO">AEROMEXPRESS S.A. DE C.V.</option>
                                                                <option value="PL">AEROPERU - EMPRESA DE TRANSPORTES</option>
                                                                <option value="VH">AEROPOSTAL</option>
                                                                <option value="VE">AEROVIAS VENEZOLANAS, S.A.</option>
                                                                <option value="Y2">AFRICAN JOINT AIR SERVICES</option>
                                                                <option value="8U">AFRIQIHYA AIRWAYS</option>
                                                                <option value="ZI">AIGLE AZUR</option>
                                                                <option value="RK">AIR AFRIQUE</option>
                                                                <option value="AH">AIR ALGERIE</option>
                                                                <option value="A6">AIR ALPS AVIATION</option>
                                                                <option value="G9">AIR ARABIA</option>
                                                                <option value="D7">AIR ASIA</option>
                                                                <option value="4L">AIR ASTANA</option>
                                                                <option value="KC">AIR ASTANA</option>
                                                                <option value="CC">AIR ATLANTA ICELANDIC</option>
                                                                <option value="LU">AIR ATLANTIC DOMINICANA</option>
                                                                <option value="UU">AIR AUSTRAL</option>
                                                                <option value="BT">AIR BALTIC CORPORATION S/A</option>
                                                                <option value="8B">AIR BENIN</option>
                                                                <option value="AB">AIR BERLIN BMBTT &amp; CO LUFTVERKEHR</option>
                                                                <option value="BP">AIR BOTSWANA CORPORATION</option>
                                                                <option value="2J">AIR BURKINA</option>
                                                                <option value="TY">AIR CALEDONIE</option>
                                                                <option value="SB">AIR CALEDONIE INTERNATIONAL</option>
                                                                <option value="AC">AIR CANADA</option>
                                                                <option value="XC">AIR CARRIBBEAN</option>
                                                                <option value="CV">AIR CHATHAMS</option>
                                                                <option value="CA">AIR CHINA INTERNATIONAL CORPORATION</option>
                                                                <option value="YN">AIR CREEBEC</option>
                                                                <option value="UX">AIR EUROPA LINEAS AEREAS, S.A.</option>
                                                                <option value="XV">AIR EXPRESS</option>
                                                                <option value="OF">AIR FINLAND</option>
                                                                <option value="GS">AIR FOYLE LTD/GRANT AVIATION, INC</option>
                                                                <option value="AF">AIR FRANCE</option>
                                                                <option value="GN">AIR GABON</option>
                                                                <option value="DA">AIR GEORGIA</option>
                                                                <option value="ZX">AIR GEORGIAN</option>
                                                                <option value="JG">AIR GREECE</option>
                                                                <option value="LD">AIR HONG KONG</option>
                                                                <option value="NY">AIR ICELAND</option>
                                                                <option value="3H">AIR INUIT</option>
                                                                <option value="I9">AIR ITALY</option>
                                                                <option value="VU">AIR IVOIRE</option>
                                                                <option value="JM">AIR JAMAICA</option>
                                                                <option value="V6">AIR JET</option>
                                                                <option value="9Y">AIR KAZAKSTAN</option>
                                                                <option value="JS">AIR KORYO</option>
                                                                <option value="UE">AIR LA</option>
                                                                <option value="QL">AIR LESOTHO (PTY) LTD.</option>
                                                                <option value="VD">AIR LIBERTE</option>
                                                                <option value="FU">AIR LITTORAL</option>
                                                                <option value="NX">AIR MACAU COMPANY LIMITED</option>
                                                                <option value="MD">AIR MADAGASCAR</option>
                                                                <option value="QM">AIR MALAWI LIMITED</option>
                                                                <option value="L6">AIR MALDIVES LIMITED</option>
                                                                <option value="KM">AIR MALTA</option>
                                                                <option value="CW">AIR MARSHALL ISLANDS, INC.</option>
                                                                <option value="MK">AIR MAURITIUS</option>
                                                                <option value="DR">AIR MEDITERRANEE</option>
                                                                <option value="MC">AIR MOBILITY COMMAND</option>
                                                                <option value="3R">AIR MOLDOVA INTERNATIONAL S.A.</option>
                                                                <option value="9U">AIR MOLDOVA INTL</option>
                                                                <option value="SW">AIR NAMIBIA</option>
                                                                <option value="ON">AIR NAURU</option>
                                                                <option value="N1">AIR NEW GUINEA</option>
                                                                <option value="NZ">AIR NEW ZEALAND LIMITED</option>
                                                                <option value="EL">AIR NIPPON CO. LTD.</option>
                                                                <option value="PX">AIR NIUGINI PTY LIMITED DBA</option>
                                                                <option value="4N">AIR NORTH</option>
                                                                <option value="M3">AIR NORWAY</option>
                                                                <option value="YW">AIR NOSTRUM L.A.M.S.A.</option>
                                                                <option value="QK">AIR NOVA</option>
                                                                <option value="AP">AIR ONE</option>
                                                                <option value="8K">AIR OSTRAVA</option>
                                                                <option value="A7">AIR PLUS COMET</option>
                                                                <option value="RY">AIR RWANDA</option>
                                                                <option value="5W">AIR SAN JUAN/CHARTAIR</option>
                                                                <option value="7W">AIR SASK AVIATION 1991</option>
                                                                <option value="HM">AIR SEYCHELLES LIMITED</option>
                                                                <option value="YI">AIR SHUNSHINE INC.</option>
                                                                <option value="BM">AIR SICILIA</option>
                                                                <option value="4D">AIR SINAI</option>
                                                                <option value="GM">AIR SLOVAKIA BWJ, LTD.</option>
                                                                <option value="CT">AIR SOFIA</option>
                                                                <option value="PJ">AIR ST. PIERRE</option>
                                                                <option value="ZP">AIR ST.THOMAS</option>
                                                                <option value="VT">AIR TAHITI</option>
                                                                <option value="TN">AIR TAHITI NUI</option>
                                                                <option value="TC">AIR TANZANIA CORPORATION</option>
                                                                <option value="YT">AIR TOGO</option>
                                                                <option value="TS">AIR TRANSAT</option>
                                                                <option value="3N">AIR URGA</option>
                                                                <option value="DO">AIR VALLEE S.P.A./AIR VIRGINIA, INC</option>
                                                                <option value="NF">AIR VANUATU (OPERATIONS) LIMITED</option>
                                                                <option value="VL">AIR VIA BULGARIAN AIRWAYS</option>
                                                                <option value="6G">AIR WALES</option>
                                                                <option value="ZW">AIR WISCONSIN</option>
                                                                <option value="RL">AIR WORLD AVIATION</option>
                                                                <option value="QC">AIR ZAIRE</option>
                                                                <option value="UM">AIR ZIMBABWE CORPORATION</option>
                                                                <option value="AI">AIR-INDIA LIMITED</option>
                                                                <option value="A9">AIRCOMPANY AIRZENA GEORGIAN AIRLINE</option>
                                                                <option value="QP">AIRKENYA AVIATION LTD</option>
                                                                <option value="A5">AIRLINAIR</option>
                                                                <option value="ND">AIRLINE PTY LTD</option>
                                                                <option value="RT">AIRLINES OF SOUTH AUSTRALIA</option>
                                                                <option value="FL">AIRTRAN AIRWAYS, INC</option>
                                                                <option value="6L">AKLAK</option>
                                                                <option value="AS">ALASKA AIRLINES INC.</option>
                                                                <option value="KO">ALASKA CENTRAL EXPRESS</option>
                                                                <option value="LV">ALBANIAN AIRLINES MAK S.H.P.K.</option>
                                                                <option value="A2">ALITALIA</option>
                                                                <option value="XM">ALITALIA EXPRESS</option>
                                                                <option value="NH">ALL NIPPON AIRWAYS CO. LTD.</option>
                                                                <option value="G4">ALLEGIANT AIR</option>
                                                                <option value="1O">ALLEGRO</option>
                                                                <option value="CD">ALLIANCE AIR</option>
                                                                <option value="9I">ALLIANCE AIR</option>
                                                                <option value="AQ">ALOHA AIRLINES INC.</option>
                                                                <option value="E8">ALPI EAGLES S.P.A.</option>
                                                                <option value="5A">ALPINE AVIATION, INC.</option>
                                                                <option value="HP">AMERICA WEST AIRLINES INC.</option>
                                                                <option value="AA">AMERICAN AIRLINES</option>
                                                                <option value="AX">AMERICAN CONNECTION</option>
                                                                <option value="MQ">AMERICAN EAGLE</option>
                                                                <option value="MV">AMRENIAN INTERNATIONAL AIRWAYS</option>
                                                                <option value="2V">AMTRACK</option>
                                                                <option value="8G">ANGEL AIRLINES</option>
                                                                <option value="AN">ANSETT AUSTRALIA</option>
                                                                <option value="ZQ">ANSETT NEW ZEALAND</option>
                                                                <option value="HO">ANTINEA AIRLINES</option>
                                                                <option value="IW">AOM-MINERVE S.A.</option>
                                                                <option value="N2">AREOLINEAS INTERNACIONALES</option>
                                                                <option value="FG">ARIANA AFGHAN AIRLINES</option>
                                                                <option value="OR">ARKEFLY</option>
                                                                <option value="IZ">ARKIA - ISRAELI AIRLINES LTD</option>
                                                                <option value="JW">ARROW AIR, INC</option>
                                                                <option value="0Z">ASIANA AIRLINES</option>
                                                                <option value="oZ">ASIANA AIRLINES</option>
                                                                <option value="OZ">ASIANA AIRLINES INC</option>
                                                                <option value="C5">ASOCIADOS LATINOAMERICANOS DE TR</option>
                                                                <option value="9T">ATHABASKA AIRWAYS LTD</option>
                                                                <option value="RC">ATLANTIC AIRWAYS FAROE ISLANDS</option>
                                                                <option value="EV">ATLANTIC SOUTHEAST AIRLINES</option>
                                                                <option value="8A">ATLAS BLUE</option>
                                                                <option value="IQ">AUGSBURG-AIRWAYS GMBH</option>
                                                                <option value="GR">AURIGNY</option>
                                                                <option value="AU">AUSTRAL</option>
                                                                <option value="OS">AUSTRIAN AIRLINES</option>
                                                                <option value="RX">AVIA EXPRESS</option>
                                                                <option value="OC">AVIACION DEL NOROESTE S.A. DE C.V.</option>
                                                                <option value="AO">AVIACION Y COMERCIO S.A. (AVIACO)</option>
                                                                <option value="T5">AVIACOMPANY TURKMENISTAN</option>
                                                                <option value="6A">AVIACSA</option>
                                                                <option value="GU">AVIATECA, S.A.</option>
                                                                <option value="DF">AVIOSARDA S.R.L.</option>
                                                                <option value="2Q">AVITRANS</option>
                                                                <option value="A1">AVRO</option>
                                                                <option value="J2">AZAL AZERBAIJA</option>
                                                                <option value="ZS">AZZURRA AIR S.P.A.</option>
                                                                <option value="9B">ACCESSRAIL</option>
                                                                <option value="31">AEROLINEAS DEL SUR</option>
                                                                <option value="I5">AIR ASIA INDIA</option>
                                                                <option value="ED">AIR BLUE</option>
                                                                <option value="TX">AIR CARAIBES</option>
                                                                <option value="EN">AIR DOLOMITI</option>
                                                                <option value="IX">AIR INDIA EXPRESS</option>
                                                                <option value="IG">AIR ITALY</option>
                                                                <option value="Q9">AIR LIAISON</option>
                                                                <option value="2P">AIR PHILIPPINES</option>
                                                                <option value="GZ">AIR RAROTONGA</option>
                                                                <option value="WB">AIR RWANDA</option>
                                                                <option value="6O">AIR SATELLITE</option>
                                                                <option value="JU">AIR SERBIA</option>
                                                                <option value="6V">AIR VEGAS</option>
                                                                <option value="8T">AIR TENDI</option>
                                                                <option value="AK">AIRASIA X</option>
                                                                <option value="PA">AIRBLUE</option>
                                                                <option value="UJ">ALMASRIA AIRLINES</option>
                                                                <option value="AZ">ALITALIA</option>
                                                                <option value="TZ">AMERICAN TRANS AIR</option>
                                                                <option value="JH">AMERIJET INC.</option>
                                                                <option value="R7">ASERCA</option>
                                                                <option value="5Y">ATLAS AIR</option>
                                                                <option value="KK">ATLASGLOBAL</option>
                                                                <option value="AV">AVIANCA</option>
                                                                <option value="O6">AVIANCA BRAZIL</option>
                                                                <option value="A4">AZIMUTH AIRLINE</option>
                                                                <option value="AD">AZUL LINHAS AEREAS BRASILEIRAS</option>
                                                                <option value="JA">B&amp;H AIRLINES</option>
                                                                <option value="TH">BA CITIEXPRESS</option>
                                                                <option value="UP">BAHAMASAIR</option>
                                                                <option value="LZ">BALKAN - BULGARIAN AIRLINES</option>
                                                                <option value="PG">BANGKOK AIRWAY CO. LTD</option>
                                                                <option value="JV">BEARSKIN AIRLINES</option>
                                                                <option value="4T">BELAIR AIRLINES AG</option>
                                                                <option value="B2">BELAVIA</option>
                                                                <option value="B3">BELLVIEW AIRLINES LTD.</option>
                                                                <option value="CH">BEMIDJI AIRLINES</option>
                                                                <option value="J8">BERJAYA AIR SDN. BHD.</option>
                                                                <option value="GQ">BIG SKY AIRLINES</option>
                                                                <option value="BG">BIMAN BANGLADESH AIRLINES</option>
                                                                <option value="NT">BINTER CANARIAS</option>
                                                                <option value="B1">BIRDY AIRLINES</option>
                                                                <option value="KF">BLUE 1</option>
                                                                <option value="JO">BLUE AIR</option>
                                                                <option value="0B">BLUE AIR</option>
                                                                <option value="BC">BLUE ISLANDS</option>
                                                                <option value="6B">BLUE SCANDINAVA AB/BAXTER AVIATION</option>
                                                                <option value="5Q">BQB LINEAS AEREAS</option>
                                                                <option value="B7">BRA</option>
                                                                <option value="BU">BRAATHENS SOUTH AMERICAN AND</option>
                                                                <option value="DB">BRIT AIR</option>
                                                                <option value="BH">BRITANNIA</option>
                                                                <option value="BF">BRITANY FERRIES</option>
                                                                <option value="BA">BRITISH AIRWAYS P.L.C.</option>
                                                                <option value="RR">BRITISH ARMY</option>
                                                                <option value="BD">BRITISH MIDLAND AIRWAYS LTD.</option>
                                                                <option value="BY">BRITTANNIA AIRWAYS LTD.</option>
                                                                <option value="SN">BRUSSELS AIRLINES</option>
                                                                <option value="U4">BURYAT AIRLINE</option>
                                                                <option value="5E">BUSINESS AVIATION SVCS EINDHOVEN</option>
                                                                <option value="ID">BATIK AIR</option>
                                                                <option value="OB">BOLIVIANA DE AVIACION</option>
                                                                <option value="1X">BRANSON AIR EXPRESS</option>
                                                                <option value="BS">BRITISH HELICOPTERS</option>
                                                                <option value="CK">CALEDONIAN AIRLINES</option>
                                                                <option value="K6">CAMBODIA ANGKOR AIR</option>
                                                                <option value="UY">CAMEROON AIRLINES</option>
                                                                <option value="FH">CAMPANIA HISPANO IRLANDESA DE AVIA</option>
                                                                <option value="2T">CANADA 3000 AIRLINE LIMITED</option>
                                                                <option value="CP">CANADIAN AIRLINES INTL LIMITED</option>
                                                                <option value="5T">CANADIAN NORTH AIRLINE</option>
                                                                <option value="C6">CANJET AIRLINES</option>
                                                                <option value="NN">CARDINAL AIRLINES LIMITED</option>
                                                                <option value="V3">CARPATAIR</option>
                                                                <option value="CX">CATHAY PACIFIC AIRWAYS LTD.</option>
                                                                <option value="KX">CAYMAN AIRWAYS LTD</option>
                                                                <option value="5J">CEBU PACIFIC AIR</option>
                                                                <option value="9M">CENTRAL MOUNTAIN AIR LTD</option>
                                                                <option value="C0">CENTRALWINGS</option>
                                                                <option value="C1">CFLCO CHARTER</option>
                                                                <option value="M6">CHALAIR</option>
                                                                <option value="MG">CHAMPION AIR</option>
                                                                <option value="RP">CHAUTAUQUA</option>
                                                                <option value="C8">CHICAGO EXPRESS AIRLINES, INC</option>
                                                                <option value="CI">CHINA AIRLINES</option>
                                                                <option value="MU">CHINA EASTERN AIRLINES</option>
                                                                <option value="CJ">CHINA NORTHERN AIRLINES</option>
                                                                <option value="WH">CHINA NORTHWEST AIRLINES</option>
                                                                <option value="CZ">CHINA SOUTHERN AIRLINES</option>
                                                                <option value="SZ">CHINA SOUTHWEST AIRLINES</option>
                                                                <option value="3O">CHINA YUNNAN AIRLINES</option>
                                                                <option value="QI">CIMBER AIR</option>
                                                                <option value="C9">CIRRUS AIR</option>
                                                                <option value="CF">CITY AIRLINE</option>
                                                                <option value="WX">CITYJET</option>
                                                                <option value="XG">CLICKAIR</option>
                                                                <option value="BX">COAST AIR K.S.</option>
                                                                <option value="9L">COLGAN AIR</option>
                                                                <option value="OH">COMAIR, INC</option>
                                                                <option value="XK">COMPAGNIE AERIENNE CORSE</option>
                                                                <option value="E9">COMPAGNIE AFRICAINE D AVIATION</option>
                                                                <option value="5L">COMPANIA BOLIVIANA DE TRANS AEREO</option>
                                                                <option value="DE">CONDOR FLUGDIENST GMBTT</option>
                                                                <option value="EO">CONGO AIRLINES/EXPRESS ONE INTL INC</option>
                                                                <option value="04">CONTINENTAL CONNECTION</option>
                                                                <option value="CS">CONTINENTAL MICRONESIA, INC.</option>
                                                                <option value="3C">CORPORATE AIRLINES</option>
                                                                <option value="C2">CORPORATE EXPRESS</option>
                                                                <option value="SS">CORSE AIR INTERNATIONAL</option>
                                                                <option value="OU">CROATIA AIRLINES</option>
                                                                <option value="X5">CRONUS AIRLINES</option>
                                                                <option value="QE">CROSSAIR</option>
                                                                <option value="CY">CYPRUS AIRWAYS</option>
                                                                <option value="OK">CZECH AIRLINES A.S. , CSA</option>
                                                                <option value="MO">CALM AIR</option>
                                                                <option value="BW">CARIBBEAN</option>
                                                                <option value="DG">CEBGO</option>
                                                                <option value="OP">CHALKS  AIRWAYS</option>
                                                                <option value="CO">COBALT AIR</option>
                                                                <option value="CM">COPA</option>
                                                                <option value="CU">CUBANA</option>
                                                                <option value="YK">CYPRUS TURKISH</option>
                                                                <option value="D3">DAALLO AIRLINES</option>
                                                                <option value="6P">DAC AIR S.A.</option>
                                                                <option value="D4">DAMAL AIRLINES</option>
                                                                <option value="DX">DANISH AIR TRANSPORT</option>
                                                                <option value="V5">DANUBE WINGS</option>
                                                                <option value="7X">DASSAULT AVIATION</option>
                                                                <option value="6X">DASSAULT AVIATION</option>
                                                                <option value="2X">DASSAULT AVIATION</option>
                                                                <option value="2G">DEBONAIR AIRWAYS LTD.</option>
                                                                <option value="DL">DELTA AIR LINES INC.</option>
                                                                <option value="03">DELTA CONNECTION</option>
                                                                <option value="05">DELTA SHUTTLE</option>
                                                                <option value="3D">DENIM AIR</option>
                                                                <option value="D1">DIRECT AIR (MYRTLE BEACH)</option>
                                                                <option value="Z6">DNIEPROAVIA</option>
                                                                <option value="D9">DONAVIA</option>
                                                                <option value="NE">DOWNEAST FLYING SERVICE, INC</option>
                                                                <option value="KB">DRUK AIR</option>
                                                                <option value="7D">DONBASSAERO</option>
                                                                <option value="KA">DRAGONAIR</option>
                                                                <option value="9H">DUTCH ANTILLES EXPRESS</option>
                                                                <option value="ZN">EAGLE AIRLINES</option>
                                                                <option value="FE">EAGLE CANYON AIRLINES INC</option>
                                                                <option value="4S">EAST WEST TRAVEL TRADE LINKS LTD.</option>
                                                                <option value="T3">EASTERN AIRWAYS</option>
                                                                <option value="DK">EASTLAND AIR</option>
                                                                <option value="W9">EASTWIND AIRLINES, INC.</option>
                                                                <option value="U2">EASYJET</option>
                                                                <option value="DS">EASYJET SWITZERLAND SA</option>
                                                                <option value="EU">ECUATORIANA DE AVIACION S.A.</option>
                                                                <option value="MS">EGYPTAIR</option>
                                                                <option value="LY">EL AL ISRAEL AIRLINES LTD.</option>
                                                                <option value="EK">EMIRATES</option>
                                                                <option value="EM">EMPIRE AIRLINES</option>
                                                                <option value="E0">EOS AIRLINES</option>
                                                                <option value="7H">ERA AVIATION</option>
                                                                <option value="ET">ETHIOPIAN AIRLINES ENTERPRISE</option>
                                                                <option value="EY">ETIHAD AIRLINE</option>
                                                                <option value="RN">EURALAIR</option>
                                                                <option value="UI">EUROCYPRIA AIRLINES</option>
                                                                <option value="GJ">EUROFLY</option>
                                                                <option value="5O">EUROPE AIRPOST</option>
                                                                <option value="E7">EUROPEAN AIR</option>
                                                                <option value="EA">EUROPEAN AIR EXPRESS</option>
                                                                <option value="QY">EUROPEAN AIR TRANSPORT</option>
                                                                <option value="2H">EUROPEAN AIRLINES</option>
                                                                <option value="9F">EUROSTAR (U.K.) LTD</option>
                                                                <option value="EW">EUROWINGS AG</option>
                                                                <option value="BR">EVA AIRWAYS CORPORATION</option>
                                                                <option value="XE">EXPRESS JET</option>
                                                                <option value="EX">EXPRESS ONE - SW</option>
                                                                <option value="ZE">EASTAR JET</option>
                                                                <option value="WK">EDELWEISS AIR</option>
                                                                <option value="E4">ENTER AIR</option>
                                                                <option value="EG">ERNEST AIRLINES</option>
                                                                <option value="ES">ESTELAR</option>
                                                                <option value="4X">F AIRLINES B.V.</option>
                                                                <option value="LK">FAIRLINES</option>
                                                                <option value="6F">FALCON AIR</option>
                                                                <option value="F2">FALCON AIR EXPRESS, INC.</option>
                                                                <option value="IH">FALCON AVIATION AB</option>
                                                                <option value="FC">FALCON EXPRESS CARGO AIRLINES/TAL</option>
                                                                <option value="FX">FEDEX</option>
                                                                <option value="AY">FINNAIR OY</option>
                                                                <option value="7F">FIRST AIR</option>
                                                                <option value="8F">FISCHER AIR</option>
                                                                <option value="8N">FLAGSHIP AIRLINES, INC DBA AMERICAN</option>
                                                                <option value="FK">FLAMENCO AIRWAYS, INC.</option>
                                                                <option value="OT">FLIGHT SAFETY</option>
                                                                <option value="YC">FLIGHT WEST AIRLINES PTY LTD.</option>
                                                                <option value="BE">FLY BE</option>
                                                                <option value="SH">FLY ME SWEEDEN AB</option>
                                                                <option value="HG">FLY NIKI</option>
                                                                <option value="F7">FLYBABOO</option>
                                                                <option value="FZ">FLYDUBAI</option>
                                                                <option value="HK">FOUR STAR AVIATION</option>
                                                                <option value="F9">FRONTIER AIRLINES, INC</option>
                                                                <option value="2F">FRONTIER FLYING SERVICE</option>
                                                                <option value="EF">FAR EASTERN AIR TRANSPORT</option>
                                                                <option value="FN">FASTJET</option>
                                                                <option value="FJ">FIJI AIRWAYS</option>
                                                                <option value="FY">FIREFLY</option>
                                                                <option value="F8">FLAIR AIRLINES</option>
                                                                <option value="FA">FLYSAFAIR</option>
                                                                <option value="XY">FLYNAS</option>
                                                                <option value="G7">GANDAS AIRLINES</option>
                                                                <option value="GA">GARUDA INDONESIA</option>
                                                                <option value="GT">GB AIRWAYS LTD.</option>
                                                                <option value="3P">GEORGIAN ARLNS</option>
                                                                <option value="ST">GERMANIA FLUGGESELLSCHAFT/YANDA</option>
                                                                <option value="4U">GERMANWINGS</option>
                                                                <option value="GP">GESTAIR S.A.</option>
                                                                <option value="GH">GHANA AIRWAYS CORP.</option>
                                                                <option value="B4">GLOBESPAN</option>
                                                                <option value="G8">GO AIR</option>
                                                                <option value="06">GO!</option>
                                                                <option value="DC">GOLDEN AIR FLYG AB</option>
                                                                <option value="GW">GOLDEN WEST AIRLINES, INC</option>
                                                                <option value="DV">GORDA AERO SERVICES, INC/ROTTNEST</option>
                                                                <option value="8L">GRAND INTERNATIONAL AIRWAYS, INC.</option>
                                                                <option value="ZK">GREAT LAKES</option>
                                                                <option value="GL">GREENLANDAIR</option>
                                                                <option value="GF">GULF AIR COMPANY G.S.C.</option>
                                                                <option value="3M">GULFSTREAM INTL AIRLINES, INC</option>
                                                                <option value="GY">GUYANA AIRWAYS</option>
                                                                <option value="GC">GAMBIA</option>
                                                                <option value="G3">GOL TRANSPORTES A�REOS</option>
                                                                <option value="GB">GREAT BARRIER</option>
                                                                <option value="HR">HAHN AIR BUSINESSLINE</option>
                                                                <option value="H1">HAHN AIR SYSTEMS</option>
                                                                <option value="HU">HAINAN AIRLINES</option>
                                                                <option value="WD">HAITIAN AVIATION</option>
                                                                <option value="HI">HAMBURG INTERNATIONAL</option>
                                                                <option value="X3">HAPAG LLOYD EXPRESS</option>
                                                                <option value="HF">HAPAG LLOYD FLUGGESELLSCHAFT</option>
                                                                <option value="H3">HARBOUR AIR LTD</option>
                                                                <option value="HQ">HARMONY AIRWAYS</option>
                                                                <option value="HA">HAWAIIAN AIRLINES</option>
                                                                <option value="LE">HELGOLAND ARLNS</option>
                                                                <option value="EC">HELI INTER RIVIERA</option>
                                                                <option value="YO">HELI-AIR-MONACO</option>
                                                                <option value="JB">HELIJET AIRWAYS</option>
                                                                <option value="YQ">HELIKOPTERSERVICE, EUROAIR</option>
                                                                <option value="T4">HELLAS JET</option>
                                                                <option value="2L">HELVETIC</option>
                                                                <option value="DU">HEMUS AIR</option>
                                                                <option value="UD">HEX AIR</option>
                                                                <option value="HS">HIGHLAND AIR AB</option>
                                                                <option value="H9">HIMALAYA AIRLINES</option>
                                                                <option value="HX">HK EXPRESS</option>
                                                                <option value="HD">HOKKAIDO INTERNATIONAL AIRLINES</option>
                                                                <option value="QX">HORIZON AIR</option>
                                                                <option value="KH">HORIZON AIRLINES</option>
                                                                <option value="AG">HUNTING CARGO AIRLINES</option>
                                                                <option value="H6">HAGELAND AVIATION SERVICES INC.</option>
                                                                <option value="4R">HAMBURG</option>
                                                                <option value="MR">HUNNU AIR</option>
                                                                <option value="IB">IBERIA -LINEAS AEREAS DE ESPANA</option>
                                                                <option value="FI">ICELANDAIR</option>
                                                                <option value="6U">ICL EXPRESS</option>
                                                                <option value="IK">IMAIR</option>
                                                                <option value="DH">INDEPENDENCE AIR.</option>
                                                                <option value="IC">INDIAN AIRLINES</option>
                                                                <option value="6E">INDIGO</option>
                                                                <option value="2Y">INDO AIRLINES</option>
                                                                <option value="IM">INTENSIVE AIR (PTY) LTD</option>
                                                                <option value="D6">INTER-AVIATION SVCS DBA INTER-AIR</option>
                                                                <option value="M4">INTERIMPEX - AVIOIMPEX</option>
                                                                <option value="40">INTERJET</option>
                                                                <option value="IR">IRAN AIR</option>
                                                                <option value="B9">IRAN AIR TOURS</option>
                                                                <option value="EP">IRAN ASSEMAN AIRLINES</option>
                                                                <option value="IA">IRAQI AIRWAYS</option>
                                                                <option value="IF">IRISH FERRIES</option>
                                                                <option value="WP">ISLAND AIR</option>
                                                                <option value="IS">ISLAND AIRLINES, INC</option>
                                                                <option value="2S">ISLAND EXPRESS</option>
                                                                <option value="HH">ISLANDFLUG</option>
                                                                <option value="CN">ISLANDS NATIONAIR</option>
                                                                <option value="WC">ISLENA AIRLINES</option>
                                                                <option value="6H">ISRAIR AIRLINES &amp; TOURISM LTD</option>
                                                                <option value="IL">ISTANBUL AIRLINES A.S.</option>
                                                                <option value="B8">ITALAIR</option>
                                                                <option value="I2">IBERIA EXPRESS</option>
                                                                <option value="QZ">INDONESIA AIRASIA</option>
                                                                <option value="XT">INDONESIA AIRASIA X</option>
                                                                <option value="J1">J.M.C.</option>
                                                                <option value="JC">JAL JAPAN AIRLINES</option>
                                                                <option value="NS">JANLIN PTY LTD DBA CAPE NY AIR</option>
                                                                <option value="3X">JAPAN AIR COMMUTER CO. LTD.</option>
                                                                <option value="JD">JAPAN AIR SYSTEM CO., LTD.</option>
                                                                <option value="JL">JAPAN AIRLINES COMPANY LTD.</option>
                                                                <option value="NU">JAPAN TRANSOCEAN AIR CO. LTD.</option>
                                                                <option value="J9">JAZEERA AIRWAYS</option>
                                                                <option value="QD">JC INTERNATIONAL AIRLINES</option>
                                                                <option value="JY">JERSEY EUROPEAN AIRWAYS LTD.</option>
                                                                <option value="9W">JET AIRWAYS (INDIA) LIMITED</option>
                                                                <option value="QJ">JET AIRWAYS, INC.</option>
                                                                <option value="PP">JET AVIATION BUSINESS JETS AG</option>
                                                                <option value="LF">JET TRAIN CORPORATION</option>
                                                                <option value="LS">JET2</option>
                                                                <option value="8J">JET4YOU</option>
                                                                <option value="B6">JETBLUE AIRWAYS</option>
                                                                <option value="2U">JOINT STOCK COMPANY AIR CO. KARAT</option>
                                                                <option value="J5">JOINT-STOCK COMPANY SOCHI AIRLINE</option>
                                                                <option value="7C">JEJU AIR CO. LTD.</option>
                                                                <option value="S2">JET LITE</option>
                                                                <option value="JQ">JETSTAR AIRWAYS</option>
                                                                <option value="3K">JETSTAR ASIA AIRWAYS</option>
                                                                <option value="GK">JETSTAR JAPAN</option>
                                                                <option value="3J">JUBBA AIRWAYS</option>
                                                                <option value="KT">KAMPUCHEA AIRLINES</option>
                                                                <option value="5R">KARTHAGO AIRLINES</option>
                                                                <option value="K4">KAZAKHSTAN AIRLINES</option>
                                                                <option value="KD">KD AVIA</option>
                                                                <option value="KQ">KENYA AIRWAYS</option>
                                                                <option value="BZ">KEYSTONE AIR SERVICE LTD.</option>
                                                                <option value="Y9">KISH AIR</option>
                                                                <option value="KP">KIWI INTERNATIONAL AIR LINES</option>
                                                                <option value="WA">KLM CITYHOPPER</option>
                                                                <option value="KL">KLM ROYAL DUTCH AIRLINES</option>
                                                                <option value="KE">KOREAN AIR LINES CO. LTD.</option>
                                                                <option value="6K">KORSAR ARLNS</option>
                                                                <option value="KU">KUWAIT AIRWAYS</option>
                                                                <option value="RQ">KAM AIR</option>
                                                                <option value="MN">KULULA</option>
                                                                <option value="JF">L.A.B. FLYING SERVICE INC</option>
                                                                <option value="7Z">L.B. LIMITED</option>
                                                                <option value="WJ">LABRADOR AIRWAYS</option>
                                                                <option value="UC">LADECO S.A. D/B/A LADECO AIRLINES</option>
                                                                <option value="TM">LAM - LINHAS AEREAS DE MOCAMBIQUE</option>
                                                                <option value="4M">LAN ARGENTINA</option>
                                                                <option value="XL">LAN ECUADOR</option>
                                                                <option value="BN">LANDAIR INTERNATIONAL AIRLINES</option>
                                                                <option value="OI">LANDMARK AVIATION</option>
                                                                <option value="ZZ">LANE AVIATION</option>
                                                                <option value="LP">LANPERU</option>
                                                                <option value="QV">LAO AVIATION</option>
                                                                <option value="NG">LAUDA AIR LUFTFAHRT AG</option>
                                                                <option value="A0">LAVION</option>
                                                                <option value="LI">LIAT (1974) LTD.</option>
                                                                <option value="LR">LINEAS AEREAS COSTARRICENSES S.A.</option>
                                                                <option value="MJ">LINEAS AEREAS PRIVADAS ARGENTINAS</option>
                                                                <option value="TE">LITHUANIAN AIRLINES</option>
                                                                <option value="LB">LLOYD AEREO BOLIVIANO S.A. (LAB)</option>
                                                                <option value="LC">LOGANAIR</option>
                                                                <option value="LO">LOT - POLISH AIRLINES</option>
                                                                <option value="XU">LOTUS AIRLINE (TAS)</option>
                                                                <option value="XO">LTE INTERNATIONAL</option>
                                                                <option value="LT">LTU INTERNATIONAL AIRWAYS</option>
                                                                <option value="CL">LUFTHANSA CITYLINE GMBH</option>
                                                                <option value="LG">LUXAIR</option>
                                                                <option value="XR">LUXOR AIR (LXO)</option>
                                                                <option value="L7">LVIV AIRLINES</option>
                                                                <option value="L1">LYNX</option>
                                                                <option value="LA">LATAM AIRLINES</option>
                                                                <option value="LN">LIBYAN ARAB</option>
                                                                <option value="Z8">LINEA AEREA AMASZONAS</option>
                                                                <option value="P8">LINHAS AEREAS</option>
                                                                <option value="JT">LION AIR</option>
                                                                <option value="LM">LOGANAIR</option>
                                                                <option value="HE">LUFTFAHRT GESELLSCHAFT</option>
                                                                <option value="LH">LUFTHANSA</option>
                                                                <option value="IN">MACEDONIAN AIRLINES</option>
                                                                <option value="DM">MAERSK AIR A/S</option>
                                                                <option value="H5">MAGADAN AIRLINES</option>
                                                                <option value="W5">MAHAN AIR</option>
                                                                <option value="MH">MALAYSIAN AIRLINE</option>
                                                                <option value="MA">MALEV HUNGARIAN AIRLINES PUBLIC LTD</option>
                                                                <option value="TF">MALMO AVIATION</option>
                                                                <option value="AE">MANDARIN AIRLINES</option>
                                                                <option value="GO">MARITIME GLOBAL AIRLINES</option>
                                                                <option value="MP">MARTINAIR HOLLAND N.V.</option>
                                                                <option value="6M">MAVERICK AIRWAYS CORPORATION</option>
                                                                <option value="MW">MAYA AIRWAYS</option>
                                                                <option value="YV">MESA AIRLINES</option>
                                                                <option value="XJ">MESABA AIRLINES</option>
                                                                <option value="OW">METAVIA AIRLINES (PTY) LTD</option>
                                                                <option value="MX">MEXICANA DE AVIACION</option>
                                                                <option value="LL">MIAMI AIR INTERNATIONAL</option>
                                                                <option value="OM">MIAT-MONGOLIAN</option>
                                                                <option value="ME">MIDDLE EAST AIRLINES AIRLIBAN</option>
                                                                <option value="JI">MIDWAY AIRLINES</option>
                                                                <option value="YX">MIDWEST AIRLINES</option>
                                                                <option value="ZV">MIDWEST EXPRESS</option>
                                                                <option value="YF">MILITARY CHARTER</option>
                                                                <option value="N3">MIRANIA ROMANIAN</option>
                                                                <option value="MB">MNG AIRLIENS CARGO/EXECAIRE INC</option>
                                                                <option value="5Z">MOKULELE FLIGHT SERVICE</option>
                                                                <option value="2M">MOLDAVIAN ARLNS</option>
                                                                <option value="ZB">MONARCH AIRLINES</option>
                                                                <option value="YM">MONTENEGRO AIRLINES</option>
                                                                <option value="ZR">MUK AIR</option>
                                                                <option value="8I">MY WAY AIRLINES</option>
                                                                <option value="UB">MYANMAR ARWYS INTL</option>
                                                                <option value="OD">MALINDO AIRWAYS</option>
                                                                <option value="RI">MANDALA</option>
                                                                <option value="JE">MANGO AIRLINES</option>
                                                                <option value="MY">MAYA ISLAND AIR</option>
                                                                <option value="HC">NASKE-AIR</option>
                                                                <option value="K7">NATIONAL AIRCOMPANY SAKHA AVIA</option>
                                                                <option value="YJ">NATIONAL AIRLINES CHILE</option>
                                                                <option value="NC">NATIONAL JET SYSTEMS PTY/NORTHERN</option>
                                                                <option value="CE">NATIONWIDE AIRLINES (PTY) LTD</option>
                                                                <option value="5C">NATURE AIR</option>
                                                                <option value="1I">NETJETS AVIATION</option>
                                                                <option value="9X">NEW AXIS AIRWAYS</option>
                                                                <option value="EJ">NEW ENGLAND AIRLINES</option>
                                                                <option value="6Y">NICA</option>
                                                                <option value="WT">NIGERIA AIRWAYS LTD.</option>
                                                                <option value="KZ">NIPPON CARGO AIRLINES</option>
                                                                <option value="5N">NORDAVIA RA SMARTAVIA</option>
                                                                <option value="NA">NORTH AMERICAN</option>
                                                                <option value="J3">NORTHWESTERN AIRWAYS</option>
                                                                <option value="DY">NORWEGIAN</option>
                                                                <option value="BJ">NOUVELAIR</option>
                                                                <option value="NV">NTW AIR</option>
                                                                <option value="9K">NANTUCKET</option>
                                                                <option value="NO">NEOSAIR</option>
                                                                <option value="RA">NEPAL AIRWAYS</option>
                                                                <option value="NP">NILE AIR</option>
                                                                <option value="DD">NOK AIR</option>
                                                                <option value="XW">NOKSCOOT</option>
                                                                <option value="N7">NORDIC REGIONAL</option>
                                                                <option value="N4">NORDWIND AIRLINES</option>
                                                                <option value="HW">NORTH WRIGHT AIR</option>
                                                                <option value="NW">NORTHWEST  INC.</option>
                                                                <option value="D8">NORWEGIAN AIR INTERNATIONAL</option>
                                                                <option value="DI">NORWEGIAN AIR UK</option>
                                                                <option value="UQ">OCONNOR-MT. GAMBIER S AIRLINES</option>
                                                                <option value="OL">OLT-OSTFRIESISCHE LUFTRANSPORT</option>
                                                                <option value="OA">OLYMPIC AIRWAYS</option>
                                                                <option value="WY">OMAN AVIATION SERVICES CO. (SAOG)</option>
                                                                <option value="X9">OMNI AIR INTERNATIONAL</option>
                                                                <option value="8Q">ONUR AIR TASIMACILIK A/S</option>
                                                                <option value="NQ">ORBI GEORGIAN AIRWAYS</option>
                                                                <option value="RF">ORD AIR CHARTER PTY. LTD</option>
                                                                <option value="OX">ORIENT THAI AIRLINES</option>
                                                                <option value="0X">ORIENT THAI AIRLINES</option>
                                                                <option value="XX">OTHER</option>
                                                                <option value="GX">PACIFIC AIRWAYS CORP (PACIFICAIR)</option>
                                                                <option value="8P">PACIFIC COASTAL AIRLINES LIMITED</option>
                                                                <option value="9J">PACIFIC ISLAND AVIATION</option>
                                                                <option value="2W">PACIFIC MIDLAND AIRLINES LTD.</option>
                                                                <option value="LW">PACIFIC WINGS</option>
                                                                <option value="PK">PAKISTAN INTERNATIONAL AIRLINES</option>
                                                                <option value="PV">PAN AIR LINEAS AEREAS S.A.</option>
                                                                <option value="KW">PAN AMERICAN WORLD AIRWAYS, INC.</option>
                                                                <option value="PQ">PANAFRICAN AIRWAYS</option>
                                                                <option value="BK">PARADISE ISLAND AIRLINES, INC DBA</option>
                                                                <option value="P3">PASSAREDO TRANSPORTES AEREOS</option>
                                                                <option value="P1">PEACE AIR</option>
                                                                <option value="PC">PEGASUS AIRLINES</option>
                                                                <option value="9P">PELANGI AIRWAYS SDN. BHD.</option>
                                                                <option value="7V">PELICAN AIR SERVICES</option>
                                                                <option value="UW">PERIMETER AIRLINES</option>
                                                                <option value="PR">PHILIPPINE AIRLINES, INC.</option>
                                                                <option value="9R">PHUKET AIR</option>
                                                                <option value="9E">PINNACLE</option>
                                                                <option value="PO">POLAR AIR CARGO</option>
                                                                <option value="PH">POLYNESIAN LIMITED</option>
                                                                <option value="PD">PORTER AIRLINES</option>
                                                                <option value="NI">PORTUGALIA-COMPANHIA PORTUGUESA DE</option>
                                                                <option value="T9">PRIME AIR, INC(D.B.A TRANSMERIDIAN)</option>
                                                                <option value="00">PRIVATE PLANE</option>
                                                                <option value="P9">PROAIR</option>
                                                                <option value="HN">PROTHEUS HELICOPTERES</option>
                                                                <option value="PB">PROVINCIAL AIRLINES</option>
                                                                <option value="BO">PT BOURAQ</option>
                                                                <option value="MZ">PT. MERPATI NUSANTARA</option>
                                                                <option value="FV">PULKOVO AVIATION ENTERPRISE</option>
                                                                <option value="BL">PACIFIC</option>
                                                                <option value="MM">PEACH AVIATION LIMITED</option>
                                                                <option value="KS">PENAIR</option>
                                                                <option value="PE">PEOPLE EXPRESS</option>
                                                                <option value="Z2">PHILIPPINES AIRASIA</option>
                                                                <option value="PU">PLUNA</option>
                                                                <option value="DP">POBEDA AIRLINES</option>
                                                                <option value="PW">PRECISION</option>
                                                                <option value="PF">PRIMERA AIR</option>
                                                                <option value="FB">PROVINCIAL AIRWAYS</option>
                                                                <option value="QF">QANTAS AIRWAYS LTD.</option>
                                                                <option value="QR">QATAR AIRWAYS (W.L.L.)</option>
                                                                <option value="WZ">RED WINGS AIRLINES</option>
                                                                <option value="RV">REEVE ALEUTIAN AIRWAYS INC</option>
                                                                <option value="7S">REGION AIR PTE. LTD.</option>
                                                                <option value="VM">REGIONAL AIRLINES</option>
                                                                <option value="QQ">RENO AIR INC.</option>
                                                                <option value="WE">RHEINTALFLUG SEEWALD</option>
                                                                <option value="GV">RIGA AIRLINES</option>
                                                                <option value="WQ">ROMAVIA</option>
                                                                <option value="AT">ROYAL AIR MAROC</option>
                                                                <option value="QN">ROYAL AVIATION INC</option>
                                                                <option value="BI">ROYAL BRUNEI AIRLINES SDN BHD</option>
                                                                <option value="RJ">ROYAL JORDANIAN</option>
                                                                <option value="ZC">ROYAL SWAZI NATIONAL AIRWAYS CORP.</option>
                                                                <option value="WR">ROYAL TONGAN AIRLINES</option>
                                                                <option value="RD">RYAN INTERNATIONAL</option>
                                                                <option value="FR">RYANAIR LTD.</option>
                                                                <option value="ZL">REGIONAL EXPRESS</option>
                                                                <option value="7R">RUSLINE</option>
                                                                <option value="4Z">SA AIRLINK</option>
                                                                <option value="HZ">SAKHALINSKIE AVIATRASSY</option>
                                                                <option value="E5">SAMARA AIRLINES</option>
                                                                <option value="S3">SANTA BARBARA AIRLINES C.A.</option>
                                                                <option value="N5">SARDAIRLIEN - SOC. COOP. A.R.L.</option>
                                                                <option value="SP">SATA - AIR ACORES</option>
                                                                <option value="S4">SATA INTERNATIONAL</option>
                                                                <option value="SV">SAUDI ARABIAN AIRLINES</option>
                                                                <option value="SK">SCANDINAVIAN AIRLINES SYSTEM (SAS)</option>
                                                                <option value="3L">SCENIC AIR, INC.</option>
                                                                <option value="AW">SCHREINER AIRWAY BV</option>
                                                                <option value="CB">SCOT AIRWAYS</option>
                                                                <option value="BB">SEABORNE AVIATION</option>
                                                                <option value="VC">SERICIOS AVENSA</option>
                                                                <option value="RZ">SERVICIOS AEREAS NATIONALES S.A.</option>
                                                                <option value="FS">SERVICIOS DE TRANSPORTES AEREOS</option>
                                                                <option value="UG">SEVENAIR</option>
                                                                <option value="D2">SEVERSTAL</option>
                                                                <option value="NL">SHAHEEN AIR INTERNATIONAL</option>
                                                                <option value="SC">SHANDONG AIRLINES</option>
                                                                <option value="7Q">SHOROUK AIR</option>
                                                                <option value="3S">SHUSWAP FLIGHT CENTER LTD</option>
                                                                <option value="S5">SHUTTLE AMERICA</option>
                                                                <option value="TB">SHUTTLE INC DBA USAIR SHUTTLE</option>
                                                                <option value="S7">SIBERIA ARLNS</option>
                                                                <option value="3U">SICHUAN AIRLINES</option>
                                                                <option value="LJ">SIERRA NATIONAL AIRLINES</option>
                                                                <option value="SI">SIERRA PACIFIC</option>
                                                                <option value="MI">SILKAIR (S) PTE LTD</option>
                                                                <option value="SQ">SINGAPORE AIRLINES</option>
                                                                <option value="OO">SKY WEST AIRLINES</option>
                                                                <option value="5P">SKYEUROPE AILINES HUNGARY AS</option>
                                                                <option value="5G">SKYSERVICE</option>
                                                                <option value="JZ">SKYWAYS AB</option>
                                                                <option value="6Q">SLOVAK AIRLINES</option>
                                                                <option value="QS">SMART WINGS</option>
                                                                <option value="Q7">SOBELAIR</option>
                                                                <option value="EH">SOCIEDAD ECUATORIANA DE TRANSPORTES</option>
                                                                <option value="PN">SOCIETE NOUVELLE AIR MARTINIQUE</option>
                                                                <option value="OY">SODER AIR</option>
                                                                <option value="8R">SOL LINEAS AEREAS</option>
                                                                <option value="IE">SOLOMON AIRLINES</option>
                                                                <option value="SA">SOUTH AFRICAN AIRWAYS</option>
                                                                <option value="S9">SOUTHEAST AIRLINES</option>
                                                                <option value="SJ">SOUTHERN AIR TRANSPORT</option>
                                                                <option value="WN">SOUTHWEST AIRLINES</option>
                                                                <option value="JK">SPANAIR S.A.</option>
                                                                <option value="SG">SPICEJET</option>
                                                                <option value="NK">SPIRIT AIRLINES</option>
                                                                <option value="UL">SRI LANKAN AIRLINES</option>
                                                                <option value="T8">STA TRANS AFRICAN AIRLINES</option>
                                                                <option value="YD">STATE ENTERPRISE GOMELAVIA</option>
                                                                <option value="NB">STERLING EUROPEAN AIRLINES A/S</option>
                                                                <option value="SD">SUDAN AIRWAYS CO. LTD.</option>
                                                                <option value="BV">SUN AIR</option>
                                                                <option value="EZ">SUN AIR OF SCANDINAVIA</option>
                                                                <option value="SY">SUN COUNTRY AIRLINES</option>
                                                                <option value="XQ">SUN EXPRESS</option>
                                                                <option value="SM">SUN WORLD INTERNATIONAL</option>
                                                                <option value="PI">SUNFLOWER AIRLINES LTD.</option>
                                                                <option value="JX">SUNJET INTERNATIONAL</option>
                                                                <option value="WG">SUNWING</option>
                                                                <option value="K0">SUNWING AIRLINES</option>
                                                                <option value="PY">SURINAM AIRWAYS LTD.</option>
                                                                <option value="08">SWAN AERO</option>
                                                                <option value="S1">SWANBERG AIR</option>
                                                                <option value="HJ">SWEDEWAYS AIR LINES AB</option>
                                                                <option value="LX">SWISS AIR LINES</option>
                                                                <option value="SR">SWISS AIR TRANS CO. LTD. (SWISSAIR)</option>
                                                                <option value="RB">SYRIAN ARAB AIRLINES</option>
                                                                <option value="4Q">SAFI AIRWAYS</option>
                                                                <option value="OV">SALAM AIR</option>
                                                                <option value="9N">SATENA</option>
                                                                <option value="YR">SCENIC  INC.</option>
                                                                <option value="TR">SCOOT TIGERAIR</option>
                                                                <option value="DN">SENEGAL AIRLINES</option>
                                                                <option value="FM">SHANGHAI</option>
                                                                <option value="ZH">SHENZHEN</option>
                                                                <option value="FT">SIEM REAP AIRWAYS</option>
                                                                <option value="H2">SKY AIRLINE</option>
                                                                <option value="SX">SKY WORK AIRLINES</option>
                                                                <option value="RU">SKYKING TURKS AND CAICOS AIRWAYS</option>
                                                                <option value="YG">SOUTH</option>
                                                                <option value="9C">SPRING AIRLINES</option>
                                                                <option value="TW">T'WAY AIR</option>
                                                                <option value="DT">TAAG - LINHAS AEREAS DE ANGOLA</option>
                                                                <option value="TA">TACA INTERNATIONAL AIRLINES, S.A.</option>
                                                                <option value="VR">TACV</option>
                                                                <option value="PZ">TAM - TRANSPORTES AEREOS DEL</option>
                                                                <option value="JJ">TAM AIRLINES</option>
                                                                <option value="GG">TAMAIR</option>
                                                                <option value="EQ">TAME</option>
                                                                <option value="TP">TAP - AIR PORTUGAL</option>
                                                                <option value="RO">TAROM - ROMANIAN AIR TRANSPORT</option>
                                                                <option value="T6">TAVREY AIRCOMPANY</option>
                                                                <option value="TG">THAI AIRWAYS INTERNATIONAL PUBLIC</option>
                                                                <option value="NM">THE MOUNT COOK GROUP LTD.</option>
                                                                <option value="FQ">THOMAS COOK AIRLINE</option>
                                                                <option value="MT">THOMAS COOKE AIRLINES</option>
                                                                <option value="KI">TIME AIR, INC</option>
                                                                <option value="ZT">TITAN AIRWAYS</option>
                                                                <option value="TI">TOL AIR SERVICES, INC</option>
                                                                <option value="FF">TOWER AIR INC.</option>
                                                                <option value="P6">TRANS AIR</option>
                                                                <option value="7T">TRANS COTE INC</option>
                                                                <option value="TL">TRANS MEDITERRANEAN AIRWAYS</option>
                                                                <option value="6N">TRANS TRAVEL</option>
                                                                <option value="UN">TRANSAERO AIRLINES</option>
                                                                <option value="GE">TRANSASIA AIRWAYS</option>
                                                                <option value="HV">TRANSAVIA AIRLINES</option>
                                                                <option value="AL">TRANSAVIAEXPORT CARGO AIRLINES</option>
                                                                <option value="4P">TRANSPORTES AEREAS DEL PERU, S.A.</option>
                                                                <option value="GD">TRANSPORTES AEREOS EJECUTIVOS S.A.</option>
                                                                <option value="TQ">TRANSWEDE AIRWAYS</option>
                                                                <option value="01">TRAVEL CITY (XL LEISURE GROUP)</option>
                                                                <option value="TU">TUNIS AIR</option>
                                                                <option value="QW">TURK &amp; CAICOS AIRWAYS</option>
                                                                <option value="TK">TURKISH AIRLINES INC.</option>
                                                                <option value="U8">TUS AIR</option>
                                                                <option value="T7">TWIN JET</option>
                                                                <option value="VO">TYROLEAN ARWYS</option>
                                                                <option value="7M">TYUMEN ARLNS</option>
                                                                <option value="FO">TASMANIA</option>
                                                                <option value="T2">THAI AIR</option>
                                                                <option value="FD">THAI AIRASIA</option>
                                                                <option value="SL">THAI LION AIR</option>
                                                                <option value="VZ">THAI VIETJET AIR</option>
                                                                <option value="TT">TIGER AIRWAYS</option>
                                                                <option value="IT">TIGERAIR TAIWAN</option>
                                                                <option value="TO">TRANSAVIA FRANCE</option>
                                                                <option value="PM">TROPIC AIR</option>
                                                                <option value="WI">U-LAND AIRLINES</option>
                                                                <option value="PS">UKRAINE INTERNATIONAL AIRLINES</option>
                                                                <option value="UA">UNITED AIRLINES, INC.</option>
                                                                <option value="UV">UNIVERSAL AIRWAYS INC.</option>
                                                                <option value="UZ">UP AIRWAYS</option>
                                                                <option value="5X">UPS</option>
                                                                <option value="U6">URAL AIRLINES</option>
                                                                <option value="US">US AIRWAYS, INC.</option>
                                                                <option value="U5">USA 3000</option>
                                                                <option value="U7">USA JET</option>
                                                                <option value="QU">UTAIR UKRAINE</option>
                                                                <option value="HY">UZBEKISTAN HAVO YULLARY</option>
                                                                <option value="UT">UTAIR</option>
                                                                <option value="J7">VALUJET AIRLINES</option>
                                                                <option value="NJ">VANGUARD AIRLINES</option>
                                                                <option value="RG">VARIG S.A.</option>
                                                                <option value="VP">VIACAO AEREA SAO PAULO, S.A. (VASP)</option>
                                                                <option value="VI">VIEQUES AIR LINK, INC.</option>
                                                                <option value="VN">VIETNAM AIRLINES CORPORATION</option>
                                                                <option value="VK">VIKING AIRLINES</option>
                                                                <option value="VQ">VINTAGE PROPS AND JETS</option>
                                                                <option value="9V">VIP AIR</option>
                                                                <option value="VX">VIRGIN AMERICA</option>
                                                                <option value="VS">VIRGIN ATLANTIC</option>
                                                                <option value="TV">VIRGIN EXPRESS</option>
                                                                <option value="V2">VISION AIRLINES</option>
                                                                <option value="UK">VISTARA</option>
                                                                <option value="VB">VIVAAEROBUS</option>
                                                                <option value="VG">VLM VLAAMSE</option>
                                                                <option value="41">VOLARIS</option>
                                                                <option value="G6">VOLGA AIRLINES</option>
                                                                <option value="V7">VOLOTEA</option>
                                                                <option value="4V">VOYAGEUR AIRWAYS</option>
                                                                <option value="VY">VUELING AIRLINES</option>
                                                                <option value="V4">VIEQUES AIR LINK</option>
                                                                <option value="VJ">VIETJET AIR</option>
                                                                <option value="DJ">VIRGIN AUSTRALIA</option>
                                                                <option value="VA">VIRGIN AUSTRALIA</option>
                                                                <option value="VV">VIVA AIR PERU</option>
                                                                <option value="Y4">VOLARIS</option>
                                                                <option value="WU">WALK IN</option>
                                                                <option value="8O">WEST COAST AIR</option>
                                                                <option value="OE">WESTAIR COMMUTER AIRLINES, INC</option>
                                                                <option value="W7">WESTERN PACIFIC</option>
                                                                <option value="WS">WESTJET AIRLINES LTD.</option>
                                                                <option value="WF">WIDEROES FLYVESELSKAP A/S</option>
                                                                <option value="IV">WIND JET</option>
                                                                <option value="RM">WINGS WEST AIRLINES, INC</option>
                                                                <option value="WM">WINWARD ISLANDS</option>
                                                                <option value="W6">WIZZ AIR</option>
                                                                <option value="WW">WOW AIR</option>
                                                                <option value="PT">WEST AIR SWEDEN</option>
                                                                <option value="P5">WINGO</option>
                                                                <option value="K5">WINGS OF ALASKA</option>
                                                                <option value="WO">WORLD AIRWAYS</option>
                                                                <option value="SE">XL AIRWAYS FRANCE</option>
                                                                <option value="MF">XIAMEN AIR</option>
                                                                <option value="XP">XTRA AIRWAYS</option>
                                                                <option value="IY">YEMENIA YEMEN AIRWAYS</option>
                                                                <option value="4Y">YUTE AIR ALASKA, INC.</option>
                                                                <option value="R3">YAKUTIA AIRLINES</option>
                                                                <option value="OQ">ZAMBIAN EXPRESSS AIRWAYS (1995) LTD</option>
                                                                <option value="Z7">ZIMBABWE EXPRESS AIRLINES</option>
                                                                <option value="C4">ZIMEX AVIATION</option>
                                                                <option value="ZM">ZOOM</option>
                                                                <option value="Z4">ZOOM AIRLINES</option>
                                                                <option value="F3">FLYADEAL</option>
                                                            </Form.Select>
                                                            {errors.returnDropoffCarrier && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Flight Number<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control className={errors.returnDropoffFlightNumber && 'is-invalid'} {...register('returnDropoffFlightNumber', {required:true})} value={returnDropoffFlightNumber} onChange={(e) => setReturnDropoffFlightNumber(e.target.value)} />
                                                            {errors.returnDropoffFlightNumber && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="gx-2 align-items-center">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>Terminal</strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Control value={returnDropoffTerminal} onChange={(e) => setReturnDropoffTerminal(e.target.value)} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            </>
                                            :
                                            ''
                                            }

                                            <Row className="gx-2">
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>{departureType()} {reqObj.dropoffType !== 'Airport' && reqObj.dropoffType !== 'RailStation' ? 'Pickup' : 'Arrival' } Date<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <InputGroup>
                                                                <input type="hidden" {...register('returnPickupDate', {required:true})} value={returnPickupDate !== null ? returnPickupDate:''} />
                                                                <DatePicker className={'form-control radius-right-0 ' + (errors.returnPickupDate && 'is-invalid')} locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={returnPickupDate} minDate={new Date(new Date(reqObj.returnDate).setDate(new Date(reqObj.returnDate).getDate() - 0))} maxDate={new Date(new Date(reqObj.returnDate).setDate(new Date(reqObj.returnDate).getDate() + 1))} onChange={ (date) => setReturnPickupDate(date)} />
                                                                <InputGroup.Text className="bg-white fn14 green"><FontAwesomeIcon icon={solid('calendar-alt')} /></InputGroup.Text>
                                                            </InputGroup>
                                                            {errors.returnPickupDate && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={6} className="mb-3">
                                                    <Row className="gx-2 align-items-center">
                                                        <Form.Label className="col-md-4 col-form-label text-sm-end"><strong>{departureType()} {reqObj.dropoffType !== 'Airport' && reqObj.dropoffType !== 'RailStation' ? 'Pickup' : 'Arrival'} Time<sup className="text-danger">*</sup></strong></Form.Label>
                                                        <Col md={8}>
                                                            <Form.Select className={errors.returnPickupTime && 'is-invalid'} {...register('returnPickupTime', {required:true})} value={returnPickupTime} onChange={(e) => setReturnPickupTime(e.target.value)}>
                                                                <option value="">Choose Time</option>
                                                                <option value="0:0">0:0</option>
                                                                <option value="0:5">0:5</option>
                                                                <option value="0:10">0:10</option>
                                                                <option value="0:15">0:15</option>
                                                                <option value="0:20">0:20</option>
                                                                <option value="0:25">0:25</option>
                                                                <option value="0:30">0:30</option>
                                                                <option value="0:35">0:35</option>
                                                                <option value="0:40">0:40</option>
                                                                <option value="0:45">0:45</option>
                                                                <option value="0:50">0:50</option>
                                                                <option value="0:55">0:55</option>
                                                                <option value="1:0">1:0</option>
                                                                <option value="1:5">1:5</option>
                                                                <option value="1:10">1:10</option>
                                                                <option value="1:15">1:15</option>
                                                                <option value="1:20">1:20</option>
                                                                <option value="1:25">1:25</option>
                                                                <option value="1:30">1:30</option>
                                                                <option value="1:35">1:35</option>
                                                                <option value="1:40">1:40</option>
                                                                <option value="1:45">1:45</option>
                                                                <option value="1:50">1:50</option>
                                                                <option value="1:55">1:55</option>
                                                                <option value="2:0">2:0</option>
                                                                <option value="2:5">2:5</option>
                                                                <option value="2:10">2:10</option>
                                                                <option value="2:15">2:15</option>
                                                                <option value="2:20">2:20</option>
                                                                <option value="2:25">2:25</option>
                                                                <option value="2:30">2:30</option>
                                                                <option value="2:35">2:35</option>
                                                                <option value="2:40">2:40</option>
                                                                <option value="2:45">2:45</option>
                                                                <option value="2:50">2:50</option>
                                                                <option value="2:55">2:55</option>
                                                                <option value="3:0">3:0</option>
                                                                <option value="3:5">3:5</option>
                                                                <option value="3:10">3:10</option>
                                                                <option value="3:15">3:15</option>
                                                                <option value="3:20">3:20</option>
                                                                <option value="3:25">3:25</option>
                                                                <option value="3:30">3:30</option>
                                                                <option value="3:35">3:35</option>
                                                                <option value="3:40">3:40</option>
                                                                <option value="3:45">3:45</option>
                                                                <option value="3:50">3:50</option>
                                                                <option value="3:55">3:55</option>
                                                                <option value="4:0">4:0</option>
                                                                <option value="4:5">4:5</option>
                                                                <option value="4:10">4:10</option>
                                                                <option value="4:15">4:15</option>
                                                                <option value="4:20">4:20</option>
                                                                <option value="4:25">4:25</option>
                                                                <option value="4:30">4:30</option>
                                                                <option value="4:35">4:35</option>
                                                                <option value="4:40">4:40</option>
                                                                <option value="4:45">4:45</option>
                                                                <option value="4:50">4:50</option>
                                                                <option value="4:55">4:55</option>
                                                                <option value="5:0">5:0</option>
                                                                <option value="5:5">5:5</option>
                                                                <option value="5:10">5:10</option>
                                                                <option value="5:15">5:15</option>
                                                                <option value="5:20">5:20</option>
                                                                <option value="5:25">5:25</option>
                                                                <option value="5:30">5:30</option>
                                                                <option value="5:35">5:35</option>
                                                                <option value="5:40">5:40</option>
                                                                <option value="5:45">5:45</option>
                                                                <option value="5:50">5:50</option>
                                                                <option value="5:55">5:55</option>
                                                                <option value="6:0">6:0</option>
                                                                <option value="6:5">6:5</option>
                                                                <option value="6:10">6:10</option>
                                                                <option value="6:15">6:15</option>
                                                                <option value="6:20">6:20</option>
                                                                <option value="6:25">6:25</option>
                                                                <option value="6:30">6:30</option>
                                                                <option value="6:35">6:35</option>
                                                                <option value="6:40">6:40</option>
                                                                <option value="6:45">6:45</option>
                                                                <option value="6:50">6:50</option>
                                                                <option value="6:55">6:55</option>
                                                                <option value="7:0">7:0</option>
                                                                <option value="7:5">7:5</option>
                                                                <option value="7:10">7:10</option>
                                                                <option value="7:15">7:15</option>
                                                                <option value="7:20">7:20</option>
                                                                <option value="7:25">7:25</option>
                                                                <option value="7:30">7:30</option>
                                                                <option value="7:35">7:35</option>
                                                                <option value="7:40">7:40</option>
                                                                <option value="7:45">7:45</option>
                                                                <option value="7:50">7:50</option>
                                                                <option value="7:55">7:55</option>
                                                                <option value="8:0">8:0</option>
                                                                <option value="8:5">8:5</option>
                                                                <option value="8:10">8:10</option>
                                                                <option value="8:15">8:15</option>
                                                                <option value="8:20">8:20</option>
                                                                <option value="8:25">8:25</option>
                                                                <option value="8:30">8:30</option>
                                                                <option value="8:35">8:35</option>
                                                                <option value="8:40">8:40</option>
                                                                <option value="8:45">8:45</option>
                                                                <option value="8:50">8:50</option>
                                                                <option value="8:55">8:55</option>
                                                                <option value="9:0">9:0</option>
                                                                <option value="9:5">9:5</option>
                                                                <option value="9:10">9:10</option>
                                                                <option value="9:15">9:15</option>
                                                                <option value="9:20">9:20</option>
                                                                <option value="9:25">9:25</option>
                                                                <option value="9:30">9:30</option>
                                                                <option value="9:35">9:35</option>
                                                                <option value="9:40">9:40</option>
                                                                <option value="9:45">9:45</option>
                                                                <option value="9:50">9:50</option>
                                                                <option value="9:55">9:55</option>
                                                                <option value="10:0">10:0</option>
                                                                <option value="10:5">10:5</option>
                                                                <option value="10:10">10:10</option>
                                                                <option value="10:15">10:15</option>
                                                                <option value="10:20">10:20</option>
                                                                <option value="10:25">10:25</option>
                                                                <option value="10:30">10:30</option>
                                                                <option value="10:35">10:35</option>
                                                                <option value="10:40">10:40</option>
                                                                <option value="10:45">10:45</option>
                                                                <option value="10:50">10:50</option>
                                                                <option value="10:55">10:55</option>
                                                                <option value="11:0">11:0</option>
                                                                <option value="11:5">11:5</option>
                                                                <option value="11:10">11:10</option>
                                                                <option value="11:15">11:15</option>
                                                                <option value="11:20">11:20</option>
                                                                <option value="11:25">11:25</option>
                                                                <option value="11:30">11:30</option>
                                                                <option value="11:35">11:35</option>
                                                                <option value="11:40">11:40</option>
                                                                <option value="11:45">11:45</option>
                                                                <option value="11:50">11:50</option>
                                                                <option value="11:55">11:55</option>
                                                                <option value="12:0">12:0</option>
                                                                <option value="12:5">12:5</option>
                                                                <option value="12:10">12:10</option>
                                                                <option value="12:15">12:15</option>
                                                                <option value="12:20">12:20</option>
                                                                <option value="12:25">12:25</option>
                                                                <option value="12:30">12:30</option>
                                                                <option value="12:35">12:35</option>
                                                                <option value="12:40">12:40</option>
                                                                <option value="12:45">12:45</option>
                                                                <option value="12:50">12:50</option>
                                                                <option value="12:55">12:55</option>
                                                                <option value="13:0">13:0</option>
                                                                <option value="13:5">13:5</option>
                                                                <option value="13:10">13:10</option>
                                                                <option value="13:15">13:15</option>
                                                                <option value="13:20">13:20</option>
                                                                <option value="13:25">13:25</option>
                                                                <option value="13:30">13:30</option>
                                                                <option value="13:35">13:35</option>
                                                                <option value="13:40">13:40</option>
                                                                <option value="13:45">13:45</option>
                                                                <option value="13:50">13:50</option>
                                                                <option value="13:55">13:55</option>
                                                                <option value="14:0">14:0</option>
                                                                <option value="14:5">14:5</option>
                                                                <option value="14:10">14:10</option>
                                                                <option value="14:15">14:15</option>
                                                                <option value="14:20">14:20</option>
                                                                <option value="14:25">14:25</option>
                                                                <option value="14:30">14:30</option>
                                                                <option value="14:35">14:35</option>
                                                                <option value="14:40">14:40</option>
                                                                <option value="14:45">14:45</option>
                                                                <option value="14:50">14:50</option>
                                                                <option value="14:55">14:55</option>
                                                                <option value="15:0">15:0</option>
                                                                <option value="15:5">15:5</option>
                                                                <option value="15:10">15:10</option>
                                                                <option value="15:15">15:15</option>
                                                                <option value="15:20">15:20</option>
                                                                <option value="15:25">15:25</option>
                                                                <option value="15:30">15:30</option>
                                                                <option value="15:35">15:35</option>
                                                                <option value="15:40">15:40</option>
                                                                <option value="15:45">15:45</option>
                                                                <option value="15:50">15:50</option>
                                                                <option value="15:55">15:55</option>
                                                                <option value="16:0">16:0</option>
                                                                <option value="16:5">16:5</option>
                                                                <option value="16:10">16:10</option>
                                                                <option value="16:15">16:15</option>
                                                                <option value="16:20">16:20</option>
                                                                <option value="16:25">16:25</option>
                                                                <option value="16:30">16:30</option>
                                                                <option value="16:35">16:35</option>
                                                                <option value="16:40">16:40</option>
                                                                <option value="16:45">16:45</option>
                                                                <option value="16:50">16:50</option>
                                                                <option value="16:55">16:55</option>
                                                                <option value="17:0">17:0</option>
                                                                <option value="17:5">17:5</option>
                                                                <option value="17:10">17:10</option>
                                                                <option value="17:15">17:15</option>
                                                                <option value="17:20">17:20</option>
                                                                <option value="17:25">17:25</option>
                                                                <option value="17:30">17:30</option>
                                                                <option value="17:35">17:35</option>
                                                                <option value="17:40">17:40</option>
                                                                <option value="17:45">17:45</option>
                                                                <option value="17:50">17:50</option>
                                                                <option value="17:55">17:55</option>
                                                                <option value="18:0">18:0</option>
                                                                <option value="18:5">18:5</option>
                                                                <option value="18:10">18:10</option>
                                                                <option value="18:15">18:15</option>
                                                                <option value="18:20">18:20</option>
                                                                <option value="18:25">18:25</option>
                                                                <option value="18:30">18:30</option>
                                                                <option value="18:35">18:35</option>
                                                                <option value="18:40">18:40</option>
                                                                <option value="18:45">18:45</option>
                                                                <option value="18:50">18:50</option>
                                                                <option value="18:55">18:55</option>
                                                                <option value="19:0">19:0</option>
                                                                <option value="19:5">19:5</option>
                                                                <option value="19:10">19:10</option>
                                                                <option value="19:15">19:15</option>
                                                                <option value="19:20">19:20</option>
                                                                <option value="19:25">19:25</option>
                                                                <option value="19:30">19:30</option>
                                                                <option value="19:35">19:35</option>
                                                                <option value="19:40">19:40</option>
                                                                <option value="19:45">19:45</option>
                                                                <option value="19:50">19:50</option>
                                                                <option value="19:55">19:55</option>
                                                                <option value="20:0">20:0</option>
                                                                <option value="20:5">20:5</option>
                                                                <option value="20:10">20:10</option>
                                                                <option value="20:15">20:15</option>
                                                                <option value="20:20">20:20</option>
                                                                <option value="20:25">20:25</option>
                                                                <option value="20:30">20:30</option>
                                                                <option value="20:35">20:35</option>
                                                                <option value="20:40">20:40</option>
                                                                <option value="20:45">20:45</option>
                                                                <option value="20:50">20:50</option>
                                                                <option value="20:55">20:55</option>
                                                                <option value="21:0">21:0</option>
                                                                <option value="21:5">21:5</option>
                                                                <option value="21:10">21:10</option>
                                                                <option value="21:15">21:15</option>
                                                                <option value="21:20">21:20</option>
                                                                <option value="21:25">21:25</option>
                                                                <option value="21:30">21:30</option>
                                                                <option value="21:35">21:35</option>
                                                                <option value="21:40">21:40</option>
                                                                <option value="21:45">21:45</option>
                                                                <option value="21:50">21:50</option>
                                                                <option value="21:55">21:55</option>
                                                                <option value="22:0">22:0</option>
                                                                <option value="22:5">22:5</option>
                                                                <option value="22:10">22:10</option>
                                                                <option value="22:15">22:15</option>
                                                                <option value="22:20">22:20</option>
                                                                <option value="22:25">22:25</option>
                                                                <option value="22:30">22:30</option>
                                                                <option value="22:35">22:35</option>
                                                                <option value="22:40">22:40</option>
                                                                <option value="22:45">22:45</option>
                                                                <option value="22:50">22:50</option>
                                                                <option value="22:55">22:55</option>
                                                                <option value="23:0">23:0</option>
                                                                <option value="23:5">23:5</option>
                                                                <option value="23:10">23:10</option>
                                                                <option value="23:15">23:15</option>
                                                                <option value="23:20">23:20</option>
                                                                <option value="23:25">23:25</option>
                                                                <option value="23:30">23:30</option>
                                                                <option value="23:35">23:35</option>
                                                                <option value="23:40">23:40</option>
                                                                <option value="23:45">23:45</option>
                                                                <option value="23:50">23:50</option>
                                                                <option value="23:55">23:55</option>
                                                            </Form.Select>
                                                            {errors.returnPickupTime && <div className="text-danger fn13 mt-1 ms-2">This field is required</div>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>

                                            </>
                                            }
                                        </div>
                                        
                                        {paymentGateway === '4' ?
                                        <div className="text-end resFixedBtn"><Button type="submit" variant="success" size="lg" className="rounded-0">&nbsp; Pay {getCurrency.currency} {parseFloat(vehicleDetail.rate.supplierTotalNet).toFixed("2")} &nbsp;</Button></div>
                                        : 
                                        <div className="text-end resFixedBtn"><Button variant="success" size="lg" className="rounded-0">&nbsp;{t("Continue")} &nbsp; <FontAwesomeIcon icon={solid('long-arrow-alt-right')} className="align-middle fn18" /></Button></div>
                                        }
                                        {/* <Button type="submit">Submit</Button> */}
                                    </form>
                                </div>
                                
                            </div>


                            <div className={"tab-pane fade " + (isActive('paymentCol') && 'active show')}>
                                <PaymentSection srvCodeObj={srvCode} transferReq={reqObj} travObj={travellerObj} bookingObj={transferDetailsObj} />
                            </div>
                        </div>

                    </div>
                </Col>
                <TransferFareBreakup transferOptDetails={vehicleDetail} />
            </Row>
        </Container> 
        :
        <Container>
            <Row>
                <Col lg={{span:8, order: 1}} xs={{order: 2}} className="mb-2">
                    <div className="bg-white rounded shadow-sm shadow-lg-none">
                        <div className="d-sm-flex flex-row">
                            <div className="hotelImg rounded-start noView d-none d-sm-block">
                                <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                            </div>
                            <div className="ps-sm-3 pe-1 pt-2 w-100 arhotelItenDetail">
                                <h3 className="nametitle blue"><div className="dummyDiv" style={{Width:'70%', Height:'27px'}}></div></h3>
                                <div className="dummyDiv" style={{Width:'45%', Height:'20px'}}></div>
                                <div>
                                    <div className="dumwave">
                                        <div className="anim anim1"></div>
                                        <div className="anim anim2"></div>
                                        <div className="anim anim3"></div>
                                        <div className="anim anim4"></div>
                                        <div className="anim anim5"></div>
                                    </div>
                                </div>
                                <div className="dummyDiv" style={{Width:'30%', Height:'20px'}}></div>
                            </div>
                        </div>  
                    </div>
                </Col>
                <Col lg={{span:4, order:2}} xs={{order:1}} className="mb-2">
                    <div className="bg-white rounded shadow-sm sticky-top shadow-lg-none">
                        <h3 className="nametitle blue plr10 pt-3 pb-1 plrlg0">{t("FareBreakup")} <Button size="sm" variant="link" className="fn14 text-warning d-inlineblock d-lg-none" onClick={() => setOpenL(!openL)}> (Show Details) </Button></h3>
                        <Collapse in={openL}>
                            <div>
                                <table className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <td>Pax</td>
                                            <td className="text-end">
                                                <div className="dumwave">
                                                    <div className="anim anim1"></div>
                                                    <div className="anim anim2"></div>
                                                    <div className="anim anim3"></div>
                                                    <div className="anim anim4"></div>
                                                    <div className="anim anim5"></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <td>{t("Tax")} </td>
                                            <td className="text-end">
                                                <div className="dumwave">
                                                    <div className="anim anim1"></div>
                                                    <div className="anim anim2"></div>
                                                    <div className="anim anim3"></div>
                                                    <div className="anim anim4"></div>
                                                    <div className="anim anim5"></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Collapse>
                        <table className="table mb-0">
                            <tbody>
                                <tr className="bg-light">
                                    <td><strong>{t("TotalAmount")}</strong></td>
                                    <td className="text-end">
                                        <div className="dumwave">
                                            <div className="anim anim1"></div>
                                            <div className="anim anim2"></div>
                                            <div className="anim anim3"></div>
                                            <div className="anim anim4"></div>
                                            <div className="anim anim5"></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Container>
        }
        </div>
    </DefaultLayout>
    </>
  )
}

export default TransferItinerary
