import { GET_EXCHANGERATES, GET_CURRENCY, GET_LANGUAGE } from "../types/commonTypes";
const intialState = {
  exchangerates: [],
  currency:'',
  language:''
};

export const exchangeRateReducer = (state = intialState.exchangerates, { type, payload }) => {
  switch (type) {
    case GET_EXCHANGERATES:
      return { ...state, exchangerates: payload };
    default:
      return state;
  }
};

export const currencyReducer = (state = intialState.currency, { type, payload }) => {
  switch (type) {
    case GET_CURRENCY:
      return { ...state, currency:payload };
    default:
      return state;
  }
};

export const languageReducer = (state = intialState.language, { type, payload }) => {
  switch (type) {
    case GET_LANGUAGE:
      return { ...state, language:payload };
    default:
      return state;
  }
};