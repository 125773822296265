import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Row, Col, Button, Form, Nav, InputGroup, Pagination, Modal} from 'react-bootstrap';
import {doFilterSort} from "../../store/actions/transfer";
import { useSearchParams, useNavigate } from 'react-router-dom';

const TransferResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t} = useTranslation();
  const getCurrency = useSelector((state) => state.currency)
  const OrgTrnsfrResult = useSelector((state) => state.transferList.transferResOrgObj);
  const TransferResult = useSelector((state) => state.transferList.transferResObj)

  const TrnsfrFilter = useSelector((state) => state.transferFilter.transferFltr);
  const TrnsfrFilterSort = useSelector((state) => state.transferFltrSort.transferFilterSort);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize] = useState(50);  
  const [pagesCount, setPagesCount] = useState(0);

  useEffect(()=>{
    setPagesCount(Math.ceil(TransferResult.transfers.transfer.length / pageSize))
    setCurrentPage(0)
  },[TransferResult]);

  const handleClick = (inde) => {
    setCurrentPage(inde)
  };

  const [searchparams] = useSearchParams();
  const qry = Object.fromEntries([...searchparams])

  const srtTxt = (val) =>{
    let transferFilterSortLet = {
      srtVal: TrnsfrFilterSort ? TrnsfrFilterSort.srtVal : "0",
      srchTxt: val
    }
    let obj = { 'transferFilters': TrnsfrFilter, 'transferFilterSort': transferFilterSortLet}
    dispatch(doFilterSort(OrgTrnsfrResult, obj));   
  };

  const srtVal = (val) =>{
    let transferFilterSortLet = {
      srtVal: val,
      srchTxt: TrnsfrFilterSort ? TrnsfrFilterSort.srchTxt : ""
    }
    let obj = { 'transferFilters': TrnsfrFilter, 'transferFilterSort': transferFilterSortLet}
    dispatch(doFilterSort(OrgTrnsfrResult, obj));  
  };


  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currTransfer, setCurrTransfer] = useState(null);
  const showTransferDtls = (v) => {
    setCurrTransfer(v)
    setShowDetailsModal(true)
  }

  const [activeItem, setActiveItem] = useState('summaryTab');
  const setActive = (menuItem) => {
    setActiveItem(menuItem)
  }
  const isActive = (menuItem) => {
    return activeItem === menuItem
  }

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [respCancelPolicy, setRespCancelPolicy] = useState(null);
  const cancelPolicy = (vd) => {
    setRespCancelPolicy(vd)
    setShowCancelModal(true)
  }

  const bookNow = (transferDetails, transferOption, sessionId) => {
    qry.transferDetails = transferDetails
    qry.transferOption = transferOption
    qry.sId = sessionId
    qry.tId = transferDetails.code
    qry.rt = transferOption.rate.ratekey
    qry.gp = transferDetails.groupCode
    navigate(`/transferItinerary`,{state:{'reqObj':qry}})
  }

  return( 
    <>
      {TransferResult.transfers && TransferResult.transfers.transfer && TransferResult.transfers.transfer.length > 0 && TransferResult.transfers.transfer[0] !==null ?
        <div className="d-lg-table-cell align-top rightResult">
          <div className="text-end mb-1">{t("TotalResultFound")}: {OrgTrnsfrResult.transfers.transfer.length}</div>
          
          <div className="sortTop sticky-top">
            <Row className="justify-content-between gx-2">
              <Col md={3} xs={4} className="mb-1">
                <Form.Select size="sm" onChange={event => srtVal(event.target.value)} value={TrnsfrFilterSort.srtVal}>
                  <option value="0">{t("SortBy")}</option>
                  <option value="plth">{t("PriceLowHigh")}</option>
                  <option value="phtl">{t("PriceHighLow")}</option>
                </Form.Select>
              </Col>
              <Col md={5} xs={8} className="mb-1">
                <InputGroup>
                    <Form.Control className="border-end-0 fn13" placeholder={t('EnterExcursionName')} value={TrnsfrFilterSort.srchTxt} onChange={event => srtTxt(event.target.value)} />
                    <InputGroup.Text className="bg-white"><FontAwesomeIcon icon={solid('search')} className="fn14" /></InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </div> 

          {pagesCount >= 2 &&
            <Pagination size='sm' className="justify-content-center mb-3">
              <Pagination.First disabled={currentPage <= 0} onClick={() => handleClick(0)} />
              <Pagination.Prev disabled={currentPage <= 0} onClick={() => handleClick(currentPage - 1)} />
              {[...Array(pagesCount)].map((page, i) => 
                <Pagination.Item active={i === currentPage} key={i} onClick={() => handleClick(i)}>
                    {i + 1}
                </Pagination.Item>
              )}
              <Pagination.Next disabled={currentPage >= pagesCount - 1} onClick={() => handleClick(currentPage + 1)} />
              <Pagination.Last disabled={currentPage >= pagesCount-1} onClick={() => handleClick(pagesCount-1)} />
            </Pagination>
          }

          {
            TransferResult.transfers.transfer.slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize
            ).map((v, i) => {
              return (
                <div key={i} className="htlboxcol">
                  <Row className="gx-2">
                    <Col md={10}>
                      <div className="d-flex flex-row">
                        <div className="transferImg rounded-top-left-1">
                          {v.images && v.images.image.length > 0 ? 
                          <img src={v.images.image[0].imagePath} alt={v.name} />
                          :
                          <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                          }
                        </div>
                        <div className="ps-3 pe-1 pt-2 w-100 htlright">
                          <Row className="gx-2 h-100">
                            <Col md={12} className="align-self-center">
                              <h3 className="nametitle blue">{v.name}</h3>
                              <Button variant="link" size="sm" onClick={()=>showTransferDtls(v)} className="px-0">More Details</Button>
                              {/* <ul className="list-inline mb-2">
                                  <li className="list-inline-item blue fn13"><FontAwesomeIcon icon={solid('briefcase')} className="fn14" /> {v.vehicleDetails.vehicleDetail[0].noOfBags}</li>
                              </ul> */}
                            </Col>
                           
                          </Row>
                        </div>
                      </div>
                    </Col>

                    <Col md={2} className="border-start d-flex">
                      <div className="align-self-center mx-md-auto ms-auto text-center d-flex d-md-block px-2">
                        <div className="mx-1 mx-md-0"><span className="nametitle blue d-md-block mb-0">{getCurrency.currency} {parseFloat(v.vehicleDetails.vehicleDetail[0].rate.supplierTotalNet).toFixed(2)}</span></div>
                      </div>
                    </Col>
                  </Row>
                  {v.vehicleDetails.vehicleDetail && v.vehicleDetails.vehicleDetail.length &&
                    <Row className="gx-2">
                      <Col md={12}>
                        <div className="table-responsive">
                          <table className="table table-sm mb-0 fn13 border-top">
                            <thead>
                              <tr>
                                <th className="text-nowrap"><strong>Vehicle Type</strong></th>
                                <th className="text-center"><strong>Max Pax</strong></th>
                                <th className="text-center"><strong>Max Luggage</strong></th>
                                <th className="text-center"><strong>Policy</strong></th>
                                <th className="text-center"><strong>Language</strong></th>
                                <th className="text-center"><strong>Status</strong></th>
                                <th className="text-center"><strong>Qty</strong></th>
                                <th><strong>Price</strong></th>
                                <th className="text-end">&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                            {v.vehicleDetails.vehicleDetail.map((vd, index) => (
                              <tr key={index}>
                                <td className="align-middle semibold">{vd.vehicle.text} and Similar</td>
                                <td className="align-middle text-center text-nowrap"><FontAwesomeIcon icon={solid('user')} className="fn11" /> X {vd.noOfSeats}</td>
                                <td className="align-middle text-center text-nowrap"><FontAwesomeIcon icon={solid('briefcase')} className="fn12" /> x {vd.noOfBags}</td>
                                <td className="align-middle text-center"><Button variant="link" size="sm" onClick={()=>cancelPolicy(vd)}><FontAwesomeIcon icon={solid('file-alt')} className="fn14" /></Button></td>
                                <td className="align-middle text-center">Local</td>
                                <td className="align-middle text-center text-success">{vd.status}</td>
                                <td className="align-middle text-center">{vd.rate.quantity}</td>
                                <td className="align-middle text-nowrap">{getCurrency.currency} {parseFloat(vd.rate.supplierTotalNet).toFixed("2")}</td>
                                <td className="align-middle text-end"><Button className="text-nowrap" variant="warning" size="sm" onClick={()=>bookNow(v, vd, TransferResult.generalInfo.sessionId)}>&nbsp;{t("BookNow")}&nbsp;</Button></td>
                              </tr>
                              ))}  
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  }
                  
                </div>
              )
            })
          }

          {pagesCount >= 2 &&
            <Pagination size='sm' className="justify-content-center mb-3">
              <Pagination.First disabled={currentPage <= 0} onClick={() => handleClick(0)} />
              <Pagination.Prev disabled={currentPage <= 0} onClick={() => handleClick(currentPage - 1)} />
              
              {[...Array(pagesCount)].map((page, i) => 
                <Pagination.Item active={i === currentPage} key={i} onClick={() => handleClick(i)}>
                    {i + 1}
                </Pagination.Item>
              )}

              <Pagination.Next disabled={currentPage >= pagesCount - 1} onClick={() => handleClick(currentPage + 1)} />
              <Pagination.Last disabled={currentPage >= pagesCount-1} onClick={() => handleClick(pagesCount-1)} />
            </Pagination>
          }

            <Modal show={showDetailsModal} onHide={() => setShowDetailsModal(false)} size="xl" centered>
              <Modal.Header closeButton>
                <Modal.Title className="fn16 w-100">
                  {currTransfer &&
                  <>
                    {currTransfer.name} Car<br />
                    <div className="fn13">{currTransfer.origin.address} - {currTransfer.destination.address}</div>
                  </>
                  }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {currTransfer &&
                <div className="arrowtab">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Nav.Link onClick={() => setActive('summaryTab')} className={isActive('summaryTab') && 'active'}>
                        <span className="tabTxt">Summary</span>
                      </Nav.Link>
                    </li>
                    <li className="nav-item">
                      <Nav.Link onClick={() => setActive('meetingsPointTab')} className={isActive('meetingsPointTab') && 'active'}>
                        <span className="tabTxt">Meetings Point</span>
                      </Nav.Link>
                    </li>
                    <li className="nav-item">
                      <Nav.Link onClick={() => setActive('transferConditionsTab')} className={isActive('transferConditionsTab') && 'active'}>
                        <span className="tabTxt">Transfer Conditions</span>
                      </Nav.Link>
                    </li>
                    <li className="nav-item">
                      <Nav.Link onClick={() => setActive('essentialInformationTab')} className={isActive('essentialInformationTab') && 'active'}>
                        <span className="tabTxt">Essential Information</span>
                      </Nav.Link>
                    </li>
                  </ul>
                  <div className="tab-content py-3 li21 fn13">
                    <div className={"tab-pane fade " + (isActive('summaryTab') && 'active show')}>
                      <p>No Data Available</p>
                    </div>
                    <div className={"tab-pane fade " + (isActive('meetingsPointTab') && 'active show')}>
                      <p>No Data Available</p>
                    </div>
                    <div className={"tab-pane fade " + (isActive('transferConditionsTab') && 'active show')}>
                      <p>No Data Available</p>
                    </div>
                    <div className={"tab-pane fade " + (isActive('essentialInformationTab') && 'active show')}>
                      {currTransfer.essentialInformation ?
                        <div dangerouslySetInnerHTML={{ __html: currTransfer.essentialInformationn }}></div>
                        :
                        <p>No Data Available</p>
                      }
                    </div>
                  </div>
                </div>
                }
              </Modal.Body>
            </Modal>

          <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title className="fn16">{t("CancellationPolicy")}</Modal.Title>
            </Modal.Header>
            <Modal.Body> 
              {respCancelPolicy &&
              <>
              {respCancelPolicy.cancellationPolicies && respCancelPolicy.cancellationPolicies.cancellationPolicy ?
              <>
              <table className="table table-bordered">
                <thead className="bg-light">
                  <tr>
                    <th>{t("From")}</th>
                    <th>{t("To")}</th>
                    <th className="text-end">Charges</th>
                  </tr>
                </thead>
                <tbody>
                    {respCancelPolicy.cancellationPolicies.cancellationPolicy.map((p, i) => ( 
                    <tr key={i}>
                      {p.textCondition &&
                        <td colSpan="3">{p.textCondition }</td>
                      }
                    </tr>
                    ))
                    }
                </tbody>
              </table>
              </>
              : 
              <div className="fn16 blue mb-3">{t("NoResultFound")}</div>
              }
              </>   
              }   
            </Modal.Body>
          </Modal>

        </div> 
        :
        <div className="d-lg-table-cell align-top rightResult"> 
          <div className="text-center my-5">
            <div className="mb-3"><img src={require('../../assets/images/no-result.png')} alt="No Result Found" /></div>
            <div className="fn21">{t("NoResultFound")}</div>
          </div>
        </div>
      }
    </> 
  );
}
export default TransferResult;