import { format } from 'date-fns';
import { useTranslation } from "react-i18next";

const HotelBooking = ({bDtls, htlStatData}) => {
  const {t} = useTranslation();
  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const capitalizeFirst = str => {
    let titleCase = str
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
    return titleCase;
  };
  
  return (
    <>
      <table width="100%" cellSpacing="0" cellPadding="10" className="cellpad10">
        <tbody>
          <tr>
            <td>
              <table width="100%" cellSpacing="0" cellPadding="2" style={{borderCollapse:'collapse',borderSpacing:'0px', marginBottom:'5px'}} className="cellpad2">
                <tbody>
                  <tr>
                    <td>
                      <strong style={{color:'#124680', fontSize:'18px', lineHeight:'24px'}}>{bDtls.hotels[0].hotelDetail.productName}</strong><br />
                      {bDtls.hotels[0].hotelDetail.pdtAddress}, {bDtls.hotels[0].hotelDetail.productCityName}  - {bDtls.hotels[0].hotelDetail.pdtCountryIsoName}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table width="100%" cellSpacing="0" cellPadding="5" bordercolor="#dfdede" border="1" style={{borderCollapse:'collapse',borderSpacing:'0px'}} className="cellpad5">
                <tbody>
                  <tr style={{background:'#eef5f6'}}>
                    <th align="left"><strong>Room count</strong></th>
                    <th align="left"><strong>No. of Nights</strong></th>
                    <th align="left"><strong>Total Guest</strong></th>
                    <th align="left"><strong>{t("Check-in")}</strong></th>
                    <th align="left"><strong>{t("Check-out")}</strong></th>
                  </tr>
                  <tr>
                    <td>{parseInt(bDtls.hotels[0].roomDetails.length)} Room(s)</td>
                    <td>{bDtls.hotels[0].hotelDetail.nights} Night(s)</td>
                    <td>
                      Total Guest: {parseInt(bDtls.hotels[0].hotelDetail.noOfAdults) + parseInt(bDtls.hotels[0].hotelDetail.noOfChilds)}<br />
                      {parseInt(bDtls.hotels[0].hotelDetail.noOfAdults)} Adult(s) 
                      {bDtls.hotels[0].hotelDetail.noOfChilds > 0 &&
                        <div>,{bDtls.hotels[0].hotelDetail.noOfChilds} Child</div>
                      }
                    </td>
                    <td>
                      {format(new Date(bDtls.hotels[0].hotelDetail.checkinDate), 'EEE, dd MMM yyyy')}
                    </td>
                    <td>
                      {format(new Date(bDtls.hotels[0].hotelDetail.checkoutDate), 'EEE, dd MMM yyyy')}
                    </td>
                  </tr>
                </tbody>
              </table>

            </td>
          </tr>
        </tbody>
      </table>

      {/* amenities */}
      <table width="100%" cellSpacing="0" cellPadding="10" className="cellpad10">
        <tbody>
          <tr>
            <td>
              <table width="100%" cellSpacing="0" cellPadding="2" style={{borderCollapse:'collapse',borderSpacing:'0px'}} className="cellpad2">
                <tbody>
                  <tr style={{background:'#eef5f6'}}>
                    <th align="left" colSpan="3"><strong>&nbsp;Hotel Amenities</strong></th>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        {
                        htlStatData && htlStatData.htlDtlObj && htlStatData.htlDtlObj.data && htlStatData.htlDtlObj.data.masterHotelAmenities &&
                        <>
                        {
                        htlStatData.htlDtlObj.data.masterHotelAmenities.map((x, i) => (
                          <li style={{width:'23.5%',float:'left',fontSize:'12px'}} key={i}>{x}</li>
                        ))
                        }
                        </>
                        }
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Room Details */}
      {bDtls.hotels[0].roomDetails &&
        <>
        {
        bDtls.hotels[0].roomDetails.map((k, j) => (
          <table key={j} width="100%" cellSpacing="0" cellPadding="10" className="cellpad10">
            <tbody>
              <tr>
                <td>
                  <table width="100%" cellSpacing="0" cellPadding="2" style={{borderCollapse:'collapse',borderSpacing:'0px', marginBottom:'5px'}} className="cellpad2">
                    <tbody>
                      <tr>
                        <td colSpan="2"><strong style={{color:'#124680', fontSize:'15px'}}>Room {parseInt(j + 1)}</strong></td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="100%" cellSpacing="0" cellPadding="2" style={{borderCollapse:'collapse',borderSpacing:'0px'}} className="cellpad2">
                    <tbody>
                      <tr style={{background:'#eef5f6'}}>
                        <td><strong>Room Details</strong></td>
                      </tr>
                      <tr>
                        <td valign="top">{capitalizeFirst(k.roomTypeName)} with {capitalizeFirst(k.rateBasisName)}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        ))
        }
        </>
      } 

      {/* Cancellation Policy */}
      {bDtls.hotels[0].hotelDetail.cancellationPolicy &&
      <>
      <table width="100%" cellSpacing="0" cellPadding="0" style={{marginBottom:'10px'}}>
        <tbody>
          <tr>
            <td>
              <div dangerouslySetInnerHTML={{ __html: bDtls.hotels[0].hotelDetail.cancellationPolicy }}></div>
            </td>
          </tr>
        </tbody>
      </table>
      </>
      }

      {/* Price Summary */}
      <table width="100%" cellSpacing="0" cellPadding="8" style={{marginBottom:'10px', borderBottom:'1px solid #dfdede', borderCollapse:'collapse',borderSpacing:'0px'}} className="cellpad8">
        <tbody>
          <tr>
            <td colSpan="2"><strong style={{fontSize:'16px', color:'#124680'}}>Price Summary</strong></td>
          </tr>
            <tr>
              <td>
                {bDtls.hotels[0].roomDetails && bDtls.hotels[0].roomDetails.length > 0 &&
                <>
                {
                bDtls.hotels[0].roomDetails.map((m, n) => (
                  <table key={n} width="100%" cellSpacing="0" cellPadding="5" className="cellpad5">
                    <tbody>
                        <tr>
                            <td><strong>Room  {parseInt(n + 1)} Type:</strong> {capitalizeFirst(m.roomTypeName)} with {capitalizeFirst(m.rateBasisName)} </td>
                            <td align="right" className="text-end">{bDtls.hotels[0].hotelDetail.systemCurrency} {parseFloat(m.systemPayable).toFixed(2)}</td>
                        </tr>
                    </tbody>
                  </table>
                ))
                }

                <table width="100%" cellSpacing="0" cellPadding="5" className="cellpad5">
                  <tbody>
                    {bDtls.hotels[0].hotelDetail.systemTax > 0 &&
                      <tr>
                        <td>Tax and other Fees</td>
                        <td align="right" className="text-end">{bDtls.hotels[0].hotelDetail.systemCurrency} {parseFloat(bDtls.hotels[0].hotelDetail.systemTax).toFixed(2)}</td>
                      </tr>
                    }
                    <tr style={{background:'#eef5f6',fontSize:'15px'}}>
                      <td><strong>Total Amount:</strong></td>
                      <td align="right" className="text-end"><strong>{bDtls.hotels[0].hotelDetail.systemCurrency}  {parseFloat(bDtls.hotels[0].hotelDetail.systemPayable).toFixed(2)}</strong></td>
                    </tr>
                  </tbody>
                </table>

                </>
                }
                

                    
              </td>
            </tr>
        </tbody>
      </table>

      {/* Hotel Rules and Regulations */}
      { isJson(bDtls.hotels[0].hotelDetail.supplierRemarks) &&
      <table width="100%" cellSpacing="0" cellPadding="10" className="cellpad10">
        <tbody>
          <tr>
            <td valign="top" style={{lineHeight:'21px'}}>
              {JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks) &&  JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo &&
              <>
              {JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.HotelRemarks.length > 0 &&
              <>
              {
                JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.HotelRemarks.map((a, i) => (
                  <div key={i}>
                    <div style={{fontSize:'17px', marginBottom:'5px', color:'#124680'}}>
                      <strong>{a.RemarkCaption}</strong>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:a.Text}}></div>
                  </div>
                ))
              }
              </>
              }

              {JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.checkinInstruction && JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.checkinInstruction.HotelRemarks && JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.checkinInstruction.HotelRemarks.length > 0 &&
              <>
              {
                JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.checkinInstruction.HotelRemarks.map((b, j) => (
                  <div key={j}>
                    <div style={{fontSize:'17px', marginBottom:'5px', color:'#124680'}}>
                        <strong>{b.RemarkCaption}</strong>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:b.Text}}></div>
                  </div>
                ))
              }
              </>
              }

              { 
              JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.CheckOutInstruction  && JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.CheckOutInstruction.HotelRemarks && JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.CheckOutInstruction.HotelRemarks.length > 0 &&
              <>
              {
                JSON.parse(bDtls.hotels[0].hotelDetail.supplierRemarks).hotelInfo.CheckOutInstruction.HotelRemarks.map((c, k) => (
                  <div key={k}>
                    <div style={{fontSize:'17px', marginBottom:'5px', color:'#124680'}}>
                        <strong>{c.RemarkCaption}</strong>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html:c.Text}}></div>
                  </div>
                ))
              }
              </>
              }
              
              </>
              }
              
            </td>
          </tr>
        </tbody>
      </table>

      }
     

    </>
  )
}

export default HotelBooking
