import {
  RESET_TOUR_RES, SET_TOURRES, SET_OGTOURRES, 
  RESET_TOUR_FILTER, SET_TOURFILTER, SET_TOURFILTERSORT,
  RESET_TOURDETRES, SET_TOURDETRES, RESET_TOUROPTRES, SET_TOUROPTRES  } from "../types/tourTypes";
  
const intialState = {
  tourResObj:null,
  tourResOrgObj:null,
  tourFltr: {
    priceFilter: []
  },
  tourFilterSort : {
    srtVal: '0',
    srchTxt: ''
  },
  tourDtlObj:null,
  tourOptObj:null,
};

export const tourListResultReducer = (state = intialState.tourResObj, { type, payload }) => {
  switch (type) {
    case RESET_TOUR_RES:
      return { ...state, tourResObj: null, tourResOrgObj: null };
    case SET_OGTOURRES:
      return { ...state, tourResOrgObj: payload };
    case SET_TOURRES:
      return { ...state, tourResObj: payload };
    
    default:
      return state;
  }
};

export const tourFltrReducer = (state = intialState.tourFltr, { type, payload }) => {
  switch (type) {
    case RESET_TOUR_FILTER:
      return { ...state, tourFltr:{priceFilter: []}, tourFilterSort:{srtVal: '0',srchTxt: ''} };
    case SET_TOURFILTER:
      return {...state, tourFltr: payload}  
    default:
      return state;
  }
};

export const tourFltrSortReducer = (state = intialState.tourFilterSort, { type, payload }) => {
  switch (type) {
    case RESET_TOUR_FILTER:
      return { ...state, tourFltr:{priceFilter: []}, tourFilterSort:{srtVal: '0',srchTxt: ''} };
    case SET_TOURFILTERSORT:
      return {...state, tourFilterSort: payload}  
    default:
      return state;
  }
};


export const tourDetailsReducer = (state = intialState.tourDtlObj, { type, payload }) => {
  switch (type) {
    case RESET_TOURDETRES:
      return { ...state, tourDtlObj: null};
    case SET_TOURDETRES:
      return { ...state, tourDtlObj: payload };
    default:
      return state;
  }
};

export const tourOptionsReducer = (state = intialState.tourOptObj, { type, payload }) => {
  switch (type) {
    case RESET_TOUROPTRES:
      return { ...state, tourOptObj: null};
    case SET_TOUROPTRES:
      return { ...state, tourOptObj: payload };
    default:
      return state;
  }
};