 import {PaymentService} from "../../services/payment.service";
//import {SET_HOTEL_PREBOOK_REQ, SET_HOTEL_PREBOOK_RES} from "../types/paymentTypes";

export const doPreBookingReq = (req) => {
    return async () => {
        try {
            //dispatch({ type: SET_HOTEL_PREBOOK_REQ, payload: req })
            const response = await PaymentService.doPreBooking(req);
            //dispatch({ type: SET_HOTEL_PREBOOK_RES, payload: response })
            return response
        } catch (err) {
            console.log("Prebooking Error", err);
            return false
        }
    }
}

export const doPaymentReq = (req) => {
    return async () => {
        try {
            const response = await PaymentService.doPayment(req);
            return response
        } catch (err) {
            console.log("Payment Booking Error", err);
            return false
        }
    }
}

export const doCnfmBooking = (req) => {
    return async () => {
        try {
            const response = await PaymentService.doCnfmBooking(req);
            return response
        } catch (err) {
            console.log("Confirm Booking Error", err);
            return false
        }
    }
}