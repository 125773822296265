import React, {useEffect, useState } from "react";
import {Container} from 'react-bootstrap';
import DefaultLayout from '../../layouts/DefaultLayout';
const Privacy = () => {

  return (
    <>
    <DefaultLayout>
        <div className="innermiddle my-4">
            <Container>
                <div className="bg-white shadow-sm p-4 rounded">
                    <h1 className="fn21 green fw-bold text-uppercase">Privacy Policy</h1>
                    <br />
                    <h3 className="green fw-bold fn16"><strong>Who are we?</strong></h3>
                    <p>Welcome to the kizan.com.sa ("<strong>Kizan</strong>") privacy policy. <strong>"KIZAN E TRAVEL SERVICES DMCC With United Experts for Tourism Company"</strong> owns and operates the booking platform which can be accessed on <a href="https://www.kizan.com.sa/" target="_blank">www.kizan.com.sa</a> and <a href="https://b2b.kizan.com.sa/" target="_blank">b2b.kizan.com.sa</a> (the "<strong>Platform</strong>") and the Kizan smartphone app for iOS and Android devices (the "<strong>App</strong>"), collectively referred to as the Kizan "<strong>Service</strong>".</p>
                    <h3 className="green fw-bold fn16"><strong>How does Kizan work?</strong></h3>
                    <p>We have developed the Kizan Service to enable travel agents ("<strong>Travel Agents</strong>") to book hotels, travel services with our selected hotels, tour operators, car rental companies and private transfer providers ("<strong>Suppliers</strong>"), in real time, on behalf of their end customers ("<strong>Customers</strong>").</p>
                    <p>We are committed to protecting and respecting the privacy of the users of the Service. This privacy policy (“Privacy Policy”) sets out the basis on which (i) your personal data, as a Travel Agent or Supplier or any representatives that you authorise to use the Service on your behalf, will be processed by us as a controller and (ii) your Customers' personal data will be processed by us as a processor. Please read the Privacy Policy carefully to understand how we will use your personal data.</p>
                    <h3 className="green fw-bold fn16"><strong>How can you contact us?</strong></h3>
                    <p>If you have any questions about your personal data, how we look after it or if there are any changes to your personal data, please email us at <a href="mailto:support@kizan.com.sa">support@kizan.com.sa</a>;</p>
                    <h3 className="green fw-bold fn16"><strong>Kizan as controller and processor</strong></h3>
                    <p><strong>Controller</strong></p>
                    <p>If you visit our register on our Platform, or download our App as a Travel Agent or Supplier, we may use your or your representatives' personal data in connection with your request for or use of our Services. In order to use the Services, you must accept our Terms and Conditions which are available here www.kizan.com.sa/terms. Where this is the case, in accordance with UK and European Economic Area ("EEA") data protection laws, we will be the controller of the personal data that you provide to us or that we collect from you. We will be responsible for safeguarding your personal data which will be processed in accordance with this Privacy Policy.</p>
                    <p><strong>Processor</strong></p>
                    <p>Our Service is intended for use by Travel Agents and Suppliers. However, our Service provides the functionality for Travel Agents to input and collect information about their Customers which is then sent to our Suppliers. Where we process any Customer personal data, our role will be limited to that of an intermediary and we will be considered a processor in accordance with UK and EEA data protection laws. We will be processing Customer personal data on behalf of Travel Agents, who will be the controllers of any Customer personal data, under the terms and conditions that we have in place with the Travel Agents. The Travel Agents will be responsible for the processing of Customer personal data on our Platform or App which will be processed in accordance with their own privacy policies. For example, a Travel agent may input Customer personal data in order to search for a hotel and send an email to the Customer's email address to provide a quotation or send a voucher for the reservation.</p>
                    <p>We may also collect, use and share aggregated data such as statistical or demographic data for any purpose. Aggregated data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate Customer personal data from confirmed bookings for internal reporting purposes.</p>
                    <h3 className="green fw-bold fn16"><strong>What data do we collect about you?</strong></h3>
                    <p>When you use the Service, we may collect, use, store, transfer and otherwise process the following types of data depending on your relationship with us:</p>
                    <p><strong>TRAVEL AGENT/ SUPPLIER</strong></p>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr className="bg-light">
                                    <th>Category of data</th>
                                    <th>Types of data collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Business representative contact details</td>
                                    <td>
                                        When you register for our Service on behalf of your organisation, you may be required to provide us with your:<br />
                                        <ul className="bulletList mb-2">
                                            <li>Full name;</li>
                                            <li>
                                                Job title;
                                            </li>
                                            <li>
                                                Work email address; and
                                            </li>
                                            <li>
                                                Work telephone number.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Company Data</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Company name;</li>
                                            <li>
                                                Company identification number;
                                            </li>
                                            <li>
                                                Company location or country of registration;
                                            </li>
                                            <li>
                                                Company telephone number;
                                            </li>
                                            <li>
                                                Company postal address; and
                                            </li>
                                            <li>
                                                Company VAT registration number or equivalent tax ID.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Billing Data</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Company debit/credit card details;</li>
                                            <li>
                                                Company bank account number;
                                            </li>
                                            <li>
                                                Invoicing currency; and
                                            </li>
                                            <li>
                                                Billing address.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Feedback and Enquiry</td>
                                    <td>Information about you from correspondence that you send to us, any conversations between us and any feedback you give to us.</td>
                                </tr>
                                <tr>
                                    <td>Marketing and Communications Data</td>
                                    <td>Information about your preferences in receiving marketing material from us and your communication preferences.</td>
                                </tr>
                                <tr>
                                    <td>Technical Data</td>
                                    <td>
                                        We may automatically collect the following types of information when you use our Services:<br />
                                        <ul className="bulletList mb-2">
                                            <li>Internet Protocol (IP) address;</li>
                                            <li>Browser type and version;</li>
                                            <li>Time zone setting;</li>
                                            <li>
                                                Browser plug-in types and versions; and
                                            </li>
                                            <li>
                                                Operating system and platform.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Usage Data</td>
                                    <td>We may generate information about how you navigate and engage with our Services and download materials and online activity data such as clickstream data, page interaction information, your preferences (including country and language) and methods used to browse away from our content or Services.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>The law treats some types of personal data about you as “special” and such data requires more protection because of its sensitive nature. This includes information about your race, religion, health, sex life, sexual orientation or criminal offences. We will only collect or use this type of personal data (a) with your explicit consent or (b) if the law requires us to do so.</p>
                    <p><strong>TRAVEL AGENT/ SUPPLIER</strong></p>
                    <h3 className="green fw-bold fn16"><strong>CUSTOMER</strong></h3>
                    <p>The Travel Agent may choose to input, collect or use some or all of the following types of the data from their Customers when using our Service:</p>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr className="bg-light">
                                    <th>Category of data</th>
                                    <th>Types of data collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Identity and  contact data</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Full name;</li>
                                            <li>
                                                Phone number(s);
                                            </li>
                                            <li>
                                                Email address;
                                            </li>
                                            <li>
                                                Postal and/or billing address;
                                            </li>
                                            <li>
                                                Country of residence;
                                            </li>
                                            <li>
                                                Country of travel;
                                            </li>
                                            <li>
                                                Passport details;
                                            </li>
                                            <li>
                                                Date of birth;
                                            </li>
                                            <li>
                                                Nationality; and
                                            </li>
                                            <li>
                                                Photo (as included on passport).
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Payment Data</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Name of card holder; </li>
                                            <li>
                                                Account number;
                                            </li>
                                            <li>
                                                Debit/credit card details.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Special requirements Data</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Special needs/requirements such as dietary information and medical information, where provided.</li>
                                        </ul>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <h3 className="green fw-bold fn16"><strong>How do we collect personal data about you?</strong></h3>
                    <p>We collect personal data in different ways, depending on whether you are Travel Agent or Customer, including:</p>
                    <p><strong>TRAVEL AGENT</strong></p>
                    <ul className="bulletList mb-2">
                        <li>
                            <strong>Directly from you via the Platform/App</strong><br />
                            We will collect your personal data directly from the Platform or App when you choose to register an account with us.
                            When you provide us with personal data about your Customers, you must ensure that you are authorised to disclose that information to Kizan and Kizan may collect, use and share such data for the purposes described in this Privacy Policy.
                        </li>
                        <li>
                            <strong>Automated technologies</strong><br />
                            When you interact with the Platform or App, we may automatically collect technical data about your mobile device, browsing actions and patterns. We collect this personal data using cookies and other similar tracking technologies. We may also receive technical data about you if you visit other websites through the Platform, or App using our cookies. For more information about cookies, please see our Cookie Policy.
                        </li>
                    </ul>
                    <p><strong>CUSTOMER</strong></p>
                    <ul className="bulletList mb-2">
                        <li>
                            <strong>From our registered Travel agents</strong><br />
                            Travel Agents registered for our Services, may input, use and share personal data about Customers for the purpose of making a booking for one of our travel services with our Suppliers.<br />
                            Any Customer personal data processed and used in connection with our Services, will be done so in accordance with the relevant Travel Agent's privacy policy.
                        </li>
                    </ul>
                    <h3 className="green fw-bold fn16"><strong>How do we use your personal data?</strong></h3>
                    <p>We collect your personal data so that we can provide you with our Services. We will only use your personal data where we have a valid lawful basis to do so. We have included a table below which lists all the ways in which we use your personal data and the lawful basis we rely on to do so.</p>
                    <p>Where we mention “legitimate interests”, this is the lawful basis we rely on when we feel that it is necessary to use your personal data for a reason which is in our and/or your interests and which does not unfairly affect your rights over your personal data.</p>
                    <p><strong>TRAVEL AGENT / SUPPLIER</strong></p>

                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr className="bg-light">
                                    <th>What we use your personal data for</th>
                                    <th>What personal data we use</th>
                                    <th>The lawful basis that we rely on to use your personal data</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>To provide our Services to you, including registering your account on the Platform and App</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Business representative contact data;</li>
                                            <li>
                                                Company data; and
                                            </li>
                                            <li>
                                                Billing data.
                                            </li>
                                        </ul>
                                    </td>
                                    <td>Performing our contract with you.</td>
                                </tr>
                                <tr>
                                    <td>To send you information about our Services which you have requested by contacting us </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Business representative contact data;</li>
                                            <li>
                                                Company data; and
                                            </li>
                                            <li>
                                                Enquiry data.
                                            </li>
                                        </ul>
                                    </td>
                                    <td>To take necessary steps at your request prior to entering into a contract with you.</td>
                                </tr>
                                <tr>
                                    <td>
                                        To provide our customer service to you:
                                        <ul className="bulletList mb-2">
                                            <li>to manage our relationship with you; </li>
                                            <li>
                                                to send you service communications about matters relevant to your use of our Services such as changes to our terms and conditions; to
                                            </li>
                                            <li>
                                                to communicate with you if you have asked us a question or made a complaint with us; and
                                            </li>
                                            <li>
                                                to provide our chat support function within the Platform and App.
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Business representative contact data; </li>
                                            <li>
                                                Company data;
                                            </li>
                                            <li>
                                                Feedback and enquiry data.
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Performing our contract with you;</li>
                                            <li>
                                                To comply with our legal duties; and/or
                                            </li>
                                            <li>
                                                Our legitimate interests of understanding you better to provide a better service to you for your benefit.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>To use data analytics to improve our Platform, App, products and services, marketing, user and customer relationships and experiences</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Usage data; and</li>
                                            <li>
                                                Technical data
                                            </li>
                                        </ul>
                                    </td>
                                    <td>Our legitimate interests of understanding you better to provide a better service to you for your benefit.</td>
                                </tr>
                                <tr>
                                    <td>To monitor operations, user activity and networks for fraud prevention and crime detection.</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Business contact representative data;</li>
                                            <li>
                                                Usage data; and
                                            </li>
                                            <li>
                                                Technical data.
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Our legitimate interests in detecting and preventing fraud and illegal conduct; or </li>
                                            <li>
                                                Necessary for compliance with a legal obligation to which we are subject.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        To send you marketing communications if you would like to receive them.<br /><br />
                                        We will only send you marketing communications about Kizan products/services that we consider may be of interest to you.
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Business contact representative data;</li>
                                            <li>
                                                Usage data; and
                                            </li>
                                            <li>
                                                Technical data.
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        Your express consent (where legally required to do so)<br /><br />
                                        If you are:<br />
                                        <ul className="bulletList mb-2">
                                            <li>a business customer (including, a director, officer or employee of the organisation); or</li>
                                            <li>have previously purchased products and/or services from us; </li>
                                        </ul>
                                        we will assume you are happy to receive relevant marketing communications unless you have previously opted out of such communications. This is based on our legitimate interests in promoting our business and to provide you with offers for relevant products/services.<br /><br />
                                        If you change your mind about this, you can withdraw your consent at any time by clicking on the “unsubscribe” link at the bottom of each email. <br /><br />
                                        If you choose to withdraw your consent to receiving these marketing emails, we will still be able to send you service emails that are necessary for our relationship with you.
                                    </td>
                                </tr>
                                <tr>
                                    <td>To share your information with our third-party service providers which facilitate the provision of our Services such as our Suppliers (if you are a Travel Agent) and our Travel Agents (if you are a Supplier) and the fulfilment of essential service functions, such as web hosting, cloud storage, analytics, marketing, accounting, security tools. </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Any data that is necessary and proportionate to enable our Services.</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Performing our contract with you; and</li>
                                            <li>
                                                Our legitimate interests in ensuring the proper operation of our Services.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>To share data with another organisation in the context of a joint venture, collaboration, financing, sale, merger, reorganisation or similar event relating to our business.</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Any data that is necessary and proportionate in connection with the event.</li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Our legitimate interests in acting in the best interests or our shareholders and/or investors; and</li>
                                            <li>
                                                Complying with our legal obligations.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        We are required to comply with certain legal and regulatory requirements and may process your personal data for compliance with such legal or regulatory obligation, to which we or regulators or law enforcement agencies are subject.<br /><br />
                                        To establish, exercise or defend legal claims.
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Business representative contact details; </li>
                                            <li>
                                                Company data; and
                                            </li>
                                            <li>
                                                Any other data as required by the regulators and/or law enforcement agencies.
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>To comply with our legal duties</li>
                                            <li>
                                                Where appropriate and proportionate, in order to satisfy our legitimate interests in complying with best practice or applicable laws.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p><strong>CUSTOMER</strong></p>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <thead>
                                <tr className="bg-light">
                                    <th>What we use your personal data for</th>
                                    <th>What personal data we use</th>
                                    <th>The lawful basis that we rely on to use your personal data</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>To provide our Services to Travel Agents that have registered an account with us, including enabling registered Travel Agents to input Customer personal data on the Platform/App for the purposes of fulfilling a booking for one of our travel services.</td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Identity and contact details;</li>
                                            <li>
                                                Payment data; and
                                            </li>
                                            <li>
                                                Special requirements data.
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        Not applicable. <br /><br />
                                        We only use your personal data on behalf of our registered Travel Agents and in accordance with their instructions.
                                    </td>
                                </tr>
                                <tr>
                                    <td>To contact you in exceptional circumstances where the Travel Agent is unable to contact you. </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>Name</li>
                                            <li>
                                                Email address
                                            </li>
                                            <li>
                                                Phone number
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        Not applicable. <br /><br />
                                        We only use your personal data on behalf of our registered Travel Agents and in accordance with their instructions.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>We will only use your personal data for the reasons we have set out above. If we need to use your personal data for any other reason, we will let you know and tell you the reason along with the relevant lawful basis, unless the law prevents us from doing so.</p>
                    <h3 className="green fw-bold fn16"><strong>Who do we share your personal data with?</strong></h3>
                    <p>We may share your personal data with other organisations so we can provide our Service to you and comply with our legal duties. We have set out below who these organisations are and the reasons for sharing your personal data with such organisations.</p>
                    <ul className="bulletList mb-2">
                        <li>
                            <strong>Our Suppliers (if you are a Travel Agent) </strong>- in order to fulfil a booking on behalf of your Customer and to provide our Service, including:
                            <ul>
                                <li>hotels;</li>
                                <li>
                                    tour operators;
                                </li>
                                <li>
                                    car rental companies;
                                </li>
                                <li>
                                    private transfer providers; and
                                </li>
                                <li>
                                    any intermediary booking platforms such as Expedia.
                                </li>
                            </ul>
                        </li>
                        <li><strong>Our Travel Agents (if you are a Supplier)</strong> - in order to fulfil a booking on behalf of our Travel Agent's Customers to provide our Service.</li>
                        <li>
                            <strong>Our service providers</strong> - who help us operate and improve our services by assisting with various tasks including:
                            <ul>
                                <li>payment service providers;</li>
                                <li>
                                    marketing;
                                </li>
                                <li>
                                    personal information hosting and maintenance;
                                </li>
                                <li>
                                    analytics;
                                </li>
                                <li>
                                    security operations; and
                                </li>
                                <li>
                                    other third-party service providers that we may use from time to time.
                                </li>
                            </ul>
                        </li>
                        <li><strong>Third party purchaser</strong> - in the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.</li>
                    </ul>
                    <p>You can ask us for more information about these organisations by contacting us using the details at the top of this policy.</p>
                    <p>We may also need your share your personal data to comply with the law; to enforce our Terms and Conditions; to protect the rights, property or safety of us, other Platform or App users or other individuals/organisations; or where you have given us your consent to do so.</p>
                    <h3 className="green fw-bold fn16"><strong>Where do we store your information?</strong></h3>
                    <p>We will not transfer or store your personal data outside of the European Economic Area (EEA) (or the UK to the extent the UK is no longer part of the EU), except to selected third parties that we have instructed to help us provide the services to you.</p>
                    <p>If we do transfer or store your personal data outside of the EEA, we will ensure we have put adequate measures in place in order to protect your personal data to an equivalent data protection standard as in the UK and the EEA.</p>
                    <p>We will only share your personal data with countries and organisations that:</p>
                    <ul className="bulletList mb-2">
                        <li>The European Commission has deemed as having equivalent data protection laws as in the EEA; or</li>
                        <li>We have entered into a contract with which include certain requirements to make sure your personal data is protected to equivalent standards as in the EEA and the UK.</li>
                    </ul>
                    <p>If there are any other circumstances which would require us to transfer your personal data outside of the EEA (or the UK to the extent the UK is no longer part of the EU), we will seek your consent to transfer your personal data outside of the EEA.</p>
                    <p>You can ask us for more information about where we may transfer or store your personal data and how we will take steps to ensure your personal data is protected by using the contact details in section 3 of this policy.</p>
                    <h3 className="green fw-bold fn16"><strong>How do we keep your personal data secure?</strong></h3>
                    <p>We will ensure we put security measures in place to protect your personal data from being destroyed, lost or shared with somebody that should not see it. However, we cannot guarantee your personal data will be free from every security risk that may be possible when your information is sent to and from the Platform or App.</p>
                    <p>The Platform or App may include links to third-party websites which may collect your personal data. We have no control over what these websites do with your personal data. Please check the policies on these websites which will tell you what they do with your personal data.</p>
                    <h3 className="green fw-bold fn16"><strong>How do we use cookies?</strong></h3>
                    <p>We use cookies or other similar technologies to capture certain data when you interact with the Platform or App. For further information on our use of cookies, please see our Cookie Policy.</p>
                    <h3 className="green fw-bold fn16"><strong>How long do we keep your information for?</strong></h3>
                    <p>We will only keep your personal data for as long as is necessary for us to do so for the reasons we collected it for in the first place.</p>
                    <p><strong>TRAVEL AGENT</strong></p>
                    <p>Generally, we will keep your personal data for as long as you are a registered for our Services and have an account with us.</p>
                    <p>After this, we may keep your personal data for up to 3 years (or longer where the law requires). This is so we can:</p>
                    <ul className="bulletList mb-2">
                        <li>Communicate with you about any questions or complaints you may have after you have stopped being a user of our Services; or</li>
                        <li>To comply with the rules on accounting, reporting or any other law.</li>
                    </ul>
                    <p>If you want to exercise any of the rights listed above at any time, please contact us using the details in section 3 of this policy.</p>
                    <p><strong>CUSTOMER</strong></p>
                    <p>We will only keep Customer personal data for a 12 month period to comply with the rules on accounting, reporting or any other law or the period determined by the relevant Travel Agent which is the controller of the Customer personal data. For details on how long the relevant Travel Agent keeps your personal data for, please contact them or read their privacy policies.</p>
                    <p>In certain circumstances, we may aggregate your personal data (so that it will no longer identify you) for research, analytical or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>
                    <p>If you want further information on how long we keep your personal data, please contact us using the details set out in section 3 of this policy.</p>
                    <h3 className="green fw-bold fn16"><strong>What are your rights in relation to your personal data?</strong></h3>
                    <p>As a result of us collecting and processing your personal data, you may have the following legal rights:</p>
                    <ul className="bulletList mb-2">
                        <li>To access the personal data we hold about you and request a copy of it;</li>
                        <li>To ask us to correct your personal data if it is inaccurate or incomplete;</li>
                        <li>To ask us to delete your personal data where we do not have a compelling reason to continue to process your personal data in such circumstances;</li>
                        <li>To ask us to stop using your personal data in certain circumstances where there is no need for us to continue processing it in certain circumstances;</li>
                        <li>To ask us to only use your personal data for certain things if: it is not accurate; it has been used unlawfully; or it is not relevant for the purposes it was initially collected for; and/or</li>
                        <li>To ask us to stop using your personal data to send you marketing emails.</li>
                    </ul>
                    <p>If you want to exercise any of the rights listed above at any time, please contact us using the details in section 3 of this policy.</p>
                    <h3 className="green fw-bold fn16"><strong>How can you make a complaint?</strong></h3>
                    <p>You also have the right to make a complaint about how we have used your personal data to:</p>
                    <ul className="bulletList mb-2">
                        <li>Us - by contacting us by email or post using the details in section 3 of this policy and we will always try to help you with your problem in the first instance.</li>
                    </ul>
                    <p>If you're still not happy with how we have dealt with your complaint, you can contact:</p>
                    <ul className="bulletList mb-2">
                        <li>A data protection regulator; or</li>
                        <li>The national courts - to seek a remedy if you think that your rights in relation to your personal data have been breached.</li>
                    </ul>
                    <h3 className="green fw-bold fn16"><strong>Changes to this Privacy Policy</strong></h3>
                    <p>We may need to change this Privacy Policy as our Service develops and offers you more exciting features or if the law changes. We will let you know if any important changes are made, otherwise, we will always display the latest version of our policy on our Platform and App.</p>
                    <h3 className="green fw-bold fn16"><strong>KIZAN WEBSITE PRIVACY POLICY</strong></h3>
                    <h3 className="green fw-bold fn16"><strong>Who are we?</strong></h3>
                    <p>Welcome to the Kizan website privacy policy. ("<strong>Kizan</strong>") is a provider of a B2A (Business to Agent) travel portal (the "<strong>Platform</strong>") that allows registered travel agents ("<strong>Travel Agents</strong>") to book hotels, flights and other travel services with our selected hotels, tour operators, car rental companies and private transfer providers (“Suppliers”) in real time on behalf of their end customers ("<strong>Customers</strong>"). References in this privacy policy ("<strong>Privacy Policy</strong>") to "<strong>we</strong>", "<strong>our</strong>", or "<strong>us</strong>" are references to Kizan. We operate the Kizan website available at https://www.kizan.com.sa/ (the "<strong>Website</strong>") to promote and provide further information and content about the Kizan Platform and to provide you with the opportunity to register to use the Platform and receive information and promotional materials about those services.</p>
                    <h3 className="green fw-bold fn16"><strong>About this Policy</strong></h3>
                    <p>We are committed to protecting and respecting the privacy of the users of the Service. This Privacy Policy sets out the basis on which your personal data, as a visitor of our Website, will be processed by us as a controller. If you visit our Website, we may use your personal data in connection with your request for or use of our services. In accordance with UK and European Economic Area (“EEA”) data protection laws, we are the controller of the personal data that you provide to us or that we collect from you. We are responsible for safeguarding your personal data which will be processed in accordance with this Privacy Policy. Please read the Privacy Policy carefully to understand how we will use your personal data.</p>
                    <p>Once you have created an account for our Platform, any personal data that we process of our Suppliers, Travel Agents, and their Customers will be governed by our Platform Privacy Policy.</p>
                    <h3 className="green fw-bold fn16"><strong>How can you contact us?</strong></h3>
                    <p>If you have any questions about your personal data, how we look after it or if there are any changes to your personal data, please email us at <a href="mailto:support@kizan.com.sa">support@kizan.com.sa</a> ;</p>
                    <h3 className="green fw-bold fn16"><strong>What personal data do we collect and process, and how do we use it?</strong></h3>
                    <p>When you access the Website, you may provide us with personal data, and we may collect and process such personal data in accordance with this Privacy Policy.</p>
                    <p>We will only use your personal data where we have a valid lawful basis to do so. We have included a table below which lists all the ways in which we use your personal data and the lawful basis we rely on to do so.</p>
                    <p>Where we mention “legitimate interests”, this is the lawful basis we rely on when we feel that it is necessary to use your personal data for a reason which is in our and/or your interests and which does not unfairly affect your rights over your personal data.</p>
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>What personal data do we process?</td>
                                    <td width="300">Why do we process this personal data?</td>
                                    <td>What is our lawful basis for processing?</td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Information you give us</strong></td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Identity Data:</strong> this may include your full name, geographical location and other personal identifiers. Additional data may be collected about you from the correspondence that you send to us, any conversations you have with us and any feedback you give us.<br /><br />
                                        <strong>Admin Contact Data:</strong> this may include your professional email address, phone number(s), postal address and any other contact details you may choose to provide to us.<br /><br />
                                        <strong>Company Data:</strong> this may include information about the company you work for including the company name, email address, phone number and postal address.
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>To register your account on the Platform on behalf of your company (whether as a Travel Agent or Supplier) </li>
                                            <li>
                                                To respond to your enquiry if you contact our customer service team
                                            </li>
                                            <li>
                                                To provide you with a demo of the Platform if you request it
                                            </li>
                                            <li>
                                                To contact you with details of our Platform if you request them
                                            </li>
                                            <li>
                                                To send you marketing and promotional material if you sign up to receive it
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>To perform our contract with you or to take steps prior to entering into a contract with you</li>
                                            <li>
                                                To satisfy our legitimate interests (to run and operate the Website, run our business and maintain our records)
                                            </li>
                                            <li>
                                                Your express consent (where legally required to do so).
                                            </li>
                                        </ul>
                                        <strong>If you are:</strong><br /><br />
                                        <ul className="bulletList mb-2">
                                            <li>a business customer (including, a director, officer or employee of the organisation); or</li>
                                            <li>
                                                have previously purchased products and/or services from us;
                                            </li>
                                        </ul>
                                        we will assume you are happy to receive relevant marketing communications unless you have previously opted out of such communications. This is based on our legitimate interests in promoting our business and to provide you with offers for relevant products/services.<br /><br />
                                        If you change your mind about this, you can withdraw your consent at any time by clicking on the “unsubscribe” link at the bottom of each email.<br /><br />
                                        If you choose to withdraw your consent to receiving these marketing emails, we will still be able to send you service emails that are necessary for our relationship with you.

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3"><strong>Information automatically collected when you browse our Website</strong></td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Technical Data:</strong> we may automatically collect data regarding general technical use of the Website, such as your IP address, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform. We may also receive technical data about you if you visit other websites through the Website using our cookies. For more information about cookies, please see our Cookie Policy.<br /><br />
                                        <strong>Usage Data:</strong> we may generate information about how you navigate and engage with our Website and download materials and online activity data such as clickstream data, page interaction information, your preferences (including country and language) and methods used to browse away from our content or Website.<br /><br />
                                        <strong>Location:</strong> we can identify the country that you are located in based on your IP address.
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>To identify any problems, defects or issues with the Website</li>
                                            <li>
                                                To optimise the performance of the Website
                                            </li>
                                            <li>
                                                To improve future versions of the Website
                                            </li>
                                            <li>
                                                To monitor operations, user activity and networks for fraud prevention and crime detection
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <ul className="bulletList mb-2">
                                            <li>To satisfy our legitimate interest (to enable you to use the Website most effectively, improve the usability of the Website and make any necessary modifications and updates to the Website).</li>
                                            <li>
                                                Necessary for compliance with a legal obligation to which we are subject.
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>We will only use your personal data for the reasons we have set out above. If we need to use your personal data for any other reason, we will let you know and tell you the reason along with the relevant lawful basis, unless the law prevents us from doing so.</p>
                    <h3 className="green fw-bold fn16"><strong>Who do we share your personal data with?</strong></h3>
                    <p>By using the Website, we may need to disclose your personal data to the following categories of third parties in limited circumstances so that we can operate our Website and comply with our legal duties.</p>
                    <ul className="bulletList mb-2">
                        <li><strong>With our third party service providers:</strong> your personal data may be held and processed securely by us on the dedicated systems supplied, monitored and maintained by our third party infrastructure providers, and by any other third party service providers we may use, such as our Website hosting provider, customer relationship management provider, analytics, security operations and other third-party service providers.</li>
                        <li>
                            <strong>In corporate transactions -</strong> we may transfer your personal information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.
                        </li>
                        <li>
                            <strong>Regulators or law enforcement agencies:</strong> we may disclose your personal information if reasonably necessary with regulators, law enforcement agencies or where mandatory under a court order: (i) to comply with a legal process, such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements; (ii) to assist in the prevention or detection of crime (subject in each case to applicable law); or (iii) to protect the safety of any person.
                        </li>
                        <li>
                            <strong>To enforce our legal rights:</strong> we may also share your personal data: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.
                        </li>
                        <li>
                            <strong>With other entities within the Group:</strong> when it is necessary for operational reasons such as the use of a group-wide logistics and IT infrastructure and for any administrative purposes to organise, develop and deliver our services, run our organisation and decide on future strategies.
                        </li>
                    </ul>
                    <p>You can ask us for more information about these organisations by contacting us using the details at the top of this policy.</p>
                    <h3 className="green fw-bold fn16"><strong>Where do we store your information?</strong></h3>
                    <p>We will not transfer or store your personal data outside of the European Economic Area (EEA) (or the UK to the extent the UK is no longer part of the EU), except to selected third parties that we have instructed to help us provide the services to you.</p>
                    <p>If we do transfer or store your personal data outside of the EEA, we will ensure we have put adequate measures in place in order to protect your personal data to an equivalent data protection standard as in the UK and the EEA.</p>
                    <p>We will only share your personal data with countries and organisations that:</p>
                    <ul className="bulletList mb-2">
                        <li>The European Commission has deemed as having equivalent data protection laws as in the EEA; or</li>
                        <li>We have entered into a contract with which include certain requirements to make sure your personal data is protected to equivalent standards as in the EEA and the UK.</li>
                    </ul>
                    <p>If there are any other circumstances which would require us to transfer your personal data outside of the EEA (or the UK to the extent the UK is no longer part of the EU), we will seek your consent to transfer your personal data outside of the EEA.</p>
                    <p>You can ask us for more information about where we may transfer or store your personal data and how we will take steps to ensure your personal data is protected by using the contact details in section 3 of this policy.</p>
                    <h3 className="green fw-bold fn16"><strong>How do we keep your personal data secure?</strong></h3>
                    <p>We will ensure we put security measures in place to protect your personal data from being destroyed, lost or shared with somebody that should not see it. However, we cannot guarantee your personal data will be free from every security risk that may be possible when your information is sent to and from the Website.</p>
                    <p>Where we have given you (or where you have chosen) a password which enables you to access certain parts of our services, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
                    <p>The Website may include links to third-party websites which may collect your personal data. We have no control over what these websites do with your personal data. Please check the policies on these websites which will tell you what they do with your personal data.</p>
                    <h3 className="green fw-bold fn16"><strong>How do we use cookies?</strong></h3>
                    <p>We use cookies or other similar technologies to capture certain data when you interact with the Website. For further information on our use of cookies, please see our Cookie Policy.</p>
                    <h3 className="green fw-bold fn16"><strong>How long do we keep your information for?</strong></h3>
                    <p>We will only keep your personal data for as long as is necessary for us to do so for the reasons we collected it for in the first place as set out in this Privacy Policy or as otherwise required by applicable laws. We store your personal data in line with legal, regulatory, financial and good-practice requirements.</p>
                    <p>If you want further information on how long we keep your personal data, please contact us using the details set out in section 3 of this policy.</p>
                    <h3 className="green fw-bold fn16"><strong>What are your rights in relation to your personal data?</strong></h3>
                    <p>As a result of us collecting and processing your personal data, you may have the following legal rights:</p>
                    <ul className="bulletList mb-2">
                        <li>To access the personal data we hold about you and request a copy of it;</li>
                        <li>
                            To ask us to correct your personal data if it inaccurate or incomplete;
                        </li>
                        <li>
                            To ask us to delete your personal data where we do not have a compelling reason to continue to process your personal data in such circumstances;
                        </li>
                        <li>
                            To ask us to stop using your personal data in certain circumstances where there is no need for us to continue processing it in certain circumstances;
                        </li>
                        <li>
                            To ask us to only use your personal data for certain things if: it is not accurate; it has been used unlawfully; or it is not relevant for the purposes it was initially collected for; and/or
                        </li>
                        <li>
                            To ask us to stop using your personal data to send you marketing emails.
                        </li>
                    </ul>
                    <p>If you want to exercise any of the rights listed above at any time, please contact us using the details in section 3 of this policy.</p>
                    <p>We will respond to all requests that we receive from users in accordance with applicable data protection laws. We may ask you to provide proof of identity before we can answer the above requests. In some cases, we may reject requests for certain reasons (for example, if the request is unlawful).</p>
                    <h3 className="green fw-bold fn16"><strong>How can you make a complaint?</strong></h3>
                    <p>You also have the right to make a complaint about how we have used your personal data to:</p>
                    <ul className="bulletList mb-2">
                        <li>Us - by contacting us by email or post using the details in section 3 of this policy and we will always try to help you with your problem in the first instance.</li>
                    </ul>
                    <p>If you're still not happy with how we have dealt with your complaint, you can contact:</p>
                    <ul className="bulletList mb-2">
                        <li>A data protection regulator; or</li>
                        <li>The national courts - to seek a remedy if you think that your rights in relation to your personal data have been breached.</li>
                    </ul>
                    <h3 className="green fw-bold fn16"><strong>Changes to this Privacy Policy</strong></h3>
                    <p>We may need to change this Privacy Policy as our Service develops and offers you more exciting features or if the law changes. We will let you know if any important changes are made, otherwise, we will always display the latest version of our policy on our Website.</p>
                </div>
            </Container>
        </div>
    </DefaultLayout>
    </>
  )
}

export default Privacy
