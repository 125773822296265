import React, {useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { useTranslation } from "react-i18next";
import DefaultLayout from '../../layouts/DefaultLayout';
import {useSearchParams, useLocation } from 'react-router-dom';
import {Container, Row, Col, Form, Button, Collapse} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro';
import {checkReprice, getHtlDtlsStaticData} from "../../store/actions/hotel";
import { format } from 'date-fns';
import HotelFareBreakup from '../../components/hotel/FareBreakup';
import EmailSection from '../../components/traveller/EmailSection';
import PaymentSection from '../../components/traveller/PaymentSection';
import { ToastContainer } from 'react-toastify';

const HotelItinerary = () => {
    const dispatch = useDispatch();
    const location = useLocation(); 
    const [searchparams] = useSearchParams();
    const qry = Object.fromEntries([...searchparams])
    const {t} = useTranslation();

    useEffect(()=> {
    if(location.state){
        dispatch(getHtlDtlsStaticData(location.state.htlBookNowObj.hId));
        dispatch(checkReprice(location.state, qry));
    }
    }, [dispatch]);

    const reviewObj = useSelector((state) => state.hotelRepriceData)
    const getCurrency = useSelector((state) => state.currency)

    const [travellerObj, setTravellerObj] = useState({
        email: "",
        password: "",
        title: "",
        fName: "",
        lName: "",
        mob: "",
        hvPasswrd: false,
        allowOffrs: true
    });

    const [errTravellerObj, setErrTravellerObj] = useState({
        email: "",
        title: "",
        fName: "",
        lName: "",
        mob: "",
    });

    const [openL, setOpenL] = useState(true);
    const [activeItem, setActiveItem] = useState('emailCol');

    const setActive = (menuItem) => {
        if(menuItem==="travellerCol"){
            if(travellerObj.email !=="" && travellerObj.mob !==""){
             setActiveItem(menuItem)
            }
            else{
                return false
            }
        }
        else{
            setActiveItem(menuItem)
        }
    }
    const isActive = (menuItem) => {
        return activeItem === menuItem
    }

    const travelOpen = (travObj) => {
        const travItems = {...travellerObj};
        travItems.email = travObj.email;
        travItems.mob = travObj.phone;
        setTravellerObj(travItems)
        setActiveItem("travellerCol")
    }

    const titleChange = (e) => {
        const travItems = {...travellerObj};
        travItems.title = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.title === ""){
            errTravItems.title = 'Please Select Title'
        }
        else{
            errTravItems.title = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    }

    const fNameChange = (e) => {
        const travItems = {...travellerObj};
        travItems.fName = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.fName === ""){
            errTravItems.fName = 'Please Enter Your First Name'
        }
        else{
            errTravItems.fName = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    }

    const lNameChange = (e) => {
        const travItems = {...travellerObj};
        travItems.lName = e.target.value

        const errTravItems = {...errTravellerObj};
        if(travItems.lName === ""){
            errTravItems.lName = 'Please Enter Your Last Name'
        }
        else{
            errTravItems.lName = ''
        }
        setErrTravellerObj(errTravItems)
        setTravellerObj(travItems)
    }
    
    const travValidation = () => {
        const errTravItems = {...errTravellerObj};
        if(travellerObj.title === ""){
            errTravItems.title = 'Please Select Title'
        }
        if(travellerObj.fName === ""){
            errTravItems.fName = 'Please Enter Your First Name'
        }
        if(travellerObj.lName === ""){
            errTravItems.lName = 'Please Enter Your Last Name'
        }
        setErrTravellerObj(errTravItems)

        if(travellerObj.title && travellerObj.fName && travellerObj.lName){
            return true;
        }
        else{
            return false;
        }
    }

    const [srvCode, setSrvCode] = useState(null);

    const payFortSubmit = () => {
        let valid = travValidation()
        if(valid){
            setSrvCode('1')
            //setActiveItem("paymentCol")
        }
        else{
            setSrvCode(null)
            return false; 
        }
    }

    const capitalizeFirst = str => {
        let titleCase = str
        .toLowerCase()
        .split(' ')
        .map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
        return titleCase;
      };

    const paymentGateway = process.env.REACT_APP_PAYMENT_GATEWAY
    
  return (
    <>
    <ToastContainer />
    <DefaultLayout>
        <div className="innermiddle mt-4">
        {reviewObj && reviewObj.htlRepriceObj ?
            <Container>
                <Row>
                    <Col className="mb-2" lg={{span: 8, order: 1}} xs={{order: 2}}>
                        <div className="bg-white rounded shadow-sm shadow-lg-none">
                            <Row className="mb-1">
                                <Col>
                                    <div className="d-sm-flex flex-row">
                                        <div className="hotelImg rounded-start noView d-none d-sm-block">
                                            {reviewObj.htlRepriceObj.imgUrl ? 
                                            <img src={`https://static.giinfotech.ae/media${reviewObj.htlRepriceObj.imgUrl}`} alt={reviewObj.htlRepriceObj.name} />
                                            :
                                            <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                                            } 
                                        </div>
                                        <div className="ps-sm-3 pe-1 pt-2 w-100 arhotelItenDetail">
                                            <h3 className="nametitle blue">{reviewObj.htlRepriceObj.name}</h3>
                                            <div className="mb-1">
                                                <span className="boxRating">
                                                    {
                                                        Array.apply(null, { length:parseInt(reviewObj.htlRepriceObj.rating)}).map((e, i) => (
                                                        <FontAwesomeIcon key={i} icon={solid('star')} className="stargold" />
                                                        ))
                                                    }
                                                </span> &nbsp;&nbsp;
                                                { !(reviewObj.htlRepriceObj.tripAdvisorRating.toString().indexOf('.') > -1) ?
                                                    <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${reviewObj.htlRepriceObj.tripAdvisorRating}.0-13387-4.png`} alt="tripadvisor" />
                                                    :
                                                    <img style={{maxWidth:"100px"}} src={`http://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/${reviewObj.htlRepriceObj.tripAdvisorRating}-13387-4.png`} alt="tripadvisor" />
                                                }
                                            </div>
                                            <div className="text-muted li21 mb-2">
                                                {reviewObj.htlRepriceObj.address &&
                                                <>
                                                {reviewObj.htlRepriceObj.address.line1 && reviewObj.htlRepriceObj.address.line1} {reviewObj.htlRepriceObj.address.line2 && reviewObj.htlRepriceObj.address.line2}, {reviewObj.htlRepriceObj.address.cityName && reviewObj.htlRepriceObj.address.cityName}
                                                </>
                                                }
                                            </div>
                                            
                                            {reviewObj.htlRepriceObj.hotel.rooms &&
                                            <>
                                            {reviewObj.htlRepriceObj.hotel.rooms.room.map((v, i) => (
                                               <div key={i}>
                                                    <div className="nametitle fn16">{capitalizeFirst(v.roomName)}</div>
                                                    <div className="nametitle fn16"><strong>{reviewObj.htlRepriceObj.selectedRmDtls.nights} {t("Nights")}: </strong> {format(new Date(reviewObj.htlRepriceObj.selectedRmDtls.chkIn), 'dd/MM/yyyy')} - {format(new Date(reviewObj.htlRepriceObj.selectedRmDtls.chkOut), 'dd/MM/yyyy')}</div>
                                                    <div className="mb-1 fn15 blue"><strong>Room {i+1} Details:</strong></div>
                                                    {v.policies &&
                                                    <div>
                                                        {v.policies.policy.map((k, i) => (
                                                            <div key={i}>
                                                                <div className="mb-1 lh-base">
                                                                    <strong>{t("CancellationPolicy")}:</strong>
                                                                    {k.condition.map((q, i) => (
                                                                    <div key={i}>{format(new Date(q.fromDate), 'dd/MM/yyyy')} to {format(new Date(q.toDate), 'dd/MM/yyyy')} charge is 
                                                                        {q.percentage && q.percentage !== '0' ?
                                                                        <> {q.percentage}% </>
                                                                        :
                                                                        <> {getCurrency.currency} {parseFloat(q.fixed).toFixed(2)} </>
                                                                        }
                                                                    </div>
                                                                    ))
                                                                    }
                                                                </div>

                                                                <div className="mb-1 lh-base"><strong>{t("Note")}:</strong> {k.textCondition}</div>
                                                                
                                                                <div className="mb-1 lh-base">
                                                                    <strong>{t("Remarks")}:</strong>
                                                                    {k.condition.map((n, i) => (
                                                                    <div key={i}>{n.applicableOn}</div>
                                                                    ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                        }
                                                    </div>
                                                    }
                                                </div> 
                                            )) }
                                            </>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <div className="px-lg-3 pb-md-3 pt-1">
                                <h2 className="fn24 blue">
                                    {paymentGateway !== '4' ? t("BookSimpleSteps3")  : t("BookSimpleSteps2") }
                                </h2>
                            </div>
                            <div className="nav nav-tabs nav-justified stepNav">
                                <Button onClick={() => setActive('emailCol')} variant="link" className={"nav-link " + (isActive('travellerCol') || isActive('paymentCol') ? 'active' : '')}>
                                    <span className="stepTxt">
                                        <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                        &nbsp;{t("EmailAddress")}
                                    </span>
                                </Button>
                                <Button onClick={() => setActive('travellerCol')} variant="link" className={"nav-link " + (!isActive('travellerCol') && !isActive('paymentCol') ? 'disabled' : '' || isActive('paymentCol') ? 'active':'')}>
                                    <span className="stepTxt">
                                        <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                        &nbsp;{t("TravellersInfo")}
                                    </span>
                                </Button>
                                {paymentGateway !== '4' && 
                                <Button onClick={() => setActive('paymentCol')} variant="link" className={"nav-link " + (!isActive('paymentCol') ? 'disabled' : '')}>
                                    <span className="stepTxt">
                                        <FontAwesomeIcon icon={regular('check-circle')} className="stepTick" />
                                        &nbsp;{t("Payment")}
                                    </span>
                                </Button>
                                }
                            </div>
                            <div className="tab-content">
                                <div className={"tab-pane fade " + (isActive('emailCol') && 'active show')}>
                                    <EmailSection toggleTrav={travelOpen} />
                                </div>
                                <div className={"tab-pane fade " + (isActive('travellerCol') && 'active show')}>
                                    <div className="pt-lg-4 px-lg-4 p-0">
                                        <Row className="mb-3 gx-2">
                                            <Form.Label className="col-md-2 align-self-center text-sm-end mb-lg-3"><strong>{t("NameOfGuest")}<sup className="text-danger">*</sup></strong></Form.Label>
                                            <Col md={10}>
                                                <Row className="gx-2">
                                                    <Col sm={2} className="mb-3">
                                                        <Form.Select className='mb-1' value={travellerObj.title} onChange={(e) => titleChange(e)}>
                                                            <option value="">--Title--</option>
                                                            <option value="Mr">Mr</option>
                                                            <option value="Miss">Miss</option>
                                                            <option value="Mrs">Mrs</option>
                                                        </Form.Select>
                                                        <div className="text-danger fn12">{errTravellerObj.title}</div>
                                                    </Col>
                                                    <Col sm={5} className="mb-3">
                                                        <Form.Control className={'mb-1 ' + (errTravellerObj.fName && 'is-invalid')} value={travellerObj.fName} onChange={(e) => fNameChange(e)} placeholder={t('FirstName')} />
                                                        <div className="text-danger fn12">{errTravellerObj.fName}</div>
                                                    </Col>
                                                    <Col sm={5} className="mb-3">
                                                        <Form.Control className={'mb-1 ' + (errTravellerObj.lName && 'is-invalid')} value={travellerObj.lName} onChange={(e) => lNameChange(e)} placeholder={t('LastName')} />
                                                        <div className="text-danger fn12">{errTravellerObj.lName}</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    {paymentGateway === '4' ?
                                    <div className="text-end resFixedBtn"><Button onClick={()=>payFortSubmit()} variant="success" size="lg" className="rounded-0">&nbsp; Pay {getCurrency.currency} {parseFloat(reviewObj.htlRepriceObj.hotel.rooms.net).toFixed(2)} &nbsp;</Button></div>
                                    : 
                                    <div className="text-end resFixedBtn"><Button variant="success" size="lg" className="rounded-0">&nbsp;{t("Continue")} &nbsp; <FontAwesomeIcon icon={solid('long-arrow-alt-right')} className="align-middle fn18" /></Button></div>
                                    }
                                </div>
                                <div className={"tab-pane fade " + (isActive('paymentCol') && 'active show')}>
                                    <PaymentSection travObj={travellerObj} bookingObj={reviewObj} srvCodeObj={srvCode} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <HotelFareBreakup />
                </Row>
            </Container>
           :
           <Container>
                <Row>
                    <Col lg={{span:8, order: 1}} xs={{order: 2}} className="mb-2">
                        <div className="bg-white rounded shadow-sm shadow-lg-none">
                            <div className="d-sm-flex flex-row">
                                <div className="hotelImg rounded-start noView d-none d-sm-block">
                                    <img src={require('../../assets/images/hotels/no-image-available-hotel.jpg')} alt="No image available" />
                                </div>
                                <div className="ps-sm-3 pe-1 pt-2 w-100 arhotelItenDetail">
                                    <h3 className="nametitle blue"><div className="dummyDiv" style={{Width:'70%', Height:'27px'}}></div></h3>
                                    <div className="dummyDiv" style={{Width:'45%', Height:'20px'}}></div>
                                    <div>
                                        <div className="dumwave">
                                            <div className="anim anim1"></div>
                                            <div className="anim anim2"></div>
                                            <div className="anim anim3"></div>
                                            <div className="anim anim4"></div>
                                            <div className="anim anim5"></div>
                                        </div>
                                    </div>
                                    <div className="dummyDiv" style={{Width:'30%', Height:'20px'}}></div>
                                </div>
                            </div>  
                        </div>
                    </Col>
                    <Col lg={{span:4, order:2}} xs={{order:1}} className="mb-2">
                        <div className="bg-white rounded shadow-sm sticky-top shadow-lg-none">
                            <h3 className="nametitle blue plr10 pt-3 pb-1 plrlg0">{t("FareBreakup")} <Button size="sm" variant="link" className="fn14 text-warning d-inlineblock d-lg-none" onClick={() => setOpenL(!openL)}> (Show Details) </Button></h3>
                            <Collapse in={openL}>
                                <div>
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td>{t("BasePriceRoom")}</td>
                                                <td className="text-end">
                                                    <div className="dumwave">
                                                        <div className="anim anim1"></div>
                                                        <div className="anim anim2"></div>
                                                        <div className="anim anim3"></div>
                                                        <div className="anim anim4"></div>
                                                        <div className="anim anim5"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className="table mb-0">
                                        <tbody>
                                            <tr>
                                                <td>{t("Tax")} </td>
                                                <td className="text-end">
                                                    <div className="dumwave">
                                                        <div className="anim anim1"></div>
                                                        <div className="anim anim2"></div>
                                                        <div className="anim anim3"></div>
                                                        <div className="anim anim4"></div>
                                                        <div className="anim anim5"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Collapse>
                            <table className="table mb-0">
                                <tbody>
                                    <tr className="bg-light">
                                        <td><strong>{t("TotalAmount")}</strong></td>
                                        <td className="text-end">
                                            <div className="dumwave">
                                                <div className="anim anim1"></div>
                                                <div className="anim anim2"></div>
                                                <div className="anim anim3"></div>
                                                <div className="anim anim4"></div>
                                                <div className="anim anim5"></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            </Container>
            }
            <p>&nbsp;</p>
        </div>       
    </DefaultLayout>
    </>
  )
}

export default HotelItinerary
