import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import {getExchangeRates,getSelectedCur, getActiveLang} from "./store/actions/common";
import {useSelector, useDispatch } from 'react-redux';
import i18next from "i18next";

import ScrollToTop from './ScrollToTop'

import Hotel from './pages/landing/Hotel';
import HotelListing from './pages/hotel/HotelListing';
import HotelDetails from './pages/hotel/HotelDetails';
import HotelItinerary from './pages/hotel/HotelItinerary';
import PaymentOrder from './pages/payment/PaymentOrder';
import PaymentReceipt from './pages/payment/PaymentReceipt';
import BookingConfirmation from './pages/booking/BookingConfirmation';
import Privacy from './pages/static/Privacy';
import Tour from './pages/landing/Tour';
import TourListing from './pages/tour/TourListing';
import TourDetails from './pages/tour/TourDetails';
import TourItinerary from './pages/tour/TourItinerary';
import Transfer from './pages/landing/Transfer';
import TransferListing from './pages/transfer/TransferListing';
import TransferItinerary from './pages/transfer/TransferItinerary';

function App() {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getExchangeRates());
    if (!window.localStorage.getItem('selectedCur')) {
      window.localStorage.setItem('selectedCur', process.env.REACT_APP_CURRENCY)
      let obj = {
        'curValue': process.env.REACT_APP_CURRENCY,
      } 
      dispatch(getSelectedCur(obj));
    }
    else{
      let obj = {
        'curValue': window.localStorage.getItem('selectedCur'),
      } 
      dispatch(getSelectedCur(obj));
    }

    if (!window.localStorage.getItem('currentLang')) {
      window.localStorage.setItem('currentLang', process.env.REACT_APP_LANGUAGE)
      i18next.changeLanguage(process.env.REACT_APP_LANGUAGE);
      dispatch(getActiveLang(process.env.REACT_APP_LANGUAGE));
    }
    else{
      i18next.changeLanguage(window.localStorage.getItem('currentLang'));
      dispatch(getActiveLang(window.localStorage.getItem('currentLang')));
    }   
  },[dispatch]);

  const getLanguage = useSelector((state) => state.language)
  if(getLanguage.language==='ar'){
    document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
    document.getElementsByTagName('html')[0].setAttribute("lang", "ar");
    document.body.className = 'arClass'
  }
  else{
    document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
    document.getElementsByTagName('html')[0].setAttribute("lang", "en");
    document.body.className = 'enClass'
  }

  return (
    <>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Hotel />} />
        <Route path="/hotelListing" element={<HotelListing />} />
        <Route path="/hotelDetails" element={<HotelDetails />} />
        <Route path="/hotelItinerary" element={<HotelItinerary />} />
        <Route path="/paymentOrder" element={<PaymentOrder />} />
        <Route path="/paymentReceipt" element={<PaymentReceipt />} />
        <Route path="/bookingConfirmation" element={<BookingConfirmation />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tour" element={<Tour />} />
        <Route path="/tourListing" element={<TourListing />} />
        <Route path="/tourDetails" element={<TourDetails />} />
        <Route path="/tourItinerary" element={<TourItinerary />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/transferListing" element={<TransferListing />} />
        <Route path="/transferItinerary" element={<TransferItinerary />} />
      </Routes>
  </BrowserRouter>
  </>
  );
}

export default App;
