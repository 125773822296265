import {TransferService} from "../../services/transfer.service";
import {UtilityService} from "../../services/utility.service";
import {
    RESET_TRNSFR_RES, SET_TRNSFRRES, SET_OGTRNSFRRES, 
    RESET_TRNSFR_FILTER, SET_TRNSFRFILTER, SET_TRNSFRFILTERSORT
    } from "../types/transferTypes";
import store from '../store';
import cloneDeep from 'lodash/cloneDeep';
const _ = require("lodash");

export const doLocalTransferLocations = (req) => {
    return async () => {
        try {
            const response = await TransferService.getLocalTransferLocations(req);
            return response
        } catch (err) {
            console.log("Local Transfer Locations Error", err);
            return false
        }
    }
}

export const doTransferSearchOnLoad = (reqObj) => {
    return async (dispatch) => {
        try {
            dispatch({ type: RESET_TRNSFR_RES})
            dispatch({ type: RESET_TRNSFR_FILTER})
            const response = await TransferService.doTransferSearch(reqObj);
            if(response.transfers && response.transfers !==''){
                for (var i = 0; i < response.transfers.transfer.length; i++) {
                    response.transfers.transfer[i].vehicleDetails.vehicleDetail = response.transfers.transfer[i].vehicleDetails.vehicleDetail.slice().sort((a, b) => a.rate.supplierTotalNet - b.rate.supplierTotalNet) 
                }
                response.transfers.transfer = response.transfers.transfer.slice().sort((a, b) => parseFloat(a.vehicleDetails.vehicleDetail[0].rate.supplierTotalNet) - parseFloat(b.vehicleDetails.vehicleDetail[0].rate.supplierTotalNet)) 
            }
            let rootCurrency =  store.getState().currency.currency
            let rootExchangeRate =  store.getState().exchangeRate.exchangerates
            if (rootCurrency !== process.env.REACT_APP_CURRENCY) {
                UtilityService.transferConvBaseToSelectedCur(response, rootCurrency, rootExchangeRate)
            }
            dispatch({ type: SET_OGTRNSFRRES, payload: response })
            dispatch({ type: SET_TRNSFRRES, payload: response })
        } catch (err) {
            console.log("Transfer Listing Error", err);
        }
    }
}


export const doFilterSort = (res, obj) => {
    let transferResult = cloneDeep(res)
    _.forEach(transferResult.transfers.transfer, (val,ind) => {
        if(val.vehicleDetails.vehicleDetail.length > 0){
            const fltrResPrkk = val.vehicleDetails.vehicleDetail.filter((o) => {
                let status = []
                if (obj.transferFilters.priceFilter.length > 0) {
                    status.push(o.rate.supplierTotalNet >= obj.transferFilters.priceFilter[0] && o.rate.supplierTotalNet <= obj.transferFilters.priceFilter[1])
                }
                let statusVar = status.includes(false)
                return !statusVar
            });

            if(fltrResPrkk.length > 0){
                transferResult.transfers.transfer[ind].vehicleDetails.vehicleDetail = fltrResPrkk
            }
            else{
                transferResult.transfers.transfer[ind] = null
            }

            const fltrResVtkk = fltrResPrkk.filter((o) => {
                let status = []
                if (obj.transferFilters.typeVehicle.length > 0) {
                    status.push(obj.transferFilters.typeVehicle.includes(o.vehicle.code))
                } 
                let statusVar = status.includes(false)
                return !statusVar
            });
            if(fltrResVtkk.length > 0){
                transferResult.transfers.transfer[ind].vehicleDetails.vehicleDetail = fltrResVtkk
            }
            else{
                transferResult.transfers.transfer[ind] = null
            }
        }
    })
    let fltrResPr = transferResult.transfers.transfer

    if(obj.transferFilterSort.srtVal !== '0'){
        switch (obj.transferFilterSort.srtVal) {
            case 'plth':
                fltrResPr.sort((a, b) => parseFloat(a.vehicleDetails.vehicleDetail[0].rate.supplierTotalNet) - parseFloat(b.vehicleDetails.vehicleDetail[0].rate.supplierTotalNet))
                break
            case 'phtl':
                fltrResPr.sort((a, b) => parseFloat(b.vehicleDetails.vehicleDetail[0].rate.supplierTotalNet) - parseFloat(a.vehicleDetails.vehicleDetail[0].rate.supplierTotalNet))
                break
        }
    }

    if (obj.transferFilterSort.srchTxt !== '') {
        const srtTxtRes = fltrResPr.filter((pdt) => {
            return pdt.name.toLowerCase().includes(obj.transferFilterSort.srchTxt.toLowerCase())
          })
          transferResult.transfers.transfer = srtTxtRes
        // fltrResPr = fltrResPr.filter(pdt => {
        //     return pdt.name.toLowerCase().includes(srchTxt.toLowerCase())
        // })
        // isSearchFilter = true
    }
    else{
        transferResult.transfers.transfer = fltrResPr
    }
    return async (dispatch) => {
        try {
            dispatch({ type: SET_TRNSFRFILTER, payload: obj.transferFilters })
            dispatch({ type: SET_TRNSFRFILTERSORT, payload: obj.transferFilterSort })
            dispatch({ type: SET_TRNSFRRES, payload: transferResult })
        }  catch (err) {
            console.log("Transfer Filter Error", err);
        }
    }
}

export const doTransferCheckTime = (req) => {
    return async () => {
        try {
            const response = await TransferService.doCheckTime(req);
            return response
        } catch (err) {
            console.log("Transfer Check Time Error", err);
            return false
        }
    }
}