import React from "react"
import Header from "../components/header/Header"
import Footer from "../components/footer/Footer"
class LandingLayout extends React.Component {
  render(){
    return (
      <>
        <div className="landing"><Header /></div>
        <main>{this.props.children}</main>
        <Footer />
      </>
    )
  }
}
export default LandingLayout;