import React, {useState } from "react";
import {Link} from 'react-router-dom';
import {Container, Row, Col, Navbar, Nav, Button, Modal} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import LandingLayout from '../../layouts/LandingLayout';
import TourModifySearch from '../../components/tour/ModifySearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {solid} from '@fortawesome/fontawesome-svg-core/import.macro';
import imgKZN1 from '../../assets/images/imgKZN1.png';
import imgKZN2 from '../../assets/images/imgKZN2.png';
import imgKZN3 from '../../assets/images/imgKZN3.png';
import imgKZN4 from '../../assets/images/imgKZN4.png';
import imgKZN5 from '../../assets/images/imgKZN5.png';
import imgKZN6 from '../../assets/images/imgKZN6.png';
import imgRouKZN1 from '../../assets/images/imgRouKZN1.jpg';
import imgRouKZN2 from '../../assets/images/imgRouKZN2.jpg';
import imgRouKZN3 from '../../assets/images/imgRouKZN3.jpg';
import imgRouKZN4 from '../../assets/images/imgRouKZN4.jpg';
import imgRouKZN5 from '../../assets/images/imgRouKZN5.jpg';
import imgRouKZN6 from '../../assets/images/imgRouKZN6.jpg';
import imgRouKZN7 from '../../assets/images/imgRouKZN7.jpg';
import userDefault from '../../assets/images/userDefault.jpg';
import kznHotel from '../../assets/images/kznHotel.png';
import kznTransfer from '../../assets/images/kznTransfer.png';
import kznService from '../../assets/images/kznService.png';
import kznCal from '../../assets/images/kznCal.png';
import introKZN from '../../assets/images/video/introKZN.mp4';

const Tour = () => {
  const { t} = useTranslation();
  const [vdoModal, setVdoModal] = useState(false);
  return (
    <>
    <LandingLayout>
      <div className="middle" id="Home">
          <div className="searchPanel">
              <Container>
                  <h1 className="title mt-3 mb-1">{t('YourSaudiStory')}<br/>
                      <span className="fn16 align-top">{t('LetustailoryouruniqueSaudiArabiaexperience')}</span>
                  </h1>
                  <Navbar expand="md" className="searchNav">
                      <Navbar.Toggle  aria-controls="navbarNav" variant="warning" className="hmNavBtn">{t('Tours')} <FontAwesomeIcon icon={solid('bars')} /></Navbar.Toggle >
                      <Navbar.Collapse id="navbarNav">
                          <Nav>
                              <Nav.Item as="li">
                                <Link to="/" className="nav-link">
                                  <i className="menuicon bg-apartment"></i><br /> {t('Hotels')}
                                </Link>
                              </Nav.Item>
                              <Nav.Item as="li">
                                <Link to="/tour" className="nav-link active">
                                    <i className="menuicon bg-tour_excursion"></i><br /> {t('Tours')}
                                </Link>
                              </Nav.Item>
                              <Nav.Item as="li">
                                <Link to="/transfer" className="nav-link">
                                    <i className="menuicon bg-transfer"></i><br /> {t('Transfer')}
                                </Link>
                              </Nav.Item>
                          </Nav>
                      </Navbar.Collapse>
                  </Navbar>
                  <div>
                    <TourModifySearch Type={'landing'} TourReq={''} />
                </div>
              </Container>
          </div>
          
          <section>
            <Container>
                <Row className="justify-content-center py-4">
                    <Col md={5}>
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <h3 className="text-center fn24 li33 mt-4">Your travel needs, on one Platform</h3>
                                <Row>
                                    <Col sm={6}>
                                        <div className="m-2 my-4 text-center">
                                            <p className="mb-2"><img src={kznHotel} alt="Best Hotels" /></p>
                                            <h4 className="fn16 green">Best Hotels</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="m-2 my-4 text-center">
                                            <p className="mb-2"><img src={kznTransfer} alt="Transfers" /></p>
                                            <h4 className="fn16 green">Transfers</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="m-2 my-4 text-center">
                                            <p className="mb-2"><img src={kznService} alt="Best Services" /></p>
                                            <h4 className="fn16 green">Best Services</h4>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div className="m-2 my-4 text-center">
                                            <p className="mb-2"><img src={kznCal} alt="Support" /></p>
                                            <h4 className="fn16 green">24x7 Support</h4>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={7} className="position-relative">
                        <video autoPlay muted style={{borderRadius:'10px',width:'100%'}}>
                            <source src={introKZN} type="video/mp4" />
                        </video>
                        <Button type="button" className="btnPlayVdo" onClick={()=> setVdoModal(true)}>
                        <svg version="1.1" id="Capa_1" xmlns="https://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 58 58" width="50" height="50" fill="#32a953">
                            <circle cx="29" cy="29" r="29"></circle>
                            <g><polygon style={{fill:'#FFFFFF'}} points="44,29 22,44 22,29.273 22,14"></polygon>
                            <path style={{fill:'#FFFFFF'}} d="M22,45c-0.16,0-0.321-0.038-0.467-0.116C21.205,44.711,21,44.371,21,44V14
                              c0-0.371,0.205-0.711,0.533-0.884c0.328-0.174,0.724-0.15,1.031,0.058l22,15C44.836,28.36,45,28.669,45,29s-0.164,0.64-0.437,0.826
                              l-22,15C22.394,44.941,22.197,45,22,45z M23,15.893v26.215L42.225,29L23,15.893z"></path></g>
                            </svg>
                        </Button>
                    </Col>
                </Row>
            </Container>
           </section>

          <section className="topDestination">
              <Container>
                  <div className="text-center fn16">
                      <h2 className="fn33 green fw-bold text-uppercase">{t('Saudidestinations')}</h2>
                      <p>{t('DiscoverKingdomneverbefore')}</p>
                  </div>
                  <Row className="justify-content-center py-4">
                      <Col lg={10}>
                          <Row>
                              <Col md={4}>
                                  <div className="text-center fn15">
                                      <div className="p-3"><img src={imgKZN1} alt="" /></div>
                                      <h5 className="fn16 text-uppercase"><strong>{t('BrideRedSea')}</strong></h5>
                                      <p className="fw-light">{t('BrideRedSeaTEXT')}</p>
                                  </div>
                              </Col>
                              <Col md="4">
                                  <div className="text-center fn15">
                                      <div className="p-3"><img src={imgKZN2} alt="" /></div>
                                      <h5 className="fn16 text-uppercase"><strong>{t('TheFascinatingHub')}</strong></h5>
                                      <p className="fw-light">{t('TheFascinatingHubTEXT')}</p>
                                  </div>
                              </Col>
                              <Col md={4}>
                                  <div className="text-center fn15">
                                      <div className="p-3"><img src={imgKZN3} alt="" /></div>
                                      <h5 className="fn16 text-uppercase"><strong>{t('TheLandWonders')}</strong></h5>
                                      <p className="fw-light">{t('TheLandWondersTEXT')}</p>
                                  </div>
                              </Col>
                              <Col md={4}>
                                  <div className="text-center fn15">
                                      <div className="p-3"><img src={imgKZN4} alt="" /></div>
                                      <h5 className="fn16 text-uppercase"><strong>{t('TheCityRoseBride')}</strong></h5>
                                      <p className="fw-light">{t('TheCityRoseBrideTEXT')}</p>
                                  </div>
                              </Col>
                              <Col md={4}>
                                  <div className="text-center fn15">
                                      <div className="p-3"><img src={imgKZN5} alt="" /></div>
                                      <h5 className="fn16 text-uppercase"><strong>{t('Arabiashighestland')}</strong></h5>
                                      <p className="fw-light">{t('AbhaContent')}</p>
                                  </div>
                              </Col>
                              <Col md={4}>
                                  <div className="text-center fn15">
                                      <div className="p-3"><img src={imgKZN6} alt="" /></div>
                                      <h5 className="fn16 text-uppercase"><strong>{t('ThePearlArabianGulf')}</strong></h5>
                                      <p className="fw-light">{t('ThePearlArabianGulfTEXT')}</p>
                                  </div>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
              </Container>
          </section>

          <section className="hmbg1" id="Services">
              <Container className="position-relative">
                  <Row className="justify-content-center py-4">
                      <Col lg={10}>
                          <Row>
                              <Col md={6}>
                                  <h4 className="fn33 green fw-bold mb-5 text-uppercase">{t("Ourservices")}</h4>
                                  <p className="lead">{t("OurservicesTEXT")}</p>
                              </Col>
                              <Col md={6} className="text-center">
                                  <div className="mb-3"><img src={imgRouKZN5} alt="" className="rounded-circle" /></div>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
                  <Row className="justify-content-center py-4 text-secondary">
                      <h3 className="green fw-bold mb-4 text-center">{t("Weknowhow")}</h3>
                      <Col lg={10}>
                          <Row>
                              <Col lg={3} md={6}>
                                  <div className="text-center">
                                      <div className="mb-3 p-2"><img src={imgRouKZN1} alt="" className="rounded-circle" /></div>
                                      <h4 className="fn20 green text-uppercase"><strong>{t("AirTravel")}</strong></h4>
                                      <p className="fw-light fn16">{t("AirTravelTEXT")}</p>
                                  </div>
                              </Col>
                              <Col lg={3} md={6}>
                                  <div className="text-center">
                                      <div className="mb-3 p-2"><img src={imgRouKZN2} alt="" className="rounded-circle" /></div>
                                      <h4 className="fn20 green text-uppercase"><strong>{t("Accommodation")}</strong></h4>
                                      <p className="fw-light fn16">{t("AccommodationTEXT")}</p>
                                  </div>
                              </Col>
                              <Col lg={3} md={6}>
                                  <div className="text-center">
                                      <div className="mb-3 p-2"><img src={imgRouKZN3} alt="" className="rounded-circle" /></div>
                                      <h4 className="fn20 green text-uppercase"><strong>{t("GroundTours")}</strong></h4>
                                      <p className="fw-light fn16">{t("GroundToursTEXT")}</p>
                                  </div>
                              </Col>
                              <Col lg={3} md={6}>
                                  <div className="text-center">
                                      <div className="mb-3 p-2"><img src={imgRouKZN4} alt="" className="rounded-circle" /></div>
                                      <h4 className="fn20 green text-uppercase"><strong>{t("SpecialServices")}</strong></h4>
                                      <p className="fw-light fn16">{t("SpecialServicesTEXT")}</p>
                                  </div>
                              </Col>
                          </Row>
                      </Col>
                  </Row>
              </Container>
          </section>

        <section className="hmbgAbt" id="About">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <Row className="align-items-center">
                            <Col md={6} className="zIndex1">
                                <h2 className="fn33 green fw-bold text-uppercase">{t("AboutKIZAN")}</h2>
                                <p className="lead">{t("AboutKIZAN1")}</p>
                                <p className="lead">{t("AboutKIZAN2")}</p>
                                <p><Button variant="warning" className="px-4 py-2 text-uppercase"><strong>{t("LearnMore")}</strong></Button></p>
                            </Col>
                            <Col md={6} className="text-end zIndex1">
                                <img src={imgRouKZN7} className="rounded-circle" alt="" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
       
        <section className="bg-white pt-5">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <div className="text-center fn16">
                            <h2 className="fn33 green fw-bold text-uppercase">{t("Testimonials")}</h2>
                            <p>{t("Becauseyouropinion")}</p>
                        </div>
                        <Row className="py-4">
                            <Col lg={4} md={6} className="my-3">
                                <div className="bggray p-4 h-100">
                                    <div className="p-3">
                                        <div className="mb-3 green text-center fn15">
                                            <FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} />
                                        </div>
                                        <p className="lead text-secondary">{t("Testi1TEXT")}</p>
                                        <div className="d-flex">
                                            <img src={userDefault} className="align-self-center me-3 rounded-circle" alt="" width="60" />
                                            <div className="align-self-center">
                                                <h5 className="fn18 m-0"><strong>{t("Testi1")}</strong></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="my-3">
                                <div className="bggray p-4 h-100">
                                    <div className="p-3">
                                        <div className="mb-3 green text-center fn15">
                                        <FontAwesomeIcon icon={solid('star')} /><FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} />
                                        </div>
                                        <p className="lead text-secondary">{t("Testi2TEXT")}</p>
                                        <div className="d-flex">
                                            <img src={userDefault} className="align-self-center me-3 rounded-circle" alt="" width="60" />
                                            <div className="align-self-center">
                                                <h5 className="fn18 m-0"><strong>{t("Testi2")}</strong></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={6} className="my-3">
                                <div className="bggray p-4 h-100">
                                    <div className="p-3">
                                        <div className="mb-3 green text-center fn15">
                                            <FontAwesomeIcon icon={solid('star')} /><FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} /> <FontAwesomeIcon icon={solid('star')} />
                                        </div>
                                        <p className="lead text-secondary">{t("Testi3TEXT")}</p>
                                        <div className="d-flex">
                                            <img src={userDefault}  className="align-self-center me-3 rounded-circle" alt="" width="60" />
                                            <div className="align-self-center">
                                                <h5 className="fn18 m-0"><strong>{t("Testi3")}</strong></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="waveAnim">
            <svg xmlns="http://www.w3.org/2000/svg" width="1380px" height="810px" viewBox="0 -8.992806499463768e-14 1380 810" preserveAspectRatio="xMidYMid meet">
                <rect id="svgEditorBackground" x="0" y="0" width="1380" height="810" className="svg1"></rect>
                <defs id="svgEditorDefs">
                    <polygon id="svgEditorShapeDefs" className="svg2"></polygon>
                    <path id="svgEditorClosePathDefs" className="svg3"></path>
                </defs>
                <path d="M15083.791600683318,645.6720847341486c496.6000000000058,747.7999999999951,1953.3704016668416,1862.6734725832403,2751.999999999978,1967.9999999999955s1884.300000000003,-198.89999999999964,2718.7056474818382,-552.6365690040448s1746.4898225846773,-519.9524548776521,2593.2943525181545,-567.3634309959566s2251,-23.399999999999636,4384,1520.0000000000032s3205.199999999997,1437.2000000000025,4047.9999999999854,1520.0000000000018s3305.000000000029,-270.2999999999993,4640.000000000029,-1152s74.60000000000582,3328.3000000000065,32,3792.0000000000073s-19509.4,4591.000000000004,-21087.999999999993,-128.00000000000182s-1679.5999999999913,-6358.300000000001,-80,-6400Z" className="svg4" id="e12_areaS3" transform="matrix(0.0650006 -0.00123765 0.00123765 0.0650006 -995.543 317.589)"></path>
                <path d="M12434.469938292996,-979.2324603008726c496.600000000004,747.8000000000001,1953.3704016668398,1862.673472583247,2752.000000000018,1967.9999999999995s1884.2999999999865,-198.90000000000055,2718.7056474818364,-552.6365690040473s1746.4898225846846,-519.9524548776479,2593.2943525181618,-567.3634309959524s2251,-23.400000000001455,4384,1520s3205.199999999997,1437.1999999999962,4048,1519.9999999999955s3304.9999999999927,-270.3000000000011,4640,-1151.9999999999955s74.60000000000582,3328.3,32,3791.9999999999955s-19509.400000000023,4590.999999999997,-21088.000000000015,-127.99999999999818s-1679.5999999999985,-6358.300000000008,-80,-6400.0000000000055Z" className="svg5" id="e19_areaS3" transform="matrix(0.0647268 0.00608646 -0.00608646 0.0647268 -823.261 331.266)"></path>
            </svg>
        </section>
        <section className="hmbgGreen">
            <Container>
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <Row>
                            <Col md={6} className="fn18">
                                <h2 className="fn33 fw-bold text-uppercase">{t("ContactCustomerService")}</h2>
                                &nbsp;
                                <p>{t("ContactCustomerServiceTEXT1")}</p>
                                <p>{t("ContactCustomerServiceTEXT2")}</p>
                               &nbsp;
                                <p><a href="#" className="btn btn-light btn-lg rounded-30"><strong className="text-secondary text-uppercase">&nbsp; {t("SpeakSpecialist")} &nbsp;</strong></a></p>
                            </Col>
                            <Col md={6} className="text-center">
                                <img src={imgRouKZN6} alt="" className="rounded-circle" />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
      </div>
    </LandingLayout>

    <Modal show={vdoModal} onHide={() => setVdoModal(false)} size="xl" centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <video controls preload="none" style={{width:'100%'}}>
                <source src={introKZN} type="video/mp4" />
            </video>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default Tour
