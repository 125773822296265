import {BookingService} from "../../services/booking.service";

export const getBookingDtls = (req) => {
    return async () => {
        try {
            const response = await BookingService.getBookingDtls(req);
            return response
        } catch (err) {
            console.log("BookingConfirmation Error", err);
            return false
        }
    }
}