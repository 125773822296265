import { 
  SET_HOTEL_PREBOOK_RES
} from "../types/paymentTypes";
  
const intialState = {
  preBookingObj: null,
};

export const preBookingReducer = (state = intialState.preBookingObj, { type, payload }) => {
  switch (type) {
    case SET_HOTEL_PREBOOK_RES:
      return { ...state, preBookingObj: payload };
    default:
      return state;
  }
};