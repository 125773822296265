//import http from "./http-common";
import ApiService from './api.service'

const CommonDataService = {
  // getAll() {
  //   return http.get("/api/Common/GetExchangeRate/ALL");
  // },

  getExchangeRatesData: async function () {
    const requestData = {
        method: 'get',
        url: 'api/Common/GetExchangeRate/ALL',
    }
    try {
        const response = await ApiService.customPromiseRequest(requestData)
        return response.data
    } catch (error) {
    }
  },
}
export default CommonDataService
export { CommonDataService }
//export default new CommonDataService();