import React, { useState, useRef } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import axios from 'axios'
import { Link, useNavigate, createSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Container, Row, Col, Button, Dropdown, Form, Collapse, ButtonGroup} from 'react-bootstrap';
import {solid, regular} from '@fortawesome/fontawesome-svg-core/import.macro'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker, { registerLocale } from "react-datepicker";
import {doHotelSearchOnLoad} from "../../store/actions/hotel";
import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'
import en from "date-fns/locale/en-US";
import ar from "date-fns/locale/ar-SA";
registerLocale("en", en);
registerLocale("ar", ar);


const HotelModifySearch = ({Type, HtlReq, sendMap, filterOpen }) => {
  const dispatch = useDispatch();
  const { t} = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(HtlReq !== '' ? [{
      cityName:HtlReq.SearchParameter.selectedCity, 
      countryCode: HtlReq.SearchParameter.CountryCode, 
      countryName:HtlReq.SearchParameter.selectedCountry,
      giDestinationId: HtlReq.SearchParameter.DestinationCode
  }]:[]);
  
  const handleSearch = async (query) => {
    setIsLoading(true);
    setOptions([]);
    const res = await axios.post(process.env.REACT_APP_DESTINATION_PREDICTIONS, {
      "text": query,
      "customercode": "KZN"
    })
    setOptions(res.data.data);
    setIsLoading(false);
  };
  const filterBy = () => true;
  const getLanguage = useSelector((state) => state.language)
  const [selectedDestination, setSelectedDestination] = useState(options);

  const [chkIn, setChkIn] = useState(HtlReq !== '' ? new Date(HtlReq.SearchParameter.CheckInDate) : new Date());
  const [chkOut, setChkOut] = useState(HtlReq !== '' ? new Date(HtlReq.SearchParameter.CheckOutDate) : new Date(new Date().setDate(new Date().getDate() + 1)));

  const endRef = useRef();
  const disableTo = () => {
    setChkOut(new Date(new Date(chkIn).setDate(new Date(chkIn).getDate() + 1)))
    endRef.current.setOpen(true);    
  };

  const [rmCountArr, setRmCountArr] = useState(HtlReq !== '' ? HtlReq.SearchParameter.paxInfoArr : [
    {
      idAdt: 'adt0',
      idChd: 'chd0',
      idDelBtn: 'delRoom0',
      adtVal: '2',
      chdVal: '0',
      chdAgesArr: [{
          idchdAges: 'chdAges0',
          chdAgeVal: '0',
          disabled: true
      },
      {
          idchdAges: 'chdAges1',
          chdAgeVal: '0',
          disabled: true
      },
      {
          idchdAges: 'chdAges2',
          chdAgeVal: '0',
          disabled: true
      },
      {
          idchdAges: 'chdAges3',
          chdAgeVal: '0',
          disabled: true
      }]
    }
  ]
  )

  const PaxDropdown = () => {
  
    const adtltChange = (e,rmCntIndx) =>{
      const items = [...rmCountArr];
      let item = {...items[rmCntIndx]};
      item.adtVal = e.target.value;
      items[rmCntIndx] = item;
      setRmCountArr(items);
    }
    
    const chdAgeChange = (e,cai,rmCntIndx) =>{
      const items = [...rmCountArr];
      let item = {...items[rmCntIndx]};
      item.chdAgesArr[cai].chdAgeVal = e.target.value;
      setRmCountArr(items);
    }
    
    const enaDisChdAges = (evt, rmCntIndx) => {
      const items = [...rmCountArr];
      let item = {...items[rmCntIndx]};
      item.chdVal = evt.target.value;
    
      for (var k = 0; k <= 3; k++) {
        if (k < item.chdVal) {
          item.chdAgesArr[k].disabled = false
        }
        else{
          item.chdAgesArr[k].disabled = true
          item.chdAgesArr[k].chdAgeVal = '0'
        }
      }
      items[rmCntIndx] = item;
      setRmCountArr(items);
    }
    
    const addRoom = () =>{
      if(rmCountArr.length <= 2){
        let items = [...rmCountArr];
        items.push({
          idAdt: 'adt'+rmCountArr.length,
          idChd: 'chd'+rmCountArr.length,
          idDelBtn: 'delRoom'+rmCountArr.length,
          adtVal: '2',
          chdVal: '0',
          chdAgesArr: [{
              idchdAges: 'chdAges0',
              chdAgeVal: '0',
              disabled: true
          },
          {
              idchdAges: 'chdAges1',
              chdAgeVal: '0',
              disabled: true
          },
          {
              idchdAges: 'chdAges2',
              chdAgeVal: '0',
              disabled: true
          },
          {
              idchdAges: 'chdAges3',
              chdAgeVal: '0',
              disabled: true
          }]
        });
      setRmCountArr(items);
      }
      else{
        toast.error("Sorry..Maximum Room Limit Exceeds.",{theme: "colored"});
      }
    }
    
    const delRoom = (eIndex) => {  
      let array = [...rmCountArr];
      let removeFromIndex = []
      removeFromIndex.push(eIndex)
      for (var i = removeFromIndex.length - 1; i >= 0; i--) {
        array.splice(removeFromIndex[i], 1);
      }
      array.map(function (v, key) {
          v.idAdt = `adt${key}`
          v.idChd = `chd${key}`
          v.idDelBtn = `delRoom${key}`
      })
      setRmCountArr(array);
    }
    
    return(
      <>
        <Dropdown align='end' autoClose={false} show={showPaxDropdown}>
          <Dropdown.Toggle className="w-100 p-0 h-0 invisible"></Dropdown.Toggle>
          <Dropdown.Menu className="fn14 dropArrow px-2" flip={false}>
            {rmCountArr.map((rmCntVal, rmCntIndx) => ( 
              <div key={rmCntIndx}>
                <p className="mb-1 blue"><strong>{t("Room")} {rmCntIndx + 1 }</strong></p>
                {/* kk - {rmCountArr[rmCntIndx].adtVal} */}
                <Row className="gx-2">
                  <Col xs={6} sm={3} className="mb-2">
                      <label>&nbsp; {t("adult")}</label>
                      <Form.Select size="sm" value={rmCountArr[rmCntIndx].adtVal} onChange={(e) => adtltChange(e, rmCntIndx)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </Form.Select>
                  </Col>
                  <Col xs={6} sm={2} className="mb-2">
                      <label>&nbsp;{t("childern")}</label>
                      <Form.Select size="sm" value={rmCountArr[rmCntIndx].chdVal} onChange={(e)=>enaDisChdAges(e, rmCntIndx)}>
                        <option value="0">-</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </Form.Select>
                  </Col>
                  <Col sm={6}>
                      <label>&nbsp;{t("ageChildern")}</label>
                      <Row className="gx-1">
                        {rmCntVal.chdAgesArr.map((cav, cai) => ( 
                          <Col xs={6} sm={3} className="mb-2" key={cai}>
                            <Form.Select size="sm" value={cav.chdAgeVal} onChange={(e)=>chdAgeChange(e, cai, rmCntIndx)} disabled={cav.disabled}>
                              <option value="0">-</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                            </Form.Select>
                          </Col>
                        ))}  
                      </Row>
                  </Col>
                  <Col sm={1}>
                      <label className="d-none d-sm-block mb-2">&nbsp;</label>
                      {rmCountArr[rmCntIndx].idDelBtn !=='delRoom0' ?
                        <Link className="text-danger fn18" onClick={()=>delRoom(rmCntIndx)}><FontAwesomeIcon icon={regular('circle-xmark')} className="mt-1" /></Link>
                      : null }
                    </Col>
                </Row>
                <Dropdown.Divider />
              </div>
            ))}  
            
            <Row className="gx-2 justify-content-between">
              {rmCountArr.length <= 2 ?
                <Col xs="auto"><Button variant="link" size="sm" onClick={()=>addRoom()}>{t("addRoom")}</Button></Col>
              : null }
              <Col xs="auto">
                <Button variant="primary" size="sm" onClick={()=>doneClick()}>{t("done")}</Button>
              </Col>
            </Row>
          </Dropdown.Menu>
        </Dropdown> 
      </>
    )
  }

  const totalGuest = () => {
    let array = [...rmCountArr];
    let guest = 0
    array.forEach((v) => {
      guest = guest + parseInt(v.adtVal) + parseInt(v.chdVal)
    })
    return guest
  }

  const [showPaxDropdown, setShowPaxDropdown] = useState(false);

  const doneClick = () => {
    let status = validateRoomDtls()
    if (status){
      setShowPaxDropdown(false)
    }                 
  }

  const validateRoomDtls = () => {
    let status = true
    let errorMsg = ''
    let array = [...rmCountArr];
    array.forEach((v, i) => {
      if (v.adtVal && v.chdVal) {
        let totalPaxCount = parseInt(v.adtVal) + parseInt(v.chdVal)
        if (totalPaxCount > 9) {
            status = false
            errorMsg = "Sorry, No of Guest Allowed Not More Than 9 In Room " + Number(i + 1)
            toast.error(errorMsg,{theme: "colored"});
            return false
        }
      }
      if (parseInt(v.chdVal) > 0) {
        for (var k = 0; k < v.chdVal; k++) {
          status = v.chdAgesArr[k].chdAgeVal > 0
          if (!status) {
              errorMsg = "Please Select Child Age In Room " + Number(i + 1)
              toast.error(errorMsg,{theme: "colored"});
              return false
          }
        }
      }
    })
    return status
  }
  
  const validate = () => {
    let status = true
    let roomValidate = validateRoomDtls()
    if (roomValidate) {
      //let destiCode = (selectedDestination !== null ? selectedDestination.giDestinationId : this.getHtlReq != null && this.getHtlReq.SearchParameter.DestinationCode ? this.getHtlReq.SearchParameter.DestinationCode : '')
      //let selectedCity = (selectedDestination !== null ? selectedDestination.cityName : this.getHtlReq != null && this.getHtlReq.SearchParameter.selectedCity ? this.getHtlReq.SearchParameter.selectedCity : '')
      let destiCode = (selectedDestination.length ? selectedDestination.giDestinationId : '')
      let selectedCity = (selectedDestination.length ? selectedDestination.cityName : '')
      if (destiCode === '' || selectedCity === '') {
          status = false
          toast.error("Please Select The Destination!",{theme: "colored"})
          return false
      }

      if (chkIn === '' || chkIn === null) {
          status = false
          toast.error("Please Select Check-In Date",{theme: "colored"})
          return false
      }

      if (chkOut === '' || chkOut === null) {
          status = false
          toast.error("Please Select Check-Out Date",{theme: "colored"})
          return false
      }
    }
    return status
  }

  const srchHtl = () => {
    let allowMe = validate()
    if (allowMe) {
      let qry = {
        city: selectedDestination.length ? selectedDestination[0].cityName : HtlReq.SearchParameter.selectedCity,
        state: '',
        country: selectedDestination.length ? selectedDestination[0].countryCode : HtlReq.SearchParameter.CountryCode,
        countryName: selectedDestination.length ? selectedDestination[0].countryName : HtlReq.SearchParameter.selectedCountry,
        area: '',
        hotelId: '',
        hotelName: '',
        SearchTag: '',
        dest_code: selectedDestination.length ? selectedDestination[0].giDestinationId : HtlReq.SearchParameter.DestinationCode,
        chk_in: format(chkIn, 'yyyy-MM-dd'),
        chk_out: format(chkOut, 'yyyy-MM-dd'),
        adults1: rmCountArr[0].adtVal,
        children1: rmCountArr[0].chdVal,
        adults2: rmCountArr[1] !== undefined ? rmCountArr[1].adtVal : 0,
        children2: rmCountArr[1] !== undefined ? rmCountArr[1].chdVal : 0,
        adults3: rmCountArr[2] !== undefined ? rmCountArr[2].adtVal : 0,
        children3: rmCountArr[2] !== undefined ? rmCountArr[2].chdVal : 0,
        ca1: 0,
        ca2: 0,
        ca3: 0,
        num_rooms: parseInt(rmCountArr.length),
        AffID: 0,
        PID: 0,
        sessionId: 0,
        uniqueId: 0
      }
      let indx = 0
      rmCountArr.forEach((v) => {
        indx = indx + 1
        qry['ca' + indx] = v.chdAgesArr.map(function (e) {
            return e.chdAgeVal;
        }).join(",")
      })
    
      if (Type === 'result') {
        let htlSrchObj = {
          "CustomerCode": "",
          "SearchParameter": {
            "DestinationCode": selectedDestination[0] !== null ? selectedDestination[0].giDestinationId : HtlReq.SearchParameter.DestinationCode,
            "CountryCode": selectedDestination[0] !== null ? selectedDestination[0].countryCode : HtlReq.SearchParameter.CountryCode,
            "selectedCity": selectedDestination[0] !== null ? selectedDestination[0].cityName : HtlReq.SearchParameter.selectedCity,
            "selectedCountry": selectedDestination[0] !== null ? selectedDestination[0].countryName : HtlReq.SearchParameter.selectedCountry,
            "CheckInDate": format(chkIn, 'yyyy-MM-dd'),
            "CheckOutDate": format(chkOut, 'yyyy-MM-dd'),
            "Currency": process.env.REACT_APP_CURRENCY,
            "Nationality": "QATARI",
            "paxInfoArr": rmCountArr
          }
        }
        navigate({
          pathname: "/hotelListing",
          search: createSearchParams(qry).toString(),
        })
        dispatch(doHotelSearchOnLoad(htlSrchObj));
      }
      else{
        navigate({
          pathname: "/hotelListing",
          search: createSearchParams(qry).toString(),
        })
      }
    }
  }
  const [modifyCollapse, setModifyCollapse] = useState(false);
  const mapViewBtn = () => {
    sendMap({Modal:true})
  }
  
 
  return(
    <>
    <ToastContainer />
    {Type === 'landing' ?
      <div className="searchColumn">
        <Row className="gx-2">
            <Col lg={10}>
                <Row className="gx-2">
                    <Col lg={6}>
                        <div className="form-group mb-4">
                            <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} /> {t("Entrycitynamelocationspecifichotel")}</label>
                            <AsyncTypeahead clearButton
                             highlightOnlyResult={true}
                              filterBy={filterBy}
                              id="async-example"
                              isLoading={isLoading}
                              labelKey={(option) => `${option.cityName}, ${option.countryName}`}
                              minLength={3}
                              onSearch={handleSearch}
                              options={options}
                              placeholder={t('pleaseEnterDestination')}
                              className="typeHeadDropdown"
                              selected={selectedDestination}
                              onChange={setSelectedDestination}
                              inputProps={{
                                className: 'hmform-control',
                              }}
                            />
                        </div>
                    </Col>
                    <Col lg={3}>
                        <div className="form-group mb-4">
                            <div className="fifthcol designCalendar">
                                <label className="text-white">&nbsp;<FontAwesomeIcon icon={solid('calendar-alt')} /> {t("Check-in")}</label>
                                <DatePicker className="form-control hmform-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={chkIn} minDate={new Date()} onChange={ (date) => setChkIn(date)} onCalendarClose={disableTo} />
                            </div>
                            <div className="fifthcol designCalendar">
                                <label className="text-white">&nbsp; <FontAwesomeIcon icon={solid('calendar-alt')} /> {t("Check-out")}</label>
                                <DatePicker className="form-control hmform-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={chkOut} minDate={new Date(new Date(chkIn).setDate(new Date(chkIn).getDate() + 1))} onChange={ (date) => setChkOut(date) } ref={endRef} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} className="passdropdown">
                        <div className="form-group mb-4">
                            <label className="text-white">&nbsp; {t("Rooms-Guest")}</label>
                            <Button variant="light" className="hmform-control w-100 text-start dropdown-toggle" onClick={() => setShowPaxDropdown(true)}>{totalGuest()} Guest in {rmCountArr.length} Room</Button>
                            <PaxDropdown />
                        </div>
                    </Col>
                </Row>

            </Col>
            <Col lg={2} className="searchBtn">
              <div className="mb-4">
                <label className="d-none d-lg-block">&nbsp;</label>
                <Button variant="warning" className="hmform-btn" onClick={()=>srchHtl()}><FontAwesomeIcon icon={solid('search')} className="fn16 mt-n1 align-middle" /> {t("Search")}</Button>
              </div>
            </Col>
        </Row>
      </div>
    : 
    <div className="modifycol">
      <Container> 
        {modifyCollapse ? 
        ''
        : 
        <>
          <div className="fn15 d-lg-flex justify-content-between align-items-center d-none">
            <div className="py-1">
                {selectedDestination[0].cityName}, {selectedDestination[0].countryName} &nbsp;|&nbsp; {format(chkIn, 'dd MMM yyyy')} to {format(chkOut, 'dd MMM yyyy')} &nbsp;|&nbsp; {totalGuest()} Guest(s)
            </div>
            <div className="py-2"><Button onClick={() => setModifyCollapse(!modifyCollapse)} aria-controls="modifyCollapse" variant="warning" className="fn13"><strong>{t("ModifySearch")}</strong></Button></div>
          </div>
          <div>
            <ButtonGroup size="sm" className="w-100 py-2 d-lg-none d-inline-flex">
              <Button variant="outline-secondary" onClick={() => mapViewBtn()}><FontAwesomeIcon icon={solid('map-marker-alt')} className="green" /> {t("MapView")}</Button>
              <Button variant="outline-secondary" onClick={() => filterOpen(true)}><FontAwesomeIcon icon={solid('filter')} className="green" /> {t("Filter")}</Button>
              <Button variant="outline-secondary" onClick={() => setModifyCollapse(!modifyCollapse)}><FontAwesomeIcon icon={solid('search')} className="green" /> {t("Search")}</Button>
            </ButtonGroup>
          </div>
        </>
        }
        <Collapse in={modifyCollapse}>
          <div className="position-relative pt-3 pb-2">
            <Button variant="link" onClick={() => setModifyCollapse(!modifyCollapse)} className="crossBtn p-0"><FontAwesomeIcon icon={regular('times-circle')} className="text-dark" /></Button>
            <Button variant="link" onClick={() => setModifyCollapse(!modifyCollapse)} className="crossBtn1 d-none d-lg-block p-0"><FontAwesomeIcon icon={regular('times-circle')} className="text-dark" /></Button>
            <Row className="gx-2">
              <Col lg={5} md={6}>
                  <div className="my-2">
                      <label className="fn13">&nbsp;<FontAwesomeIcon icon={solid('map-marker-alt')} className="green" /> {t("Entrycitynamelocationspecifichotel")}</label>
                      <AsyncTypeahead clearButton
                        highlightOnlyResult={true}
                        filterBy={filterBy}
                        id="async-example"
                        isLoading={isLoading}
                        labelKey={(option) => `${option.cityName}, ${option.countryName}`}
                        minLength={3}
                        onSearch={handleSearch}
                        options={options}
                        placeholder={t('pleaseEnterDestination')}
                        className="typeHeadDropdown"
                        selected={selectedDestination}
                        onChange={setSelectedDestination}
                        inputProps={{
                          className: 'fn13',
                        }}
                      />
                  </div>
              </Col>
              <Col lg={3} md={6}>
                  <div className="my-2">
                      <div className="fifthcol designCalendar">
                          <label className="fn13">&nbsp;<FontAwesomeIcon icon={solid('calendar-alt')} className="green" /> {t("Check-in")}</label>
                          <DatePicker className="fn13 form-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={chkIn} minDate={new Date()}   onChange={ (date) => setChkIn(date)} onCalendarClose={disableTo} />
                      </div>
                      <div className="fifthcol designCalendar">
                          <label className="fn13">&nbsp; <FontAwesomeIcon icon={solid('calendar-alt')} className="green" /> {t("Check-out")}</label>
                          <DatePicker className="fn13 form-control" locale={getLanguage.language} dateFormat="dd MMM yyyy" selected={chkOut} minDate={new Date(new Date(chkIn).setDate(new Date(chkIn).getDate() + 1))} onChange={ (date) => setChkOut(date) } ref={endRef} />
                      </div>
                  </div>
              </Col>
              <Col lg={3} md={12} className="passdropdown">
                  <div className="my-2">
                      <label className="fn13">&nbsp; {t("Rooms-Guest")}</label>
                      <Button variant="light" className="w-100 text-start dropdown-toggle bg-white border fn13 arroBtn" onClick={() => setShowPaxDropdown(true)}>{totalGuest()} Guest in {rmCountArr.length} Room</Button>
                      <PaxDropdown />
                  </div>
              </Col>
              <Col lg={1} sm={3}>
                <div className="my-2">
                  <label className="d-none d-md-block fn13">&nbsp;</label>
                  <Button variant="warning" className="w-100 fn13" onClick={()=>srchHtl()}>{t("Search")}</Button>
                </div>
              </Col>
            </Row> 
          </div>
        </Collapse>   
      </Container>
    </div>
    }
    </>
  );
}
export default HotelModifySearch;